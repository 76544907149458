import React from "react";
import "../Portada/style.css";

export const Portada = ({ className, Name, id }) => {
  return (
    <>
      <div className={className} id={id}>
        <div className="g-container">
          <h1 className="o-heading-1">{Name}</h1>
        </div>
      </div>
    </>
  );
};

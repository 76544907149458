import React from "react";
import { styled } from "@mui/styles";
import MuiGrid from "@mui/material/Grid";
import MuiInputLabel from "@mui/material/InputLabel";
import MuiDivider from "@mui/material/Divider";
import { Autocomplete } from "@mui/material";
import MuiTextField from "@mui/material/TextField";
import { CreateMotoContext } from "../../../../../contexts/CreateMotoContext";

const Grid = styled(MuiGrid)({
  display: "flex",
  marginTop: 50,
  marginBottom: 20,
  justifyContent: "center",
});

const InputLabel = styled(MuiInputLabel)({
  marginRight: 10,
  alignSelf: "center",
});

const Divider = styled(MuiDivider)({
  width: "100%",
});

const TextField = styled(MuiTextField)({
  width: "60%",
  marginRight: 10,
  "& > p": {
    fontSize: "1.2rem",
  },
});

const Aditionals = ({ arranque }) => {
  const defaultPropsArranque = {
    options: arranque,
    getOptionLabel: (option) => {
      if (option.hasOwnProperty("name")) {
        return option.name;
      }
      return option;
    },
  };

  const { values, onChange, onChangeArranque } =
    React.useContext(CreateMotoContext);

  return (
    <Grid container style={{ margin: 0 }}>
      <Grid item xs={12}>
        <InputLabel
          // style={{
          //   position: "relative",
          //   left: 120,
          //   width: "100%",
          // }}
          htmlFor="input-type-arranque"
        >
          Tipo de arranque
        </InputLabel>
        <Autocomplete
          id="input-type-arranque"
          name="id_arranque"
          noOptionsText="No se encuentran tipos de arranques"
          label="Seleccione tipo arranque"
          data={arranque}
          size="small"
          isOptionEqualToValue={(option, value) => option.name === value.name}
          {...defaultPropsArranque}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Seleccione tipo arranque"
              margin="normal"
              variant="outlined"
              // style={{
              //   minWidth: "731px",
              // }}
            />
          )}
          // style={{
          //   marginLeft: -552,
          // }}
          style={{
            display: "flex",
            justifyContent: "center",
            marginLeft: -9,
            width: "60%",
            marginRight: 10,
            "& > p": {
              fontSize: "1.2rem",
            },
          }}
          fullWidth
          onChange={onChangeArranque}
          value={values.id_arranque?.name}
        />
      </Grid>
      <Divider />
      <Grid item xs={12}>
        <InputLabel htmlFor="certification">Certificación</InputLabel>
        <TextField
          id="certification"
          name="certificacion"
          variant="outlined"
          // style={{
          //   marginLeft: 100,
          // }}
          maxLength={55}
          onChange={onChange}
          value={values.certificacion}
        />
      </Grid>
      <Divider />
    </Grid>
  );
};

export default Aditionals;

/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faCircleExclamation,
} from "@fortawesome/free-solid-svg-icons";
import CircularProgress from "@mui/material/CircularProgress";
import "./style.css";

export const SuccesRequest = ({ values, setValues, stateAplication }) => {
  const history = useHistory();

  return values.loading === true ? (
    <CircularProgress />
  ) : (
    <div className="c-form-3__finish" id="cotizacion-step-5">
      <div className="c-form-3__box">
        <div className="c-form-3__box-content">
          <div className="c-form-3_cont-check">
            <FontAwesomeIcon
              icon={stateAplication === true ? faCheck : faCircleExclamation}
              className="c-form-3_check"
            />
          </div>
          <h2 className="c-form-3__heading_finish">
            {stateAplication === true
              ? "¡Solicitud enviada con éxito!"
              : "Error en el envío de la solicitud"}
          </h2>
          <p className="c-form-3__p">
            {stateAplication === true
              ? "Un asesor se comunicará contigo en el menor tiempo posible."
              : "Discúlpanos por las molestias ocasionadas, por favor intentalo nuevamente."}
          </p>
        </div>
      </div>
      <div className="c-form-3__btns">
        <a
          className="o-btn-3 js-btn-enviar-cotizacion"
          onClick={() => {
            history.push("/financiamiento/");
            setValues(() => ({
              tap: 1,
              ip: "",
              marca_moto: "",
              nombre_moto: "",
              color_moto: "",
              precio_moto: "",
              abono_inicial: 0,
              discount: false,
              cupon: "",
              valor_cupon_descuento: 0,
              plazo: 24,
              cuota_min: 0,
              cuota_max: 0,
              cedula: "",
              nombres: "",
              apellidos: "",
              fecha_nacimiento: null,
              genero: "",
              estado_civil: "",
              personas_cargo: "",
              nivel_educacion: "",
              ciudad: "",
              tipo_vivienda: "",
              direccion: "",
              celular: "",
              email: "",
              ocupacion: "",
              nombre_empresa: "",
              direccion_empresa: "",
              ingresos: 0,
              terminos: false,
            }));
          }}
        >
          Enviar otra solicitud
        </a>
      </div>
    </div>
  );
};

import React from "react";
import { Portada } from "../components/molecules/Portada";
import { NavBarCatalogo } from "../components/molecules/NavBarCatalogo";
import Helmet from "react-helmet";
import "../css/CatalogoMotos.css";

export const CatalogoMoto = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Catálogos de motos - Supre</title>
        <meta name="description" content="Catálogo de motos Supre" />
      </Helmet>
      <Portada className="c-block-3" id="CatMotos" Name="Catálogos de motos" />
      <div className="c-block-6">
        <NavBarCatalogo />
      </div>
    </>
  );
};

import React, { createContext, useState } from 'react';

export const CreateMotoContext = createContext();

export const CreateMotoProvider = ({ children }) => {
  const [values, setValues] = useState({
    // * Seo Card
    seo_titulo: '',
    seo_descripcion: '',
    slug_moto: '',
    // * General Information Card
    nombre: '',
    descripcion_corta: '',
    modelo: '',
    id_marca: null,
    id_estilo: null,
    url_foto: '',
    foto: new File([''], ''),
    mostrar_ocultar: false,
    // * Prices Card
    precio_moto: 0,
    precio_papeles: 0,
    // * Details Card
    // * Tab Motor
    cilindraje: 0,
    tipo_motor: '',
    potencia_maxima_hp: 0,
    potencia_maxima_rpm: 0,
    torque_maximo_nm: 0,
    torque_maximo_rpm: 0,
    relacion_compresion: '',
    id_tipo_transmision: null,
    // * Tab Dimensiones
    rueda_delantera: '',
    rueda_trasera: '',
    dimension_total: '',
    distancia_entre_ejes: '',
    peso: 0,
    // * Tab Seguridad
    id_freno_delantero: null,
    id_freno_trasero: null,
    id_suspension_delantera: null,
    id_suspension_trasera: null,
    // * Tab Extras
    id_arranque: null,
    certificacion: '',
    // * 360 Photos Card
    photo360: new File([''], ''),
    url_fotos_360: '',
    maxFinancedValue: 0,
    canShowInOmnia: false,
  });

  const onChange = (e) => {
    setValues({
      ...values,
      [e.target.name]: e.target.value,
    });
  };

  const onChangeMarca = (e, data) => {
    setValues({
      ...values,
      id_marca: data,
    });
  };

  const onChangeEstilo = (e, data) => {
    setValues({
      ...values,
      id_estilo: data,
    });
  };

  const onChangeFoto = (e) => {
    setValues({
      ...values,
      foto: e.target.files[0],
    });
  };

  const onChangeCheck = (e) => {
    setValues({
      ...values,
      mostrar_ocultar: e.target.checked,
    });
  };

  const onChangeCheckOmnia = (e) => {
    setValues({
      ...values,
      canShowInOmnia: e.target.checked,
    });
  };

  const onChangeTransmision = (e, data) => {
    setValues({
      ...values,
      id_tipo_transmision: data,
    });
  };

  const onChangeFrenoDelantero = (e, data) => {
    setValues({
      ...values,
      id_freno_delantero: data,
    });
  };

  const onChangeFrenoTrasero = (e, data) => {
    setValues({
      ...values,
      id_freno_trasero: data,
    });
  };

  const onChangeSuspensionDelantera = (e, data) => {
    setValues({
      ...values,
      id_suspension_delantera: data,
    });
  };

  const onChangeSuspensionTrasera = (e, data) => {
    setValues({
      ...values,
      id_suspension_trasera: data,
    });
  };

  const onChangeArranque = (e, data) => {
    setValues({
      ...values,
      id_arranque: data,
    });
  };
  // save array of files
  const onChangePhoto360 = (e) => {
    const files = Array.from(e.target.files);
    setValues({
      ...values,
      photo360: files,
    });
  };

  return (
    <CreateMotoContext.Provider
      value={{
        values,
        setValues,
        onChange,
        onChangeMarca,
        onChangeEstilo,
        onChangeFoto,
        onChangeCheck,
        onChangeTransmision,
        onChangeFrenoDelantero,
        onChangeFrenoTrasero,
        onChangeSuspensionDelantera,
        onChangeSuspensionTrasera,
        onChangeArranque,
        onChangePhoto360,
        onChangeCheckOmnia,
      }}
    >
      {children}
    </CreateMotoContext.Provider>
  );
};

import React from "react";
import { Button, Grid, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

import "./style-related.css"

export const RelatedNewsCard = ({ id, shortImage, shortDescription }) => {
  return (
    <Grid
      item
      xs={12}
      className="card-related"
      classes={{ item: "news-card-root-related" }}>
      <div className="container-related">
        <div className="container-img">
          <img src={shortImage} alt="" className="image-related" />
        </div>
        <div className="secondContainer-related">
          <div ClassName="title-container">
            <Typography variant="h5" className="text-related">
              {shortDescription}
            </Typography>
          </div>
          <div className="button-container-related">
            <div></div>
            <div className="button-direction-related">
              <Link to={`/detail-blog/${id}`}>
                <Button
                  className="button-item-related"
                  variant="text"
                  endIcon={<ArrowForwardIcon />}>
                  Ver noticia
                </Button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </Grid>
  );
};

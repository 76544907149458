import { BrowserRouter as Router } from 'react-router-dom';
import { Routes } from './routes/Routes';
import { ThemeProvider, StyledEngineProvider } from "@mui/material/styles";
import theme from "./themeConfig";

function App() {
  return (
    <StyledEngineProvider injectFirst>
    <ThemeProvider theme={theme}>
    <Router>
      <Routes />
    </Router>
    </ThemeProvider>
    </StyledEngineProvider>
  );
}

export default App;

import React from "react";
import { NavBar } from "./NavBar/index.jsx";
import { BtnWap } from "./BtnWap/index.jsx";
import { BusquedaGuiadaModal } from "../molecules/BusquedaGuiadaModal";
import { ModalCar } from "../Organisms/ModalCar/index";

export const Master = ({ children }) => {
  return (
    <>
      <NavBar />
      <BusquedaGuiadaModal />
      <ModalCar />
      {children}
      <BtnWap />
    </>
  );
};

import React, { useState } from "react";
import { Link } from "react-router-dom";
import { BodyAffiliateLink } from "../../molecules/BodyAffiliateLink/BodyAffiliateLink";
import { DetailOrders } from "../../molecules/Orders/Orders";
import { DetailCreditApplications } from "../../molecules/DetailCreditApplication";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome, faAngleRight } from "@fortawesome/free-solid-svg-icons";
import clsx from "clsx";
import "./style.css";

export const BodyOrders = () => {
  const [tab, setTab] = useState(0);

  const handleTab = (value) => {
    setTab(value);
  };

  return (
    <>
      <ul className="c-nav-6--v2">
        <li className="icon-arrow-right3">
          <Link to="/" className="c-list4__compare-v3 icon-image-compare">
            <FontAwesomeIcon icon={faHome} />
          </Link>
        </li>
        <div className="icon-arrow">
          <FontAwesomeIcon icon={faAngleRight} />
        </div>
        <li className="icon-arrow-right3">
          <div className="c-list4__compare-fn icon-image-compare">
            Mis ordenes
          </div>
        </li>
      </ul>
      <div className="g-row-p">
        <div className="col-p-xl-3">
          <ul className="c-nav-7-p">
            <li>
              <div
                onClick={() => handleTab(0)}
                className={clsx("c-nav-lis-p", tab === 0 && "active")}
              >
                Solicitudes de crédito
              </div>
            </li>
            <li>
              <div
                onClick={() => handleTab(1)}
                className={clsx("c-nav-lis-p", tab === 1 && "active")}
              >
                Motos
              </div>
            </li>
            <li>
              <div
                onClick={() => handleTab(2)}
                className={clsx("c-nav-lis-p", tab === 2 && "active")}
              >
                Link de afiliado
              </div>
            </li>
          </ul>
        </div>
        <div className="col-p-xl-9">
          {tab === 0 ? (
            <DetailCreditApplications />
          ) : tab === 1 ? (
            <DetailOrders />
          ) : (
            <BodyAffiliateLink />
          )}
        </div>
      </div>
    </>
  );
};

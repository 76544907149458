import { apiAdminInstance } from "../Api/ApiInstance";
import { string_to_slug } from "../helpers/Strings";

export const CreateBackBrake = async (backBrake) => {
  let { nombre_freno_trasero } = backBrake;
  const slug = string_to_slug(nombre_freno_trasero);
  
  return await apiAdminInstance.post("api/official-site/backBrake/", {
    nombre_freno_trasero,
    slug,
    state: 1,
  });
};

export const ListBackBrakes = async () => {
  return await apiAdminInstance.get("api/official-site/backBrake/");
};

export const ListBackBrakesFilter = async () => {
  return await apiAdminInstance.get("api/official-site/backBrake/filter");
};

export const UpdateBackBrake = async (backBrake) => {
  const { id, nombre_freno_trasero, state } = backBrake;
  const slug = nombre_freno_trasero ? string_to_slug(nombre_freno_trasero) : nombre_freno_trasero;

  return await apiAdminInstance.put("api/official-site/backBrake/" + id, {
    nombre_freno_trasero,
    slug,
    state,
  });
};

export const DeleteBackBrake = async (id) => {
  return await apiAdminInstance.delete("api/official-site/backBrake/" + id);
};

import { useEffect, useState } from "react";
import Helmet from "react-helmet";
import { Portada } from "../components/molecules/Portada/index.jsx";
import "../css/TyC.css";
import { AccordeonItem } from "../components/atoms/AccordeonItem/index";
import { ConditionsTerms } from "../data/Politics/politics.jsx";
import { useHistory } from "react-router-dom";
import { DataPrivacy } from "../data/Politics/data-privacity.jsx";
import { CentralRisk } from "../data/Politics/central-risk.jsx";
import { DataTreatment } from "../data/Politics/data-treatment.jsx";

export const Politicas = () => {
  let history = useHistory();
  const [panelNameOpen, setPanelNameOpen] = useState();

  useEffect(() => {
    history.location.pathname.split("/")[2] !== (undefined || "")
      ? setPanelNameOpen(history.location.pathname.split("/")[2])
      : setPanelNameOpen("terminos-y-condiciones");
  }, [history.location.pathname]);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Políticas - Supre</title>
        <meta name="description" content="Políticas" />
      </Helmet>
      <Portada className="c-block-3" Name="Políticas" />
      <div className="c-block-5">
        {panelNameOpen && (
          <div className="g-container-tyc">
            <AccordeonItem
              title="Política de privacidad de datos"
              panelNameOpen={panelNameOpen}
              panelName="privacidad-de-datos"
            >
              <DataPrivacy />
            </AccordeonItem>
            <AccordeonItem
              title="Autorización de consulta en centrales de riesgo"
              panelNameOpen={panelNameOpen}
              panelName="centrales-de-riesgo"
            >
              <CentralRisk />
            </AccordeonItem>
            <AccordeonItem
              title="Política de tratamiento de datos personales"
              panelNameOpen={panelNameOpen}
              panelName="tratamiento-de-datos"
            >
              <DataTreatment />
            </AccordeonItem>
            <AccordeonItem
              title="Términos y condiciones"
              panelNameOpen={panelNameOpen}
              panelName="terminos-y-condiciones"
            >
              <ConditionsTerms />
            </AccordeonItem>
          </div>
        )}
      </div>
    </>
  );
};

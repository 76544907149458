import React from "react";

export const Marca = ({ listas, filtrar }) => {
  return (
    <div className="c-modal-1__section">
      <div className="c-modal-1__heading">Marca</div>
      <div className="c-modal-1__item">
        <div className="c-list-6 js-scrollbar-marca">
          {listas.listasmarcas.map((item) => (
            <div className="c-list-6__item" key={item.id}>
              <div className="o-form-check">
                <input
                  className="o-form-check__campo--v2"
                  id={`,${item.id}`}
                  type="checkbox"
                  name="marca__check"
                  value={item.id}
                  onClick={filtrar}
                />
                <label htmlFor={`,${item.id}`}>
                  <span className="c-form-1__text-2">
                    {" "}
                    {item.nombre_marca}{" "}
                  </span>
                </label>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

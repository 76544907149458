/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from "react";
import { formatCurrency } from "../../../helpers/FormatMoney";
import { Coupons } from "../../../utils/Coupons";
import { PMT } from "../../../helpers/Financing";
import { parseInt } from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleExclamation } from "@fortawesome/free-solid-svg-icons";
import Financing from "../../../data/Financing.json";
import Box from "@mui/material/Box";
import Slider from "@mui/material/Slider";
import "./style.css";
//Financing information
export const CotizadorProduct = ({ product, setValues, values }) => {
  useEffect(() => {
    //Calculate financing rate
    let totalProduct =
      parseInt(product?.precio_moto || 0) +
      parseInt(product?.precio_papeles || 0) -
      parseInt(values.abono_inicial || 0) -
      parseInt(values.valor_cupon_descuento || 0);

    let valueFee = PMT(Financing.tasa, values.plazo, -totalProduct);

    setValues((prevState) => ({
      ...prevState,
      cuota_min: valueFee * Financing.porcentaje_cuota_min,
      cuota_max: valueFee * Financing.porcentaje_cuota_max,
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    values.plazo,
    values.valor_cupon_descuento,
    values.abono_inicial,
    product,
  ]);

  const handleChange = (name, value) => {
    setValues((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleChangeCoupon = (coupon) => {
    //Search for valid discount in database
    if (coupon !== "") {
      Coupons(coupon)
        .then((result) => {
          setValues((prevState) => ({
            ...prevState,
            cupon: result.data.codigo_cupon,
            valor_cupon_descuento: result.data.valor_descuento,
            discount: true,
          }));
        })
        .catch((err) => {
          setValues((prevState) => ({
            ...prevState,
            cupon: "",
            valor_cupon_descuento: 0,
            discount: true,
          }));
        });
    } else {
      setValues((prevState) => ({
        ...prevState,
        cupon: "",
        valor_cupon_descuento: 0,
        discount: false,
      }));
    }
  };

  const handleChangeAbono = () => {
    let abono = document
      .getElementById("abono_inicial")
      .value.replace(/\D/g, "")
      .replace(/\B(?=(\d{3})+(?!\d))/g, "");
    if (
      abono <
      parseInt(product?.precio_moto || 0) +
        parseInt(product?.precio_papeles || 0) -
        parseInt(values.valor_cupon_descuento || 0)
    ) {
      handleChange("abono_inicial", abono);
    }
  };

  const handleButton = () => {
    setValues((prevState) => ({
      ...prevState,
      tap: 2,
    }));
  };

  return (
    <div className="c-list__cotizador">
      <form id="form-cotizador">
        <div className="c-form-3__step o-is-hidden-2XX" id="cotizacion-step-1">
          <div className="c-card-4">
            <div className="c-card-4__col-1">
              <div className="c-form-1__field">
                <label className="o-form-label c-card-4__label-slider">
                  ¿A cuántos meses quieres pagar tu moto{" "}
                  <span className="br">{product?.nombre}</span>?
                </label>
                <div className="slider slider-horizontal">
                  <Box width={"100"}>
                    <Slider
                      aria-label="Always visible"
                      valueLabelDisplay="on"
                      step={1}
                      defaultValue={24}
                      min={3}
                      max={48}
                      onChange={(e) => {
                        handleChange("plazo", e.target.value);
                      }}
                    />
                  </Box>
                </div>
                <div className="c-card-4__labels-meses">
                  <span>3 meses</span>
                  <span>48 meses</span>
                </div>
              </div>
              <div className="c-form-1__field">
                <label className="o-form-label">
                  ¿Quieres realizar un abono inicial?
                  <a className="tooltip-info">
                    <i
                      className="icon-info2 c-card-4__icon-info"
                      aria-hidden="true"
                    />
                    <span
                      className="o-tooltip-top"
                      data-tooltip-top="La cuota inicial se solicitará de acuerdo al estudio de crédito."
                    >
                      <div>
                        El valor del abono inicial puede variar
                        dependiendo del estudio de crédito.
                      </div>
                    </span>
                  </a>
                </label>
                <input
                  className="o-form-field--text-center"
                  id="abono_inicial"
                  type="text"
                  placeholder={`Abono mínimo: ${formatCurrency(
                    "es-CO",
                    "COP",
                    0,
                    (parseInt(product?.precio_moto || 0) +
                      parseInt(product?.precio_papeles || 0)) *
                      0.15
                  )}`}
                  autoComplete="off"
                  value={
                    values.abono_inicial === 0 || values.abono_inicial === ""
                      ? ""
                      : formatCurrency("es-CO", "COP", 0, values.abono_inicial)
                  }
                  onChange={() => {
                    handleChangeAbono();
                  }}
                />
              </div>
              <div className="c-form-1__field-2">
                <label className="o-form-label">
                  ¿Tienes un cupón de descuento?
                </label>
                <input
                  className="o-form-field--text-center"
                  id="cupon-descuento"
                  type="currency"
                  name="cotizador__cupon"
                  placeholder="Ingresa el código del cupón"
                  autoComplete="off"
                  onBlur={() => {
                    handleChangeCoupon(
                      document.getElementById("cupon-descuento").value
                    );
                  }}
                />
                <div className="c-card-4__cupon-ok js-notify-cupon-ok">
                  {values.discount === true
                    ? values.valor_cupon_descuento > 0
                      ? `Se aplicó un descuento de ${formatCurrency(
                          "es-CO",
                          "COP",
                          2,
                          values.valor_cupon_descuento
                        )}`
                      : "Código no valido"
                    : ""}
                </div>
              </div>
            </div>
            <div className="c-card-4__col-2">
              <div className="c-card-4__box">
                <div className="c-card-4__heading-1">
                  El valor de tu cuota mensual está entre
                </div>
                <div className="c-card-4__cuota">
                  <div className="c-card-4__price">
                    <span id="valor-cuota-minima">
                      {formatCurrency(
                        "es-CO",
                        "COP",
                        0,
                        values.cuota_min < 1 ? 0 : values.cuota_min
                      )}
                    </span>
                  </div>
                  <div className="c-card-4__and">y</div>
                  <div className="c-card-4__price">
                    <span id="valor-cuota-maxima">
                      {formatCurrency(
                        "es-CO",
                        "COP",
                        0,
                        values.cuota_max < 1 ? 0 : values.cuota_max
                      )}
                    </span>
                  </div>
                </div>
                <div className="c-card-4__btn">
                  <button
                    className="o-btn-3 js-btn-coti-next"
                    data-step="#cotizacion-step-2"
                    onClick={() => handleButton()}
                    disabled={
                      (values.cuota_min > 0) &
                      (values.abono_inicial >=
                        (parseInt(product?.precio_moto || 0) +
                          parseInt(product?.precio_papeles || 0)) *
                          0.15)
                        ? false
                        : true
                    }
                  >
                    Solicitar crédito
                  </button>
                </div>
                {product === undefined ||
                values.abono_inicial <
                  (parseInt(product?.precio_moto || 0) +
                    parseInt(product?.precio_papeles || 0)) *
                    0.15 ? (
                  <div className="c-card-4__exclamation">
                    <FontAwesomeIcon
                      className="icon-sc-exclamation"
                      icon={faCircleExclamation}
                    />{" "}
                    {product === undefined
                      ? "Debes seleccionar un producto a financiar"
                      : "El abono inicial debe ser superior al 15% del valor de la moto"}
                  </div>
                ) : (
                  <div></div>
                )}
                <div className="c-card-4__text-1">
                  {`El valor de los documentos es de ${formatCurrency(
                    "es-CO",
                    "COP",
                    2,
                    product?.precio_papeles ? product?.precio_papeles : 0
                  )} y está incluido en la cuota mensual.`}
                  <a className="tooltip-info">
                    <i
                      className="icon-info2 c-card-4__icon-info"
                      aria-hidden="true"
                    />
                    <span
                      className="o-tooltip-top"
                      data-tooltip-top="La cuota inicial se solicitará de acuerdo al estudio de crédito."
                    >
                      <div>
                        El valor de los documentos puede estar sujeto a cambios
                      </div>
                    </span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

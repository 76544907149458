import React from "react";

export const DimensionesTable = ({ motos, listallmotos }) => {
  return (
    <>
      <div className="c-table-1__header-2">Dimensiones</div>
      <div className="c-table-1__box-2">
        {[0, 1, 2].map((element) => {
          if ((motos[element] !== undefined) & (listallmotos.length > 0)) {
            //eslint-disable-next-line
            let moto1 = listallmotos.find((moto) => moto.id == motos[element]);
            if (moto1 !== undefined) {
              return (
                <div className="c-table-1__item-2" key={element}>
                  <div className="c-table-1__title">Rueda delantera</div>
                  <div className="c-table-1__value">
                    {moto1?.rueda_delantera}
                  </div>
                </div>
              );
            }
          }
          return (
            <div className="c-table-1__item-2" key={element}>
              <div className="c-table-1__title">
                <div className="c-table-1__vacio-1"></div>
              </div>
              <div className="c-table-1__value">
                <div className="c-table-1__vacio-2"></div>
              </div>
            </div>
          );
        })}
        {[0, 1, 2].map((element) => {
          if ((motos[element] !== undefined) & (listallmotos.length > 0)) {
            //eslint-disable-next-line
            let moto1 = listallmotos.find((moto) => moto.id == motos[element]);

            if (moto1 !== undefined) {
              return (
                <div className="c-table-1__item-2" key={element}>
                  <div className="c-table-1__title">Rueda trasera</div>
                  <div className="c-table-1__value">{moto1?.rueda_trasera}</div>
                </div>
              );
            }
          }
          return (
            <div className="c-table-1__item-2" key={element}>
              <div className="c-table-1__title">
                <div className="c-table-1__vacio-1"></div>
              </div>
              <div className="c-table-1__value">
                <div className="c-table-1__vacio-2"></div>
              </div>
            </div>
          );
        })}
        {[0, 1, 2].map((element) => {
          if ((motos[element] !== undefined) & (listallmotos.length > 0)) {
            //eslint-disable-next-line
            let moto1 = listallmotos.find((moto) => moto.id == motos[element]);

            if (moto1 !== undefined) {
              return (
                <div className="c-table-1__item-2" key={element}>
                  <div className="c-table-1__title">Dimensión total</div>
                  <div className="c-table-1__value">
                    {moto1?.dimension_total}
                  </div>
                </div>
              );
            }
          }
          return (
            <div className="c-table-1__item-2" key={element}>
              <div className="c-table-1__title">
                <div className="c-table-1__vacio-1"></div>
              </div>
              <div className="c-table-1__value">
                <div className="c-table-1__vacio-2"></div>
              </div>
            </div>
          );
        })}
        {[0, 1, 2].map((element) => {
          if ((motos[element] !== undefined) & (listallmotos.length > 0)) {
            //eslint-disable-next-line
            let moto1 = listallmotos.find((moto) => moto.id == motos[element]);

            if (moto1 !== undefined) {
              return (
                <div className="c-table-1__item-2" key={element}>
                  <div className="c-table-1__title">Dimensión entre ejes</div>
                  <div className="c-table-1__value">
                    {moto1?.distancia_entre_ejes}
                  </div>
                </div>
              );
            }
          }
          return (
            <div className="c-table-1__item-2" key={element}>
              <div className="c-table-1__title">
                <div className="c-table-1__vacio-1"></div>
              </div>
              <div className="c-table-1__value">
                <div className="c-table-1__vacio-2"></div>
              </div>
            </div>
          );
        })}
        {[0, 1, 2].map((element) => {
          if ((motos[element] !== undefined) & (listallmotos.length > 0)) {
            //eslint-disable-next-line
            let moto1 = listallmotos.find((moto) => moto.id == motos[element]);

            if (moto1 !== undefined) {
              return (
                <div className="c-table-1__item-2" key={element}>
                  <div className="c-table-1__title">Peso</div>
                  <div className="c-table-1__value">{`${moto1?.peso} kg`}</div>
                </div>
              );
            }
          }
          return (
            <div className="c-table-1__item-2" key={element}>
              <div className="c-table-1__title">
                <div className="c-table-1__vacio-1"></div>
              </div>
              <div className="c-table-1__value">
                <div className="c-table-1__vacio-2"></div>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};

import React from "react";
import { useChangeBackground } from "../hooks/useChangeBackground";
import useVerifyMenu from "../hooks/useVerifyMenu";
import { Helmet } from "react-helmet";
import { useHistory } from "react-router-dom";
import AdminMui from "../components/Masters/AdminMui";

const Admin = () => {
  const back = useVerifyMenu();
  useChangeBackground("#f5f5f8");
  const history = useHistory();

  return !back ? (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Admin index - Supre</title>
        <meta name="description" content="Pagina principal admin supre" />
      </Helmet>
      <AdminMui />
    </>
  ) : (
    <>{history.push("/admin/login")}</>
  );
};

export default Admin;

import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Portada } from "../components/molecules/Portada";
import { BodyDetailOrder } from "../components/Organisms/Profile/BodyDetailOrder";
import { GetOrderDetail } from "../utils/Order";
import "../css/Orders.css";

export const OrderDetail = () => {
  let params = useParams();
  const [order, setOrder] = useState();
  const [load, setLoad] = useState(true);

  useEffect(() => {
    let idOrder = params.order;
    if (idOrder !== "") {
      GetOrderDetail(idOrder)
        .then((result) => {
          setOrder(result.data[0]);
          setLoad(false);
        })
        .catch((err) => setLoad(false));
    }
  }, [params]);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Detalle orden - Supre</title>
        <meta name="description" content="Detalle ordenes Supre" />
      </Helmet>
      <Portada className="c-block-3" id="My orders" Name="Detalle orden" />
      <div className="c-block-5-p--v2">
        <div className="g-container-p">
          {order !== undefined ? (
            <>
              <BodyDetailOrder order={order} />
            </>
          ) : load === true ? (
            <div className="menu-mobile-overlay">
              <div className="loader-popup">
                <div className="loader__box">
                  <div className="icon-spinner9 animate-spin"></div>
                </div>
              </div>
            </div>
          ) : (
            <>
              <div className="c-block-5__heading-6">Error en la busqueda</div>
              <p className="c-block-5__pc">
                {
                  "Lo sentimos hemos tenido problemas al cargar esta orden, por favor vuelve a tus "
                }
                <a className="o-link-2 js-step-registro-usuario" href="/perfil">
                  ordenes
                </a>
                {" e ingresa nuevamente."}
              </p>
            </>
          )}
        </div>
      </div>
    </>
  );
};

import { useEffect } from "react";
import { Helmet } from "react-helmet";
import { Button, Divider, Grid, Typography } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import { Link, useParams } from "react-router-dom";
import { RelatedNewsCard } from "../components/atoms/RelatedNewsCard";
import { Portada } from "../components/molecules/Portada";
import newsCard from "../components/atoms/cards-news/news";

import "../css/DetailBlog.css";

export const DetailBlog = () => {
  const { id } = useParams(); // Obtiene el valor de "id" de la URL
  const newsItem = newsCard.find((item) => item.id === id);

  // Filtra las noticias con el mismo tipo pero con un ID diferente
  const newsWithSameType = newsCard.filter((item) => item.id !== id);

  // Toma hasta un máximo de 3 noticias del mismo tipo o menos si no hay suficientes
  const relatedNews = newsWithSameType.slice(0, 3);

  useEffect(() => {
    const htmlString = newsItem.description;
    const element = document.getElementById("content");

    element.innerHTML = htmlString;
    if (!htmlString && !element) {
      element.innerHTML = htmlString;
    }
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title> Detail Blog</title>
        <meta name="description" content="Detail Blog" />
      </Helmet>
      <Portada className="c-block-3" Name={newsItem.title} />
      <div className="c-block-5">
        <div className="g-container-tyc">
          <img
            src={newsItem.largeImage}
            alt="Noticia"
            className="large-image"
          />
          <Divider className="divider" />
          <Grid container spacing={2} className="container">
            <Grid item sm={8}>
              <Typography variant="h5" className="text-description">
                {newsItem.shortDescription}
              </Typography>
              <div id="content" className="paragraphs" />
              <i>Fecha de publicación: {newsItem.createdAt}</i>
            </Grid>
            <Grid item sm={4}>
              <div className="container-news-related">
                <Typography variant="h5" className="text-description-related">
                  {" "}
                  NOTICIAS RELACIONADAS
                </Typography>
                {relatedNews.map((item) => (
                  <RelatedNewsCard
                    id={item.id}
                    shortImage={item.image}
                    shortDescription={item.shortDescription}
                    key={item.id}
                  />
                ))}
              </div>
            </Grid>
          </Grid>
          <Grid item sm={4} className="container-button">
            <Link to={"/general-blog"}>
              <Button
                className="back-button"
                variant="outlined"
                color="warning"
                startIcon={<ArrowBackIcon />}>
                Ver todas las noticias
              </Button>
            </Link>
          </Grid>
        </div>
      </div>
    </>
  );
};

/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome, faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import "./style.css";

export const RoutCatalogo = ({ product }) => {
  return (
    <ul className="c-nav-6--v2">
      <li className="icon-arrow-right3">
        <Link to="/" className="c-list4__compare-v3 icon-image-compare">
          <FontAwesomeIcon icon={faHome} />
        </Link>
      </li>
      <div className="icon-arrow">
        <FontAwesomeIcon icon={faAngleRight} />
      </div>
      <li className="icon-arrow-right3">
        <Link
          to="/catalogo-de-motos"
          className="c-list4__compare-v3 icon-image-compare"
        >
          Catálago
        </Link>
      </li>
      <div className="icon-arrow">
        <FontAwesomeIcon icon={faAngleRight} />
      </div>
      <li className="icon-arrow-right3">
        <a className="c-list4__compare-fn icon-image-compare">{product}</a>
      </li>
    </ul>
  );
};

import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck } from "@fortawesome/free-solid-svg-icons";
import { UpdatePassword } from "../../../utils/Register";
import "../../../css/NewPassword.css";

export const NewPasswordForm = ({ code }) => {
  const [status, setStatus] = useState(true);
  const history = useHistory();

  const ContactSchema = Yup.object().shape({
    password: Yup.string().min(
      5,
      "Debes ingresar una contraseña con mínimo 5 caracteres"
    ),
    passwordConfirmation: Yup.string()
      .oneOf([Yup.ref("password"), null], "Las contraseñas deben coincidir")
      .required("Este campo es requerido"),
  });

  const onSubmit = (values, { resetForm }) => {
    values.codigo_registro = code;
    UpdatePassword(values)
      .then((res) => {
        history.push("/login");
      })
      .catch((err) => {
        setStatus(false);
      });
    resetForm();
  };

  return (
    <>
      <div className="c-block-4-np__box-3">
        <Formik
          initialValues={{
            password: "",
            passwordConfirmation: "",
          }}
          validationSchema={ContactSchema}
          onSubmit={onSubmit}
        >
          {({ errors, touched }) => (
            <Form className="c-form-1-np">
              <div className="c-form-1-np__heading">Actualizar contraseña</div>
              <div className="c-form-1-np__text-intro">
                Crea una nueva contraseña para poder iniciar sesión.
              </div>
              <div className="js-hide-is-change-password-np">
                <div className="c-form-1-np__field js-show-error">
                  <label className="o-form-np-label">Nueva contraseña</label>
                  <Field
                    className="o-form-field-r"
                    type="password"
                    name="password"
                    autoComplete="off"
                  />
                  {errors.password && touched.password ? (
                    <div className="js_error">{errors.password}</div>
                  ) : null}
                </div>
                <div className="c-form-1-np__field js-show-error">
                  <label className="o-form-np-label">
                    Confirmar nueva contraseña
                  </label>
                  <Field
                    className="o-form-field-r"
                    type="password"
                    name="passwordConfirmation"
                    autoComplete="off"
                  />
                  {errors.passwordConfirmation &&
                  touched.passwordConfirmation ? (
                    <div className="js_error">
                      {errors.passwordConfirmation}
                    </div>
                  ) : null}
                </div>
              </div>
              <div className="c-form-1-np__field o-is-hidden-2 js-notification--ok">
                <div className="c-form-1-np__notification">
                  <div className="c-notify--ok-np">
                    <i className="icon-checkmark-np"></i> Tu contraseña se ha
                    actualizado.
                  </div>
                </div>
              </div>
              <div className="c-form-1-np__btn js-hide-is-change-password">
                <input type="hidden" name="new__code" />
                <button
                  className="o-btn-3--v2 js-btn-recuperar-np"
                  type="submit"
                >
                  Guardar
                </button>
              </div>
              {!status && (
                <div className="c-form-1__new-password">
                  <div className={"c-form-1__notification"}>
                    <div className="c-notify--alert_Requeriments">
                      <FontAwesomeIcon
                        className="icon_check"
                        icon={faCircleCheck}
                      />
                      {`Ocurrió un error en la actualización de la información, por favor ingresa nuevamente al link que te enviamos anteriormente a tu correo y realiza el proceso nuevamente.`}
                    </div>
                  </div>
                </div>
              )}
            </Form>
          )}
        </Formik>
        <div className="c-modal-1__box-2-np">
          <span className="o-text-6-np">
            ¿Ya tienes cuenta?{" "}
            <Link to="/login/" className="o-link-2 js-step-registro-usuario-np">
              Ingresar
            </Link>
          </span>
        </div>
      </div>
    </>
  );
};

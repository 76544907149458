import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import {
  ListAllMarcas,
  ListAllEstilos,
  ListAllTransmision,
  ListAllArranque,
  ListAllFrenoDelantero,
  ListAllFrenoTrasero,
} from "../../../utils/AllMotos";
import { GetFilterMotos } from "../../../utils/AllMotos";
import { formatCurrency } from "../../../helpers/FormatMoney";
import { BtnClose } from "../../atoms/BtnClose";
import { FrenoTrasero } from "../InputsFilters/FrenoTrasero";
import { FrenoDelantero } from "../InputsFilters/FrenoDelantero";
import { Torque } from "../InputsFilters/Torque";
import { Potencia } from "../InputsFilters/Potencia";
import { TipoArranque } from "../InputsFilters/TipoArranque";
import { TipoTransmision } from "../InputsFilters/TipoTransmision";
import { Cilindrada } from "../InputsFilters/Cilindrada";
import { Estilos } from "../InputsFilters/Estilos";
import { Precio } from "../InputsFilters/Precio";
import { Marca } from "../InputsFilters/Marca";
import { Keyword } from "../InputsFilters/Keyword";
import "./style.css";

export const FiltrarMotos = ({
  characteristics,
  setCharacteristics,
  setListasMotos,
  menus,
  setMenus,
}) => {
  let {
    keyword,
    precio_min,
    precio_max,
    cilindraje_min,
    cilindraje_max,
    potencia_min,
    potencia_max,
    torque_min,
    torque_max,
    orden,
  } = characteristics;
  // //Para recorrer las demas variables de la base de datos
  const [listas, setListas] = useState({
    listasmarcas: [],
    listasestilos: [],
    listastransmision: [],
    listasarranque: [],
    listasfrenodelantero: [],
    listasfrenotrasero: [],
  });
  const [btnsname, setBtnsName] = useState({
    btn_precio: "",
    btn_cilindraje: "",
    btn_potencia: "",
    btn_torque: "",
  });
  // corregir (para hacer el cambio automatico)
  let keyword2 = "''";
  let id_marca2 = "''";
  let id_estilo2 = "''";
  let id_transmision2 = "''";
  let id_arranque2 = "''";
  let freno_del2 = "''";
  let freno_tra2 = "''";
  //consulta a la BD
  const consulta = async () => {
    GetFilterMotos(
      keyword2,
      id_marca2,
      precio_min,
      precio_max,
      id_estilo2,
      cilindraje_min,
      cilindraje_max,
      id_transmision2,
      id_arranque2,
      potencia_min,
      potencia_max,
      torque_min,
      torque_max,
      freno_del2,
      freno_tra2,
      orden
    ).then((res) => {
      setListasMotos(res.data);
    });
  };
  // cargar la información en el visual del filtro
  useEffect(() => {
    ListAllMarcas().then((result) => {
      setListas((prevState) => ({
        ...prevState,
        listasmarcas: result.data,
      }));
    });
    ListAllEstilos().then((result) => {
      setListas((prevState) => ({
        ...prevState,
        listasestilos: result.data,
      }));
    });
    ListAllTransmision().then((result) => {
      setListas((prevState) => ({
        ...prevState,
        listastransmision: result.data,
      }));
    });
    ListAllArranque().then((result) => {
      setListas((prevState) => ({
        ...prevState,
        listasarranque: result.data,
      }));
    });
    ListAllFrenoDelantero().then((result) => {
      setListas((prevState) => ({
        ...prevState,
        listasfrenodelantero: result.data,
      }));
    });
    ListAllFrenoTrasero().then((result) => {
      setListas((prevState) => ({
        ...prevState,
        listasfrenotrasero: result.data,
      }));
    });
  }, []);

  // filtro
  function filtrar(e) {
    const json_validate_marcas = [
      ",1",
      ",2",
      ",3",
      ",4",
      ",5",
      ",6",
      ",7",
      ",8",
      ",9",
      ",10",
      ",11",
      ",12",
      ",13",
    ];
    const json_validate_estilo = [
      "check-estilo-1",
      "check-estilo-2",
      "check-estilo-3",
      "check-estilo-4",
      "check-estilo-5",
    ];
    const json_validate_transmision = [
      "check-transmision-1",
      "check-transmision-2",
      "check-transmision-3",
    ];
    const json_validate_arranque = [
      "check-arranque-1",
      "check-arranque-2",
      "check-arranque-3",
    ];
    const json_validate_freno_delantero = [
      "check-freno-delantero-1",
      "check-freno-delantero-2",
      "check-freno-delantero-3",
    ];
    const json_validate_freno_trasero = [
      "check-freno-trasero-1",
      "check-freno-trasero-2",
      "check-freno-trasero-3",
    ];

    // Campos que requieren concatenación
    id_marca2 = "";
    json_validate_marcas.forEach((id) => {
      if (document.getElementById(id) !== null) {
        if (document.getElementById(id).checked) {
          id_marca2 += id;
          setCharacteristics((prevState) => ({
            ...prevState,
            id_marca: id_marca2,
          }));
        }
      }
    });

    id_estilo2 = "";
    json_validate_estilo.forEach((id) => {
      const id_estilos = [",1", ",2", ",3", ",4", ",5"];
      if (document.getElementById(id) !== null) {
        if (document.getElementById(id).checked) {
          id_estilo2 += id_estilos[json_validate_estilo.indexOf(id)];
          setCharacteristics((prevState) => ({
            ...prevState,
            id_estilo: id_estilo2,
          }));
        }
      }
    });

    id_transmision2 = "";
    json_validate_transmision.forEach((id) => {
      const id_transmision = [",1", ",2", ",3"];
      if (document.getElementById(id) !== null) {
        if (document.getElementById(id).checked) {
          id_transmision2 +=
            id_transmision[json_validate_transmision.indexOf(id)];
          setCharacteristics((prevState) => ({
            ...prevState,
            id_transmision: id_transmision2,
          }));
        }
      }
    });

    id_arranque2 = "";
    json_validate_arranque.forEach((id) => {
      const id_arranque = [",1", ",2", ",3"];

      if (document.getElementById(id) !== null) {
        if (document.getElementById(id).checked) {
          id_arranque2 += id_arranque[json_validate_arranque.indexOf(id)];
          setCharacteristics((prevState) => ({
            ...prevState,
            id_arranque: id_arranque2,
          }));
        }
      }
    });

    freno_del2 = "";
    json_validate_freno_delantero.forEach((id) => {
      const id_freno_delantero = [",1", ",2", ",3"];
      if (document.getElementById(id) !== null) {
        if (document.getElementById(id).checked) {
          freno_del2 +=
            id_freno_delantero[json_validate_freno_delantero.indexOf(id)];
          setCharacteristics((prevState) => ({
            ...prevState,
            freno_del: freno_del2,
          }));
        }
      }
    });

    freno_tra2 = "";
    json_validate_freno_trasero.forEach((id) => {
      const id_freno_trasero = [",1", ",2", ",3"];
      if (document.getElementById(id) !== null) {
        if (document.getElementById(id).checked) {
          freno_tra2 +=
            id_freno_trasero[json_validate_freno_trasero.indexOf(id)];
          setCharacteristics((prevState) => ({
            ...prevState,
            freno_tra: freno_tra2,
          }));
        }
      }
    });
    // campos que no requieren de concatenación
    if (e.target.name === "keyword_filter") {
      keyword2 = document.getElementById("BuscadorName").value;
      setCharacteristics((prevState) => ({
        ...prevState,
        keyword: keyword2,
      }));
      document.getElementById("keyword").classList.remove("o-is-hidden");
      opendivbtntag();
    }
    if (e.target.name === "precio__check") {
      precio_min = e.target.getAttribute("value1");
      precio_max = e.target.getAttribute("value2");
      setCharacteristics((prevState) => ({
        ...prevState,
        precio_min: precio_min,
        precio_max: precio_max,
      }));
      setBtnsName((prevState) => ({
        ...prevState,
        btn_precio: e.target.getAttribute("btn_name"),
      }));
      opendivbtntag();
      document.getElementById("precio").classList.remove("o-is-hidden");
      document.getElementById("preciomin").value = "";
      document.getElementById("preciomax").value = "";
    }
    if (e.target.name === "precio_write") {
      precio_min = document.getElementById("preciomin").value;
      precio_max = document.getElementById("preciomax").value;
      setCharacteristics((prevState) => ({
        ...prevState,
        precio_min: precio_min,
        precio_max: precio_max,
      }));
      setBtnsName((prevState) => ({
        ...prevState,
        btn_precio: `${formatCurrency(
          "es-CO",
          "COP",
          0,
          precio_min
        )} a ${formatCurrency("es-CO", "COP", 0, precio_max)}`,
      }));
      document.getElementById("precio_hasta").checked = false;
      document.getElementById("precio_rango1").checked = false;
      document.getElementById("precio_rango2").checked = false;
      document.getElementById("precio_desde").checked = false;
      document.getElementById("precio").classList.remove("o-is-hidden");
      opendivbtntag();
    }
    if (e.target.name === "cilindrada__check") {
      cilindraje_min = e.target.getAttribute("value1");
      cilindraje_max = e.target.getAttribute("value2");
      setCharacteristics((prevState) => ({
        ...prevState,
        cilindraje_min: cilindraje_min,
        cilindraje_max: cilindraje_max,
      }));
      setBtnsName((prevState) => ({
        ...prevState,
        btn_cilindraje: e.target.getAttribute("btn_name"),
      }));
      opendivbtntag();
      document.getElementById("cilindraje").classList.remove("o-is-hidden");
    }
    if (e.target.name === "potencia__check") {
      potencia_min = e.target.getAttribute("value1");
      potencia_max = e.target.getAttribute("value2");
      setCharacteristics((prevState) => ({
        ...prevState,
        potencia_min: potencia_min,
        potencia_max: potencia_max,
      }));
      setBtnsName((prevState) => ({
        ...prevState,
        btn_potencia: e.target.getAttribute("btn_name"),
      }));
      opendivbtntag();
      document.getElementById("potencia").classList.remove("o-is-hidden");
    }
    if (e.target.name === "torque__check") {
      torque_min = e.target.getAttribute("value1");
      torque_max = e.target.getAttribute("value2");
      setCharacteristics((prevState) => ({
        ...prevState,
        torque_min: torque_min,
        torque_max: torque_max,
      }));
      setBtnsName((prevState) => ({
        ...prevState,
        btn_torque: e.target.getAttribute("btn_name"),
      }));
      opendivbtntag();
      document.getElementById("torque").classList.remove("o-is-hidden");
    }
    consulta();
  }

  //(corregir en uan sola funcion)
  const closeMenu = () => {
    setMenus((prevState) => ({
      ...prevState,
      menufiltrar: "c-modal-1",
    }));
    document.getElementById("barranav").classList.remove("op");
    document.getElementById("CatMotos").classList.remove("op");
    document.getElementById("NBC").classList.remove("op");
    document.getElementById("NBC2").classList.remove("op");
  };

  const opendivbtntag = () => {
    document.getElementById("tags-filtros").classList.remove("o-is-hidden");
  };

  const closedivbtntag = () => {
    if (
      keyword === "''" &&
      btnsname.btn_precio === "" &&
      btnsname.btn_cilindraje === "" &&
      btnsname.btn_potencia === "" &&
      btnsname.btn_torque === "" /* && Honda === ""*/
    ) {
      document.getElementById("tags-filtros").classList.add("o-is-hidden");
    }
  };
  // pruebas (corregir en uan sola funcion)
  const closefiltermarca = (e) => {
    document.getElementById(e.target.id).classList.add("o-is-hidden");
  };

  const closefilterkeyword = () => {
    keyword = "''";
    setCharacteristics((prevState) => ({
      ...prevState,
      keyword: "''",
    }));
    document.getElementById("BuscadorName").value = "";
    consulta();
    closedivbtntag();
    document.getElementById("keyword").classList.add("o-is-hidden");
  };

  const closefilterprecio = () => {
    precio_min = "''";
    precio_max = "''";
    setCharacteristics((prevState) => ({
      ...prevState,
      precio_min: "''",
      precio_max: "''",
    }));
    setBtnsName((prevState) => ({
      ...prevState,
      btn_precio: "",
    }));

    btnsname.btn_precio = "";
    consulta();
    document.getElementById("precio_hasta").checked = false;
    document.getElementById("precio_rango1").checked = false;
    document.getElementById("precio_rango2").checked = false;
    document.getElementById("precio_desde").checked = false;
    document.getElementById("preciomin").value = "";
    document.getElementById("preciomax").value = "";
    closedivbtntag();
    document.getElementById("precio").classList.add("o-is-hidden");
  };

  const closefiltercilindraje = () => {
    cilindraje_min = "''";
    cilindraje_max = "''";
    setCharacteristics((prevState) => ({
      ...prevState,
      cilindraje_min: "''",
      cilindraje_max: "''",
    }));
    setBtnsName((prevState) => ({
      ...prevState,
      btn_cilindraje: "",
    }));
    btnsname.btn_cilindraje = "";
    consulta();
    document.getElementById("check-cilindrada-hasta").checked = false;
    document.getElementById("check-cilindrada-rango").checked = false;
    document.getElementById("check-cilindrada-desde").checked = false;
    closedivbtntag();
    document.getElementById("cilindraje").classList.add("o-is-hidden");
  };

  const closefilterpotencia = () => {
    potencia_min = "''";
    potencia_max = "''";
    setCharacteristics((prevState) => ({
      ...prevState,
      potencia_min: "''",
      potencia_max: "''",
    }));
    setBtnsName((prevState) => ({
      ...prevState,
      btn_potencia: "",
    }));
    btnsname.btn_potencia = "";
    consulta();
    document.getElementById("check-potencia-hasta").checked = false;
    document.getElementById("check-potencia-rango").checked = false;
    document.getElementById("check-potencia-desde").checked = false;
    closedivbtntag();
    document.getElementById("potencia").classList.add("o-is-hidden");
  };

  const closefiltertorque = () => {
    torque_min = "''";
    torque_max = "''";
    setCharacteristics((prevState) => ({
      ...prevState,
      torque_min: "''",
      torque_max: "''",
    }));
    setBtnsName((prevState) => ({
      ...prevState,
      btn_torque: "",
    }));
    consulta();
    document.getElementById("check-torque-hasta").checked = false;
    document.getElementById("check-torque-rango").checked = false;
    document.getElementById("check-torque-desde").checked = false;
    closedivbtntag();
    document.getElementById("torque").classList.add("o-is-hidden");
  };

  return (
    <div className={menus.menufiltrar} id="modal-filtros">
      <div className="c-modal-1__header">
        <div className="c-modal-1__header-bar">
          <div className="c-modal-1__header-title">Filtrar motos</div>
          <div className="c-modal-1__header-close">
            <button
              className="c-modal-1__close js-close-filtros"
              onClick={closeMenu}
            >
              <FontAwesomeIcon className="icon-cross2" icon={faXmark} />
              Cerrar
            </button>
          </div>
        </div>
        <div className="c-modal-1__tags-filter o-is-hidden" id="tags-filtros">
          {listas.listasmarcas.map((item) => (
            <BtnClose
              funtion={closefiltermarca}
              id={item.nombre_marca}
              name={item.nombre_marca}
              key={Math.random() * 100}
            />
          ))}
          <BtnClose
            funtion={closefilterkeyword}
            id={"keyword"}
            name={keyword}
          />
          <BtnClose
            funtion={closefilterprecio}
            id={"precio"}
            name={btnsname.btn_precio}
          />
          <BtnClose
            funtion={closefiltercilindraje}
            id={"cilindraje"}
            name={btnsname.btn_cilindraje}
          />
          <BtnClose
            funtion={closefilterpotencia}
            id={"potencia"}
            name={btnsname.btn_potencia}
          />
          <BtnClose
            funtion={closefiltertorque}
            id={"torque"}
            name={btnsname.btn_torque}
          />
        </div>
      </div>
      <div className="c-modal-1__content ps">
        <Keyword filtrar={filtrar} />
        <Marca listas={listas} filtrar={filtrar} />
        <Precio filtrar={filtrar} />
        <Estilos listas={listas} filtrar={filtrar} />
        <Cilindrada filtrar={filtrar} />
        <TipoTransmision listas={listas} filtrar={filtrar} />
        <TipoArranque listas={listas} filtrar={filtrar} />
        <Potencia filtrar={filtrar} />
        <Torque filtrar={filtrar} />
        <FrenoDelantero listas={listas} filtrar={filtrar} />
        <FrenoTrasero listas={listas} filtrar={filtrar} />
      </div>
    </div>
  );
};

import { apiUser } from "../Api/ApiInstance";

export async function SaveOrder(values) {
  return await apiUser.post("api/official-site/order", values);
}

export async function Orders() {
  return await apiUser.get("api/official-site/order");
}

export async function SaveOrderDetail(values) {
  return await apiUser.post("api/official-site/order/detail", values);
}

export async function GetOrderDetail(ordenId) {
  return await apiUser.get(`api/official-site/order/detailOrder/${ordenId}`);
}

export async function UpdateOrderPay(values) {
  return await apiUser.post("api/official-site/order/pay", values);
}

import React from "react";
import { Link } from "react-router-dom";
import { DetailOrder } from "../../molecules/DetailOrder";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome, faAngleRight } from "@fortawesome/free-solid-svg-icons";
import "./style.css";

export const BodyDetailOrder = ({ order }) => {
  return (
    <>
      <ul className="c-nav-6--v2">
        <li className="icon-arrow-right3">
          <Link to="/" className="c-list4__compare-v3 icon-image-compare">
            <FontAwesomeIcon icon={faHome} />
          </Link>
        </li>
        <div className="icon-arrow">
          <FontAwesomeIcon icon={faAngleRight} />
        </div>
        <li className="icon-arrow-right3">
          <Link to="/perfil" className="c-list4__compare-v3 icon-image-compare">
            Mis ordenes
          </Link>
        </li>
        <div className="icon-arrow">
          <FontAwesomeIcon icon={faAngleRight} />
        </div>
        <li className="icon-arrow-right3">
          <div className="c-list4__compare-fn icon-image-compare">
            {order.ordenid}
          </div>
        </li>
      </ul>
      <div className="g-row-order-detail">
        <DetailOrder order={order} />
      </div>
    </>
  );
};

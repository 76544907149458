import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { Link } from "react-router-dom";
import { Formik, Form, Field } from "formik";
import { ContactSave } from "../../../utils/FormContactos";
import { getIpClient } from "../../../helpers/GetIp";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck } from "@fortawesome/free-solid-svg-icons";
import { Select } from "../../atoms/Select";
import "../FormContactos/style.css";
import { HelpersByCode } from "../../../utils/Helpers";
import { Email } from "../../../helpers/Email";
import { htmlContact } from "../../../data/initEmail/emailContact";
import GeneralData from "../../../data/GeneralData";

export const FormContactos = () => {
  const [ip_usuario, setIP] = useState("");
  const [msg, setMsg] = useState({});
  const [select, setSelect] = useState([]);
  const [id_pqrs, setIdPqrs] = useState("");

  useEffect(() => {
    Promise.all([getIpClient(), HelpersByCode("PQRS")])
      .then((res) => {
        setIP(res[0]);
        setSelect(res[1].data);
      })
      .catch((err) => console.log(err));
  }, []);

  const ContactSchema = Yup.object().shape({
    nombres: Yup.string()
      .min(3, "Muy corto!")
      .required("Este campo es requerido"),
    apellidos: Yup.string()
      .min(3, "Muy corto!")
      .required("Este campo es requerido"),
    celular: Yup.string()
      .matches(
        /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
      )
      .min(10, "Número de celular no valido"),
    email: Yup.string()
      .min(3, "muy corto!")
      .email("Por favor, escribe una dirección de correo válida.")
      .required("Este campo es requerido"),
    mensaje: Yup.string()
      .min(3, "muy corto!")
      .required("Este campo es requerido"),
    check2: Yup.boolean().oneOf([true], "Este campo es obligatorio."),
  });

  const onSubmit = (values, { resetForm }) => {
    values = { values, ip_usuario, id_pqrs };
    ContactSave(values)
      .then((res) => {
        setMsg(res);
        //Email internal
        Email({
          to: GeneralData.from,
          subject: `Contacto: ${values.values.nombres} ${values.values.apellidos}`,
          text: "Contacto Supre",
          html: htmlContact,
          attachments: [],
          options: values.values,
        });
      })
      .catch((err) => setMsg(err));
    resetForm();
    setIdPqrs("");
  };

  const handleChangeSelect = (event) => {
    setIdPqrs(event.target.value);
  };

  return (
    <div className="c-block-4__col--frcc">
      <Formik
        initialValues={{
          nombres: "",
          apellidos: "",
          celular: "",
          email: "",
          mensaje: "",
          check2: false,
        }}
        validationSchema={ContactSchema}
        onSubmit={onSubmit}
      >
        {({ errors, touched }) => (
          <Form className="c-form-ct js-form">
            <div className="c-form-ct__row">
              <div className="c-form-ct__field js-show-error">
                <label className="o-form-label">Nombres</label>
                <Field className="o-form-fieldC" name="nombres" id="name" />
                {errors.nombres && touched.nombres ? (
                  <div className="js_error">{errors.nombres}</div>
                ) : null}
              </div>
              <div className="c-form-ct__field js-show-error">
                <label className="o-form-label">Apellidos</label>
                <Field
                  className="o-form-fieldC"
                  name="apellidos"
                  id="lastname"
                />
                {errors.apellidos && touched.apellidos ? (
                  <div className="js_error">{errors.apellidos}</div>
                ) : null}
              </div>
            </div>
            <div className="c-form-ct__row">
              <div className="c-form-ct__field js-show-error">
                <label className="o-form-label">Celular</label>
                <Field className="o-form-fieldC" type="text" name="celular" />
                {errors.celular && touched.celular ? (
                  <div className="js_error">{errors.celular}</div>
                ) : null}
              </div>
              <div className="c-form-ct__field js-show-error">
                <label className="o-form-label">Correo electrónico</label>
                <Field className="o-form-fieldC" name="email" />
                {errors.email && touched.email ? (
                  <div className="js_error">{errors.email}</div>
                ) : null}
              </div>
            </div>
            <div className="c-form-ct__field js-show-error">
              <label className="o-form-label">Tipo de contacto</label>
              <Select
                className="o-form-fieldC2"
                data={select}
                value={id_pqrs}
                required
                onChange={handleChangeSelect}
              ></Select>
            </div>
            <div className="c-form-ct__field js-show-error">
              <label className="o-form-label">Mensaje</label>
              <Field
                component="textarea"
                className="o-form-field--textarea"
                name="mensaje"
              />
              {errors.mensaje && touched.mensaje ? (
                <div className="js_error">{errors.mensaje}</div>
              ) : null}
            </div>
            <div className="c-form-ct__field js-show-error">
              <div className="o-form-check">
                <Field
                  name="check2"
                  type="checkbox"
                  className="o-form-check__campo"
                  id="check-2"
                />
                <label htmlFor="check-2"></label>
                <span className="c-form-ct__text-1">
                  He leído y acepto Política de Privacidad de Datos.
                  <Link
                    className="o-link-1"
                    id="popupTerminos"
                    target="_blank"
                    to="/politicas/"
                  >
                    Ver detalle legal
                  </Link>
                </span>
              </div>
              {errors.check2 && touched.check2 ? (
                <div className="js_error">{errors.check2}</div>
              ) : null}
            </div>
            {msg.status === 201 ? (
              <div className="c-form-ct__field">
                <div className={"c-form-ct__notification"}>
                  <div className="c-notify--ok_Requeriments">
                    <FontAwesomeIcon
                      className="icon_check"
                      icon={faCircleCheck}
                    />
                    Muchas gracias, hemos recibido tu comentario. <br />{" "}
                  </div>
                </div>
              </div>
            ) : (
              Object.keys(msg).length > 0 && (
                <div className="c-form-1__field">
                  <div className={"c-form-1__notification"}>
                    <div className="c-notify--alert_Requeriments">
                      <FontAwesomeIcon
                        className="icon_check"
                        icon={faCircleCheck}
                      />
                      Error en el envío de la solicitud, por favor intentalo
                      nuevamente.
                    </div>
                  </div>
                </div>
              )
            )}
            <div className="c-form-ct__btn">
              <button className="o-btn-3--v2" type="submit">
                Enviar
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

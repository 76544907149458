import React, { useState, useEffect } from "react";
import { CreditApplications } from "../../../utils/CreditApplication";
import { formatCurrency } from "../../../helpers/FormatMoney";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCoins } from "@fortawesome/free-solid-svg-icons";
import "./style.css";

export const DetailCreditApplications = () => {
  const [creditApplications, setCreditApplications] = useState([]);
  const [load, setLoad] = useState(true);

  useEffect(() => {
    CreditApplications()
      .then((res) => {
        setCreditApplications(res.data);
        setLoad(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <>
      <div className="c-block-5-p__heading-6">Solicitudes de crédito</div>
      <div className="c-block-5-p__tables">
        {creditApplications.length > 0 ? (
          creditApplications.map((elemnt, index) => {
            return (
              <div className="c-table-2-p--v2" key={index}>
                <div className="c-table-2-p__row">
                  <div className="c-table-2-p__col-sol">
                    <div className="c-table-2-p__heading">Moto</div>
                    <div className="c-table-2-p__content">
                      {elemnt.nombre_moto}
                    </div>
                  </div>
                  <div className="c-table-2-p__col">
                    <div className="c-table-2-p__heading">Color</div>
                    <div className="c-table-2-p__content">
                      {elemnt.color_moto}
                    </div>
                  </div>
                  <div className="c-table-2-p__col">
                    <div className="c-table-2-p__heading">Fecha creación</div>
                    <div className="c-table-2-p__content">
                      {elemnt.createdAt}
                    </div>
                  </div>
                  <div className="c-table-2-p__col">
                    <div className="c-table-2-p__heading">Precio</div>
                    <div className="c-table-2-p__content">
                      {formatCurrency("es-CO", "COP", 0, elemnt.precio_moto)}
                    </div>
                  </div>
                  <div className="c-table-2-p__col">
                    <div className="c-table-2-p__heading">Cupon</div>
                    <div className="c-table-2-p__content">
                      {elemnt.cupon || "No aplica"}
                    </div>
                  </div>
                  <div className="c-table-2-p__col">
                    <div className="c-table-2-p__heading">Descuento</div>
                    <div className="c-table-2-p__content">
                      {formatCurrency(
                        "es-CO",
                        "COP",
                        0,
                        elemnt.valor_cupon_descuento
                      )}
                    </div>
                  </div>
                  <div className="c-table-2-p__col">
                    <div className="c-table-2-p__heading">Plazo</div>
                    <div className="c-table-2-p__content">{elemnt.plazo}</div>
                  </div>
                </div>
              </div>
            );
          })
        ) : load ? (
          <div className="menu-mobile-overlay">
            <div className="loader-popup">
              <div className="loader__box">
                <div className="icon-spinner9 animate-spin"></div>
              </div>
            </div>
          </div>
        ) : (
          <div className="c-table-2-p--v2">
            <div className="c-table-2-p__row">
              <div className="c-table-2-p__col">
                <div className="c-table-2-p__heading-null">
                  <FontAwesomeIcon className="icon_check" icon={faCoins} />
                </div>
                <div className="c-table-2-p__content-null">
                  Aún no tienes solicitudes de créditos
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

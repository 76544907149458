import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Helmet } from "react-helmet";

import { Portada } from "../components/molecules/Portada";
import { AccordeonItem } from "../components/atoms/AccordeonItem";
import totalQuestions from "../data/frequent-questions/frequent-questions.json";

export const FrequentQuestions = () => {
  let history = useHistory();
  const panel =
    history.location.pathname.split("/")[2] !== (undefined || "")
      ? history.location.pathname.split("/")[2]
      : totalQuestions[0].slug;

  const [panelNameOpen, setPanelNameOpen] = useState(panel);

  useEffect(() => {
    setPanelNameOpen();
  }, [history.location.pathname]);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Preguntas frecuentes</title>
        <meta name="description" content="Preguntas frecuentes" />
      </Helmet>
      <Portada className="c-block-3" Name="Preguntas frecuentes" />
      <div className="c-block-5">
        <div className="g-container-tyc">
          {totalQuestions.map((item) => (
            <AccordeonItem
              key={item.id}
              title={item.title}
              panelNameOpen={panelNameOpen}
              panelName={`${item.slug}`}
            >
              {item.answers.map((answer, index) => (
                <p key={index} className="paragraphs">
                  {answer}
                </p>
              ))}
            </AccordeonItem>
          ))}
        </div>
      </div>
    </>
  );
};

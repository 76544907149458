import { SendMessagesEmail } from "../utils/Email";

export async function Email(value) {
  let { html, to, subject, attachments, options } = value;
  //Change values in HTML format
  Object.entries(options).forEach(([key, value]) => {
    html = html.replace(`@${key}`, value || "");
  });
  //Send messages email
  return await SendMessagesEmail({
    to: to,
    subject: subject,
    html: html,
    file: attachments,
  });
}

import React from "react";
import Helmet from "react-helmet";
import AdminMui from "../components/Masters/AdminMui";
import Swal from "sweetalert2";
import { useHistory, useLocation } from "react-router-dom";
import { MotoByID } from "../utils/Catalogue";
import { CreateGallery, UpdateGallery, DeleteGallery } from "../utils/Gallery";
import { useChangeBackground } from "../hooks/useChangeBackground";
import { useChangeTitleAppBar } from "../hooks/useChangeTitleAppBar";
import { GalleryTable } from "../components/Organisms/Galery/GaleryTable";
import { EditGallery } from "../components/Organisms/Galery/EditGallery";
import useVerifyMenu from "../hooks/useVerifyMenu";

export const GalleryMotosAdmin = () => {
  let location = useLocation();
  let url_array = location.pathname.split("/");
  let id = url_array[4];
  const back = useVerifyMenu();
  const history = useHistory();
  const [data, setData] = React.useState([]);
  const [againFetch, setAgainFetch] = React.useState(false);
  const [showModal, setShowMondal] = React.useState({
    show: false,
    data: {},
  });

  React.useEffect(() => {
    MotoByID(id)
      .then((res) => {
        setData(res.data);
      })
      .catch((err) =>
        Swal.fire({
          title: "Error!",
          text: err.message || "",
          icon: "error",
          confirmButtonText: "Ok",
        })
      );
  }, [id, againFetch]);

  useChangeBackground("#f5f5f8");
  useChangeTitleAppBar(`Galería de ${data.nombre}`);

  const ApiDeleteGallery = (id) => {
    return DeleteGallery(id);
  };
  
  const handleClick = (gallery, selectedImage) => {
    if (!gallery.id) {
      CreateGallery(gallery, data.id, selectedImage)
        .then((res) => {
          setShowMondal({
            show: false,
            data: {},
          });
          Swal.fire({
            title: "Proceso Ejecutado exitosamente!",
            text: res.message || "",
            icon: "success",
            confirmButtonText: "Ok",
          }).then(() => setAgainFetch(!againFetch));
        })
        .catch((err) => {
          setShowMondal({
            show: false,
            data: {},
          });
          Swal.fire({
            title: "Error!",
            text: err || "",
            icon: "error",
            confirmButtonText: "Ok",
          });
        });
    } else {
      UpdateGallery(gallery, selectedImage)
        .then((res) => {
          setShowMondal({
            show: false,
            data: {},
          });
          Swal.fire({
            title: "Proceso Ejecutado exitosamente!",
            text: res.message || "",
            icon: "success",
            confirmButtonText: "Ok",
          }).then(() => setAgainFetch(!againFetch));
        })
        .catch((err) => {
          setShowMondal({
            show: false,
            data: {},
          });
          Swal.fire({
            title: "Error!",
            text: err.message || "",
            icon: "error",
            confirmButtonText: "Ok",
          });
        });
    }
  };
  return !back ? (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Galería de motos - Supre</title>
        <meta name="description" content="listado de motos admin supre" />
      </Helmet>
      <AdminMui>
        <GalleryTable
          data={data.MotosGaleria ? data.MotosGaleria : null}
          setShowMondal={setShowMondal}
          setAgainFetch={setAgainFetch}
          againFetch={againFetch}
          ApiDeleteGallery={ApiDeleteGallery}
        />
        {showModal.show && (
          <EditGallery
            showModal={showModal}
            setShowMondal={setShowMondal}
            handleClick={handleClick}
            data={data.MotosGaleria ? data.MotosGaleria : null}
          />
        )}
      </AdminMui>
    </>
  ) : (
    <>{history.push("/admin/login")}</>
  );
};

/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { VerifyClientLogged } from "../../../utils/Permissions";
import { HelpersByCode } from "../../../utils/Helpers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import "./style.css";

export const CartBilling = ({
  setModalCar,
  paymentMethod,
  setPaymentMethod,
  status,
}) => {
  const [userLogged, setUserLogged] = useState({});
  const [select, setSelect] = useState([]);
  const [loading, setLoading] = useState(false);

  React.useEffect(() => {
    VerifyClientLogged()
      .then((res) => {
        setUserLogged(res.data);
        setLoading(true);
      })
      .catch((err) => {
        setUserLogged({});
        setLoading(true);
      });
    HelpersByCode("TYPEPAY")
      .then((res) => {
        setSelect(res.data);
        setPaymentMethod({ id: res.data[0]?.id, iden: res.data[0]?.iden });
      })
      .catch((err) => {
        console.log(err);
      }); //eslint-disable-next-line
  }, []);

  const handleChangeSelect = (event) => {
    setPaymentMethod({
      id: event.target.value,
      iden: select.find((item) => item.id === event.target.value).iden,
    });
  };

  return (
    <>
      {Object.entries(userLogged).length > 0 ? (
        <div className="modal-info">
          <div className="modal-info-container">
            <div className="modal-info-title">Nombre:</div>
            <div className="modal-info-client">{`${userLogged.nombres} ${userLogged.apellidos}`}</div>
          </div>
          <div className="modal-info-container">
            <div className="modal-info-title">Cédula:</div>
            <div className="modal-info-client">{userLogged.cedula}</div>
          </div>
          <div className="modal-info-container">
            <div className="modal-info-title">Dirreción:</div>
            <div className="modal-info-client">{`${userLogged.direccion_envio}, ${userLogged.ciudad_envio}`}</div>
          </div>
          <div className="modal-info-container">
            <div className="modal-info-title" style={{ marginTop: 3 }}>
              Método de pago:
            </div>
            <FormControl
              variant="standard"
              style={{ margin: 0 }}
              sx={{ m: 1, minWidth: 140 }}
            >
              <Select
                labelId="demo-simple-select-standard-label"
                id="demo-simple-select-standard"
                value={paymentMethod.id || 1}
                onChange={handleChangeSelect}
                label="Age"
                style={{
                  color: "#A9B9E6",
                  padding: 0,
                  fontSize: "1.5rem",
                  fontWeight: 600,
                }}
              >
                {select.map((value) => (
                  <MenuItem
                    style={{
                      color: "#213b87",
                      // backgroundColor: "#213b87",
                      fontSize: "1.4rem",
                      fontWeight: 500,
                    }}
                    key={Math.random() + ""}
                    name={value.iden}
                    value={value.id}
                  >
                    {value.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <div className="modal-info-btn-col">
            <Link to={"/perfil/editar/"}>
              <button
                className="o-btn-card--v2"
                onClick={() => {
                  setModalCar((prevState) => ({
                    ...prevState,
                    state: false,
                  }));
                }}
              >
                Editar datos
              </button>
            </Link>
          </div>
          {status === false && (
            <div className="c-form-1__field">
              <div className={"c-form-1__notification"}>
                <div className="c-notify--alert_Requeriments">
                  <FontAwesomeIcon className="icon_check" icon={faInfoCircle} />
                  Ocurrió un error al guardar la operación, por favor inténtalo
                  nuevamente
                </div>
              </div>
            </div>
          )}
        </div>
      ) : loading === true ? (
        <div className="modal-info">
          <p className="modal-info-register">
            Para obtener los datos de facturación por favor ingresa a tu cuenta
            o regístrate para crear una nueva.
          </p>
          <div className="modal-info-btn-col">
            <Link to={"/login/"}>
              <button
                className="o-btn-card--v2"
                onClick={() => {
                  setModalCar((prevState) => ({
                    ...prevState,
                    state: false,
                  }));
                }}
              >
                Ingresar
              </button>
            </Link>
          </div>
          <div className="modal-info-btn-col">
            <Link to={"/login/registro/"}>
              <button
                className="o-btn-card--v2"
                onClick={() => {
                  setModalCar((prevState) => ({
                    ...prevState,
                    state: false,
                  }));
                }}
              >
                Registrate
              </button>
            </Link>
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

//para dejar fija la barra de los filtros
window.addEventListener("scroll", () => {
  if (window.scrollY > 150) {
    let header = document.getElementById("header-comparador");
    if (header !== null) {
      document.getElementById("header-comparador").classList.add("staticCM");
      document.getElementById("ComMotos").classList.add("static2");
      document
        .getElementById("header-comparador-sticky-wrapper")
        .classList.add("static3CM");

      var div1 = document.getElementsByClassName("c-list-4__item-2--vacio");
      for (var d1 = 0; d1 < div1.length; d1++) {
        div1[d1].classList.add("static2CM");
      }

      var div2 = document.getElementsByClassName("c-list-4__box-5");
      for (var d2 = 0; d2 < div2.length; d2++) {
        div2[d2].classList.add("staticCM5");
      }

      var div3 = document.getElementsByClassName("c-list-4__item-2");
      for (var d3 = 0; d3 < div3.length; d3++) {
        div3[d3].classList.add("staticinfo");
      }

      var div4 = document.getElementsByClassName("fullview");
      for (var d4 = 0; d4 < div4.length; d4++) {
        div4[d4].style.display = "none";
      }

      var div5 = document.getElementsByClassName("c-list-4__data");
      for (var d5 = 0; d5 < div5.length; d5++) {
        div5[d5].style.marginBottom = "0px";
      }

      var div6 = document.getElementsByClassName("c-list-4__name");
      for (var d6 = 0; d6 < div6.length; d6++) {
        div6[d6].style.fontSize = "1.6rem";
      }

      var div7 = document.getElementsByClassName("c-list-4__price-2");
      for (var d7 = 0; d7 < div7.length; d7++) {
        div7[d7].style.fontSize = "1.8rem";
      }

      var div8 = document.getElementsByClassName("c-list4__close");
      for (var d8 = 0; d8 < div8.length; d8++) {
        div8[d8].style.padding = "3px";
      }

      var div9 = document.getElementsByClassName("closeview");
      for (var d9 = 0; d9 < div9.length; d9++) {
        div9[d9].setAttribute("style", "left: 0px; display: flex");
      }
    }
  } else {
    let header = document.getElementById("header-comparador");
    if (header !== null) {
      header.classList.remove("staticCM");

      document.getElementById("ComMotos").classList.remove("static2");
      document
        .getElementById("header-comparador-sticky-wrapper")
        .classList.remove("static3CM");

      var div10 = document.getElementsByClassName("c-list-4__item-2--vacio");
      for (var d10 = 0; d10 < div10.length; d10++) {
        div10[d10].classList.remove("static2CM");
      }

      var div11 = document.getElementsByClassName("c-list-4__item-2");
      for (var d11 = 0; d11 < div11.length; d11++) {
        div11[d11].classList.remove("staticinfo");
      }

      var div12 = document.getElementsByClassName("fullview");
      for (var d12 = 0; d12 < div12.length; d12++) {
        div12[d12].style.display = "initial";
      }

      var div13 = document.getElementsByClassName("c-list-4__data");
      for (var d13 = 0; d13 < div13.length; d13++) {
        div13[d13].style.marginBottom = "15px";
      }

      var div14 = document.getElementsByClassName("c-list-4__name");
      for (var d14 = 0; d14 < div14.length; d14++) {
        div14[d14].style.fontSize = "1.9rem";
      }

      var div15 = document.getElementsByClassName("c-list-4__price-2");
      for (var d15 = 0; d15 < div15.length; d15++) {
        div15[d15].style.fontSize = "2.1rem";
      }
      var div16 = document.getElementsByClassName("c-list4__close");
      for (var d16 = 0; d16 < div16.length; d16++) {
        div16[d16].style.padding = "5px";
      }

      var div17 = document.getElementsByClassName("closeview");
      for (var d17 = 0; d17 < div17.length; d17++) {
        div17[d17].setAttribute("style", "left: none; display: none");
      }
    }
  }
});

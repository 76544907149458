import { createTheme } from "@mui/material/styles";
import { tablePaginationClasses, outlinedInputClasses } from "@mui/material";

const theme = createTheme({
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          [`& .${outlinedInputClasses.input}`]: {
            fontSize: 15,
          },
        },
      },
    },
    MuiTablePagination: {
      styleOverrides: {
        root: {
          [`& .${tablePaginationClasses.displayedRows}`]: {
            fontSize: 15,
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontSize: 15,
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          fontSize: "1.6rem",
        },
      },
    },
  },
});

export default theme;

import { apiAdminInstance, apiUser } from "../Api/ApiInstance";

export async function VerifyPermission() {
  return await apiAdminInstance.get("api/official-site/permission/menu");
}

export async function VerifyUserLogged() {
  return await apiAdminInstance.get("api/official-site/permission/logged");
}

export async function VerifyClientLogged() {
  return await apiUser.get("api/official-site/permission/client/logged");
}

import apiInstance from "../Api/ApiInstance";

export async function RequerimentSave(values) {
  const {
    nombres,
    apellidos,
    celular,
    email,
    cedula,
    placa_moto,
    tipo_requerimiento,
    fecha_liquidacion,
    observacion,
    ip_usuario,
    id_estado,
  } = values;

  return await apiInstance.post("api/official-site/requeriments", {
    nombres,
    apellidos,
    celular,
    email,
    cedula,
    placa_moto,
    tipo_requerimiento,
    fecha_liquidacion,
    observacion,
    ip_usuario,
    id_estado,
  });
}

import React from "react";
import { DetailProduct } from "../../molecules/DetailProduct";
import { InfoProduct } from "../../molecules/InfoProduct";
import { RoutCatalogo } from "../../molecules/RoutCatalogo";
import "./style.css";

export const DescriptionMoto = ({ product }) => {
  return (
    <div className="c-block-5--v3">
      <div className="g-container-dp">
        <RoutCatalogo product={product?.nombre} />
        <div className="g-row-v1 c-block-5__section-1">
          <InfoProduct product={product} />
        </div>
        <div className="g-row-v1">
          <DetailProduct product={product} />
        </div>
      </div>
    </div>
  );
};

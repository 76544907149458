const ConfigurationMenus = [
  { id: 1, name: "Colores", to: "/admin/configuracion/colores/" },
  { id: 2, name: "Estilos", to: "/admin/configuracion/estilos/" },
  { id: 3, name: "Marcas", to: "/admin/configuracion/marcas/" },
  {
    id: 4,
    name: "Frenos delanteros",
    to: "/admin/configuracion/frenos-delanteros/",
  },
  {
    id: 5,
    name: "Frenos traseros",
    to: "/admin/configuracion/frenos-traseros/",
  },
  {
    id: 6,
    name: "Suspensión delantera",
    to: "/admin/configuracion/suspension-delantera/",
  },
  {
    id: 7,
    name: "Suspensión trasera",
    to: "/admin/configuracion/suspension-trasera/",
  },
  { id: 8, name: "Tipo de arranque", to: "/admin/configuracion/arranque/" },
  {
    id: 9,
    name: "Costos de financiamiento",
    to: "/admin/configuracion/costos-financiamiento/",
  },
];

export default ConfigurationMenus;

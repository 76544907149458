import React from "react";
import { ProductCredit } from "../../molecules/ProductCredit/ProductCredit";
import { RoutCatalogo } from "../../molecules/RoutCatalogo";
import "./style.css";

export const Solicitud = ({ product }) => {
  return (
    <div className="g-container-sc">
      <RoutCatalogo product={"Solicitud de crédito"} />
      <ProductCredit product={product} />
    </div>
  );
};

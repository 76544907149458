import React from "react";

export const TipoArranque = ({ listas, filtrar }) => {
  return (
    <div className="c-modal-1__section">
      <div className="c-modal-1__heading">Tipo de arranque</div>
      <div className="c-modal-1__item">
        <div className="c-list-6">
          <div className="c-list-6">
            {listas.listasarranque.map((item) => (
              <div className="c-list-6__item" key={item.id}>
                <div className="o-form-check">
                  <input
                    className="o-form-check__campo--v2"
                    id={`check-arranque-${item.id}`}
                    type="checkbox"
                    name="transmision__check"
                    value={item.id}
                    onClick={filtrar}
                  />
                  <label htmlFor={`check-arranque-${item.id}`}>
                    {" "}
                    <span className="c-form-1__text-2">
                      {" "}
                      {item.nombre_tipo_arranque}{" "}
                    </span>
                  </label>
                  <input type="hidden" />
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

import React from "react";
import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleCheck,
  faCircleExclamation,
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { VerifyClientLogged } from "../../../utils/Permissions";
import { UpdateUser } from "../../../utils/Register";
import "../../../css/EditProfile.css";

const EditProfileSchema = Yup.object().shape({
  nombres: Yup.string().required("Required"),
  apellidos: Yup.string().required("Required"),
  cedula: Yup.number().required("Required"),
  email: Yup.string()
    .min(3, "Too short!")
    .email("Invalid email")
    .required("Required"),
  password: Yup.string().min(3, "Too short!"),
  passwordConfirmation: Yup.string().oneOf(
    [Yup.ref("password"), null],
    "Las contraseñas deben coincidir"
  ),
  celular: Yup.number().required("Required"),
  ciudad_envio: Yup.string().required("Required"),
  direccion_envio: Yup.string().required("Required"),
});

export const BodyEdittProfile = () => {
  const [userLogged, setUserLogged] = React.useState([]);
  const [status, setStatus] = React.useState({});

  React.useEffect(() => {
    VerifyClientLogged()
      .then((res) => setUserLogged(res.data))
      .catch((err) => {});
  }, []);

  return (
    <>
      <ul className="c-nav-6-ep">
        <li className="icon-ep-arrow-right">
          <Link to="/">
            <i className="icon-ep-home2"></i>
          </Link>
        </li>
        <li className="icon-ep-arrow-right3">
          <Link className="active" to="/perfil/editar/">
            Editar perfil
          </Link>
        </li>
      </ul>
      <div className="c-block-4-ep__box-4">
        {Object.entries(userLogged).length !== 0 ? (
          <Formik
            initialValues={{
              id: userLogged.id,
              nombres: userLogged.nombres,
              apellidos: userLogged.apellidos,
              cedula: userLogged.cedula, // create de values initial
              email: userLogged.email,
              celular: userLogged.celular,
              ciudad_envio: userLogged.ciudad_envio,
              direccion_envio: userLogged.direccion_envio,
            }}
            validationSchema={EditProfileSchema}
            onSubmit={(values) => {
              UpdateUser(values)
                .then((res) => {
                  localStorage.setItem("token_session", res.data.token);
                  setStatus(true);
                })
                .catch((err) => {
                  setStatus(false);
                });
            }}
          >
            {({ errors, touched }) => (
              <Form
                className="c-form-1-r"
                id="form-registro"
                data-action="/login/register/"
                data-ref="<?php echo CoreHelper::getURL('ref'); ?>"
                data-url-ok="/perfil/"
                data-url-ok-carrito="/perfil/#open-cart"
              >
                <div className="c-form-1-r__row">
                  <div className="c-form-1-r__field js-show-error">
                    <label for="o-form-label-r" className="o-form-label-r">
                      Nombres
                    </label>
                    <Field
                      className="o-form-field-r"
                      type="text"
                      name="nombres"
                      autoComplete="off"
                    />
                    {errors.nombres && touched.nombres ? (
                      <div className="js_error">{errors.nombres}</div>
                    ) : null}
                  </div>
                  <div className="c-form-1-r__field js-show-error">
                    <label className="o-form-label-r">Apellidos</label>
                    <Field
                      className="o-form-field-r"
                      type="text"
                      name="apellidos"
                      autoComplete="off"
                    />
                    {errors.apellidos && touched.apellidos ? (
                      <div className="js_error">{errors.apellidos}</div>
                    ) : null}
                  </div>
                </div>
                <div className="c-form-1-r__row">
                  <div className="c-form-1-r__field js-show-error">
                    <label className="o-form-label-r">Número de cédula</label>
                    <Field
                      className="o-form-field-r"
                      type="number"
                      name="cedula"
                      autoComplete="off"
                    />
                    {errors.cedula && touched.cedula ? (
                      <div className="js_error">{errors.cedula}</div>
                    ) : null}
                  </div>
                  <div className="c-form-1-r__field js-show-error">
                    <label className="o-form-label-r">Correo electrónico</label>
                    <Field
                      className="o-form-field-r"
                      type="email"
                      name="email"
                      autoComplete="off"
                    />
                    {errors.email && touched.email ? (
                      <div className="js_error">{errors.email}</div>
                    ) : null}
                  </div>
                </div>
                <div className="c-form-1-r__row">
                  <div className="c-form-1-r__field js-show-error">
                    <label className="o-form-label-r">Número de celular</label>
                    <Field
                      className="o-form-field-r"
                      type="number"
                      name="celular"
                      autoComplete="off"
                    />
                    {errors.celular && touched.celular ? (
                      <div className="js_error">{errors.celular}</div>
                    ) : null}
                  </div>
                  <div className="c-form-1-r__field js-show-error">
                    <label className="o-form-label-r">Ciudad de envío</label>
                    <Field
                      className="o-form-field-r"
                      type="text"
                      name="ciudad_envio"
                      autoComplete="off"
                    />
                    {errors.ciudad_envio && touched.ciudad_envio ? (
                      <div className="js_error">{errors.ciudad_envio}</div>
                    ) : null}
                  </div>
                </div>
                <div className="c-form-1-r__field js-show-error">
                  <label className="o-form-label-r">Dirección de envío</label>
                  <Field
                    className="o-form-field-r"
                    type="text"
                    name="direccion_envio"
                    autoComplete="off"
                  />
                  {errors.direccion_envio && touched.direccion_envio ? (
                    <div className="js_error">{errors.direccion_envio}</div>
                  ) : null}
                </div>
                <div className="c-form-1-r__separador"></div>
                <div className="c-form-1-r__field js-show-error">
                  <label className="o-form-label-r">Editar contraseña</label>
                  <Field
                    className="o-form-field-r"
                    type="password"
                    name="password"
                    autoComplete="off"
                  />
                  {errors.password && touched.password ? (
                    <div className="js_error">{errors.password}</div>
                  ) : null}
                </div>
                <div className="c-form-1-r__field js-show-error">
                  <label className="o-form-label-r">Confirmar contraseña</label>
                  <Field
                    className="o-form-field-r"
                    type="password"
                    name="passwordConfirmation"
                    autoComplete="off"
                  />
                  {errors.passwordConfirmation &&
                  touched.passwordConfirmation ? (
                    <div className="js_error">
                      {errors.passwordConfirmation}
                    </div>
                  ) : null}
                </div>
                <div className="c-form-1-ep__field o-is-hidden-2 js-notification--ok">
                  <div className="c-form-1-ep__notification">
                    <div className="c-notify--ok">
                      <i className="icon-ep-checkmark"></i> Se han guardado los
                      cambios.
                    </div>
                  </div>
                </div>
                <div className="c-form-1-ep__btn">
                  <button className="o-btn-3 js-btn-registro" type="submit">
                    Guardar cambios
                  </button>
                </div>
                {status === true ? (
                  <div className="c-form-ct__field">
                    <div className="c-form-ct__notification">
                      <div className="c-notify--ok_Requeriments">
                        <FontAwesomeIcon
                          className="icon_check"
                          icon={faCircleCheck}
                        />
                        Perfil actualizado exitosamente
                      </div>
                    </div>
                  </div>
                ) : (
                  status === false && (
                    <div className="c-form-ct__field">
                      <div className="c-form-ct__notification">
                        <div className="c-notify--alert_Requeriments">
                          <FontAwesomeIcon
                            className="icon_check"
                            icon={faCircleExclamation}
                          />
                          ¡Lo sentimos! Ha ocurrido un error inesperado. Intenta
                          nuevamente.
                        </div>
                      </div>
                    </div>
                  )
                )}
              </Form>
            )}
          </Formik>
        ) : (
          <></>
        )}
      </div>
    </>
  );
};

import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { Portada } from "../components/molecules/Portada";
import { Button, Grid } from "@mui/material";
import { NewsCard } from "../components/atoms/News-Card/index.";
import newsCard from "../components/atoms/cards-news/news";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";

export const GeneralBlog = () => {
  const [showMore, setShowMore] = useState(false);

  // Obtén los primeros 10 elementos del array newsCard
  const firstNews = newsCard.slice(0, 10);

  // Obtén el resto de elementos para mostrarlos solo cuando showMore sea true
  const remainingNews = showMore ? newsCard.slice(10) : [];

  const toggleShowMore = () => {
    setShowMore(!showMore);
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Blog</title>
        <meta name="description" content="Blog" />
      </Helmet>
      <Portada className="c-block-3" Name="Blog" />
      <div className="c-block-5">
        <div className="container-card">
          <Grid container spacing={2}>
            {firstNews.map((item) => (
              <NewsCard
                key={item.id}
                createdAt={item.createdAt}
                shortDescription={item.shortDescription}
                shortImage={item.image}
                title={item.title}
                id={item.id}
              />
            ))}
            {showMore &&
              remainingNews.map((item) => (
                <NewsCard
                  key={item.id}
                  createdAt={item.createdAt}
                  shortDescription={item.shortDescription}
                  shortImage={item.image}
                  title={item.title}
                  id={item.id}
                />
              ))}
          </Grid>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "10px",
              marginLeft: "-25rem",
            }}
          >
            {newsCard.length > 8 && (
              <Button
                onClick={toggleShowMore}
                variant="outlined"
                color="warning"
                style={{
                  color: "#F18C17",
                  textTransform: "none",
                  fontSize: "1.4rem",
                }}
                endIcon={showMore ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />}
              >
                {showMore ? "Ver menos" : "Cargar más noticias"}
              </Button>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

import React from "react";
import Helmet from "react-helmet";
import { Portada } from "../components/molecules/Portada";
import { FormRequerimientos } from "../components/molecules/FormRequerimientos";
import "../css/Requerimientos.css";

export const Requerimientos = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Requerimientos - Supre</title>
        <meta name="description" content="Requerimientos Supre" />
      </Helmet>
      <Portada className="c-block-3" Name="Requerimientos" />
      <div className="c-block-4">
        <div className="g-container-rq c-block-4__container">
          <div className="c-block-4__col--moto">
            <div className="c-block-4__box">
              <div style={{ textAlign: "center" }}>
                <h1 style={{ marginBottom: "25px", color: "#ffffff" }}>
                  ¡IMPORTANTE!
                </h1>
                <p className="c-block-4__p">
                  Completa el siguiente formulario de acuerdo al requerimiento
                  que tengas en tu crédito y en los próximos 15 días hábiles un
                  asesor se comunicará contigo para darle solución lo antes
                  posible.
                </p>
                <p className="c-block-4__p">
                  Recuerda que solo es permitido solicitar un requerimiento, de
                  cada uno, al mes.
                </p>
                <p className="c-block-4__p">
                  Levantamiento de Prenda y Paz y Salvo son gratuitos la primera
                  vez, los siguientes tendrán un costo de $ 30.000 cada uno.
                </p>
              </div>
            </div>
          </div>
          <div className="c-block-4__col">
            <FormRequerimientos />
          </div>
        </div>
      </div>
    </>
  );
};

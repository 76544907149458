import React from "react";
import { Helmet } from "react-helmet";
import { faCircleUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { useChangeBackground } from "../hooks/useChangeBackground";
import Swal from "sweetalert2";
import { ApiLoginAdmin } from "../utils/InicioSesion";
import { useHistory } from "react-router-dom";
import GeneralData from "../data/GeneralData.js";
import "../css/LoginAdmin.css";

const SignupSchema = Yup.object().shape({
  email: Yup.string()
    .min(3, "Muy corto!")
    .email("Email invalido")
    .required("Campo requerido"),
  password: Yup.string().min(3, "Muy corto!").required("Campo requerido"),
});

const LoginAdmin = () => {
  const history = useHistory();
  useChangeBackground("#f5f5f8");

  function handleLogin(values) {
    ApiLoginAdmin(values)
      .then((res) => {
        const { data } = res;
        localStorage.setItem("SESSION_ID", data.token);
        history.push("/admin/");
        // window.location.reload()
      })
      .catch((err) => {
        Swal.fire({
          title: "Error!",
          text: err.message || "",
          icon: "error",
          confirmButtonText: "Ok",
        });
      });
  }
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Login - Supre</title>
        <meta name="description" content="Iniciar sesión admin Supre" />
      </Helmet>
      <section className="body-sign">
        <div className="center-sign">
          <div className="panel card-sign">
            <div className="card-title-sign mt-3 text-right">
              <span className="logo float-left">
                <img
                  src={GeneralData.host + "images/logo.png"}
                  width="117"
                  alt="Logo Admin"
                  className="logo-admin-supre"
                />
              </span>
              <h2 className="title text-uppercase font-weight-bold m-0">
                <FontAwesomeIcon
                  icon={faCircleUser}
                  className="mr-1 text-6 position-relative top-5"
                />
                <strong
                  style={{
                    marginLeft: "6px",
                    position: "relative",
                    top: "-4px",
                  }}
                >
                  {" "}
                  Iniciar sesión
                </strong>
              </h2>
            </div>
            <div className="card-body">
              <Formik
                initialValues={{
                  email: "",
                  password: "",
                }}
                validationSchema={SignupSchema}
                onSubmit={handleLogin}
              >
                {({ errors, touched }) => (
                  <Form
                    id="form-login"
                    // data-action="/admin/login/entrar/"
                    // data-url-ok="/admin/"
                  >
                    <div className="form-group mb-3 js-show-error">
                      <label>Correo electrónico</label>
                      <div className="input-group ">
                        <Field
                          name="email"
                          className="form-control form-control-modernx"
                        />
                        {errors.email && touched.email ? (
                          <div>{errors.email}</div>
                        ) : null}
                        <span className="input-group-append">
                          <span className="input-group-text">
                            <i className="bx bx-user text-4"></i>
                          </span>
                        </span>
                      </div>
                    </div>

                    <div className="form-group mb-3 js-show-error">
                      <div className="clearfix">
                        <label className="float-left">Contraseña</label>
                      </div>
                      <div className="input-group ">
                        <Field
                          name="password"
                          className="form-control"
                          type="password"
                        />
                        {errors.password && touched.password ? (
                          <div>{errors.password}</div>
                        ) : null}
                        <span className="input-group-append">
                          <span className="input-group-text">
                            <i className="bx bx-lock text-4"></i>
                          </span>
                        </span>
                      </div>
                    </div>

                    <div className="row pb-3">
                      <div className="col-sm-12">
                        <button
                          className="btn btn-primary mt-2  js-btn-login"
                          type="submit"
                        >
                          Iniciar sesión
                        </button>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
            <p className="text-center text-muted mt-3 mb-3">
              &copy; Copyright 2022 Todos los derechos reservados.
            </p>
          </div>
        </div>
      </section>
      {/* {sweet && (
        <SweetAlert
          success
          title="Bienvenido!"
          onConfirm={() => setSweet(!sweet)}
        />
      )} */}
    </>
  );
};

export default LoginAdmin;

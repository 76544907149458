import React, { useState, useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { MotoByID } from "../utils/Catalogue";
import { useChangeBackground } from "../hooks/useChangeBackground";
import { useChangeTitleAppBar } from "../hooks/useChangeTitleAppBar";
import {
  CreateNewMotoColor,
  UpdateMotoColor,
  DeleteMotoColor,
} from "../utils/MotosColors";
import { ListColors } from "../utils/Colors";
import Helmet from "react-helmet";
import Swal from "sweetalert2";
import AdminMui from "../components/Masters/AdminMui";
import ColorMotosTable from "../components/Organisms/Color/ColorMotosTable";
import EditColorMotos from "../components/Organisms/Color/EditColorMotos";
import useVerifyMenu from "../hooks/useVerifyMenu";

export const ColorsMotosAdmin = () => {
  const history = useHistory();
  const back = useVerifyMenu();
  let location = useLocation();
  let url_array = location.pathname.split("/");
  let id = null;

  if (url_array.length > 3) {
    id = url_array[4];
  }

  const [motojson, setMotoJson] = useState([]);
  const [listcolor, setListColor] = useState([]);
  const [againFetch, setAgainFetch] = React.useState(false);
  const [showModal, setShowMondal] = React.useState({
    show: false,
    data: {},
  });

  useChangeBackground("#f5f5f8");
  useChangeTitleAppBar(`Colores de ${motojson.nombre}`);

  useEffect(() => {
    MotoByID(id)
      .then((res) => {
        setMotoJson(res.data);
      })
      .catch((err) =>
        Swal.fire({
          title: "Error!",
          text: err.message || "",
          icon: "error",
          confirmButtonText: "Ok",
        })
      );
    ListColors().then((res) => setListColor(res.data));
  }, [againFetch, id]);

  const ApiDeleteColor = (id) => {
    return DeleteMotoColor(id);
  };

  const handleClick = (motonewcolor, selectedImage) => {
    if (showModal?.data) {
      UpdateMotoColor(motonewcolor, selectedImage)
        .then((res) => {
          setShowMondal({
            show: false,
            data: {},
          });
          Swal.fire({
            title: "Proceso Ejecutado exitosamente!",
            text: res.message || "",
            icon: "success",
            confirmButtonText: "Ok",
          }).then(() => setAgainFetch(!againFetch));
        })
        .catch((err) => {
          setShowMondal({
            show: false,
            data: {},
          });
          Swal.fire({
            title: "Error!",
            text: err.message || "",
            icon: "error",
            confirmButtonText: "Ok",
          });
        });
    } else {
      CreateNewMotoColor(motonewcolor, selectedImage)
        .then((res) => {
          setShowMondal({
            show: false,
            data: {},
          });
          Swal.fire({
            title: "Proceso Ejecutado exitosamente!",
            text: res.message || "",
            icon: "success",
            confirmButtonText: "Ok",
          }).then(() => setAgainFetch(!againFetch));
        })
        .catch((err) => {
          setShowMondal({
            show: false,
            data: {},
          });
          Swal.fire({
            title: "Error!",
            text: err || "",
            icon: "error",
            confirmButtonText: "Ok",
          });
        });
    }
  };

  return !back ? (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Colores de motos - Supre</title>
        <meta name="description" content="listado de motos admin supre" />
      </Helmet>
      <AdminMui>
        <ColorMotosTable
          motojson={motojson ? motojson : null}
          ApiDeleteColor={ApiDeleteColor}
          setAgainFetch={setAgainFetch}
          setShowMondal={setShowMondal}
          againFetch={againFetch}
        />
        {showModal.show && (
          <EditColorMotos
            showModal={showModal}
            setShowMondal={setShowMondal}
            handleClick={handleClick}
            listcolor={listcolor}
            motojson={motojson ? motojson : null}
          />
        )}
      </AdminMui>
    </>
  ) : (
    <>{history.push("/admin/login")}</>
  );
};

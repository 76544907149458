import React, { useContext } from "react";
import { CounterContext } from "../../../contexts/CounterContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faImages, faCartShopping } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { formatCurrency } from "../../../helpers/FormatMoney";
import GeneralData from "../../../data/GeneralData.js";
import clsx from "clsx";
import "./style.css";

export const CardMotos = ({ LIST, ListasMotos }) => {
  const { comparador, setComparador, modalCar, setModalCar } =
    useContext(CounterContext);

  const handleComparar = (moto) => {
    let motosComparador = comparador.product;
    if (motosComparador.map((elemnt) => elemnt.id).indexOf(moto.id) < 0) {
      motosComparador.length === 3 && motosComparador.shift();
      motosComparador.push(moto);
    }
    setComparador((prevState) => ({
      ...prevState,
      modal: true,
      product: motosComparador,
    }));
  };

  const handleCar = (moto) => {
    if (
      modalCar.product.find((element) => element.id === moto.id) === undefined
    ) {
      modalCar.product.push(moto);
    }
    setModalCar((prevState) => ({
      ...prevState,
      state: true,
      product: modalCar.product,
    }));
  };

  return ListasMotos.map((item) => (
    <div className={clsx("c-list-4__item", LIST && "LIST2")} key={item.id}>
      <div className={clsx("c-list-4__icons-grid", LIST && "LIST5")}>
        <div className="c-list4__compare" onClick={() => handleComparar(item)}>
          <FontAwesomeIcon icon={faImages} />
          <div className="o-tooltip-bottom-v2">Añadir al comparador</div>
        </div>
        <div className="c-list4__compare" onClick={() => handleCar(item)}>
          <FontAwesomeIcon icon={faCartShopping} />
          <div className="o-tooltip-bottom-v3">Añadir al carrito de compra</div>
        </div>
      </div>
      <Link
        className={clsx("c-list-4__link", LIST && "LIST7")}
        to={`/catalogo-de-motos/product/${item.slug_moto}`}
        key={item.nombre}
      >
        <div className={clsx("c-list-4__img", LIST && "LIST4")} key={item.id}>
          <img
            src={GeneralData.host + item.url_foto}
            alt={item.nombre}
          />
        </div>
        <div className="c-list-4__box" key={item.slug_moto}>
          <div className="c-list-4__heading">
            <div className="c-list-4__box-2">
              <div className="c-list-4__name"> {item.nombre}</div>
              <div className="c-list-4__brand">
                {item.MotosMarca.nombre_marca}
              </div>
            </div>
            <div className={clsx("c-list-4__icons-list", LIST && "LIST6")}>
              <div className="c-list4__compare2">
                <FontAwesomeIcon icon={faImages} />
              </div>
            </div>
          </div>
          <p className={clsx("c-list-4__p", LIST && "open")}>
            {item.descripcion_corta}
          </p>
          <div className="c-list-4__box-3">
            <div className="c-list-4__colors">
              {item.MotosColores.map((p) => (
                <ul className="c-list-5" key={Math.random() * 9 + 1}>
                  <li style={{ backgroundColor: p.Colore.codigo_color }}></li>
                </ul>
              ))}
            </div>
            <div className="c-list-4__price">
              {formatCurrency("es-CO", "COP", 0, item.precio_moto)}
            </div>
          </div>
        </div>
      </Link>
    </div>
  ));
};

/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAdd } from "@fortawesome/free-solid-svg-icons";
import { CardProduct } from "../CardProduct/CarProduct";
import { CotizadorProduct } from "../CotizadorProduct/CotizadorProduct";
import { InfoCredit } from "../InfoCredit/InfoCredit";
import { SuccesRequest } from "../SuccesRequest/SuccesRequest";
import { SaveCreditApplication } from "../../../utils/CreditApplication";
import { Email } from "../../../helpers/Email";
import { htmlAdmin } from "../../../data/initEmail/emailAdminSolicitudMoto";
import { ModalListMotos } from "../ModalListMotos";
import GeneralData from "../../../data/GeneralData";
import "./style.css";

export const ProductCredit = ({ product }) => {
  const history = useHistory();
  const [stateAplication, setStateAplication] = useState(false);
  const [openSearch, setOpenSearch] = useState(false);
  const [save, setSave] = useState(false);
  const [values, setValues] = useState({
    loading: false,
    tap: 1,
    marca_moto: "",
    nombre_moto: "",
    color_moto: "",
    precio_moto: "",
    abono_inicial: 0,
    discount: false,
    cupon: "",
    valor_cupon_descuento: 0,
    plazo: 24,
    cuota_min: 0,
    cuota_max: 0,
    cedula: "",
    nombres: "",
    apellidos: "",
    fecha_nacimiento: null,
    genero: "",
    estado_civil: "",
    personas_cargo: "",
    nivel_educacion: "",
    ciudad: "",
    tipo_vivienda: "",
    direccion: "",
    celular: "",
    email: "",
    ocupacion: "",
    nombre_empresa: "",
    direccion_empresa: "",
    ingresos: 0,
    terminos: false,
    ip_usuario: "",
    codigo_referido: "",
  });

  useEffect(() => {
    setValues((prevState) => ({
      ...prevState,
      marca_moto: product?.MotosMarca?.nombre_marca,
      nombre_moto: product?.nombre,
      color_moto: product?.MotosColores[0].Colore.nombre_color,
      precio_moto: product?.precio_moto,
    }));
  }, [product]);

  useEffect(() => {
    if (save) {
      const { loading, ...newValues } = values;
      SaveCreditApplication(newValues)
        .then((res) => {
          const subjectDynamic = res.data.usuario_referido ? '- Referido por el cliente: ' + res.data.usuario_referido : null

          setStateAplication(true);
          setValues((prevState) => ({
            ...prevState,
            loading: false,
          }));
          Email({
            to: GeneralData.from,
            subject: `Solicitud financiamiento moto: ${values.nombres} ${values.apellidos} ${subjectDynamic ?? ''}`,
            text: "Solicitud financiamiento moto",
            html: htmlAdmin,
            attachments: [],
            options: values,
          });
        })
        .catch((err) => {
          console.log(err);
          setStateAplication(false);
          setValues((prevState) => ({
            ...prevState,
            loading: false,
          }));
        });
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [save]);

  const handleSelectModal = (props) => {
    history.push(`/financiamiento/${props.slug_moto}`);
  };

  return (
    <>
      <ModalListMotos
        openSearch={openSearch}
        setOpenSearch={setOpenSearch}
        handleSelect={handleSelectModal}
      />
      <div
        className="content-row-sc c-block-5__section-1-sc"
        style={{ marginTop: "25px" }}
      >
        <div className="g-col-xl-6-sc g-col-offset-xl-3-sc">
          <div className="c-block-5__heading-4--v2-sc">
            Tú sí puedes tener una
          </div>
          <div className="c-block-5__heading-4--v3-sc">
            <span>¡MOTO NUEVA!</span>
          </div>
        </div>
        <div className="g-col-xl-6-sc-1 c-block-5__heading-5-sc">
          Te ofrecemos <strong>las mejores opciones de crédito</strong> de
          acuerdo a tu perfil, para que puedas comprar tu moto ideal y llegar
          tan lejos como esperas.
        </div>
      </div>
      <div className="content-row-sc c-block-5__section-1-sc">
        <div className="g-col-xl-4-sc g-col-offset-xl-4-sc">
          <div className="c-block-5__box-2-sc">
            {product?.id === undefined ? (
              <div className="c-list-4__item-2--vacio-sc">
                <button className="o-btn-4" onClick={() => setOpenSearch(true)}>
                  <FontAwesomeIcon
                    icon={faAdd}
                    style={{ marginRight: "20px" }}
                  />
                  <span>Buscar moto</span>
                </button>
              </div>
            ) : (
              <CardProduct product={product} setValues={setValues} />
            )}
          </div>
        </div>
        <div className="g-col-xl-8-sc" id="ref-proceso-registro">
          {values.tap === 1 ? (
            <CotizadorProduct
              product={product}
              setValues={setValues}
              values={values}
            />
          ) : values.tap === 2 ? (
            <InfoCredit
              setValues={setValues}
              values={values}
              setSave={setSave}
            />
          ) : (
            <SuccesRequest
              values={values}
              setValues={setValues}
              stateAplication={stateAplication}
            />
          )}
        </div>
      </div>
    </>
  );
};

import React from "react";
import Helmet from "react-helmet";
import { Portada } from "../components/molecules/Portada";
import { FormContactos } from "../components/molecules/FormContactos";
import { InforLocalizacion } from "../components/molecules/InfoLocalizacion";
import "../css/Contactos.css";

export const Contactos = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Contáctanos - Supre</title>
        <meta name="description" content="Contactos Supre" />
      </Helmet>
      <Portada className="c-block-3" Name="Contáctanos" id="contact" />
      <div className="c-block-4">
        <div className="g-containerC c-block-4__container">
          <FormContactos />
          <InforLocalizacion />
        </div>
      </div>
    </>
  );
};

import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  Typography,
  TextField,
  Divider,
} from "@mui/material";
import { ExitToApp } from "@mui/icons-material";
import SaveIcon from "@mui/icons-material/Save";

export const EditFrontBrake = ({ showModal, setShowMondal, handleClick }) => {
  const [initialValue, setInitialValue] = React.useState(showModal?.data);
  const handleChange = (e) => {
    const { value, name } = e.target;
    setInitialValue((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  return (
    <Dialog
      open={showModal.show}
      keepMounted
      onClose={ExitModal}
      fullWidth
      maxWidth="xs"
    >
      <Typography
        style={{ fontSize: "41px", margin: "16px 24px" }}
        variant="h1"
        gutterBottom
      >
        {showModal.data ? "Editar freno delantero" : "Crear freno delantero"}
      </Typography>
      <Divider />
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              label="Nombre del freno delantero"
              name="nombre_freno_delantero"
              fullWidth
              value={initialValue?.nombre_freno_delantero}
              onChange={handleChange}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <Divider style={{ marginBottom: "10px" }} />
      <DialogActions>
        <Button
          size="medium"
          variant="contained"
          style={{ height: 40 }}
          onClick={() => {
            if (initialValue) {
              handleClick(initialValue);
            }
          }}
        >
          <SaveIcon style={{ fontSize: 25 }} />
        </Button>
        <Button
          size="medium"
          variant="contained"
          color="error"
          onClick={ExitModal}
          style={{ height: 40 }}
        >
          <ExitToApp style={{ fontSize: 25 }} />
        </Button>
      </DialogActions>
    </Dialog>
  );
  function ExitModal(e) {
    setShowMondal({
      show: false,
      data: {},
    });
  }
};

import React from "react";
import { Helmet } from "react-helmet";
import { Portada } from "../components/molecules/Portada";
import { RegisterForm } from "../components/Organisms/Register/RegisterForm";
import "../css/Register.css";

export const Register = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Registro - Supre</title>
        <meta name="description" content="Registro Supre" />
      </Helmet>
      <Portada className="c-block-3" id="Register" Name="Regístrate" />
      <div className="c-block-4-r">
        <div className="g-container-r">
          <div className="c-block-4__box-4-r">
            <RegisterForm />
          </div>
        </div>
      </div>
    </>
  );
};

import React from "react";
import collectionAllies from "../../atoms/CollectionAllies/collectionAllies";
import { Grid } from "@mui/material";

export const Bancos = () => {
  // Filtrar aliados con convenio "11237"
  const filteredAllies = collectionAllies.filter((ally) =>
    ally.cuentas.some((cuenta) => cuenta.numcuenta === "11237")
  );

  // Filtrar aliados con tipocuenta "Pago en linea"
  const onlinePaymentAllies = collectionAllies.filter((ally) =>
    ally.cuentas.some((cuenta) => cuenta.tipocuenta === "Pago en linea")
  );

  // Filtrar aliados excluyendo los que tienen convenio "11237" y tipocuenta "Pago en linea"
  const excludedAllies = collectionAllies.filter(
    (ally) =>
      !ally.cuentas.some(
        (cuenta) =>
          cuenta.numcuenta === "11237" || cuenta.tipocuenta === "Pago en linea"
      )
  );
  return (
    <div>
      <p className="c-block-5__pc">
        Aliados con convenio <b>11237</b>{" "}
      </p>
      <Grid
        container
        spacing={2}
        style={{
          display: "flex",
          alignContent: "center",
          justifyContent: "center",
          marginBottom: "10px",
        }}
      >
        {filteredAllies.map((item) => (
          <Grid item key={item.alliesId}>
            <div className="c-list-3__logo">
              <img src={item.cuentas[0].urlimg} alt={item.name} />
            </div>
          </Grid>
        ))}
      </Grid>
      <p className="c-block-5__pc">Pagos aliados</p>
      {excludedAllies.map((item) => (
        <Grid className="c-list-3__item-2" item key={item.alliesId}>
          <div className="c-list-3__logo">
            <img src={item.cuentas[0].urlimg} alt={item.name} />
          </div>
          <div className="c-list-3__info">
            <div className="c-list-3__info-1">{item.cuentas[0].numcuenta}</div>
            <div className="c-list-3__info-2">
              {item?.cuentas[0].tipocuenta}
            </div>
          </div>
        </Grid>
      ))}
      <p className="c-block-5__pc">Pasarelas de pagos</p>
      <Grid
        container
        spacing={2}
        style={{
          display: "flex",
          alignContent: "center",
          justifyContent: "center",
          marginBottom: "10px",
        }}
      >
        {onlinePaymentAllies.map((item) => (
          <Grid item key={item.alliesId}>
            <div className="c-list-3__logo">
              <img src={item.cuentas[0].urlimg} alt={item.name} />
            </div>
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

import React from "react";
import { DataGridPro, GridActionsCellItem, esES } from "@mui/x-data-grid-pro";
import ModeEditOutlinedIcon from "@mui/icons-material/ModeEditOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import Swal from "sweetalert2";
import { Button } from "@mui/material";

export const MarkTable = ({
  setShowMondal,
  marks,
  setAgainFetch,
  againFetch,
  ApiDeleteMark,
}) => {
  const columns = [
    {
      field: "nombre_marca",
      headerName: "Nombre marca",
      flex: 1,
    },
    {
      field: "actions",
      headerName: "Acciones",
      type: "actions",
      getActions: (data) => [
        <GridActionsCellItem
          icon={<ModeEditOutlinedIcon style={{ fontSize: 20 }} />}
          label="Edit"
          onClick={(e) => {
            e.stopPropagation();
            EditRowSelected(data?.row);
          }}
        />,
        <GridActionsCellItem
          icon={<DeleteOutlineOutlinedIcon style={{ fontSize: 20 }} />}
          label="Delete"
          onClick={(e) => {
            e.stopPropagation();
            DeleteRow(data?.row);
          }}
        />,
      ],
    },
  ];

  const addRow = () => {
    setShowMondal({
      show: true,
      data: undefined,
    });
  };
  return (
    <div style={{ height: 672, marginTop: 12 }}>
      <Button
        variant="contained"
        onClick={addRow}
        style={{ width: 200, height: 40, marginBottom: 20, fontSize: 13 }}
      >
        Agregar marca
      </Button>
      <DataGridPro
        rows={marks}
        columns={columns}
        localeText={esES.components.MuiDataGrid.defaultProps.localeText}
        pageSize={15}
        pagination
        style={{ fontSize: 15 }}
      />
    </div>
  );
  function EditRowSelected(rowData) {
    //display modal for edit or create
    setShowMondal({
      show: true,
      data: rowData,
    });
  }

  function DeleteRow(rowData) {
    Swal.fire({
      title: "¿Estás seguro que quieres eliminar esta fila?",
      text: "No podrás revertir estos cambios.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      cancelButtonText: "Cancelar",
      confirmButtonText: "Si",
    }).then((result) => {
      if (result.isConfirmed) {
        ApiDeleteMark({ id: rowData.id, state: 0 }).then(
          Swal.fire(
            "Eliminado!",
            "Este archivo se ha elimidado",
            "Satisfactoriamente"
          ).then(() => setAgainFetch(!againFetch))
        );
      }
    });
  }
};

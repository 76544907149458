import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import "./style.css";

export const BtnClose = ({ funtion, name, id }) => {
  return (
    <button onClick={funtion} className="o-btn-5 o-is-hidden" id={id} key={id}>
      {name}
      <FontAwesomeIcon
        className="icon-cross2"
        icon={faXmark}
        onClick={funtion}
      />
    </button>
  );
};

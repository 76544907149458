import GeneralData from "../GeneralData.js";

export var htmlRequeriment = `<!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Transitional//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">
<html xmlns="http://www.w3.org/1999/xhtml" lang="es">

    <head>
        <meta http-equiv="Content-Type" content="text/html; charset=UTF-8">
        <title>Requerimiento - Supre</title>
        <meta name="viewport" content="width=device-width, initial-scale=1.0">

    </head>

    <body style="-moz-osx-font-smoothing: grayscale; -webkit-font-smoothing: antialiased; color: #6d757c; font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; font-size: 16px; font-weight: 400; margin: 0; padding: 0;">
        <table width="100%" cellpadding="0" cellspacing="0" bgcolor="#ebeff2" class="full-content" style="-moz-osx-font-smoothing: grayscale; -webkit-font-smoothing: antialiased; background: #ebeff2; color: #6d757c; font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; font-size: 16px; font-weight: 400;">
            <tr>
                <td height="35" class="texto-inicial" style="-moz-osx-font-smoothing: grayscale; -webkit-font-smoothing: antialiased; color: #ebeff2; font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; font-size: 16px; font-weight: 400;"></td>
            </tr>
            <tr>
                <td style="-moz-osx-font-smoothing: grayscale; -webkit-font-smoothing: antialiased; color: #6d757c; font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; font-size: 16px; font-weight: 400;">
                    <table cellpadding="0" cellspacing="0" align="center" class="contenedor" style="-moz-osx-font-smoothing: grayscale; -webkit-font-smoothing: antialiased; color: #6d757c; font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; font-size: 16px; font-weight: 400; margin: 0 auto; max-width: 600px; width: 90%;">
                        <tr>
                            <td style="-moz-osx-font-smoothing: grayscale; -webkit-font-smoothing: antialiased; color: #6d757c; font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; font-size: 16px; font-weight: 400;">
                                <!-- header -->
                                <table width="100%" cellpadding="0" cellspacing="0" class="header" style="-moz-osx-font-smoothing: grayscale; -webkit-font-smoothing: antialiased; color: #6d757c; font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; font-size: 16px; font-weight: 400; width: 100%;">
                                    <tr>
                                        <td class="header__conten-logo" style="-moz-osx-font-smoothing: grayscale; -webkit-font-smoothing: antialiased; background: #fff; border-radius: 5px 5px 0 0; color: #6d757c; font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; font-size: 16px; font-weight: 400; padding: 20px;"><img src="${GeneralData.host}/images/logo-supre-dark-email@2x.png" width="144" alt="Logo Supre" style="-moz-osx-font-smoothing: grayscale; -webkit-font-smoothing: antialiased; color: #6d757c; font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; font-size: 16px; font-weight: 400;"></td>
                                    </tr>
                                </table>
                                <!-- / header -->
                                <!-- Bloque blanco-->
                                <table width="100%" cellpadding="0" cellspacing="0" style="-moz-osx-font-smoothing: grayscale; -webkit-font-smoothing: antialiased; color: #6d757c; font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; font-size: 16px; font-weight: 400;">
                                    <tr>
                                        <td class="bloque-blanco" style="-moz-osx-font-smoothing: grayscale; -webkit-font-smoothing: antialiased; background: #fff; color: #6d757c; font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; font-size: 16px; font-weight: 400;">
                                            <table cellpadding="0" cellspacing="0" align="center" class="contenido" style="-moz-osx-font-smoothing: grayscale; -webkit-font-smoothing: antialiased; color: #6d757c; font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; font-size: 16px; font-weight: 400; padding: 0 26px 0 26px; width: 100%;">
                                                <tr>
                                                    <td height="10" style="-moz-osx-font-smoothing: grayscale; -webkit-font-smoothing: antialiased; color: #6d757c; font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; font-size: 16px; font-weight: 400;"></td>
                                                </tr>
                                                <tr>
                                                    <td style="-moz-osx-font-smoothing: grayscale; -webkit-font-smoothing: antialiased; color: #6d757c; font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; font-size: 16px; font-weight: 400;">


														<!-- Contenido -->

                                                        <p style="-moz-osx-font-smoothing: grayscale; -webkit-font-smoothing: antialiased; color: #6d757c; font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; font-size: 16px; font-weight: 400; line-height: 1.4; margin-bottom: 25px; margin-top: 0;">Un usuario ha solicitado un requerimiento desde supre.com.co</p>

                                                        <p style="-moz-osx-font-smoothing: grayscale; -webkit-font-smoothing: antialiased; color: #6d757c; font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; font-size: 16px; font-weight: 400; line-height: 1.4; margin-bottom: 25px; margin-top: 0;">

															<strong class="strong" style="-moz-osx-font-smoothing: grayscale; -webkit-font-smoothing: antialiased; color: #151616; font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; font-size: 16px; font-weight: 400;">Requerimiento:</strong> @tipo_requerimiento

															<br>

                                                            <strong class="strong" style="-moz-osx-font-smoothing: grayscale; -webkit-font-smoothing: antialiased; color: #151616; font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; font-size: 16px; font-weight: 400;">Fecha de liquidación:</strong> @fecha_liquidacion

                                                            <br>

															<strong class="strong" style="-moz-osx-font-smoothing: grayscale; -webkit-font-smoothing: antialiased; color: #151616; font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; font-size: 16px; font-weight: 400;">Nombre:</strong> @nombres @apellidos

															<br>

															<strong class="strong" style="-moz-osx-font-smoothing: grayscale; -webkit-font-smoothing: antialiased; color: #151616; font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; font-size: 16px; font-weight: 400;">Celular:</strong> @celular

															<br>

															<strong class="strong" style="-moz-osx-font-smoothing: grayscale; -webkit-font-smoothing: antialiased; color: #151616; font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; font-size: 16px; font-weight: 400;">Email:</strong> @email

															<br>

															<strong class="strong" style="-moz-osx-font-smoothing: grayscale; -webkit-font-smoothing: antialiased; color: #151616; font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; font-size: 16px; font-weight: 400;">Cédula:</strong> @cedula

															<br>

															<strong class="strong" style="-moz-osx-font-smoothing: grayscale; -webkit-font-smoothing: antialiased; color: #151616; font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; font-size: 16px; font-weight: 400;">Placa moto:</strong> @placa_moto

															<br><br>

															<strong class="strong" style="-moz-osx-font-smoothing: grayscale; -webkit-font-smoothing: antialiased; color: #151616; font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; font-size: 16px; font-weight: 400;">Obervación:</strong>
															<br>
															@observacion

                                                        </p>

                                                        <!-- / Contenido -->


                                                    </td>
                                                </tr>
                                            </table>
                                        </td>
                                    </tr>
                                </table>
                                <!-- / Bloque blanco -->
                                <!-- Footer -->
                                <table width="100%" cellpadding="0" cellspacing="0" style="-moz-osx-font-smoothing: grayscale; -webkit-font-smoothing: antialiased; color: #6d757c; font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; font-size: 16px; font-weight: 400;">
                                    <tr>
                                        <td class="footer" style="-moz-osx-font-smoothing: grayscale; -webkit-font-smoothing: antialiased; background: #f7fafc; border-radius: 0 0 5px 5px; border-top: 1px solid #edf0f2; color: #6d757c; font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; font-size: 16px; font-weight: 400;">
                                            <table cellpadding="0" cellspacing="0" align="center" class="contenido" style="-moz-osx-font-smoothing: grayscale; -webkit-font-smoothing: antialiased; color: #6d757c; font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; font-size: 16px; font-weight: 400; padding: 0 26px 0 26px; width: 100%;">
                                                <tr>
                                                    <td height="30" style="-moz-osx-font-smoothing: grayscale; -webkit-font-smoothing: antialiased; color: #6d757c; font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; font-size: 16px; font-weight: 400;"></td>
                                                </tr>
                                                <tr>
                                                    <td class="footer__co1" style="-moz-osx-font-smoothing: grayscale; -webkit-font-smoothing: antialiased; color: #6d757c; font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; font-size: 16px; font-weight: 400; width: 60%;">
                                                        <table width="100%" cellpadding="0" cellspacing="0" style="-moz-osx-font-smoothing: grayscale; -webkit-font-smoothing: antialiased; color: #6d757c; font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; font-size: 16px; font-weight: 400;">
                                                            <tr>
                                                                <td style="-moz-osx-font-smoothing: grayscale; -webkit-font-smoothing: antialiased; color: #6d757c; font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; font-size: 16px; font-weight: 400;">Cordialmente,</td>
                                                            </tr>
                                                            <tr>
                                                                <td height="5" style="-moz-osx-font-smoothing: grayscale; -webkit-font-smoothing: antialiased; color: #6d757c; font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; font-size: 16px; font-weight: 500;">Supre</td>
                                                            </tr>
                                                        </table>
                                                    </td>
                                                    <td valign="middle" class="footer__co2" style="-moz-osx-font-smoothing: grayscale; -webkit-font-smoothing: antialiased; color: #6d757c; font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; font-size: 16px; font-weight: 400; width: 40%;"></td>
                                                </tr>
                                                <tr>
                                                    <td height="30" style="-moz-osx-font-smoothing: grayscale; -webkit-font-smoothing: antialiased; color: #6d757c; font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; font-size: 16px; font-weight: 400;"></td>
                                                </tr>
                                            </table>
                                        </td>
                                    </tr>
                                </table>
                                <!-- / Footer -->
                            </td>
                        </tr>
                    </table>
                </td>
            </tr>
            <tr>
                <td height="50" style="-moz-osx-font-smoothing: grayscale; -webkit-font-smoothing: antialiased; color: #6d757c; font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; font-size: 16px; font-weight: 400;"></td>
            </tr>
        </table>
    </body>

</html>`;

import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight, faLocationDot } from "@fortawesome/free-solid-svg-icons";

export const ContactosItem = ({ data }) => {
  return (
    <div className="c-list-3__item" key={Math.random()}>
      <div className="c-list-3__icon">
        <div className="icon-location5">
          <FontAwesomeIcon icon={faLocationDot} />
        </div>
      </div>
      <div className="c-list-3__box">
        <h3 className="c-list-3__heading">{data?.ciudadDepartamento}</h3>
        <ul className="c-list-3__items">
          {data.direcciones.map((item) => (
            <>
              <li className="icon-arrow-right4" key={Math.random() * 9}>
                <FontAwesomeIcon className="IconRight" icon={faAngleRight} />
                {item?.direccion}
                <br />
                {item?.barrio}
              </li>
              <p className="c-list-3__p-2">
                Lunes a viernes: {item?.horaAtencion}
              </p>
              <p className="c-list-3__p-2">
                Sabados: {item?.horaAtencionFinSemana}
              </p>
            </>
          ))}
        </ul>
      </div>
    </div>
  );
};

import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Portada } from "../components/molecules/Portada";
import { NewPasswordForm } from "../components/Organisms/Login/NewPasswordForm";
import "../css/NewPassword.css";

export const NewPassword = () => {
  let params = useParams();
  const [code, setCode] = useState();

  useEffect(() => {
    setCode(params.codeRegistro);
  }, [params]);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Cambiar contraseña - Supre</title>
        <meta name="description" content="Cambiar contraseña Supre" />
      </Helmet>
      <Portada
        className="c-block-3"
        id="Edit profile"
        Name="Cambiar contraseña"
      />
      <div className="c-block-4-np">
        <div className="g-container-np">
          <NewPasswordForm code={code} />
        </div>
      </div>
    </>
  );
};

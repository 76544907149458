import { useState, useEffect } from "react";
import { VerifyPermission } from "../utils/Permissions";
const useVerifyMenu = () => {
  const [back, setBack] = useState(false);

  useEffect(() => {
    verify();
    //eslint-disable-next-line
  }, []);
  return back;

  function verify() {
    VerifyPermission()
      .then((res) => {
        if (!res.data) {
          exitApp();
        }
      })
      .catch((err) => {
        exitApp();
      });
  }

  function exitApp() {
    setBack(true);
    localStorage.removeItem("SESSION_ID");
  }
};

export default useVerifyMenu;

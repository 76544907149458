import React from "react";
import { Typography } from "@mui/material";
import "./style.css";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "../AccordeonSettings/index";

export const AccordeonItem = ({  title, children, panelName, panelNameOpen }) => {

  const [expanded, setExpanded] = React.useState(panelNameOpen);

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <Accordion
      expanded={expanded === `${panelName}`}
      onChange={handleChange(`${panelName}`)}
      className="accordeonContainer"
    >
      <AccordionSummary
        aria-controls={`${panelName}`}
        id={`${panelName}`}
        className="titleContainer"
      >
        <Typography className="titleContainer__title">{title} </Typography>
      </AccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </Accordion>
  );
};

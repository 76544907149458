import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleCheck,
  faCircleExclamation,
} from "@fortawesome/free-solid-svg-icons";
import { getIpClient } from "../../../helpers/GetIp";
import { Register } from "../../../utils/Register";
import "../../../css/Register.css";

const RegisterSchema = Yup.object().shape({
  nombres: Yup.string().required("Este campo es requerido"),
  apellidos: Yup.string().required("Este campo es requerido"),
  cedula: Yup.number().required("Este campo es requerido"),
  email: Yup.string()
    .min(3, "Muy corto")
    .email("Correo invalido")
    .required("Este campo es requerido"),
  password: Yup.string()
    .min(3, "Demasiado corto")
    .required("Este campo es requerido"),
  passwordConfirmation: Yup.string()
    .oneOf([Yup.ref("password"), null], "Las contraseñas deben coincidir")
    .required("Este campo es requerido"),
  celular: Yup.string()
    .required("Este campo es requerido")
    .matches(
      /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
      "Número de celular no valido"
    )
    .min(10, "Número de celular no valido"),
  ciudad_envio: Yup.string().required("Este campo es requerido"),
  direccion_envio: Yup.string().required("Este campo es requerido"),
  check2: Yup.boolean().oneOf(
    [true],
    "Debes aceptar la Política de Privacidad de Datos"
  ),
});

export const RegisterForm = () => {
  const [errorApi, setErrorApi] = useState(2);
  const [ip, setIp] = useState();

  useEffect(() => {
    getIpClient()
      .then((result) => {
        setIp(result);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <>
      <Formik
        initialValues={{
          nombres: "",
          apellidos: "",
          cedula: "", // create de values initial
          email: "",
          celular: "",
          ciudad_envio: "",
          direccion_envio: "",
          password: "",
          passwordConfirmation: "",
          ip_usuario: ip,
          check2: false,
        }}
        validationSchema={RegisterSchema}
        onSubmit={(values) => {
          let data = values;
          data.ip_usuario = ip;
          data.codigo_referido = Math.random().toString(36).slice(2);
          data.codigo_registro = crypto.randomUUID();
          Register(data)
            .then((res) => {
              setErrorApi(1);
            })
            .catch((err) => setErrorApi(0));
        }}
      >
        {({ errors, touched }) => (
          <Form className="c-form-1-r" id="form-registro">
            <div className="c-form-1-r__heading">Formulario de registro</div>
            <div className="c-form-1-r__row">
              <div className="c-form-1-r__field js-show-error">
                <label className="o-form-label-r">Nombres</label>
                <Field
                  className="o-form-field-r"
                  type="text"
                  name="nombres"
                  autoComplete="off"
                />
                {errors.nombres && touched.nombres ? (
                  <div className="js_error">{errors.nombres}</div>
                ) : null}
              </div>
              <div className="c-form-1-r__field js-show-error">
                <label className="o-form-label-r">Apellidos</label>
                <Field
                  className="o-form-field-r"
                  type="text"
                  name="apellidos"
                  autoComplete="off"
                />
                {errors.apellidos && touched.apellidos ? (
                  <div className="js_error">{errors.apellidos}</div>
                ) : null}
              </div>
            </div>
            <div className="c-form-1-r__row">
              <div className="c-form-1-r__field js-show-error">
                <label className="o-form-label-r">Número de cédula</label>
                <Field
                  className="o-form-field-r"
                  type="number"
                  name="cedula"
                  autoComplete="off"
                />
                {errors.cedula && touched.cedula ? (
                  <div className="js_error">{errors.cedula}</div>
                ) : null}
              </div>
              <div className="c-form-1-r__field js-show-error">
                <label className="o-form-label-r">Correo electrónico</label>
                <Field
                  className="o-form-field-r"
                  type="email"
                  name="email"
                  autoComplete="off"
                />
                {errors.email && touched.email ? (
                  <div className="js_error">{errors.email}</div>
                ) : null}
              </div>
            </div>
            <div className="c-form-1-r__row">
              <div className="c-form-1-r__field js-show-error">
                <label className="o-form-label-r">Número de celular</label>
                <Field
                  className="o-form-field-r"
                  type="number"
                  name="celular"
                  autoComplete="off"
                />
                {errors.celular && touched.celular ? (
                  <div className="js_error">{errors.celular}</div>
                ) : null}
              </div>
              <div className="c-form-1-r__field js-show-error">
                <label className="o-form-label-r">Ciudad de envío</label>
                <Field
                  className="o-form-field-r"
                  type="text"
                  name="ciudad_envio"
                  autoComplete="off"
                />
                {errors.ciudad_envio && touched.ciudad_envio ? (
                  <div className="js_error">{errors.ciudad_envio}</div>
                ) : null}
              </div>
            </div>
            <div className="c-form-1-r__field js-show-error">
              <label className="o-form-label-r">Dirección de envío</label>
              <Field
                className="o-form-field-r"
                type="text"
                name="direccion_envio"
                autoComplete="off"
              />
              {errors.direccion_envio && touched.direccion_envio ? (
                <div className="js_error">{errors.direccion_envio}</div>
              ) : null}
            </div>
            <div className="c-form-1-r__separador"></div>
            <div className="c-form-1-r__field js-show-error">
              <label className="o-form-label-r">Crear contraseña</label>
              <Field
                className="o-form-field-r"
                type="password"
                name="password"
                autoComplete="off"
              />
              {errors.password && touched.password ? (
                <div className="js_error">{errors.password}</div>
              ) : null}
            </div>
            <div className="c-form-1-r__field js-show-error">
              <label className="o-form-label-r">Confirmar contraseña</label>
              <Field
                className="o-form-field-r"
                type="password"
                name="passwordConfirmation"
                autoComplete="off"
              />
              {errors.passwordConfirmation && touched.passwordConfirmation ? (
                <div className="js_error">{errors.passwordConfirmation}</div>
              ) : null}
            </div>
            <div className="c-form-ct__field js-show-error">
              <div className="o-form-check">
                <Field
                  name="check2"
                  type="checkbox"
                  className="o-form-check__campo"
                  id="check-2"
                  autoComplete="off"
                />
                <label htmlFor="check-2"></label>
                <span className="c-form-ct__text-1">
                  He leído y acepto Política de Privacidad de Datos.
                  <Link
                    className="o-link-1"
                    id="popupTerminos"
                    target="_blank"
                    to="/politicas/"
                  >
                    Ver detalle legal
                  </Link>
                </span>
              </div>
              {errors.check2 && touched.check2 ? (
                <div className="js_error">{errors.check2}</div>
              ) : null}
            </div>
            {errorApi === 0 ? (
              <div className="c-form-ct__field">
                <div className="c-form-ct__notification">
                  <div className="c-notify--alert_Requeriments">
                    <FontAwesomeIcon
                      className="icon_check"
                      icon={faCircleExclamation}
                    />
                    <div>
                      <div>
                        ¡Lo sentimos! Ha ocurrido un error inesperado. Por
                        favor, vuelve a intentarlo nuevamente. En caso de que ya
                        te encuentres registrado inicia sesión con tu correo.
                      </div>
                      <div>
                        <a href="/login/">Iniciar sesión</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              errorApi === 1 && (
                <div className="c-form-ct__field">
                  <div className="c-form-ct__notification">
                    <div className="c-notify--ok_Requeriments">
                      <FontAwesomeIcon
                        className="icon_check"
                        icon={faCircleCheck}
                      />
                      Registro exitoso.&nbsp;{" "}
                      <a href="/login/">Iniciar sesión</a>
                    </div>
                  </div>
                </div>
              )
            )}
            <div className="c-form-1-r__btn">
              <button className="o-btn-3 js-btn-registro-r" type="submit">
                Registrarme
              </button>
            </div>
          </Form>
        )}
      </Formik>
      <div className="c-modal-1__box-2-r">
        <span className="o-text-6-r">
          ¿Ya tienes cuenta?{" "}
          <a className="o-link-2-r" href="/login/">
            Ingresar
          </a>
        </span>
      </div>
    </>
  );
};

import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { CounterContext } from "../../../contexts/CounterContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight, faXmark } from "@fortawesome/free-solid-svg-icons";
import GeneralData from "../../../data/GeneralData.js";
import clsx from "clsx";
import "./style.css";

export const ModalComparar = () => {
  const { comparador, setComparador } = useContext(CounterContext);

  const handleClose = (id) => {
    let items = comparador.product;
    for (let i = 0; i < items.length; i++) {
      //eslint-disable-next-line
      if (items[i].id == id) {
        items.splice(i, 1);
        setComparador((prevState) => ({
          ...prevState,
          product: items,
        }));
      }
    }
  };

  return (
    <div
      className={clsx("c-modal-2", comparador.modal && "open")}
      id="comparador-modal-2"
    >
      <div className="c-modal-2__header">
        <div
          className="c-modal-2__close"
          onClick={() => {
            setComparador((prevState) => ({
              ...prevState,
              modal: false,
            }));
          }}
        >
          Ocultar
          <FontAwesomeIcon className="icon-arrow-right8" icon={faArrowRight} />
        </div>
      </div>
      <div className="c-modal-2__list">
        <div className="c-list-11" id="compare-list-moto">
          <div id="compare-moto" className="c-list-11__item">
            {comparador.product.map((element) => {
              if (element !== undefined) {
                //eslint-disable-next-line
                return (
                  <div className="c-list-11__img" key={element.id}>
                    <div className="c-list-11__icons-grid">
                      <div
                        className="c-list-11__close icon-cancel-square2"
                        onClick={() => handleClose(element.id)}
                      >
                        <FontAwesomeIcon icon={faXmark} />
                      </div>
                    </div>
                    <img
                      src={`${GeneralData.host}${element.url_foto}`}
                      alt="Moto"
                    />
                  </div>
                );
              } else {
                return <></>;
              }
            })}
          </div>
        </div>
      </div>
      {comparador.product.length === 3 && (
        <div className="c-modal-2__msj">
          {" "}
          Solo se pueden comparar 3 motos a la vez
        </div>
      )}
      <div className="c-modal-2__box">
        <Link
          className="c-modal-2__btn"
          to={
            comparador.product.length === 0
              ? `/comparador-de-motos/`
              : `/comparador-de-motos/?motos=${comparador.product
                  .map((elemnt) => elemnt.id)
                  .join(",")}`
          }
        >
          Comparar
        </Link>
      </div>
    </div>
  );
};

import { apiAdminInstance } from "../Api/ApiInstance";
import { string_to_slug } from "../helpers/Strings";

export async function ListSuspensionTrasera() {
  return await apiAdminInstance.get(
    "api/official-site/suspension-trasera/"
  );
}

export async function ListSuspensionTraserFilter() {
  return await apiAdminInstance.get(
    "api/official-site/suspension-trasera/filter"
  );
}

export async function DeleteSuspensionT(id) {
  return await apiAdminInstance.delete(
    "api/official-site/suspension-trasera/" + id
  );
}

export async function UpdateSuspensionT(suspension_name) {
  const { id, nombre_suspension_trasera, state } = suspension_name;
  const slug = nombre_suspension_trasera
    ? string_to_slug(nombre_suspension_trasera)
    : nombre_suspension_trasera;

  return await apiAdminInstance.put(
    "api/official-site/suspension-trasera/" + id,
    {
      nombre_suspension_trasera,
      slug,
      state,
    }
  );
}

export async function CreateSuspensionT(suspension_name) {
  const { nombre_suspension_trasera } = suspension_name;
  const slug = string_to_slug(nombre_suspension_trasera);

  return await apiAdminInstance.post(
    "api/official-site/suspension-trasera/",
    {
      nombre_suspension_trasera,
      slug,
      state: 1,
    }
  );
}

import React from "react";
import { Helmet } from "react-helmet";
import { Portada } from "../components/molecules/Portada";
import { BodyOrders } from "../components/Organisms/Profile/BodyOrders";
import "../css/Orders.css";

export const Orders = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Mis ordenes - Supre</title>
        <meta name="description" content="Ordenes Supre" />
      </Helmet>
      <Portada className="c-block-3" id="My orders" Name="Mis ordenes" />
      <div className="c-block-5-p--v2">
        <div className="g-container-p">
          <BodyOrders />
        </div>
      </div>
    </>
  );
};

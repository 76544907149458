import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import { CounterContext } from "../../../contexts/CounterContext";
import { formatCurrency } from "../../../helpers/FormatMoney";
import { ProductCar } from "../..//molecules/ProductCar/ProductCar";
import { CartBilling } from "../..//molecules/CartBilling/CartBilling";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import { getIpClient } from "../../../helpers/GetIp";
import {
  SaveOrder,
  SaveOrderDetail,
  UpdateOrderPay,
} from "../../../utils/Order";
import { WompiPay } from "../../../helpers/Wompi";
import { ReferenceGenerator } from "../../../helpers/ReferenceGenerator";
import Backdrop from "@mui/material/Backdrop";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Email } from "../../../helpers/Email";
import { htmlAdmin } from "../../../data/initEmail/emailOrder";
import GeneralData from "../../../data/GeneralData";
import clsx from "clsx";
import "./style.css";
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 4, padding: "0px" }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export const ModalCar = () => {
  const [value, setValue] = React.useState(0);
  const [paymentMethod, setPaymentMethod] = React.useState();
  const [status, setStatus] = React.useState(true);
  const { modalCar, setModalCar } = useContext(CounterContext);
  const history = useHistory();
  const codigoReferido = sessionStorage.getItem('referido')

  const totalPrice = () => {
    return modalCar.product.reduce(
      (a, b) =>
        a +
        parseInt(b.precio_moto || 0) +
        parseInt(b.buyMatr === true ? b.precio_papeles || 0 : 0),
      0
    );
  };

  const newProcess = () => {
    setModalCar({
      state: false,
      product: [],
    });
    setValue(0);
    setStatus(true);
    history.push("/perfil");
  };
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleSubmit = async () => {
    let valueOrder = {
      ordenid: await ReferenceGenerator(),
      valor_total: totalPrice(),
      metodo_pago: paymentMethod.id,
      orden_estado_id: 1,
      ip_usuario: await getIpClient(),
      codigo_referido: codigoReferido ?? '',
    };

    function asyncFunction(item, cb) {
      SaveOrderDetail(item);
      cb();
    }

    SaveOrder(valueOrder)
      .then((res) => {
        let valueDetailOrder = {};
        let requests = modalCar.product.map((element) => {
          valueDetailOrder = {
            orden_id: res.data.id,
            moto_id: element.id,
            nombre_moto: element.nombre,
            marca_moto: element.MotosMarca.nombre_marca,
            modelo_moto: element.modelo,
            color_moto: element?.colorSelect
              ? element?.colorSelect
              : element.MotosColores[0].Colore.codigo_color,
            cantidad: 1,
            precio_moto: element.precio_moto,
            precio_papeles: element?.buyMatr ? element.precio_papeles : "0",
          };
          return new Promise((resolve) => {
            asyncFunction(valueDetailOrder, resolve);
          });
        });

        if (paymentMethod.iden === "GATEWAY") {
          const valueTransaction = {
            price: valueOrder.valor_total,
            orderId: valueOrder.ordenid,
          };
          const savePay = (transaction) => {
            if (transaction.status === "APPROVED") {
              UpdateOrderPay({
                ordenid: valueOrder.ordenid,
                orden_estado_id: 3,
              });
            }
          };
          WompiPay(valueTransaction, savePay);
        }

        Promise.all(requests)
          .then(() => {
            Email({
              to: GeneralData.from,
              subject: `Compra moto`,
              text: "Compra moto",
              html: htmlAdmin,
              attachments: [],
              options: valueOrder,
            });
            newProcess();
          })
          .cath((err) => {
            setStatus(false);
          });
      })
      .catch((err) => {
        setStatus(false);
      });
  };

  return (
    modalCar.state === true && (
      <Backdrop
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={modalCar.state}
      >
        <div className="c-modal-card--right" id="modal-carrito">
          <div className="c-modal-card__header">
            <div className="c-modal-card__header-bar">
              <div className="c-modal-card__header-close">
                <button
                  className="c-modal-card__close"
                  onClick={() => {
                    setModalCar((prevState) => ({
                      ...prevState,
                      state: false,
                    }));
                  }}
                >
                  <FontAwesomeIcon
                    className="c-info__icon-modal"
                    icon={faClose}
                  />
                  Cerrar
                </button>
              </div>
              <div className="c-modal-card__header-title">
                Proceso de compra
              </div>
            </div>
          </div>
          <Box
            sx={{
              width: "100%",
              backgroundColor: "#213b87",
              minHeight: "auto",
              height: "100000px",
            }}
          >
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
                variant="fullWidth"
                indicatorColor="primary"
              >
                <Tab
                  label="Carrito de compra"
                  {...a11yProps(0)}
                  className={clsx("tab", value === 0 && "tab-active")}
                />
                <Tab
                  label="Datos de facturación"
                  {...a11yProps(1)}
                  className={clsx("tab", value === 1 && "tab-active")}
                />
              </Tabs>
            </Box>
            <TabPanel value={value} index={0}>
              {modalCar.product.map((element) => {
                return (
                  <ProductCar
                    element={element}
                    modalCar={modalCar}
                    setModalCar={setModalCar}
                  />
                );
              })}
            </TabPanel>
            <TabPanel value={value} index={1}>
              <CartBilling
                setModalCar={setModalCar}
                paymentMethod={paymentMethod}
                setPaymentMethod={setPaymentMethod}
                status={status}
              />
            </TabPanel>
          </Box>
          <div className="c-modal-card__footer-btns" id="footer-carrito">
            <div className="c-modal-card__gran-total">
              <div className="c-modal-card__gran-total-text-1">
                <span className="total">Total </span>
                <span id="precio-total-carrito">
                  {formatCurrency("es-CO", "COP", 1, totalPrice())}
                </span>
              </div>
              <div className="c-modal-card__gran-total-text-2">
                IVA Incluido
              </div>
            </div>
            {value === 0 ? (
              <div className="c-modal-card__footer-box" id="confirmar-carrito">
                <div className="c-modal-card__footer-btn-col">
                  <button
                    className="o-btn-card js-seguir-comprando"
                    onClick={() => {
                      setModalCar((prevState) => ({
                        ...prevState,
                        state: false,
                      }));
                    }}
                  >
                    Seguir comprando
                  </button>
                </div>
                <div className="c-modal-card__footer-btn-col">
                  <button
                    className="o-btn-card--v2"
                    onClick={() => setValue(1)}
                  >
                    Confirmar pedido
                  </button>
                </div>
              </div>
            ) : (
              value === 1 && (
                <div className="c-modal-card__footer-box" id="procesar-orden">
                  <div className="c-modal-card__footer-btn-col">
                    <button
                      className="o-btn-card js-volver-carrito"
                      onClick={() => setValue(0)}
                    >
                      Volver al carrito
                    </button>
                  </div>
                  <div className="c-modal-card__footer-btn-col">
                    <button
                      className="o-btn-card--v2 js-procesar-pedido"
                      onClick={handleSubmit}
                    >
                      Procesar orden
                    </button>
                  </div>
                </div>
              )
            )}
          </div>
        </div>
      </Backdrop>
    )
  );
};

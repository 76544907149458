import React from "react";
import { useChangeBackground } from "../hooks/useChangeBackground";
import useVerifyMenu from "../hooks/useVerifyMenu";
import { Helmet } from "react-helmet";
import { useHistory } from "react-router-dom";
import AdminMui from "../components/Masters/AdminMui";
import { useChangeTitleAppBar } from "../hooks/useChangeTitleAppBar";
import {
  CreateFrontBrake,
  ListFrontBrakes,
  UpdateFrontBrake,
} from "../utils/FrontBrake";
import Swal from "sweetalert2";
import { FronBrakeTable } from "../components/Organisms/FrontBrake/FrontBrakeTable";
import { EditFrontBrake } from "../components/Organisms/FrontBrake/EditFrontBrake";

export const FrontBrake = () => {
  const back = useVerifyMenu();
  const history = useHistory();
  const [frontBrakes, setFrontBrakes] = React.useState([]);
  const [againFetch, setAgainFetch] = React.useState(false);
  const [showModal, setShowMondal] = React.useState({
    show: false,
    data: {},
  });
  useChangeBackground("#f5f5f8");
  useChangeTitleAppBar("Configuración de frenos delanteros");

  React.useEffect(() => {
    ListFrontBrakes()
      .then((res) => setFrontBrakes(res.data))
      .catch((err) =>
        Swal.fire({
          title: "Error!",
          text: err.message || "",
          icon: "error",
          confirmButtonText: "Ok",
        })
      );
  }, [againFetch]);

  const ApiDeleteFrontBrake = (frontBrake) => {
    return UpdateFrontBrake(frontBrake);
  };

  const handleClick = (frontBrake) => {
    if (!frontBrake.id) {
      CreateFrontBrake(frontBrake)
        .then((res) => {
          setShowMondal({
            show: false,
            data: {},
          });
          Swal.fire({
            title: "Proceso Ejecutado exitosamente!",
            text: res.message || "",
            icon: "success",
            confirmButtonText: "Ok",
          }).then(() => setAgainFetch(!againFetch));
        })
        .catch((err) => {
          setShowMondal({
            show: false,
            data: {},
          });
          Swal.fire({
            title: "Error!",
            text: err || "",
            icon: "error",
            confirmButtonText: "Ok",
          });
        });
    } else {
      UpdateFrontBrake(frontBrake)
        .then((res) => {
          setShowMondal({
            show: false,
            data: {},
          });
          Swal.fire({
            title: "Proceso Ejecutado exitosamente!",
            text: res.message || "",
            icon: "success",
            confirmButtonText: "Ok",
          }).then(() => setAgainFetch(!againFetch));
        })
        .catch((err) => {
          setShowMondal({
            show: false,
            data: {},
          });
          Swal.fire({
            title: "Error!",
            text: err.message || "",
            icon: "error",
            confirmButtonText: "Ok",
          });
        });
    }
  };

  return !back ? (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Configuración de frenos delanteros - Supre</title>
        <meta name="description" content="frenos delanteros admin supre" />
      </Helmet>
      <AdminMui>
        <FronBrakeTable
          setShowMondal={setShowMondal}
          frontBrakes={frontBrakes}
          setAgainFetch={setAgainFetch}
          againFetch={againFetch}
          ApiDeleteFrontBrake={ApiDeleteFrontBrake}
        />
        {showModal.show && (
          <EditFrontBrake
            showModal={showModal}
            setShowMondal={setShowMondal}
            handleClick={handleClick}
          />
        )}
      </AdminMui>
    </>
  ) : (
    <>{history.push("/admin/login")}</>
  );
};

import { Card, Grid } from "@mui/material";
import React from "react";
import { styledGridSEO } from "../SEO/MuiStyles";
import LeftInformation from "./LeftInformation";
import Prices from "./Prices";

const PricesMotos = () => {
  return (
    <Card style={{ minHeight: 276 }}>
      <Grid container style={{ minHeight: 276 }}>
        <Grid item xs={2} style={styledGridSEO}>
          <LeftInformation />
        </Grid>
        <Grid item xs={10}>
          <Prices />
        </Grid>
      </Grid>
    </Card>
  );
};

export default PricesMotos;

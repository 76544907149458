import React from "react";
import { formatCurrency } from "../../../helpers/FormatMoney";
import GeneralData from "../../../data/GeneralData.js";
import "./style.css";

export const DetailOrder = ({ order }) => {
  return (
    <>
      <div className="c-block-5-p__heading-6">Detalle orden</div>
      <div className="c-block-5-p__tables">
        <div className="c-table-2-p--v2" key="Initial">
          <div className="c-table-2-p__row">
            <div className="c-table-2-p__col">
              <div className="c-table-2-p__heading">Número orden</div>
              <div className="c-table-2-p__content">{order.ordenid}</div>
            </div>
            <div className="c-table-2-p__col">
              <div className="c-table-2-p__heading">Fecha creación</div>
              <div className="c-table-2-p__content">
                {order.fecha_creado_order}
              </div>
            </div>
            <div className="c-table-2-p__col">
              <div className="c-table-2-p__heading">Total</div>
              <div className="c-table-2-p__content">
                {formatCurrency("es-CO", "COP", 0, order.valor_total)}
              </div>
            </div>
            <div className="c-table-2-p__col">
              <div className="c-table-2-p__heading">Método pago</div>
              <div className="c-table-2-p__content">{order.Helper.name}</div>
            </div>
            <div className="c-table-2-p__col">
              <div className="c-table-2-p__heading">Estado</div>
              <div
                className="o-tag-1"
                style={{
                  background: order.OrdenesEstado.color_fondo_estado,
                  color: order.OrdenesEstado.color_texto_estado,
                }}
              >
                {order.OrdenesEstado.nombre_estado}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="c-detail-order">
        <div className="c-block-5-p__heading-8">Motos compradas</div>
        <div className="c-block-5-p__tables">
          {order.OrdenesDetalles.map((element, index) => {
            return (
              <div className="c-table-2-p--v2" key={index}>
                <div className="c-table-2-p__row">
                  <div className="c-table-2-p__col">
                    <div className="c-table-2-p__content">
                      <div className="c-table-2__img">
                        <img
                          src={
                            GeneralData.host +
                            element.Moto.url_foto
                          }
                          alt={element.descripcion}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="c-table-2-p__col">
                    <div className="c-table-2-p__heading">Marca</div>
                    <div className="c-table-2-p__content">
                      {element.marca_moto}
                    </div>
                  </div>
                  <div className="c-table-2-p__col">
                    <div className="c-table-2-p__heading">Moto</div>
                    <div className="c-table-2-p__content">
                      {element.nombre_moto}
                    </div>
                  </div>
                  <div className="c-table-2-p__col">
                    <div className="c-table-2-p__heading">Modelo</div>
                    <div className="c-table-2-p__content">
                      {element.modelo_moto}
                    </div>
                  </div>
                  <div className="c-table-2-p__col">
                    <div className="c-table-2-p__heading">Color</div>
                    <div className="c-table-2-p__content">
                      <div
                        className="c-list-5__color js-select-color"
                        style={{ backgroundColor: element.color_moto }}
                      >
                        <div
                          className="c-list-5__color-boder"
                          style={{ borderColor: element.color_moto }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="c-table-2-p__col">
                    <div className="c-table-2-p__heading">Precio moto</div>
                    <div className="c-table-2-p__content">
                      {formatCurrency("es-CO", "COP", 0, element.precio_moto)}
                    </div>
                  </div>
                  <div className="c-table-2-p__col">
                    <div className="c-table-2-p__heading">
                      Precio documentos
                    </div>
                    <div className="c-table-2-p__content">
                      {element.precio_papeles > 0
                        ? formatCurrency(
                            "es-CO",
                            "COP",
                            0,
                            element.precio_papeles
                          )
                        : "No aplica"}
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

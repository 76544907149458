const GeneralData = {
  Referencia_de_pago: "SUPRE_6227585f07b5c",
  publicKey: "pub_prod_0ivkSyn1a3XMrk7UUtHITvFvi7slfc94",
  host_api: process.env.REACT_APP_URL_API,
  domain: process.env.REACT_APP_URL_DOMAIN,
  host: process.env.REACT_APP_HOST_SITE,
  from: process.env.REACT_APP_EMAIL_USER_FROM,
  link_davivienda: process.env.REACT_APP_LINK_DAVIVIENDA,
  politictUpdatedAt: process.env.REACT_APP_POLITIC_UPDATE_AT,
};

module.exports = GeneralData;

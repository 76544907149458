import React, { useState } from "react";
import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleCheck,
  faCircleExclamation,
} from "@fortawesome/free-solid-svg-icons";
import { ClientRecovery } from "../../../utils/RecoveryPassword";
import { Email } from "../../../helpers/Email";
import { htmlNewPassword } from "../../../data/initEmail/emailNewPassword";
import "../../../css/RecoveryPassword.css";

export const RecoveryPasswordForm = () => {
  const [state, setState] = useState(0);

  const RecoveryPasswordSchema = Yup.object().shape({
    email: Yup.string()
      .email("Correo invalido")
      .required("Este campo es requerido"),
  });

  const handleSave = (values) => {
    ClientRecovery(values)
      .then((res) => {
        Email({
          to: values.email,
          subject: "Recupera tu cuenta en Supre",
          text: "Recupera tu cuenta en Supre",
          html: htmlNewPassword,
          attachments: [],
          options: res.data,
        })
          .then((res) => {
            setState(2);
          })
          .catch((err) => setState(1));
      })
      .catch((err) => setState(1));
  };

  return (
    <div className="c-block-4-rp__box-3">
      <Formik
        initialValues={{
          email: "",
        }}
        validationSchema={RecoveryPasswordSchema}
        onSubmit={(values, { resetForm }) => {
          handleSave(values);
          resetForm();
        }}
      >
        {({ errors, touched }) => (
          <Form className="c-form-1-rp" id="form-recuperar-password">
            <div className="c-form-1-rp__heading">Recuperar contraseña</div>
            <div className="c-form-1-rp__text-intro">
              Te enviaremos un email para que puedas recuperar tu contraseña.
            </div>
            <div className="c-form-1-rp__field js-show-error">
              <label className="o-form-rp-label">Correo electrónico</label>
              <Field className="o-form-rp-field" type="email" name="email" />
              {errors.email && touched.email ? (
                <div className="js_error">{errors.email}</div>
              ) : null}
            </div>
            <div className="c-form-1-rp__field o-is-hidden-2 js-notification--error">
              <div className="c-form-1-rp__notification">
                <div className="c-notify-rp--error">
                  <i className="icon-notification2-rp"></i> El email ingresado
                  no existe.
                </div>
              </div>
            </div>
            <div className="c-form-1-rp__field o-is-hidden-2 js-notification--ok">
              <div className="c-form-1-rp__notification">
                <div className="c-notify-rp--ok">
                  <i className="icon-checkmark"></i> Se ha enviado un email con
                  la información para recuperar tu contraseña.
                </div>
              </div>
            </div>
            <div className="c-form-1-rp__btn">
              <button className="o-btn-3--v2" type="submit">
                Enviar
              </button>
            </div>
            {state === 2 ? (
              <div className="c-form-ct__field">
                <div className="c-form-ct__notification">
                  <div className="c-notify--ok_Requeriments">
                    <FontAwesomeIcon
                      className="icon_check"
                      icon={faCircleCheck}
                    />
                    Correo enviado exitosamente
                  </div>
                </div>
              </div>
            ) : (
              state === 1 && (
                <div className="c-form-ct__field">
                  <div className="c-form-ct__notification">
                    <div className="c-notify--alert_Requeriments">
                      <FontAwesomeIcon
                        className="icon_check"
                        icon={faCircleExclamation}
                      />
                      ¡Lo sentimos! Ha ocurrido un error inesperado. Valida que
                      estes ingresando un correo que se encuentre registrado.
                      Por favor, vuelve a intentarlo nuevamente.
                    </div>
                  </div>
                </div>
              )
            )}
            <div className="c-modal-1-rp__box-2">
              <span className="o-text-6-rp">
                ¿Ya tienes cuenta?{" "}
                <a
                  className="o-link-2 js-step-registro-usuario-rp"
                  href="/login/"
                >
                  Ingresar
                </a>
              </span>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

import React, { useState, useEffect } from "react";
import { VerifyClientLogged } from "../../../utils/Permissions";
import GeneralData from "../../../data/GeneralData.js";
import "./style.css";

export const BodyAffiliateLink = () => {
  const [userLogged, setUserLogged] = useState(true);
  const [load, setLoad] = useState(true);

  useEffect(() => {
    VerifyClientLogged()
      .then((res) => {
        setUserLogged(res.data);
        setLoad(false);
      })
      .catch((err) => {});
  }, []);

  return load ? (
    <div className="menu-mobile-overlay">
      <div className="loader-popup">
        <div className="loader__box">
          <div className="icon-spinner9 animate-spin"></div>
        </div>
      </div>
    </div>
  ) : (
    <>
      <div className="c-block-5-p__heading-6">Link de afiliado</div>
      <div className="c-block-5-al__p-2">
        Puedes compartir tu link de afiliado y ganar comisiones por cada venta
        efectiva realizada.
      </div>
      <div className="c-block-5-al__tables">
        <div className="c-table-2-p--v2">
          <div className="c-table-2-p__row">
            <div className="c-table-2-p__col">
              <input
                className="o-form-field-al"
                type="text"
                name="link_afiliado"
                defaultValue={`${GeneralData.domain}/?referido=${userLogged.codigo_referido}`}
                readOnly
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

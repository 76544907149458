import React from "react";

export const useChangeBackground = (background) => {
  React.useEffect(() => {
    // eslint-disable-next-line
    let prev_background = React.memo(document.body.style.background);
    let new_background = background;
    document.body.style.background = background;
    return () => {
      return (prev_background = new_background);
    };
  }, [background]);
};

import React from "react";

export const Torque = ({ filtrar }) => {
  return (
    <div className="c-modal-1__section">
      <div className="c-modal-1__heading">Torque</div>
      <div className="c-modal-1__item">
        <div className="c-list-6">
          <div className="c-list-6__item">
            <div className="o-form-check">
              <input
                className="o-form-check__campo--v2"
                id="check-torque-hasta"
                type="radio"
                name="torque__check"
                value1="''"
                value2="45"
                btn_name="Hasta 45 NM"
                onClick={filtrar}
              />
              <label htmlFor="check-torque-hasta">
                <span className="c-form-1__text-2">Hasta 45 NM</span>
              </label>
              <input type="hidden" />
            </div>
          </div>
          <div className="c-list-6__item">
            <div className="o-form-check">
              <input
                className="o-form-check__campo--v2"
                id="check-torque-rango"
                type="radio"
                name="torque__check"
                value1="45"
                value2="75"
                btn_name="45 a 75 NM"
                onClick={filtrar}
              />
              <label htmlFor="check-torque-rango">
                <span className="c-form-1__text-2">45 a 75 NM</span>
              </label>
            </div>
          </div>
          <div className="c-list-6__item">
            <div className="o-form-check">
              <input
                className="o-form-check__campo--v2"
                id="check-torque-desde"
                type="radio"
                name="torque__check"
                value1="75"
                value2="''"
                btn_name="Más de 75 NM"
                onClick={filtrar}
              />
              <label htmlFor="check-torque-desde">
                <span className="c-form-1__text-2">Más de 75 NM</span>
              </label>
              <input type="hidden" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

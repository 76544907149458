const newsCard = [
  {
    id: "b4c347f406b54700ba12903235a25979",
    title: "Gana una motocicleta Pulsar 150 en nuestra promoción!",
    shortDescription:
      "Mantén tus pagos al día y podrías ser el afortunado ganador de una motocicleta Pulsar 150 en nuestro sorteo.",
    createdAt: "21/02/2024",
    description: `
    <div>
        <p>Imagina que tienes una motocicleta Pulsar 150 nueva ¡Y PUEDE SER TUYA! Todo comienza con algo tan simple: pagar tus cuotas a tiempo. Así es, cada mes que cierras al día ¡Te ganas una oportunidad para llevar a casa esa genial moto! Pero espera, hay algunas reglas simples que necesitas seguir: para participar solo necesitas mantener tus pagos al día, sin facturas vencidas ni pagos fuera de fecha, fácil ¿Verdad?.</p>
        <p>Ahora la parte emocionante: ¡EL SORTEO! El cual se llevará a cabo el viernes 19 de julio a las 5:00 p. m. en nuestra sede en Montería, también puedes verlo en vivo a través de nuestras redes sociales, así que asegúrate de sintonizarlas para ver quién será el afortunado ganador. Y si eres el elegido ¡TE LLEVARÁS LA MOTOCICLETA A CASA! Pero recuerda algunos gastos, como la matrícula y el SOAT, correrán por tu cuenta. Después de todo ¡LA AVENTURA DE LA MOTO ES TODA TUYA!.</p>
        <p>Si ganas necesitarás venir a recoger tu premio en nuestra sede, solo trae tu cédula de ciudadanía y prepárate para firmar algunos papeles ¡Ah, y no te olvides de sonreír para la foto que publicaremos en nuestras redes! Y si por alguna razón no puedes recoger la moto dentro de un mes, la moto encontrará un nuevo hogar en otra de nuestras promociones.</p>
        <p>Puedes cono conocer más información <a href="/preguntas-frecuentes/pregunta-10">aquí</a>.</p>
        <p>¡Así que adelante, mantén tus pagos al día y cruza los dedos para que esa motocicleta sea tuya! ¡Buena suerte para todos! Recuerda que en supre queremos #CrecerParaAyudarACrecer.</p>
    </div>`,
    image: "/images/cards/win-motorcycle.png",
    largeImage: "/images/cards/win-motorcycle-large.png",
    type: "portfolio",
  },
  {
    id: "b4c347f406b54701ba12903235a25989",
    title: "La ruta del sol en moto",
    shortDescription:
      "Colombia es un país con una gran riqueza natural y cultural, y su costa caribeña es uno de los destinos más populares del país.",
    createdAt: "21/02/2024",
    description: `
        <p>La Ruta del Sol es una vía que conecta Bogotá con Cartagena, y es una excelente opción para los viajeros que quieren disfrutar de los paisajes de la costa caribeña y de las playas paradisíacas de la zona.</p>
        <p>La Ruta del Sol se divide en tres tramos:</p>
        <ul>
            <li style="list-style-type: disc; margin-left: 15px; line-height: 23px"><strong>Ruta del Sol 1:</strong> Este tramo conecta Bogotá con Puerto Salgar, en el departamento de Cundinamarca. La carretera es de doble calzada y tiene una longitud de 320 kilómetros.</li>
            <li style="list-style-type: disc; margin-left: 15px; line-height: 23px"><strong>Ruta del Sol 2:</strong> Conecta Puerto Salgar con San Roque, en el departamento de Cesar. La carretera es de doble calzada y tiene una longitud de 450 kilómetros.</li>
            <li style="list-style-type: disc; margin-left: 15px; line-height: 23px"><strong>Ruta del Sol 3:</strong> Finaliza con la vía que conecta a San Roque con Cartagena, en el departamento de Bolívar. La carretera es de doble calzada y tiene una longitud de 300 kilómetros.</li>
        </ul>
        <p>A lo largo de la Ruta del Sol, hay muchos lugares interesantes para visitar, entre ellos:</p>
        <ul>
            <li style="list-style-type: disc; margin-left: 15px; line-height: 23px"><strong>El Parque Nacional Natural Tayrona:</strong> este parque nacional es uno de los más visitados de Colombia. Se encuentra ubicado en el departamento de Magdalena y cuenta con playas paradisíacas, manglares, selvas tropicales y ruinas arqueológicas.</li>
            <li style="list-style-type: disc; margin-left: 15px; line-height: 23px"><strong>La Ciudad Perdida:</strong> esta ciudad arqueológica es una de las más importantes de Colombia. Se encuentra ubicada en el departamento de Magdalena y se cree que fue construida por la civilización Tayrona.</li>
            <li style="list-style-type: disc; margin-left: 15px; line-height: 23px"><strong>La Catedral de Sal de Zipaquirá:</strong> esta catedral es una de las atracciones turísticas más populares de Colombia. Se encuentra ubicada en el departamento de Cundinamarca y está construida dentro de una mina de sal.</li>
            <li style="list-style-type: disc; margin-left: 15px; line-height: 23px"><strong>El Puente Pumarejo:</strong> es el más largo de Colombia y se encuentra ubicado en el departamento de Atlántico. Es una excelente oportunidad para disfrutar de las vistas de la ciudad de Barranquilla.</li>
            <li style="list-style-type: disc; margin-left: 15px; line-height: 23px"><strong>Las playas de Cartagena:</strong> Cartagena es una ciudad colonial con un hermoso centro histórico. Es también un destino turístico popular por sus playas paradisíacas.</li>
        </ul>
        <p>Un viaje en moto por la Ruta del Sol es una experiencia inolvidable que te permitirá conocer la riqueza natural y cultural de Colombia ¿Qué esperas para montarte en tu moto y comenzar la aventura?</p>`,
    image: "/images/cards/route-to-the-sun.png",
    largeImage: "/images/cards/route-to-the-sun-large.png",
    type: "marketing",
  },
  {
    id: "7241f27db00846ec942cc0c79fd14df8",
    title: "Las motos en Colombia: un medio de transporte imprescindible",
    shortDescription:
      "Las motos son un medio de transporte cada vez más popular en Colombia.",
    createdAt: "21/02/2024",
    description: `
        <p>En 2023, el parque automotor colombiano contaba con más de 10 millones de motos, lo que representa más del 70% del total de vehículos en el país.</p>

        <p>Hay varias razones para el auge de las motos en Colombia. En primer lugar, las motos son relativamente económicas, lo que las hace accesibles para un gran número de personas. En segundo lugar, las motos son muy eficientes en el consumo de combustible, lo que las hace una buena opción para el transporte en ciudades congestionadas. En tercer lugar, las motos son muy versátiles, lo que las hace adecuadas para una variedad de propósitos, desde el transporte diario hasta el trabajo y el ocio.</p>

        <p>Estas juegan un papel importante en el transporte urbano colombiano. Son una opción popular para las personas que necesitan desplazarse por las ciudades congestionadas. Las motocicletas son más eficientes en el consumo de combustible que los automóviles, lo que ayuda a la economía del motociclista. Además, estas son más maniobrables que los automóviles, lo que les permite esquivar el tráfico y llegar a su destino más rápido.</p>

        <p>Las motos también son una opción popular para las personas que disfrutan de la conducción recreativa. Ofreciendo una experiencia de conducción emocionante y estimulante que no se puede encontrar con otros vehículos. Además, son una buena opción para explorar la naturaleza y descubrir nuevos lugares.</p>

        <p>A pesar de los beneficios de las motos, también hay algunos desafíos asociados a su uso. Uno de los principales desafíos es la seguridad, puesto que son más vulnerables a los accidentes que los automóviles, por lo que es importante que los motociclistas tomen las medidas de seguridad necesarias, como usar casco y equipo reflectante.</p>

        <p>Otro desafío es la contaminación del aire. Las motos producen más emisiones de gases contaminantes que los automóviles, por lo que es importante que los motociclistas sean conscientes de su impacto ambiental.</p>

        <p>¿Qué opinas tú sobre las motos en Colombia?</p>`,
    image: "/images/cards/essential-means-transportation.png",
    largeImage: "/images/cards/essential-means-transportation-large.png",
    type: "marketing",
  },
  {
    id: "c432da277b4b4bf99b60f49f556d8eac",
    title: "Los pueblos patrimoniales de Colombia en moto",
    shortDescription:
      "Colombia es un país con una gran riqueza cultural e histórica, y sus pueblos patrimoniales son un reflejo de ello.",
    createdAt: "21/02/2024",
    description: `
        <p>Estos, declarados como tales por el Ministerio de Cultura, conservan su arquitectura colonial, sus tradiciones y su cultura, lo que los convierte en destinos turísticos imperdibles.</p>
        <p>Una de las mejores maneras de conocer los pueblos patrimoniales de Colombia es en moto. Este medio de transporte te permite recorrer los caminos sinuosos y pintorescos de estos pueblos, disfrutando de sus paisajes y de la tranquilidad de sus calles.</p>
        <p>La ruta de los pueblos patrimoniales de Colombia en moto puede comenzar en cualquier ciudad del país, pero lo más común es iniciar en Bogotá. Desde allí, se puede tomar la carretera que conduce a Villa de Leyva, uno de los pueblos coloniales más bellos de Colombia.</p>
        <ul>
            <li style="list-style-type: disc; margin-left: 15px; line-height: 23px"><strong>Villa de Leyva:</strong> es un pueblo mágico ubicado en el departamento de Boyacá. Su arquitectura colonial, sus calles empedradas y su ambiente tranquilo lo convierten en un destino ideal para los amantes de la historia y la cultura. Puedes visitar la Plaza Mayor, declarada como Monumento Nacional, el Museo del Oro de la Fundación del Banco de la República, el Parque Arqueológico El Infiernito y las Termales de Paipa.</li>
            <li style="list-style-type: disc; margin-left: 15px; line-height: 23px"><strong>Barichara:</strong> es otro pueblo colonial ubicado en el departamento de Santander. Es conocido como "el pueblo más lindo de Colombia" por su arquitectura colonial bien conservada, sus calles empedradas y su entorno natural. Allí puedes visitar la Plaza Mayor, la iglesia de Nuestra Señora del Rosario, el Parque Natural Regional El Socorro y el Pozo de los deseos.</li>
            <li style="list-style-type: disc; margin-left: 15px; line-height: 23px"><strong>Jardín:</strong> es un pueblo ubicado en el departamento de Antioquia. Es conocido como "el pueblo de los balcones" por sus coloridas casas con balcones de madera. En este pueblo no puedes dejar de ir a la Plaza Principal, la iglesia de San José, el Parque Nacional Natural Los Nevados y las Cascadas de Jardín.</li>
        </ul>
        <p>Además de Villa de Leyva, Barichara y Jardín, existen otros pueblos patrimoniales en Colombia que vale la pena visitar en moto. Algunos de ellos son:</p>
        <ul>
            <li style="list-style-type: disc; margin-left: 15px; line-height: 23px"><strong>Popayán:</strong> capital del departamento del Cauca, conocida como "la ciudad blanca" por su arquitectura colonial encalado.</li>
            <li style="list-style-type: disc; margin-left: 15px; line-height: 23px"><strong>Mompox:</strong> ubicado en el departamento de Bolívar, declarado como Patrimonio de la Humanidad por la UNESCO.</li>
            <li style="list-style-type: disc; margin-left: 15px; line-height: 23px"><strong>San Gil:</strong> ubicado en el departamento de Santander, conocido como "la capital del turismo de aventura".</li>
            <li style="list-style-type: disc; margin-left: 15px; line-height: 23px"><strong>Guatapé:</strong> ubicado en el departamento de Antioquia, conocido por su imponente Piedra del Peñol.</li>
        </ul>
        <p>Si estás planeando un viaje en moto por la ruta de los pueblos patrimoniales de Colombia, te recomendamos tener en cuenta los siguientes consejos:</p>
        <ul>
            <li style="list-style-type: disc; margin-left: 15px; line-height: 23px"><strong>Revisa tu moto antes de partir:</strong> Asegúrate de que esté en buenas condiciones mecánicas y que cuente con el equipo de seguridad necesario.</li>
            <li style="list-style-type: disc; margin-left: 15px; line-height: 23px"><strong>Planifica tu ruta:</strong> Investiga los pueblos que quieres visitar y planifica las rutas que vas a tomar.</li>
            <li style="list-style-type: disc; margin-left: 15px; line-height: 23px"><strong>Elige la moto adecuada:</strong> Si no tienes mucha experiencia en moto, es recomendable elegir una moto de cilindrada pequeña o media.</li>
            <li style="list-style-type: disc; margin-left: 15px; line-height: 23px"><strong>Sé prudente al conducir:</strong> Respeta las normas de tránsito y ten cuidado con las condiciones climáticas.</li>
        </ul>
        <p>Un viaje en moto por la ruta de los pueblos patrimoniales de Colombia es una experiencia inolvidable que te permitirá conocer la riqueza cultural e histórica de este hermoso país.</p>
    `,
    image: "/images/cards/heritage-towns.png",
    largeImage: "/images/cards/heritage-towns-large.png",
    type: "marketing",
  },
  {
    id: "8c0d8d6698a040f39a0e9c9ec7e83898",
    title: "¡Vive la independencia de una moto!",
    shortDescription:
      "En este blog, hablaremos sobre la independencia que una moto puede brindarte",
    createdAt: "21/02/2024",
    description: `
    <div>
        <p>Desde la libertad de moverte a donde quieras, hasta la sensación de aventura y libertad que solo una moto puede darte.</p>

        <p>Una de las principales ventajas de tener una moto es la libertad de movimiento que te brinda. No tienes que depender de horarios de transporte público, ni de la disponibilidad de taxis o Uber. Puedes ir a donde quieras, cuando quieras.</p>

        <p>Esto es especialmente importante en ciudades congestionadas, donde el tráfico puede ser un verdadero dolor de cabeza. Con una moto, puedes esquivar el tráfico y llegar a tu destino más rápido.</p>

        <p>Además, montar en moto es una experiencia emocionante que te hace sentir libre y aventurero. El viento en tu cara, el rugido del motor y la sensación de la carretera bajo tus ruedas son una sensación única que te hará querer salir a rodar todos los días.</p>

        <p>Si estás buscando una nueva forma de experimentar la vida, una moto es una excelente opción. Te brindará la libertad y la emoción que estás buscando.</p>
    </div>
    <div>
        <h3>Consejos para vivir la independencia de una moto</h3>
        <ul>
            <li style="list-style-type: disc; margin-left: 15px; line-height: 23px"><strong>Elige la moto adecuada para tus necesidades:</strong> hay muchos tipos diferentes de motos disponibles, así que toma tu tiempo para encontrar la que sea adecuada para ti. Si eres nuevo en el mundo de las motos, es recomendable empezar con una moto pequeña y manejable.</li>
            <li style="list-style-type: disc; margin-left: 15px; line-height: 23px"><strong>Toma lecciones de conducción:</strong> aprender a conducir una moto de forma segura es esencial. Las lecciones de conducción te enseñarán los fundamentos de la conducción segura, como cómo controlar la moto, frenar y tomar curvas.</li>
            <li style="list-style-type: disc; margin-left: 15px; line-height: 23px"><strong>Equipamiento de seguridad:</strong> siempre lleva el equipo de seguridad adecuado cuando conduzcas una moto. Esto incluye un casco, guantes, botas y ropa reflectante.</li>
            <li style="list-style-type: disc; margin-left: 15px; line-height: 23px"><strong>Sé respetuoso con los demás:</strong> recuerda que las motos son vehículos y deben respetarse las normas de tránsito. Sé cortés con los demás conductores y peatones.</li>
        </ul>
    </div>
    `,
    image: "/images/cards/independence-motorcycle.png",
    largeImage: "/images/cards/independence-motorcycle-large.png",
    type: "marketing",
  },
  {
    id: "298132cb9aa54f4b885d632400fc7519",
    title: "Las multas a motocicletas más comunes en Colombia",
    shortDescription:
      "Con el aumento de la popularidad de las motos en Colombia, también ha aumentado la cantidad de multas que se imponen a los motociclistas por incumplimiento de las normas de tránsito.",
    createdAt: "21/02/2024",
    description: `
    <div>
        <p>Según el informe del SIMIT, las multas a motociclistas más comunes en Colombia son las siguientes:</p>
        <ul>
            <li style="list-style-type: disc; margin-left: 15px; line-height: 23px">No usar casco: esta es la multa más común a motociclistas en Colombia. El valor de la multa es de 15 salarios mínimos diarios legales vigentes (SMLDV).</li>
            <li style="list-style-type: disc; margin-left: 15px; line-height: 23px">No respetar las señales de tránsito: esta también es muy común. El valor de la multa es de 15 SMLDV.</li>
            <li style="list-style-type: disc; margin-left: 15px; line-height: 23px">Conducir a una velocidad superior a la permitida: esta multa es importante para la seguridad de todos los usuarios de la vía. El valor de la multa es de 15 SMLDV.</li>
            <li style="list-style-type: disc; margin-left: 15px; line-height: 23px">Conducir en los andenes: esta influye en la seguridad de los peatones. El valor de la multa es de 15 SMLDV.</li>
            <li style="list-style-type: disc; margin-left: 15px; line-height: 23px">Conducir sin portar la licencia de conducción: dicha multa es importante para garantizar que los conductores de motos tengan las habilidades necesarias para conducir de forma segura. El valor de la multa es de 30 SMLDV.</li>
        </ul>
        <p>Otras multas comunes a motociclistas en Colombia incluyen:</p>
        <ul>
            <li style="list-style-type: disc; margin-left: 15px; line-height: 23px">Conducir sin portar los seguros ordenados por la ley: el valor de la multa es de 30 SMLDV.</li>
            <li style="list-style-type: disc; margin-left: 15px; line-height: 23px">Conducir con una motocicleta en mal estado mecánico: el valor de la multa es de 15 SMLDV.</li>
            <li style="list-style-type: disc; margin-left: 15px; line-height: 23px">Conducir en estado de embriaguez: el valor de la multa es de 100 SMLDV.</li>
        </ul>
    </div>
    <div>
        <h3>Consejos para motociclistas</h3>
        <ul>
            <li style="list-style-type: disc; margin-left: 15px; line-height: 23px"><strong>Siempre use casco:</strong> el casco es el elemento de seguridad más importante para los motociclistas.</li>
            <li style="list-style-type: disc; margin-left: 15px; line-height: 23px"><strong>Respete las señales de tránsito:</strong> las señales de tránsito están diseñadas para garantizar la seguridad de todos los usuarios de la vía.</li>
            <li style="list-style-type: disc; margin-left: 15px; line-height: 23px"><strong>Conduzca a la velocidad permitida:</strong> conducir a una velocidad superior a la permitida aumenta el riesgo de accidentes.</li>
            <li style="list-style-type: disc; margin-left: 15px; line-height: 23px"><strong>No conduzca en los andenes:</strong> los andenes son para peatones, no para vehículos.</li>
            <li style="list-style-type: disc; margin-left: 15px; line-height: 23px"><strong>Porte su licencia de conducción:</strong> la licencia de conducción es un documento obligatorio para conducir cualquier tipo de vehículo.</li>
            <li style="list-style-type: disc; margin-left: 15px; line-height: 23px"><strong>Porte los seguros obligatorios:</strong> los seguros obligatorios protegen a los conductores y a los terceros en caso de accidentes.</li>
            <li style="list-style-type: disc; margin-left: 15px; line-height: 23px"><strong>Conduzca su motocicleta en buen estado mecánico:</strong> una motocicleta en mal estado mecánico puede ser un peligro para usted y para los demás.</li>
            <li style="list-style-type: disc; margin-left: 15px; line-height: 23px"><strong>No conduzca en estado de embriaguez:</strong> conducir en estado de embriaguez es un delito grave que puede tener consecuencias fatales.</li>
        </ul>
    </div>
    `,
    image: "/images/cards/motorcycle-fines.png",
    largeImage: "/images/cards/motorcycle-fines-large.png",
    type: "marketing",
  },
];

export default newsCard;

import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Portada } from "../components/molecules/Portada";
import { useParams } from "react-router-dom";
import { Solicitud } from "../components/Organisms/SolicitudCredito/index";
import { infoMoto } from "../utils/GetMotos";
import "../css/SolicitudCredito.css";

export const SolicitudCredito = () => {
  let params = useParams();
  const [product, setProduct] = useState();

  useEffect(() => {
    let nameProduct = params.product;
    if (nameProduct !== "" & nameProduct !== undefined) {
      infoMoto(nameProduct).then((result) => {
        setProduct(result.data[0]);
      });
    } else {
      setProduct();
    }
  }, [params]);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          {product !== undefined
            ? `Financiar moto - ${product.nombre} - Supre`
            : "Financiamiento Supre"}
        </title>
        <meta name="description" content="Financiamiento motocicleta" />
      </Helmet>
      <Portada
        className="c-block-3"
        id="CatMotos"
        Name="Solicitud de crédito"
      />
      <div className="c-block-5--v2">
        <Solicitud product={product} />
      </div>
    </>
  );
};

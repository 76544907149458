import React from "react";

export const Potencia = ({ filtrar }) => {
  return (
    <div className="c-modal-1__section">
      <div className="c-modal-1__heading">Potencia</div>
      <div className="c-modal-1__item">
        <div className="c-list-6">
          <div className="c-list-6__item">
            <div className="o-form-check">
              <input
                className="o-form-check__campo--v2"
                id="check-potencia-hasta"
                type="radio"
                name="potencia__check"
                value1="''"
                value2="65"
                btn_name="Hasta 65 HP"
                onClick={filtrar}
              />
              <label htmlFor="check-potencia-hasta">
                <span className="c-form-1__text-2">Hasta 65 HP</span>
              </label>
            </div>
          </div>
          <div className="c-list-6__item">
            <div className="o-form-check">
              <input
                className="o-form-check__campo--v2"
                id="check-potencia-rango"
                type="radio"
                name="potencia__check"
                value1="65"
                value2="125"
                btn_name="65 a 125 HP"
                onClick={filtrar}
              />
              <label htmlFor="check-potencia-rango">
                <span className="c-form-1__text-2">65 a 125 HP</span>
              </label>
            </div>
          </div>
          <div className="c-list-6__item">
            <div className="o-form-check">
              <input
                className="o-form-check__campo--v2"
                id="check-potencia-desde"
                type="radio"
                name="potencia__check"
                value1="125"
                value2="''"
                btn_name="Más de 125 HP"
                onClick={filtrar}
              />
              <label htmlFor="check-potencia-desde">
                <span className="c-form-1__text-2">Más de 125 HP</span>
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

import React, { createContext, useState } from "react";

export const CounterContext = createContext();

export const CounterProvider = ({ children }) => {
  // variables oficiales (estas si mantenerlas en este context)
  const [comparador, setComparador] = useState({
    modal: false,
    product: [],
  });
  const [modalCar, setModalCar] = useState({
    state: false,
    product: [],
  });
  const [openBG, setOpenBG] = useState(false);

  // Variables para pruebas (Busqueda guiada) (mirar si se aplica otro context)
  const [estilosBG, setEstilosBG] = useState("");
  const [preciominBG, setPrecioMinBG] = useState("");
  const [preciomaxBG, setPrecioMaxBG] = useState("");
  const [transmisionBG, setTransmisionBG] = useState("");

  // variables para pruebas 2 (Abrir modal 2 comparador)
  const [handlecomparador, setHandleComparador] = useState(false);

  return (
    <CounterContext.Provider
      value={{
        comparador,
        setComparador,
        modalCar,
        setModalCar,
        openBG,
        setOpenBG,
        estilosBG,
        setEstilosBG,
        preciominBG,
        setPrecioMinBG,
        preciomaxBG,
        setPrecioMaxBG,
        transmisionBG,
        setTransmisionBG,
        handlecomparador,
        setHandleComparador,
      }}
    >
      {children}
    </CounterContext.Provider>
  );
};

import React, { useEffect, useState } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck } from "@fortawesome/free-solid-svg-icons";
import { getIpClient } from "../../../helpers/GetIp";
import { RequerimentSave } from "../../../utils/FormRequeriments";
import { Email } from "../../../helpers/Email";
import { htmlRequerimentClient } from "../../../data/initEmail/emailRequerimentClient";
import { htmlRequeriment } from "../../../data/initEmail/emailRequeriment";
import GeneralData from "../../../data/GeneralData";
import "./style.css";

export const FormRequerimientos = () => {
  const [selectedvalue, setSelectedValue] = useState(); //almacenar el valor del dropdown de requerimientos
  const [msg, setMsg] = useState({}); // para mostrar el mensaje de enviado con exito
  const [ip_usuario, setIP] = useState(); //almacenar la ip_usuario
  const id_estado = "1";

  useEffect(() => {
    getIpClient().then((res) => {
      setIP(res);
    });
  }, []);

  const RequirementSchema = Yup.object().shape({
    nombres: Yup.string()
      .min(3, "Muy corto!")
      .required("Este campo es requerido"),
    apellidos: Yup.string()
      .min(3, "Muy corto!")
      .required("Este campo es requerido"),
    celular: Yup.string()
      .matches(
        /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
        "Número de celular no valido"
      )
      .min(10, "Número de celular no valido")
      .max(10, "Número de celular no valido"),
    email: Yup.string()
      .min(3, "muy corto!")
      .email("Por favor, escribe una dirección de correo válida.")
      .required("Este campo es requerido"),
    cedula: Yup.string()
      .min(3, "muy corto!")
      .required("Este campo es requerido")
      .test(
        "is-number",
        "Número de Cédula no valido",
        (value) => !isNaN(value)
      ),
    placa_moto: Yup.string()
      .min(3, "muy corto!")
      .required("Este campo es requerido"),
    tipo_requerimiento: Yup.string().required("Este campo es requerido"),
    fecha_liquidacion: Yup.date()
      .nullable()
      .when("tipo_requerimiento", {
        is: "Liquidación del crédito",
        then: Yup.date()
          .typeError("Debes ingresar una fecha valida")
          .required("El campo es obligatorio"),
      }),
    observacion: Yup.string().required("Este campo es requerido"),
    check3: Yup.boolean().oneOf(
      [true],
      "Debes aceptar la Política de Privacidad de Datos"
    ),
  });

  const onSubmit = (values, { resetForm }) => {
    values.ip_usuario = ip_usuario;
    values.id_estado = id_estado;

    RequerimentSave(values).then((res) => {
      setMsg(res);
      //Email client
      Email({
        to: values.email,
        subject: "Supre: Solicitud de requerimiento",
        text: "Solicitud de requerimiento",
        html: htmlRequerimentClient,
        attachments: [],
        options: values,
      });
      //Email internal
      Email({
        to: GeneralData.from,
        subject: `Requerimiento: ${values.tipo_requerimiento} - ${values.nombres} ${values.apellidos}`,
        text: "Solicitud de requerimiento",
        html: htmlRequeriment,
        attachments: [],
        options: values,
      });
    });
    setSelectedValue();
    resetForm();
  };

  return (
    <Formik
      initialValues={{
        nombres: "",
        apellidos: "",
        celular: "",
        email: "",
        cedula: "",
        placa_moto: "",
        tipo_requerimiento: "",
        fecha_liquidacion: null,
        observacion: "",
        check3: false,
      }}
      validationSchema={RequirementSchema}
      onSubmit={onSubmit}
    >
      {({ errors, touched }) => (
        <Form className="c-form-rq">
          <div className="c-form-rq__row">
            <div className="c-form-1__field js-show-error">
              <label className="o-form-label">Nombres del titular</label>
              <Field className="o-form-field-rq" name="nombres" />
              {errors.nombres && touched.nombres ? (
                <div className="js_error">{errors.nombres}</div>
              ) : null}
            </div>
            <div className="c-form-1__field js-show-error">
              <label className="o-form-label">Apellidos del titular</label>
              <Field className="o-form-field-rq" name="apellidos" />
              {errors.apellidos && touched.apellidos ? (
                <div className="js_error">{errors.apellidos}</div>
              ) : null}
            </div>
          </div>
          <div className="c-form-rq__row">
            <div className="c-form-1__field js-show-error">
              <label className="o-form-label">
                Cédula del títular del crédito
              </label>
              <Field className="o-form-field-rq" name="cedula" />
              {errors.cedula && touched.cedula ? (
                <div className="js_error">{errors.cedula}</div>
              ) : null}
            </div>
            <div className="c-form-1__field js-show-error">
              <label className="o-form-label">Placa de la moto</label>
              <Field className="o-form-field-rq" name="placa_moto" />
              {errors.placa_moto && touched.placa_moto ? (
                <div className="js_error">{errors.placa_moto}</div>
              ) : null}
            </div>
          </div>
          <div className="c-form-rq__row">
            <div className="c-form-1__field js-show-error">
              <label className="o-form-label">Número de celular</label>
              <Field className="o-form-field-rq" type="text" name="celular" />
              {errors.celular && touched.celular ? (
                <div className="js_error">{errors.celular}</div>
              ) : null}
            </div>
            <div className="c-form-1__field js-show-error">
              <label className="o-form-label">Correo electrónico</label>
              <Field className="o-form-field-rq" name="email" />
              {errors.email && errors.email ? (
                <div className="js_error">{errors.email}</div>
              ) : null}
            </div>
          </div>
          <div className="c-form-rq__row">
            <div className="c-form-1__field js-show-error">
              <label className="o-form-label">Requerimiento</label>
              <div className="c-form-3__select">
                <Field
                  id="select-requerimiento"
                  as="select"
                  name="tipo_requerimiento"
                  onClick={(e) => {
                    setSelectedValue(e.target.value);
                  }}
                >
                  <option value="" className="opciones">
                    Seleccionar
                  </option>
                  <option value="Paz y salvo" className="opciones">
                    Paz y salvo
                  </option>
                  <option value="Levantamiento de prenda" className="opciones">
                    Levantamiento de prenda
                  </option>
                  <option value="Estado de cuenta" className="opciones">
                    Estado de cuenta
                  </option>
                  <option
                    value="Certificado de cuenta al día"
                    className="opciones"
                  >
                    Certificado de cuenta al día
                  </option>
                  <option value="Certificado de deuda" className="opciones">
                    Certificado de deuda
                  </option>
                  <option
                    value="Certificado de deuda para declaración de renta"
                    className="opciones"
                  >
                    Certificado de deuda para declaración de renta
                  </option>
                  <option value="Liquidación del crédito" className="opciones">
                    Liquidación del crédito
                  </option>
                  <option value="Extracto de cuenta" className="opciones">
                    Extracto de cuenta
                  </option>
                  <option value="Recibo de caja" className="opciones">
                    Recibo de caja
                  </option>
                  <option value="Tabla de amortización" className="opciones">
                    Tabla de amortización
                  </option>
                  <option value="Cambio fecha de pago" className="opciones">
                    Cambio fecha de pago
                  </option>
                  <option value="Traslado de cuota" className="opciones">
                    Traslado de cuota
                  </option>
                  <option value="Camara de comercio" className="opciones">
                    Camara de comercio
                  </option>
                  <option value="Rut" className="opciones">
                    Rut
                  </option>
                  <option value="Otro" className="opciones">
                    Otro
                  </option>
                </Field>
              </div>
              {errors.tipo_requerimiento && touched.tipo_requerimiento ? (
                <div className="js_error">{errors.tipo_requerimiento}</div>
              ) : null}
            </div>
            {selectedvalue === "Liquidación del crédito" && (
              <div
                className="c-form-1__field js-show-error"
                id="fecha-liquidacion"
              >
                <label className="o-form-label">Fecha de liquidación</label>
                <Field
                  className="o-form-field-rq"
                  type="date"
                  name="fecha_liquidacion"
                />
                {errors.fecha_liquidacion && touched.fecha_liquidacion ? (
                  <div className="js_error">{errors.fecha_liquidacion}</div>
                ) : null}
              </div>
            )}
          </div>
          <div className="o-form-text-help" id="help-select-requerimiento">
            {selectedvalue === "Paz y salvo" && (
              <span id="Paz y salvo">
                Documento oficial que certifica que no tienes deudas pendientes
                con Supre y finalizaste tu crédito con nosotros.
              </span>
            )}
            {selectedvalue === "Levantamiento de prenda" && (
              <span id="Levantamiento de prenda">
                Documento exigido por las entidades de tránsito para levantar la
                prenda o pignoración que recae sobre la motocicleta, se emite al
                finalizar el crédito.
              </span>
            )}
            {selectedvalue === "Estado de cuenta" && (
              <span id="Estado de cuenta">
                Documento que consigna los valores de las cuotas que adeudas a
                la fecha.
              </span>
            )}
            {selectedvalue === "Certificado de cuenta al día" && (
              <span data-value="Certificado de cuenta al día">
                Certificado que se expide cuando el crédito está vigente y te
                encuentras al día con el pago de las cuotas.
              </span>
            )}
            {selectedvalue === "Certificado de deuda" && (
              <span data-value="Certificado de deuda">
                Certificado que se expide con el valor del capital adeudado a
                fecha de corte actual.
              </span>
            )}
            {selectedvalue ===
              "Certificado de deuda para declaración de renta" && (
              <span data-value="Certificado de deuda para declaración de renta">
                Certificado que se expide con el valor del capital adeudado con
                corte al 31 de diciembre del año anterior.
              </span>
            )}
            {selectedvalue === "Liquidación del crédito" && (
              <span data-value="Liquidación del crédito">
                Procedimiento para finalizar el crédito de manera anticipada
                efectuando el pago de la deuda.
              </span>
            )}
            {selectedvalue === "Extracto de cuenta" && (
              <span data-value="Extracto de cuenta">
                Documento que detalla los pagos generados a la obligación a la
                fecha y saldo actual.
              </span>
            )}
            {selectedvalue === "Recibo de caja" && (
              <span data-value="Recibo de caja">
                Documento que garantiza que el pago realizado ha sido recibido y
                aplicado a la obligación.
              </span>
            )}
            {selectedvalue === "Tabla de amortización" && (
              <span data-value="Tabla de amortización">
                Documento que detalla la distribución de cada pago, tasa de
                financiación pactada y saldo del capital.
              </span>
            )}
            {selectedvalue === "Cambio fecha de pago" && (
              <span data-value="Cambio fecha de pago">
                Proceso para solicitar cambiar la fecha del pago de la cuota
                mensual dentro de un mismo mes, aplica términos y condiciones.
              </span>
            )}
            {selectedvalue === "Traslado de cuota" && (
              <span data-value="Traslado de cuota">
                Proceso para solicitar trasladar máximo 2 cuotas de la
                obligación para el final del crédito, aplica términos y
                condiciones.
              </span>
            )}
            {selectedvalue === "Camara de comercio" && (
              <span data-value="Camara de comercio">
                Documento que certifica la existencia y representación legal de
                la compañía, el cual es solicitado para tramitar el
                levantamiento de la prenda.
              </span>
            )}
            {selectedvalue === "Rut" && (
              <span data-value="Rut">
                Es el documento de identificación fiscal esencial para tramites
                comerciales y administrativos, el cual es solicitado para
                tramitar el levantamiento de la prenda.
              </span>
            )}
          </div>
          <div className="c-form-1__field">
            <label className="o-form-label">Observación</label>
            <Field
              className="o-form-field-rq--textarea"
              component="textarea"
              name="observacion"
            />
            {errors.observacion && touched.observacion ? (
              <div className="js_error">{errors.observacion}</div>
            ) : null}
          </div>
          <div className="c-form-1__field js-show-error">
            <div className="o-form-check">
              <Field
                className="o-form-check__campo"
                id="check-3"
                type="checkbox"
                name="check3"
              />
              <label htmlFor="check-3"></label>
              <span className="c-form-1__text-1">
                He leído y acepto Política de Privacidad de Datos.
                <a className="o-link-1" href="/politicas/" target="_blank">
                  Ver detalle legal
                </a>
              </span>
            </div>
            {errors.check3 && touched.check3 ? (
              <div className="js_error">{errors.check3}</div>
            ) : null}
          </div>
          {msg.status === 201 && (
            <div className="c-form-1__field">
              <div className={"c-form-1__notification"}>
                <div className="c-notify--ok_Requeriments">
                  <FontAwesomeIcon
                    className="icon_check"
                    icon={faCircleCheck}
                  />
                  Gracias, el requerimiento ha sido enviado exitosamente y será
                  revisado por el área encargada. Recibirás una respuesta en las
                  próximos 15 días hábiles.
                </div>
              </div>
            </div>
          )}
          <div className="c-form-1__btn">
            <button className="o-btn-3--v2" type="submit">
              Enviar
            </button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

import React from "react";
import { useChangeBackground } from "../hooks/useChangeBackground";
import useVerifyMenu from "../hooks/useVerifyMenu";
import { Helmet } from "react-helmet";
import { useHistory } from "react-router-dom";
import AdminMui from "../components/Masters/AdminMui";
import { useChangeTitleAppBar } from "../hooks/useChangeTitleAppBar";
import {
  CreateBackBrake,
  ListBackBrakes,
  UpdateBackBrake,
} from "../utils/BackBrake";
import Swal from "sweetalert2";
import { BackBrakeTable } from "../components/Organisms/BackBrake/BackBrakeTable";
import { EditBackBrake } from "../components/Organisms/BackBrake/EditBackBrake/index";

export const BackBrake = () => {
  const back = useVerifyMenu();
  const history = useHistory();
  const [backBrakes, setBackBrakes] = React.useState([]);
  const [againFetch, setAgainFetch] = React.useState(false);
  const [showModal, setShowMondal] = React.useState({
    show: false,
    data: {},
  });

  useChangeBackground("#f5f5f8");
  useChangeTitleAppBar("Configuración de frenos traseros");

  React.useEffect(() => {
    ListBackBrakes()
      .then((res) => setBackBrakes(res.data))
      .catch((err) =>
        Swal.fire({
          title: "Error!",
          text: err.message || "",
          icon: "error",
          confirmButtonText: "Ok",
        })
      );
  }, [againFetch]);

  const ApiDeleteBackBrake = (backBrake) => {
    return UpdateBackBrake(backBrake);
  };

  const handleClick = (backBrake) => {
    if (!backBrake.id) {
      CreateBackBrake(backBrake)
        .then((res) => {
          setShowMondal({
            show: false,
            data: {},
          });
          Swal.fire({
            title: "Proceso Ejecutado exitosamente!",
            text: res.message || "",
            icon: "success",
            confirmButtonText: "Ok",
          }).then(() => setAgainFetch(!againFetch));
        })
        .catch((err) => {
          setShowMondal({
            show: false,
            data: {},
          });
          Swal.fire({
            title: "Error!",
            text: err || "",
            icon: "error",
            confirmButtonText: "Ok",
          });
        });
    } else {
      UpdateBackBrake(backBrake)
        .then((res) => {
          setShowMondal({
            show: false,
            data: {},
          });
          Swal.fire({
            title: "Proceso Ejecutado exitosamente!",
            text: res.message || "",
            icon: "success",
            confirmButtonText: "Ok",
          }).then(() => setAgainFetch(!againFetch));
        })
        .catch((err) => {
          setShowMondal({
            show: false,
            data: {},
          });
          Swal.fire({
            title: "Error!",
            text: err.message || "",
            icon: "error",
            confirmButtonText: "Ok",
          });
        });
    }
  };

  return !back ? (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Configuración de frenos traseros - Supre</title>
        <meta name="description" content="frenos traseros admin supre" />
      </Helmet>
      <AdminMui>
        <BackBrakeTable
          setShowMondal={setShowMondal}
          backBrakes={backBrakes}
          setAgainFetch={setAgainFetch}
          againFetch={againFetch}
          ApiDeleteBackBrake={ApiDeleteBackBrake}
        />
        {showModal.show && (
          <EditBackBrake
            showModal={showModal}
            setShowMondal={setShowMondal}
            handleClick={handleClick}
          />
        )}
      </AdminMui>
    </>
  ) : (
    <>{history.push("/admin/login")}</>
  );
};

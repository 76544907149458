import React from "react";
import { Button, Card } from "@mui/material";
import {
  styledButton,
  styledCard,
  styledLink,
} from "../../../../muiStyles/AdminCreateMoto";
import { Link } from "react-router-dom";
import { CreateMotoContext } from "../../../../contexts/CreateMotoContext";
import Swal from "sweetalert2";
import { CreateCatalogue, UpdateCatalogue } from "../../../../utils/Catalogue";

const FooterCreateMoto = ({ isEdit }) => {
  const { values } = React.useContext(CreateMotoContext);

  const handleClick = (e) => {
    if (
      values["slug_moto"] === "" ||
      values["nombre"] === "" ||
      values["certificacion"] === "" ||
      values["modelo"] === ""
    ) {
      return Swal.fire({
        title: "Error",
        text: "Debe validar campos slug (URL), nombre de moto, certificacion y modelo",
        icon: "error",
        confirmButtonText: "Ok",
      });
    }

    // validate if is create or edit
    if (!isEdit) {
      CreateCatalogue(values).then((res) => {
        if (res.status === 201) {
          Swal.fire({
            title: "Exito",
            text: "Se ha creado el catalogo",
            icon: "success",
            confirmButtonText: "Ok",
          }).then(() => {
            window.location.href = "/admin/motos/";
          });
        } else {
          Swal.fire({
            title: "Error",
            text: "No se ha podido crear el catalogo",
            icon: "error",
            confirmButtonText: "Ok",
          });
        }
      });
    } else {
      UpdateCatalogue(values, values.id).then((res) => {
        if (res.status === 200) {
          Swal.fire({
            title: "Exito",
            text: "Se ha editado el catalogo",
            icon: "success",
            confirmButtonText: "Ok",
          }).then(() => {
            window.location.href = "/admin/motos/";
          });
        } else {
          Swal.fire({
            title: "Error",
            text: "No se ha podido editar el catalogo",
            icon: "error",
            confirmButtonText: "Ok",
          });
        }
      });
    }
  };

  return (
    <Card style={styledCard}>
      <Button
        color="primary"
        variant="contained"
        style={styledButton}
        onClick={handleClick}
      >
        Guardar moto
      </Button>
      <Button color="inherit" variant="contained" style={styledButton}>
        <Link to="/admin/motos/" style={styledLink}>
          Cancelar
        </Link>
      </Button>
    </Card>
  );
};

export default FooterCreateMoto;

import React from "react";
import { DataGridPro, GridActionsCellItem, esES } from "@mui/x-data-grid-pro";
import ModeEditOutlinedIcon from "@mui/icons-material/ModeEditOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { Button } from "@mui/material";
import Swal from "sweetalert2";
import GeneralData from "../../../../data/GeneralData.js";

const ColorMotosTable = ({
  setShowMondal,
  setAgainFetch,
  againFetch,
  ApiDeleteColor,
  motojson,
}) => {
  const columns = [
    {
      field: "url_foto_color_peque",
      headerName: "Imagen",
      renderCell: (params) => {
        return (
          <span style={{ height: 55, width: 55 }}>
            <img
              src={
                GeneralData.host + params.row.url_foto_color_peque
              }
              alt={params.row.Colore.nombre_color}
              style={{ paddingTop: 8 }}
            />
          </span>
        );
      },
    },
    {
      field: "Colore.nombre_color",
      headerName: "Nombre color",
      flex: 1,
      renderCell: (params) => <p> {params.row.Colore.nombre_color}</p>,
    },
    {
      field: "color2",
      headerName: "Color",
      flex: 1,
      renderCell: (params) => (
        <span
          style={{
            borderRadius: "50%",
            background: params.row.Colore.codigo_color,
            height: 20,
            width: 20,
          }}
        />
      ),
    },
    {
      field: "actions",
      headerName: "Acciones",
      type: "actions",
      getActions: (data) => [
        <GridActionsCellItem
          icon={<ModeEditOutlinedIcon style={{ fontSize: 20 }} />}
          label="Edit"
          onClick={(e) => {
            e.stopPropagation();
            EditRowSelected(data?.row);
          }}
        />,
        <GridActionsCellItem
          icon={<DeleteOutlineOutlinedIcon style={{ fontSize: 20 }} />}
          label="Delete"
          onClick={(e) => {
            e.stopPropagation();
            DeleteRow(data?.row);
          }}
        />,
      ],
    },
  ];

  const addRow = () => {
    setShowMondal({
      show: true,
      data: undefined,
    });
  };

  function EditRowSelected(rowData) {
    //display modal for edit or create
    setShowMondal({
      show: true,
      data: rowData,
    });
  }

  function DeleteRow(rowData) {
    Swal.fire({
      title: "¿Estás seguro que quieres eliminar esta fila?",
      text: "No podrás revertir estos cambios.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      cancelButtonText: "cancelar",
      confirmButtonText: "Si",
    }).then((result) => {
      if (result.isConfirmed) {
        ApiDeleteColor({ rowData }).then(
          Swal.fire(
            "Eliminado!",
            "Este archivo se ha elimidado",
            "Satisfactoriamente"
          ).then(() => setAgainFetch(!againFetch))
        );
      }
    });
  }

  return (
    <div style={{ height: 600, marginTop: 12 }}>
      <Button
        variant="contained"
        onClick={addRow}
        style={{ width: 300, height: 40, marginBottom: 20, fontSize: 13 }}
      >
        Agregar un nuevo color
      </Button>
      <DataGridPro
        rows={motojson?.MotosColores}
        columns={columns}
        style={{ fontSize: 15 }}
        localeText={esES.components.MuiDataGrid.defaultProps.localeText}
        pageSize={15}
        pagination
      />
    </div>
  );
};

export default ColorMotosTable;

import apiInstance from "../Api/ApiInstance";

export async function createPaymentSessionService({
  value,
  ip,
  dni,
  reference,
}) {
  return await apiInstance.post("api/official-site/payment-gateway/", {
    value,
    ip,
    dni,
    reference,
  });
}

export async function updateStatusSessionService({
  requestId,
  status,
  signature,
  date,
}) {
  return await apiInstance.patch(`api/official-site/payment-gateway/`, {
    requestId,
    status,
    signature,
    date,
  });
}

export async function getStatusSessionService(requestId) {
  return await apiInstance.get(
    `api/official-site/payment-gateway/${requestId}`
  );
}

export async function GetStatusSessionByReferencesServices(reference) {
  return await apiInstance.get(
    `api/official-site/payment-gateway/by-reference/${reference}`
  );
}

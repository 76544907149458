/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { Citys } from "../../../utils/Citys";
import { getIpClient } from "../../../helpers/GetIp";
import "./style.css";
//Information of the person for the credit application
export const InfoCredit = ({ setValues, values, setSave }) => {
  const [citys, setCitys] = useState([]);
const codigoReferido = sessionStorage.getItem('referido')
  useEffect(() => {
    //Query cities in database
    Citys().then((result) => {
      setCitys(result.data);
    });
  }, []);

  const SearchSchema = Yup.object().shape({
    //Field validation
    cedula: Yup.string().required("Este campo es requerido"),
    nombres: Yup.string().required("Este campo es requerido"),
    apellidos: Yup.string().required("Este campo es requerido"),
    fecha_nacimiento: Yup.date()
      .required("Se debe ingresar una fecha valida")
      .nullable(),
    genero: Yup.string().required("Este campo es requerido"),
    estado_civil: Yup.string().required("Este campo es requerido"),
    personas_cargo: Yup.number()
      .min(0, "Debe ingresar un número entre 0 a 9")
      .max(9, "Debe ingresar un número entre 0 a 9")
      .required("Este campo es requerido"),
    nivel_educacion: Yup.string().required("Este campo es requerido"),
    ciudad: Yup.string().required("Este campo es requerido"),
    direccion: Yup.string().required("Este campo es requerido"),
    tipo_vivienda: Yup.string().required("Este campo es requerido"),
    celular: Yup.string()
      .required("Este campo es requerido")
      .matches(
        /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
        "Número de celular no valido"
      )
      .min(10, "Número de celular no valido")
      .max(10, "Número de celular no valido"),
    email: Yup.string()
      .min(3, "Muy corto")
      .email("Correo invalido")
      .required("Este campo es requerido"),
    ocupacion: Yup.string().required("Este campo es requerido"),
    ingresos: Yup.number()
      .typeError("Debes ingresar un valor numérico")
      .min(0, "Valor mínimo 0")
      .required("Este campo es requerido"),
    terminos: Yup.bool().oneOf(
      [true],
      "Debes aceptar la Política de Privacidad de Datos"
    ),
  });

  const handleSave = async (value) => {

    setValues((prevState) => ({
      ...prevState,
      loading: true,
    }));
    //Get user ip
    await getIpClient()
      .then((result) => {
        setValues((prevState) => ({
          ...prevState,
          ip_usuario: result,
        }));
      })
      .catch((error) => {
        console.log(error);
      });

    setValues((prevState) => ({
      ...prevState,
      tap: 3,
      cedula: value.cedula,
      nombres: value.nombres,
      apellidos: value.apellidos,
      fecha_nacimiento: value.fecha_nacimiento,
      genero: value.genero,
      estado_civil: value.estado_civil,
      personas_cargo: value.personas_cargo,
      nivel_educacion: value.nivel_educacion,
      ciudad: value.ciudad,
      tipo_vivienda: value.tipo_vivienda,
      direccion: value.direccion,
      celular: value.celular,
      email: value.email,
      ocupacion: value.ocupacion,
      nombre_empresa: value.nombre_empresa,
      direccion_empresa: value.direccion_empresa,
      ingresos: value.ingresos,
      terminos: value.terminos,
      codigo_referido: codigoReferido
    }));

    setSave(true);
  };

  const handleBack = () =>
    setValues((prevState) => ({
      ...prevState,
      tap: 1,
    }));

  return (
    <>
      <Formik
        initialValues={values}
        validationSchema={SearchSchema}
        onSubmit={handleSave}
      >
        {({ errors, touched }) => (
          <Form className="c-form-isc o-is-hidden-isc" id="form-login">
            <div className="c-form-3__row-isc">
              <div className="c-form-3__field-isc">
                <label className="o-form-label-isc">Número de cédula</label>
                <Field
                  className="o-form-field-isc"
                  type="number"
                  name="cedula"
                />
                {errors.cedula && touched.cedula && (
                  <div className="js_error">{errors.cedula}</div>
                )}
              </div>
              <div className="c-form-3__field-isc">
                <label className="o-form-label-isc">Nombres</label>
                <Field
                  className="o-form-field-isc"
                  type="text"
                  name="nombres"
                />
                {errors.nombres && touched.nombres && (
                  <div className="js_error">{errors.nombres}</div>
                )}
              </div>
              <div className="c-form-3__field-isc">
                <label className="o-form-label-isc">Apellidos</label>
                <Field
                  className="o-form-field-isc"
                  type="text"
                  name="apellidos"
                />
                {errors.apellidos && touched.apellidos && (
                  <div className="js_error">{errors.apellidos}</div>
                )}
              </div>
              <div className="c-form-3__field-isc">
                <label className="o-form-label-isc">Fecha de nacimiento</label>
                <Field
                  className="o-form-field-isc"
                  type="date"
                  name="fecha_nacimiento"
                />
                {errors.fecha_nacimiento && touched.fecha_nacimiento && (
                  <div className="js_error">{errors.fecha_nacimiento}</div>
                )}
              </div>
              <div className="c-form-3__cont-field">
                <div className="c-form-3__field-isc">
                  <label className="o-form-label-isc">Genero</label>
                  <div className="c-form-3__select">
                    <Field
                      id="select-solicitud"
                      as="select"
                      className="select"
                      name="genero"
                    >
                      <option value="" className="opciones">
                        Seleccionar
                      </option>
                      <option value="Femenino" className="opciones">
                        Femenino
                      </option>
                      <option value="Masculino" className="opciones">
                        Masculino
                      </option>
                    </Field>
                    {errors.genero && touched.genero && (
                      <div className="js_error">{errors.genero}</div>
                    )}
                  </div>
                </div>
                <div className="c-form-3__field-isc">
                  <label className="o-form-label-isc">Estado civil</label>
                  <div className="c-form-3__select">
                    <Field
                      id="select-solicitud"
                      as="select"
                      className="select"
                      name="estado_civil"
                    >
                      <option value="" className="opciones">
                        Seleccionar
                      </option>
                      <option value="Soltero" className="opciones">
                        Soltero
                      </option>
                      <option value="Casado" className="opciones">
                        Casado
                      </option>
                      <option value="Unión Libre" className="opciones">
                        Unión Libre
                      </option>
                    </Field>
                    {errors.estado_civil && touched.estado_civil && (
                      <div className="js_error">{errors.estado_civil}</div>
                    )}
                  </div>
                </div>
              </div>
              <div className="c-form-3__cont-field">
                <div className="c-form-3__field-isc">
                  <label className="o-form-label-isc">Personas a cargo</label>
                  <Field
                    className="o-form-field-isc"
                    type="number"
                    name="personas_cargo"
                  />
                  {errors.personas_cargo && touched.personas_cargo && (
                    <div className="js_error">{errors.personas_cargo}</div>
                  )}
                </div>
                <div className="c-form-3__field-isc">
                  <label className="o-form-label-isc">Nivel de educación</label>
                  <div className="c-form-3__select">
                    <Field
                      id="select-solicitud"
                      as="select"
                      className="select"
                      name="nivel_educacion"
                    >
                      <option value="" className="opciones">
                        Seleccionar
                      </option>
                      <option value="Primaria" className="opciones">
                        Primaria
                      </option>
                      <option value="Bachiller" className="opciones">
                        Bachiller
                      </option>
                      <option value="Técnico" className="opciones">
                        Técnico
                      </option>
                      <option value="Profesional" className="opciones">
                        Profesional
                      </option>
                    </Field>
                    {errors.nivel_educacion && touched.nivel_educacion && (
                      <div className="js_error">{errors.nivel_educacion}</div>
                    )}
                  </div>
                </div>
              </div>
              <div className="c-form-3__cont-field">
                <div className="c-form-3__field-isc">
                  <label className="o-form-label-isc">Ciudad</label>
                  <div className="c-form-3__select">
                    <Field
                      id="select-solicitud"
                      as="select"
                      className="select"
                      name="ciudad"
                    >
                      <option value="" className="opciones">
                        Seleccionar
                      </option>
                      {citys.map((city) => (
                        <option
                          value={city.nombre_ciudad}
                          className="opciones"
                          key={city.id}
                        >
                          {city.nombre_ciudad}
                        </option>
                      ))}
                    </Field>
                    {errors.ciudad && touched.ciudad && (
                      <div className="js_error">{errors.ciudad}</div>
                    )}
                  </div>
                </div>
                <div className="c-form-3__field-isc">
                  <label className="o-form-label-isc">Tipo de vivienda</label>
                  <div className="c-form-3__select">
                    <Field
                      id="select-solicitud"
                      as="select"
                      className="select"
                      name="tipo_vivienda"
                    >
                      <option value="" className="opciones">
                        Seleccionar
                      </option>
                      <option value="Propia" className="opciones">
                        Propia
                      </option>
                      <option value="Arrendada" className="opciones">
                        Arrendada
                      </option>
                      <option value="Familiar" className="opciones">
                        Familiar
                      </option>
                    </Field>
                    {errors.tipo_vivienda && touched.tipo_vivienda && (
                      <div className="js_error">{errors.tipo_vivienda}</div>
                    )}
                  </div>
                </div>
              </div>
              <div className="c-form-3__field-isc">
                <label className="o-form-label-isc">Dirección</label>
                <Field
                  className="o-form-field-isc"
                  type="text"
                  name="direccion"
                />
                {errors.direccion && touched.direccion && (
                  <div className="js_error">{errors.direccion}</div>
                )}
              </div>
              <div className="c-form-3__field-isc">
                <label className="o-form-label-isc">Celular</label>
                <Field
                  className="o-form-field-isc"
                  type="text"
                  name="celular"
                />
                {errors.celular && touched.celular && (
                  <div className="js_error">{errors.celular}</div>
                )}
              </div>
              <div className="c-form-3__field-isc">
                <label className="o-form-label-isc">Correo electrónico</label>
                <Field className="o-form-field-isc" type="email" name="email" />
                {errors.email && touched.email && (
                  <div className="js_error">{errors.email}</div>
                )}
              </div>
              <div className="c-form-3__field-isc">
                <label className="o-form-label-isc">Ocupación</label>
                <div className="c-form-3__select">
                  <Field
                    id="select-solicitud"
                    as="select"
                    className="select"
                    name="ocupacion"
                  >
                    <option value="" className="opciones">
                      Seleccionar
                    </option>
                    <option value="Independiente" className="opciones">
                      Independiente
                    </option>
                    <option value="Informal" className="opciones">
                      Informal
                    </option>
                    <option value="Asalariado" className="opciones">
                      Asalariado
                    </option>
                    <option value="Estudiante" className="opciones">
                      Estudiante
                    </option>
                    <option value="Pensionado" className="opciones">
                      Pensionado
                    </option>
                  </Field>
                  {errors.ocupacion && touched.ocupacion && (
                    <div className="js_error">{errors.ocupacion}</div>
                  )}
                </div>
              </div>
              <div className="c-form-3__field-isc">
                <label className="o-form-label-isc">Nombre empresa</label>
                <Field
                  className="o-form-field-isc"
                  type="text"
                  name="nombre_empresa"
                />
              </div>
              <div className="c-form-3__field-isc">
                <label className="o-form-label-isc">
                  Dirección de la empresa
                </label>
                <Field
                  className="o-form-field-isc"
                  type="text"
                  name="direccion_empresa"
                />
              </div>
              <div className="c-form-3__field-isc">
                <label className="o-form-label-isc">Ingresos</label>
                <Field
                  className="o-form-field-isc"
                  type="text"
                  name="ingresos"
                />
                {errors.ingresos && touched.ingresos && (
                  <div className="js_error">{errors.ingresos}</div>
                )}
              </div>
            </div>
            <div className="c-form-3__row">
              <div className="c-form-3__field-isc">
                <div className="o-form-check">
                  <Field
                    className="o-form-check__campo"
                    id="check-terminoscotizador"
                    type="checkbox"
                    name="terminos"
                  />
                  <label for="check-terminoscotizador"></label>
                  <span className="c-form-1__text-1">
                    He leído y acepto Política de Privacidad de Datos.{" "}
                    <a
                      className="o-link-1"
                      href="/politicas/"
                      target="_blank"
                    >
                      Ver detalle legal
                    </a>
                  </span>
                </div>
                {errors.terminos && touched.terminos && (
                  <div className="js_error">{errors.terminos}</div>
                )}
              </div>
            </div>
            <div className="c-form-3__btns-isc">
              <button
                className="o-btn-2--isc js-btn-coti-previous"
                onClick={handleBack}
              >
                Anterior
              </button>
              <button
                className="o-btn-3 js-btn-enviar-cotizacion  js-btn-send"
                type="submit"
              >
                Enviar
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

import apiInstance from "../Api/ApiInstance";

export async function SearchSave({
  nombre,
  email,
  estilo,
  presupuesto,
  transmision,
  ip,
}) {
  return await apiInstance.post(
    `api/official-site/Busqueda-Guiada/${nombre}/${email}/${estilo}/${presupuesto}/${transmision}/${ip}/`
  );
}

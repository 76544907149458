import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
import { Portada } from "../components/molecules/Portada";
import { infoMoto } from "../utils/GetMotos";
import { DescriptionMoto } from "../components/Organisms/DetalleMoto/index";
import "../css/CatalogoMotos.css";

export const DetalleMoto = () => {
  let params = useParams();
  const [product, setProduct] = useState();
  const [load, setLoad] = useState(true);

  useEffect(() => {
    let nameProduct = params.product;
    if (nameProduct !== "") {
      infoMoto(nameProduct).then((result) => {
        setProduct(result.data[0]);
        setLoad(false);
      });
    }
  }, [params]);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          {product !== undefined ? `${product.nombre} - Supre` : "Supre"}
        </title>
        <meta name="description" content="Información de motocicleta" />
      </Helmet>
      <Portada
        className="c-block-3"
        id="CatMotos"
        Name={product?.nombre || "Producto no disponible"}
      />
      {product !== undefined ? (
        <>
          <DescriptionMoto product={product} />
        </>
      ) : load === true ? (
        <div className="menu-mobile-overlay">
          <div className="loader-popup">
            <div className="loader__box">
              <div className="icon-spinner9 animate-spin"></div>
            </div>
          </div>
        </div>
      ) : (
        <div className="g-container-nd">
          <div className="c-block-5__heading-6">Error en la busqueda</div>
          <p className="c-block-5__pc">
            {
              "Lo sentimos hemos tenido problemas al cargar este producto, esto se puede deber a que actualmente no se encuentra disponible en nuestro catálogo. Te invitamos a que lo vuelvas a revisar el "
            }
            <a
              className="o-link-2 js-step-registro-usuario"
              href="/catalogo-de-motos"
            >
              catálogo de moto.
            </a>
          </p>
        </div>
      )}
    </>
  );
};

import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight, faLocationDot } from "@fortawesome/free-solid-svg-icons";

export const Localizacion = ({ data }) => {
  const direccionesConRecaudo = data.direcciones.filter(
    (elemento) => elemento.recaudo
  );

  return (
    <div className="c-list-3__item">
      <div className="c-list-3__iconPC">
        <div className="icon-location5">
          <FontAwesomeIcon icon={faLocationDot} />
        </div>
      </div>
      <div className="c-list-3__box">
        <h3 className="c-list-3__heading">{data?.name}</h3>
        <ul className="c-list-3__items">
          {direccionesConRecaudo.map((item) => (
            <li className="icon-arrow-right4" key={item?.id}>
              <FontAwesomeIcon className="IconRight" icon={faAngleRight} />
              {item?.direccion}
              <br />
              {item?.barrio}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

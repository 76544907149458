import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";

export const Keyword = ({ filtrar }) => {
  return (
    <div className="c-modal-1__section ">
      <div className="c-modal-1__heading">Buscar por palabra clave</div>
      <div className="c-modal-1__item ">
        <div className="c-modal-1__row">
          <div className="c-modal-1__field">
            <input
              className="o-form-field--v2"
              id="BuscadorName"
              type="text"
              name="keyword"
              data-tag-field="keyword"
            />
          </div>
          <div className="c-modal-1__btn">
            <button className="o-btn-6" name="keyword_filter" onClick={filtrar}>
              <FontAwesomeIcon
                className="icon-arrow-right2"
                icon={faAngleRight}
              />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

import { apiAdminInstance } from "../Api/ApiInstance";
import { string_to_slug } from "../helpers/Strings";

export async function ListArranque() {
  return await apiAdminInstance.get("api/official-site/arranque/");
}

export async function ListArranqueFilter() {
  return await apiAdminInstance.get("api/official-site/arranque/filter");
}

export async function DeleteArranque(id) {
  return await apiAdminInstance.delete("api/official-site/arranque/" + id);
}

export async function UpdateArranque(arranque_json) {
  const { id, nombre_tipo_arranque, state } = arranque_json;
  const slug = nombre_tipo_arranque
    ? string_to_slug(nombre_tipo_arranque)
    : nombre_tipo_arranque;

  return await apiAdminInstance.put("api/official-site/arranque/" + id, {
    nombre_tipo_arranque,
    slug,
    state,
  });
}

export async function CreateArranque(arranque_json) {
  const { nombre_tipo_arranque } = arranque_json;
  const slug = string_to_slug(nombre_tipo_arranque);

  return await apiAdminInstance.post("api/official-site/arranque/", {
    nombre_tipo_arranque,
    slug,
    state: 1,
  });
}

//validate if user is admin
import { Switch, Route } from "react-router-dom";
import { Contactos } from "../pages/Contactos";
import { CatalogoMoto } from "../pages/CatalogoMoto";
import { ComparadorMotos } from "../pages/ComparadorMotos";
import { HomePage } from "../pages/HomePage";
import { Politicas } from "../pages/Politicas";
import { QuienesSomos } from "../pages/QuienesSomos";
import { Requerimientos } from "../pages/Requerimientos";
import { PagaTuCuota } from "../pages/PagaTuCuota";
import IniciarSesion from "../pages/IniciarSesion";
import { SolicitudCredito } from "../pages/SolicitudCredito";
import { Master } from "../components/Masters";
import { Footer } from "../components/Masters/Footer";
import LoginAdmin from "../pages/LoginAdmin";
import Admin from "../pages/Admin";
import { Orders } from "../pages/Orders";
import { OrderDetail } from "../pages/OrderDetail";
import { CounterProvider } from "../contexts/CounterContext";
import { DetalleMoto } from "../pages/DetalleMoto";
import Colors from "../pages/Colors";
import Catalogue from "../pages/Catalogue";
import AdminCreateMoto from "../pages/AdminCreateMoto";
import { EditProfile } from "../pages/EditProfile";
import { RecoveryPassword } from "../pages/RecoveryPassword";
import { NewPassword } from "../pages/NewPassword";
import { Register } from "../pages/Register";
import SuspensionDelantera from "../pages/SuspensionDelantera";
import SuspensionTrasera from "../pages/SuspensionTrasera";
import Arranque from "../pages/Arranque";
import CostosFinanciamiento from "../pages/CostosFinanciamiento";
import { Styles } from "../pages/Styles";
import { FrontBrake } from "../pages/FrontBrake";
import { BackBrake } from "../pages/BackBrake";
import { Marks } from "../pages/Marks";
import { ColorsMotosAdmin } from "../pages/ColorsMotosAdmin";
import { GalleryMotosAdmin } from "../pages/GalleryMotosAdmin";
import { FrequentQuestions } from "../pages/PreguntasFrecuentes";
import { GeneralBlog } from "../pages/GeneralBlog";
import { DetailBlog } from "../pages/DetailBlog";
import { PayOnline } from "../pages/PayOnline";

export const Routes = () => {
  return (
    <Switch>
      {/* rutas validadas privadas para no tener la master publica */}
      <Route path="/admin/login/" component={LoginAdmin} />
      <Route path="/admin" component={Admin} exact />
      <Route path="/admin/configuracion/colores" component={Colors} />
      <Route
        path="/admin/configuracion/suspension-delantera/"
        component={SuspensionDelantera}
      />
      <Route
        path="/admin/configuracion/suspension-trasera/"
        component={SuspensionTrasera}
      />
      <Route path="/admin/configuracion/arranque/" component={Arranque} />
      <Route
        path="/admin/configuracion/costos-financiamiento/"
        component={CostosFinanciamiento}
      />
      <Route path="/admin/configuracion/estilos" component={Styles} />
      <Route path="/admin/configuracion/marcas" component={Marks} />
      <Route
        path="/admin/configuracion/frenos-delanteros"
        component={FrontBrake}
      />
      <Route
        path="/admin/configuracion/frenos-traseros"
        component={BackBrake}
      />
      <Route path="/admin/motos/colores/" component={ColorsMotosAdmin} />
      <Route path="/admin/motos/galeria/" component={GalleryMotosAdmin} />
      <Route path="/admin/motos/" component={Catalogue} exact />
      <Route path="/admin/motos/crear-moto" component={AdminCreateMoto} exact />
      <Route path="/admin/motos/editar-moto/" component={AdminCreateMoto} />
      <CounterProvider>
        <Master>
          {/* rutas publicas */}
          <Route exact path="/" component={HomePage} />
          <Route path="/comparador-de-motos/" component={ComparadorMotos} />
          <Route exact path="/catalogo-de-motos/" component={CatalogoMoto} />
          <Route
            path="/catalogo-de-motos/product/:product"
            component={DetalleMoto}
          />
          <Route path="/politicas" component={Politicas} />
          <Route path="/paga-tu-cuota" component={PagaTuCuota} />
          <Route path="/preguntas-frecuentes" component={FrequentQuestions} />
          <Route path="/requerimientos" component={Requerimientos} />
          <Route path="/contacto" component={Contactos} />
          <Route path="/quienes-somos" component={QuienesSomos} />
          <Route exact path="/login" component={IniciarSesion} />
          <Route exact path="/login/registro/" component={Register} />
          <Route exact path="/login/password/" component={RecoveryPassword} />
          <Route
            path="/login/new-password/:codeRegistro"
            component={NewPassword}
          />
          <Route exact path="/perfil/" component={Orders} />
          <Route path="/perfil/order/:order" component={OrderDetail} />
          <Route exact path="/perfil/editar/" component={EditProfile} />
          <Route exact path="/financiamiento/" component={SolicitudCredito} />
          <Route path="/financiamiento/:product" component={SolicitudCredito} />
          <Route path="/general-blog" component={GeneralBlog} />
          <Route path="/detail-blog/:id" component={DetailBlog} />
          <Route path="/pay-online/:id" component={PayOnline} />
        </Master>
        <Footer />
      </CounterProvider>
    </Switch>
  );
};

import React from "react";
import { styled } from "@mui/styles";
import MuiGrid from "@mui/material/Grid";
import MuiInputLabel from "@mui/material/InputLabel";
import MuiDivider from "@mui/material/Divider";
import { Autocomplete } from "@mui/material";
import MuiTextField from "@mui/material/TextField";
import { CreateMotoContext } from "../../../../../contexts/CreateMotoContext";

const Grid = styled(MuiGrid)({
  display: "flex",
  marginTop: 50,
  marginBottom: 20,
  justifyContent: "center",
});

const InputLabel = styled(MuiInputLabel)({
  marginRight: 10,
  alignSelf: "center",
});

const Divider = styled(MuiDivider)({
  width: "100%",
});
const TextField = styled(MuiTextField)({
  width: "60%",
  marginRight: 10,
  "& > p": {
    fontSize: "1.2rem",
  },
});

const Security = ({
  frontBrakes,
  backBrakes,
  suspensionDelantera,
  suspensionTrasera,
}) => {
  const defaultPropsfrenosFront = {
    options: frontBrakes,
    getOptionLabel: (option) => {
      if (option.hasOwnProperty("name")) {
        return option.name;
      }
      return option;
    },
  };

  const defaultPropsfrenosBack = {
    options: backBrakes,
    getOptionLabel: (option) => {
      if (option.hasOwnProperty("name")) {
        return option.name;
      }
      return option;
    },
  };

  const defaultPropsSuspFront = {
    options: suspensionDelantera,
    getOptionLabel: (option) => {
      if (option.hasOwnProperty("name")) {
        return option.name;
      }
      return option;
    },
  };

  const defaultPropsSuspBack = {
    options: suspensionTrasera,
    getOptionLabel: (option) => {
      if (option.hasOwnProperty("name")) {
        return option.name;
      }
      return option;
    },
  };

  const {
    values,
    onChangeFrenoDelantero,
    onChangeFrenoTrasero,
    onChangeSuspensionDelantera,
    onChangeSuspensionTrasera,
  } = React.useContext(CreateMotoContext);

  return (
    <Grid container style={{ margin: 0 }}>
      <Grid item xs={12}>
        <InputLabel htmlFor="input-type-freno-front">
          Freno delantero
        </InputLabel>
        <Autocomplete
          id="input-type-freno-front"
          name="id_freno_delantero"
          noOptionsText="No se encuentran frenos"
          label="Seleccione freno delentero"
          data={frontBrakes}
          size="small"
          isOptionEqualToValue={(option, value) => option.name === value.name}
          {...defaultPropsfrenosFront}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Seleccione freno delentero"
              margin="normal"
              variant="outlined"
            />
          )}
          style={{
            display: "flex",
            justifyContent: "center",
            width: "70%",
            marginRight: 10,
            "& > p": {
              fontSize: "1.2rem",
            },
          }}
          fullWidth
          onChange={onChangeFrenoDelantero}
          value={values.id_freno_delantero?.name}
        />
      </Grid>
      <Divider />
      <Grid item xs={12}>
        <InputLabel
          style={{
            left: 13,
          }}
          htmlFor="input-freno-back"
        >
          Freno trasero
        </InputLabel>
        <Autocomplete
          id="input-freno-back"
          name="id_freno_trasero"
          noOptionsText="No se encuentran frenos"
          label="Seleccione freno trasero"
          data={backBrakes}
          size="small"
          isOptionEqualToValue={(option, value) => option.name === value.name}
          {...defaultPropsfrenosBack}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Seleccione freno trasero"
              margin="normal"
              variant="outlined"
            />
          )}
          style={{
            display: "flex",
            justifyContent: "center",
            marginLeft: 17,
            width: "70%",
            marginRight: 10,
            "& > p": {
              fontSize: "1.2rem",
            },
          }}
          fullWidth
          onChange={onChangeFrenoTrasero}
          value={values.id_freno_trasero?.name}
        />
      </Grid>
      <Divider />
      <Grid item xs={12}>
        <InputLabel htmlFor="input-suspen-front">
          Tipo de suspención delantera
        </InputLabel>

        <Autocomplete
          id="input-suspen-front"
          name="id_suspension_delantera"
          noOptionsText="No se encuentran suspensiones"
          label="Seleccione suspension delantera"
          data={suspensionDelantera}
          size="small"
          isOptionEqualToValue={(option, value) => option.name === value.name}
          {...defaultPropsSuspFront}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Seleccione suspension delantera"
              margin="normal"
              variant="outlined"
            />
          )}
          style={{
            display: "flex",
            justifyContent: "center",
            width: "70%",
            position: "relative",
            left: -47,
            marginRight: 10,
            "& > p": {
              fontSize: "1.2rem",
            },
          }}
          fullWidth
          onChange={onChangeSuspensionDelantera}
          value={values.id_suspension_delantera?.name}
        />
      </Grid>
      <Divider />
      <Grid item xs={12}>
        <InputLabel htmlFor="input-suspen-back">
          Tipo de suspensión trasera
        </InputLabel>
        <Autocomplete
          id="input-suspen-back"
          name="id_suspension_trasera"
          noOptionsText="No se encuentran suspensiones"
          label="Seleccione suspension trasera"
          data={suspensionTrasera}
          size="small"
          isOptionEqualToValue={(option, value) => option.name === value.name}
          {...defaultPropsSuspBack}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Seleccione suspension trasera"
              margin="normal"
              variant="outlined"
            />
          )}
          style={{
            display: "flex",
            justifyContent: "center",
            width: "70%",
            position: "relative",
            left: -40,
            marginRight: 10,
            "& > p": {
              fontSize: "1.2rem",
            },
          }}
          fullWidth
          onChange={onChangeSuspensionTrasera}
          value={values.id_suspension_trasera?.name}
        />
      </Grid>
      <Divider />
    </Grid>
  );
};

export default Security;

import axios from "axios";
import GeneralData from "../data/GeneralData";

const BASE_URL = `${GeneralData.host_api}`;

const apiInstance = axios.create({ baseURL: BASE_URL });

export const apiAdminInstance = axios.create({ baseURL: BASE_URL });

export const apiUser = axios.create({ baseURL: BASE_URL });
//interceptor user logged request
apiUser.interceptors.request.use((request) => {
  request.headers["Authorization"] =
    "Bearer " + process.env.REACT_APP_JWT_PORTFOLIO_TEMP;
  request.headers.user = "Bearer " + localStorage.getItem("token_session");
  return request;
});

apiAdminInstance.interceptors.request.use((request) => {
  request.headers["Authorization"] =
    "Bearer " + process.env.REACT_APP_JWT_PORTFOLIO_TEMP;
  request.headers.user = "Bearer " + localStorage.getItem("SESSION_ID");
  return request;
});

apiInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (!error.response) {
      return Promise.reject({
        status: 500,
        timestamp: new Date(),
        message: "Se ha producido un error inesperado",
        error: "Error inesperado",
      });
    }
    return Promise.reject(error.response.data);
  }
);

export default apiInstance;

import apiInstance from "../Api/ApiInstance";

export async function GetFilterMotos(
  keyword,
  id_marca,
  precio_min,
  precio_max,
  id_estilo,
  cilindraje_min,
  cilindraje_max,
  id_transmision,
  id_arranque,
  potencia_min,
  potencia_max,
  torque_min,
  torque_max,
  freno_del,
  freno_tra,
  orden
) {
  return await apiInstance.get(
    `api/official-site/catalogo-de-motos/${keyword || `''`}/${id_marca || `''`}/${
      precio_min || `''`
    }/${precio_max || `''`}/${id_estilo || `''`}/${cilindraje_min || `''`}/${
      cilindraje_max || `''`
    }/${id_transmision || `''`}/${id_arranque || `''`}/${
      potencia_min || `''`
    }/${potencia_max || `''`}/${torque_min || `''`}/${torque_max || `''`}/${
      freno_del || `''`
    }/${freno_tra || `''`}/${orden || `''`}/`
  );
}
export async function ListAllMarcas() {
  return await apiInstance.get("api/official-site/catalogo-de-motos/marcas/");
}
export async function ListAllEstilos() {
  return await apiInstance.get("api/official-site/catalogo-de-motos/estilos/");
}
export async function ListAllTransmision() {
  return await apiInstance.get("api/official-site/catalogo-de-motos/tipotransmision/");
}
export async function ListAllArranque() {
  return await apiInstance.get("api/official-site/catalogo-de-motos/tipoarranque/");
}
export async function ListAllFrenoDelantero() {
  return await apiInstance.get("api/official-site/catalogo-de-motos/frenodelantero/");
}
export async function ListAllFrenoTrasero() {
  return await apiInstance.get("api/official-site/catalogo-de-motos/frenotrasero/");
}

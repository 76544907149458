import apiInstance from "../Api/ApiInstance";

export const ListAllMotos = async (keyword = "''") => {
  return await apiInstance.get(
    `api/official-site/catalogo-de-motos/${keyword}/`
  );
};

export async function infoMoto(sLugo) {
  return await apiInstance.get(
    `api/official-site/catalogo-de-motos/product/${sLugo}`
  );
}

import GeneralData from "../GeneralData";

export const ConditionsTerms = () => {
  return (
    <div>
      <ol>
        <li className="paragraphs">
          <p className="paragraphs">
            Los Términos y Condiciones expuestos a continuación rigen el uso de
            del sitio web “www.supre.com.co”, que aplica a Usuarios de{" "}
            <b>Suprecredito S.A.S.</b> (en adelante Suprecredito) (“de ahora en
            adelante “Suprecredito”), y cualquier otro tercero aliado y
            proveedor de bienes o servicios, que opere o navegue por la página
            web.
          </p>
          <p className="paragraphs">
            Si usted decide acceder a este sitio web le aplicarán estos términos
            y condiciones. Usted debe revisar detenidamente este acuerdo para
            asegurarse que lo entiende, cómo puede operar, cómo navegar por ella
            y como realizar transacciones de bienes.
          </p>
          <p className="paragraphs">
            <b>AVISO IMPORTANTE: </b> Por el hecho de ingresar a nuestra Página
            Web, entendemos la aceptación y conocimiento por parte del Usuario
            de nuestra Política de Privacidad la cual se puede encontrar en la
            página{" "}
            <a href="https://www.supre.com.co/politicas/privacidad-de-datos">
              www.supre.com.co/politicas/
            </a>{" "}
            .
          </p>
        </li>
        <li className="paragraphs">
          <h2 className="o-heading-4"> Sobre el servicio de Suprecredito </h2>
          <p className="paragraphs">
            Suprecredito se dedica principalmente al otorgamiento de créditos
            dentro del territorio colombiano. Por lo que, mediante{" "}
            <a href="https://www.supre.com.co"> www.supre.com.co</a> se le
            suministrará al cliente o usuario el portafolio de servicios
            ofrecidos, facilitando de esta manera el acceso a información sobre
            las características, requisitos, beneficios y condiciones sobre las
            cuales se otorga el crédito.
          </p>
        </li>
        <li className="paragraphs">
          <h2 className="o-heading-4">
            {" "}
            Información sobre la página web y la aplicación{" "}
          </h2>
          <p className="paragraphs">
            Este documento contiene los términos y condiciones generales y
            particulares que rigen el acceso y uso del sitio{" "}
            <a href="https://www.supre.com.co"> www.supre.com.co</a>
          </p>
          <ol className="c-lista-1">
            <li className="paragraphs">
              <p className="paragraphs">
                El uso de la página{" "}
                <a href="https://www.supre.com.co"> www.supre.com.co</a> que
                incluye los servicios prestados en la misma está sujeto a los
                términos que figuran en el presente y a las condiciones
                complementarias y subsidiarias determinadas por la empresa
                Suprecredito e informadas a los Usuarios a través de la página{" "}
                <a href="https://www.supre.com.co"> www.supre.com.co</a>{" "}
                debiendo también respetarse todas las demás leyes o
                reglamentaciones nacionales e internacionales aplicables al
                igual que políticas de privacidad.
              </p>
            </li>
            <li className="paragraphs">
              <p className="paragraphs">
                La política de privacidad disponible en www.supre.com.co (en
                adelante, la <b> “Política de Privacidad” </b>) es parte
                integral de los presentes Términos y Condiciones, así como todas
                las páginas y enlaces disponibles en el{" "}
                <a href="https://www.supre.com.co"> www.supre.com.co</a> que
                contienen información que genera derechos y/u obligaciones para
                los usuarios. La Política de Privacidad puede ser consultada en
                el siguiente link{" "}
                <a href="https://supre.com.co/politicas/privacidad-de-datos">
                  {" "}
                  https://supre.com.co/politicas/
                </a>
                .
              </p>
            </li>
            <li className="paragraphs">
              <p className="paragraphs">
                Al presentar conformidad por los presentes Términos y
                Condiciones, el Usuario también está prestando conformidad a la
                Política de Privacidad y a todos los derechos y obligaciones
                contenidos en{" "}
                <a href="https://www.supre.com.co"> www.supre.com.co</a>.
              </p>
            </li>
            <li className="paragraphs">
              <p className="paragraphs">
                El Usuario que <b>NO ACEPTE</b> los Términos y Condiciones
                deberá abstenerse de ingresar a{" "}
                <a href="https://www.supre.com.co"> www.supre.com.co</a> y/o
                utilizar los servicios ofrecidos en el mismo.
              </p>
            </li>
            <li className="paragraphs">
              <p className="paragraphs">
                El Usuario reconoce y acepta que en los Términos y Condiciones
                se establecen las reglas de un contrato bilateral que se
                concertará mediante el uso, por los contratantes, de
                comunicaciones y mensajes recíprocos, a través de las
                herramientas de comunicación provistas en www.supre.com.co en el
                que prevalecerán como principios rectores, y esenciales, la
                buena fe y el deber de colaboración entre el Usuario y{" "}
                Suprecredito.
              </p>
            </li>
            <li className="paragraphs">
              <p className="paragraphs">
                El acceso a{" "}
                <a href="https://www.supre.com.co"> www.supre.com.co</a> está
                disponible para las personas naturales que (i) sean mayores de
                dieciocho (18) años, (ii) tengan capacidad legal para contratar,
                (iii) que sean titulares de un número de celular personal,
                entendiéndose que el celular permite recibir llamadas y mensajes
                SMS. Por ello, no podrán utilizar los servicios de
                www.supre.com.co: Las personas jurídicas, los menores de edad o
                las personas temporal o definitivamente inhabilitadas para
                contratar por el régimen colombiano.
              </p>
            </li>
            <li className="paragraphs">
              <p className="paragraphs">
                Suprecredito en cualquier momento y a su solo arbitrio, podrán
                disponer la suspensión o inhabilitación temporal o definitiva
                para el acceso del Usuario a www.supre.com.co, en adelante “la
                Plataforma”.
              </p>
            </li>
          </ol>
        </li>
        <li className="paragraphs">
          <h2 className="o-heading-4">
            {" "}
            Regulación - No somos entidad financiera{" "}
          </h2>
          <p className="paragraphs">
            Se deja expresamente aclarado que Suprecredito no es una entidad
            financiera ni presta al Usuario ningún servicio bancario o
            cambiario, asimismo, tampoco realiza ninguna captación de recursos
            por parte de sus usuarios, por lo que, no resulta aplicable ni a los
            presentes Términos y Condiciones ni a ninguno de los servicios
            prestados por Suprecredito, al igual que no están controlados ni
            vigilados por la Superintendencia Financiera de Colombia.
          </p>
        </li>
        <li className="paragraphs">
          <h2 className="o-heading-4">
            Condiciones de la solicitud, aprobación y aceptación del contrato de
            apertura de crédito.
          </h2>
          <ol className="c-list-1">
            <li className="paragraphs">
              <p className="paragraphs">
                El Usuario, luego de ingresar a su Cuenta en{" "}
                <a href="https://www.supre.com.co"> www.supre.com.co</a>, podrá
                solicitar a en todo momento, un crédito, indicando: (i) El
                producto que desea financiar o acceder mediante crédito; (ii) El
                monto del mismo y; (iii) El plazo de devolución escogido.
              </p>
              <p className="paragraphs">
                Una vez el usuario indique los puntos inmediatamente anteriores,
                la plataforma mostrará un posible resumen del crédito, siendo
                esta una simulación que mostrará los posibles valores de: (i)
                Monto Solicitado; (ii) La Tasa de interés efectiva anual
                aplicable; (iii) el plazo acordado, (iv) el monto y la fecha de
                la devolución de la ​obligación​, (v) el sistema de amortización
                del capital y cancelación de los intereses (vi) el valor del
                seguro, (vii) la cantidad, periodicidad y monto​/cuota​ de los
                pagos a realizar, (viii) los gastos adicionales permitidos por
                la ley, cargos, y (ix) los intereses ​moratorios (conforme dicho
                término se define más adelante) que resultarían aplicables en
                caso de mora.
              </p>
              <p className="paragraphs">
                Recuerde que el posible resumen del crédito tiene un fin
                estrictamente informativo y no constituye oferta, opción o
                promesa de contratar a cargo de Suprecredito S.A.S. Los términos
                de esta simulación son suministrados con base en las condiciones
                comerciales y de mercado que han sido establecidas para la fecha
                en que se realiza, por lo que usted acepta que los valores y
                cargos podrán variar una vez la solicitud de crédito sea
                enviada.
              </p>
            </li>
            <li className="paragraphs">
              <p className="paragraphs">
                Suprecredito una vez recibida la solicitud, en un plazo no mayor
                a dos (2) días hábiles, comunicará al Usuario (i) la aprobación
                del cupo aprobado, (ii) la tasa de interés efectiva anual
                aplicable, (iii) el plazo acordado, (iv) el monto y la fecha de
                la devolución de la ​obligación​, (v) el sistema de amortización
                del capital y cancelación de los intereses (vi) el valor del
                seguro, (vii) la cantidad, periodicidad y monto​/cuota​ de los
                pagos a realizar, (viii) los gastos adicionales permitidos por
                la ley, cargos, y (ix) los intereses ​moratorios​ (conforme
                dicho término se define más adelante) que resultarían aplicables
                en caso de mora.
              </p>
              <p className="paragraphs">
                En igual plazo, Suprecredito comunicará al Usuario el rechazo ​o
                negación ​de ​su​ solicitud.
              </p>
            </li>
            <li className="paragraphs">
              <p className="paragraphs">
                El Usuario, una vez recibida la ​aprobación​, comunicará a
                Suprecredito en la Plataforma la aceptación de la ​misma,
                incluyendo los Términos y Condiciones ​​del crédito ​(en
                adelante, la “Aceptación”).
              </p>
            </li>
            <li className="paragraphs">
              <p className="paragraphs">
                El Usuario, al comunicar la ​aprobación​, reconoce y acepta que
                deberá a Suprecredito sujeto a la utilización del cupo de
                crédito, la suma que éste indique en la aprobación.
              </p>
            </li>
            <li className="paragraphs">
              <p className="paragraphs">
                Nuestra plataforma tiene tres tipos de cobros:
              </p>
              <ol className="c-lista-2">
                <li className="paragraphs">
                  <p className="paragraphs">
                    En cuanto a los intereses remuneratorios: Computarán: las
                    sumas que el deudor pague por concepto de servicios
                    vinculados directamente con el crédito, puntos relacionados
                    con la inflación, porcentaje de utilidades a que tiene
                    derecho la entidad crediticia en desarrollo de su negocio,
                    costos financieros (gastos por administración del crédito,
                    manejo de cartera, papelería), estudio de crédito, cuotas de
                    administración o de manejo u otra equivalente, costos de
                    operación, envíos de extractos mensuales, expedición y
                    manejo de cupones de pago, nómina, implementación y
                    desarrollo de tecnologías; entre otros, por tratarse de
                    rubros que se hacen en función y mejora del objeto social de
                    Suprecredito.
                  </p>
                </li>
                <li className="paragraphs">
                  <p className="paragraphs">
                    En cuanto a los intereses no remuneratorios, tenemos
                    servicios adicionales que proveemos en la plataforma:
                    avales, fianzas, garantías mobiliarias y otros servicios.
                  </p>
                </li>
                <li className="paragraphs">
                  <p className="paragraphs">
                    Frente a los gastos de cobranza, siendo este un cobro que se
                    causara solo en caso de que el cliente entre en mora
                    (incumplimiento) frente a su obligación con Suprecredito,
                    estos no son considerados como intereses, y su monto está
                    condicionado por la labor de cobranza desplegada por
                    Suprecredito.
                  </p>
                </li>
                <p className="paragraphs">
                  Nota: A partir del 1 de noviembre 2024 se implementó el cobro
                  de una comisión por valor de $8.000 pesos a los clientes que
                  realizan transacciones a través de Corresponsales de
                  Bancolombia (valor por transacción realizada). Este cargo
                  obedece al convenio existente entre Bancolombia y
                  Suprecredito, que establece una comisión por las transacciones
                  efectuadas en corresponsales bancarios.
                </p>
              </ol>
            </li>
            <li className="paragraphs">
              <p className="paragraphs">
                Los porcentajes que se refieren a: la prestación de servicios o
                la asesoría para la ayuda en la liquidación, los costos de
                entrega de los bienes o servicios cuyo precio fue financiado
                mediante el crédito, estudio de títulos, cargos por seguros,
                mantenimiento y disponibilidad del cupo del crédito (costo de
                oportunidad) y los costos cobrados al deudor mediante la cuota
                de manejo derivados de la infraestructura técnica y/o lógica que
                subyace para el funcionamiento de la respectiva tarjeta o
                plataforma de comercio electrónico.
              </p>
              <p>
                <b>
                  No se refutarán como intereses remuneratorios, pues el pago
                  por aquellos servicios no se encuentra vinculados directamente
                  al crédito.
                </b>
              </p>
              <p className="paragraphs">
                La Aceptación obligará a Suprecredito a que, en un plazo no
                mayor a setenta y dos (72) horas hábiles, tener a disposición
                del consumidor el cupo de crédito, en las condiciones detalladas
                en la respectiva aprobación.
              </p>
              <p className="paragraphs">
                El monto del crédito aprobado es el respaldo de la compra de un
                producto, por lo que el tiempo puede variar para el caso de
                motocicletas toda vez que, dependerá del trámite ante
                autoridades de tránsito y de la expedición del Seguro
                Obligatorio de Accidentes de Tránsito (SOAT).
              </p>
              <p className="paragraphs">
                Si transcurridos treinta (30) días contados a partir de la
                aprobación del crédito, el cliente no realiza ningún tipo de
                gestión con el fin de respaldar el crédito, este quedará sin
                efectos y en caso de requerir nuevamente el servicio, será
                necesario realizar nuevamente la solicitud del crédito.
              </p>
            </li>
            <li className="paragraphs">
              <p className="paragraphs">
                La tasa de interés remuneratorio, cobrada por Suprecredito en
                todos sus créditos será liquidada basada en la Tasa Efectiva
                Anual. Los intereses remuneratorios se computarán desde la fecha
                de la utilización del cupo hasta la fecha en la que el Usuario
                hiciera íntegra y efectiva pago de dicho monto, basado a un año
                de trescientos sesenta y cinco días (365) días, para lo cual se
                incluirá el primer día de utilización y se excluirá el último.
              </p>
            </li>
            <p className="paragraphs">
              Recuerda que el AVAL es una garantía que cubre cumplimiento del
              pago total o parcial del crédito. Para acceder a un crédito con
              Suprecredito, debes cumplir con el requisito de constitución de un
              AVAL. Si deseas, puedes contratarlo con los aliados de
              Suprecredito al momento de tu solicitud. De igual forma, puedes
              cumplir el requisito del AVAL por medio de otros canales, donde en
              dicho caso deberás suministrar los documentos necesarios que
              soporten dicho AVAL, que serán sujetos a revisión del equipo de
              Suprecredito. Recuerda que el AVAL no se computa para la tasa de
              interés, ya que el pago de los mismos es recibido por un tercero
              distinto al originador del crédito, en este caso Suprecredito.
            </p>
            <li className="paragraphs">
              <p className="paragraphs">
                Aplican a esta modalidad las normas definidas en el artículo
                1400 y siguientes del Código de Comercio colombiano.
              </p>
            </li>
            <li className="paragraphs">
              <p className="paragraphs">
                Respecto a la figura de Garantías mobiliarias , el cliente
                deberá constituir garantía mobiliaria conforme a la Ley 1676 de
                2013 y demás normas concordantes y complementarias aplicable
                sobre el vehículo automotor adquirido mediante financiamiento
                con el fin de garantizar el cumplimiento de las Obligaciones. El
                otorgamiento de esta Garantía no limita al Garante para hacer
                efectiva cualquier otra garantía disponible a su favor, ni para
                ejercer otras acciones personales o reales, contra el deudor o
                contra sus codeudores, fiadores o garantes para hacer cumplir
                las Obligaciones Garantizadas. Los costos estarán definidos
                según la tarifa estipulada por la Confedereción Colombiana de
                Cámaras de Comercio “Confecamaras” aplicables según la
                Resolución 834 de 2014, 0356 de 2015 y 001 de 2015 del
                Ministerio de Comercio Industria y Turismo que para la vigencia
                2024 son:
              </p>
              <table
                style={{
                  width: " 100%",
                  textAlign: "center",
                  borderCollapse: "collapse",
                }}>
                <tr>
                  <th
                    style={{
                      border: "1px solid #ccc",
                    }}>
                    Formulario de Registro
                  </th>
                  <th
                    style={{
                      border: "1px solid #ccc",
                    }}>
                    Costo sin impuestos
                  </th>
                </tr>
                <tr>
                  <td
                    style={{
                      border: "1px solid #ccc",
                    }}>
                    Inscripción Inicial (nueva garantía)
                  </td>
                  <td
                    style={{
                      border: "1px solid #ccc",
                    }}>
                    $ 50.000
                  </td>
                </tr>

                <tr>
                  <td
                    style={{
                      border: "1px solid #ccc",
                    }}>
                    Modificación Global Acreedor/Entidad
                  </td>
                  <td
                    style={{
                      border: "1px solid #ccc",
                    }}>
                    $ 50.000
                  </td>
                </tr>

                <tr>
                  <td
                    style={{
                      border: "1px solid #ccc",
                    }}>
                    Formulario de Ejecución Concursal
                  </td>
                  <td
                    style={{
                      border: "1px solid #ccc",
                    }}>
                    $ 50.000
                  </td>
                </tr>

                <tr>
                  <td
                    style={{
                      border: "1px solid #ccc",
                    }}>
                    Modificación de Registros
                  </td>
                  <td
                    style={{
                      border: "1px solid #ccc",
                    }}>
                    $ 13.000
                  </td>
                </tr>

                <tr>
                  <td
                    style={{
                      border: "1px solid #ccc",
                    }}>
                    Cancelación
                  </td>
                  <td
                    style={{
                      border: "1px solid #ccc",
                    }}>
                    $ 13.000
                  </td>
                </tr>

                <tr>
                  <td
                    style={{
                      border: "1px solid #ccc",
                    }}>
                    Cesión
                  </td>
                  <td
                    style={{
                      border: "1px solid #ccc",
                    }}>
                    $ 13.000
                  </td>
                </tr>
                <tr>
                  <td
                    style={{
                      border: "1px solid #ccc",
                    }}>
                    Ejecución
                  </td>
                  <td
                    style={{
                      border: "1px solid #ccc",
                    }}>
                    $ 13.000
                  </td>
                </tr>
                <tr>
                  <td
                    style={{
                      border: "1px solid #ccc",
                    }}>
                    Restitución
                  </td>
                  <td
                    style={{
                      border: "1px solid #ccc",
                    }}>
                    $ 13.000
                  </td>
                </tr>
                <tr>
                  <td
                    style={{
                      border: "1px solid #ccc",
                    }}>
                    Terminación de la Ejecución
                  </td>
                  <td
                    style={{
                      border: "1px solid #ccc",
                    }}>
                    $ 13.000
                  </td>
                </tr>
                <tr>
                  <td
                    style={{
                      border: "1px solid #ccc",
                    }}>
                    Terminación de la Ejecución Concursal
                  </td>
                  <td
                    style={{
                      border: "1px solid #ccc",
                    }}>
                    $ 13.000
                  </td>
                </tr>
                <tr>
                  <td
                    style={{
                      border: "1px solid #ccc",
                    }}>
                    Certificados pre-pagados
                  </td>
                  <td
                    style={{
                      border: "1px solid #ccc",
                    }}>
                    $ 13.000
                  </td>
                </tr>
              </table>
              <p>
                Nota 1: Los anteriores valores están en pesos colombianos y NO
                incluyen el IVA del 19%, ni el costo de las retenciones si a
                ello hay lugar. El valor de los impuestos debe sumarse a los
                valores indicados en la presente tabla.
              </p>
              <p>
                Nota 2: La ejecución de la garantía, se realizará sin perjuicio
                de libertad que tiene el Acreedor para acudir a cualquiera de
                los mecanismos de ejecución judicial previstos en los artículo
                437 y 468 del Código General del Proceso, así como también el
                cobro de la obligación por vía del proceso ejecutivo. Por lo
                anterior, SUPRECREDITO S.A.S como acreedor también podrá,
                discrecionalmente, hacer ejercicio de sus derechos a través de
                los mecanismos de ejecución de “Pago Directo” o de “Ejecución
                Especial de la Garantía” previstos en la Ley 1676 de 2013,
                Decreto 1835 de 2015 y las demás normas que la reglamente,
                modifiquen o sustituyan.
              </p>
            </li>
          </ol>
        </li>
        <li className="paragraphs">
          <h2 className="o-heading-4">
            {" "}
            Mora: intereses y cargos aplicables en caso de mora a los servicios
            prestados por Suprecredito.
          </h2>
          <ol className="c-list-1">
            <li className="paragraphs">
              <p className="paragraphs">
                El Usuario acepta que incurrirá en mora de pleno derecho y en
                forma automática, sin necesidad de interpelación judicial y/o
                extrajudicial previa alguna, en caso de no abonar íntegramente
                en tiempo y forma cualquiera de las sumas adeudadas a
                Suprecredito bajo cualquiera de los servicios prestados por
                Suprecredito y/o la plataforma{" "}
                <a href="https://www.supre.com.co">www.supre.com.co</a>.
              </p>
            </li>
            <li className="paragraphs">
              <p className="paragraphs">
                La tasa aplicable en caso de mora será la equivalente a la tasa
                máxima legal permitida, que será aplicada sobre las cuotas
                efectivamente vencidas. En lo que se refiere a los intereses
                remuneratorio y moratorio, la Superintendencia Financiera ha
                establecido que no podrán exceder 1.5 veces el Interés Bancario
                Corriente, es decir, el efectivo anual para la modalidad de
                crédito de consumo y ordinario.
              </p>
            </li>
            <li className="paragraphs">
              <p className="paragraphs">
                <b>Suprecredito</b> podrá cobrar honorarios: En caso de
                incumplimiento y en caso de desplegarse efectivamente gestión de
                cobranza, se generarán las siguientes tarifas de acuerdo con el
                número de cuotas vencidas y sobre el saldo en ese estado, así:
              </p>
              <strong>COBRO PRE-JURÍDICO Y JURÍDICO</strong>
              <p className="paragraphs">
                La compañía realizará las gestiones de cobranza pre-jurídica y
                jurídica con el fin de obtener el pronto pago de la obligación,
                la gestión de cobranza se realizará con personal interno,
                abogados y/o casas de cobro, gestión que tendrá una tarifa de
                honorarios dependiendo del tipo de cobro y la edad de la
                cartera, así:
              </p>
              <table
                style={{
                  width: " 100%",
                  textAlign: "center",
                  borderCollapse: "collapse",
                }}>
                <tr>
                  <th
                    style={{
                      border: "1px solid #ccc",
                    }}>
                    Tipo de proceso
                  </th>
                  <th style={{ border: "1px solid #ccc" }}>31-60</th>
                  <th style={{ border: "1px solid #ccc" }}>61-90</th>
                  <th style={{ border: "1px solid #ccc" }}>91-150</th>
                  <th style={{ border: "1px solid #ccc" }}>151-180</th>
                  <th style={{ border: "1px solid #ccc" }}>181 - 360</th>
                  <th style={{ border: "1px solid #ccc" }}> {">360"} </th>
                </tr>
                <tr>
                  <td style={{ border: "1px solid #ccc" }}>Pre-jurídico</td>
                  <td style={{ border: "1px solid #ccc" }}>1%</td>
                  <td style={{ border: "1px solid #ccc" }}>3%</td>
                  <td style={{ border: "1px solid #ccc" }}>7%</td>
                  <td style={{ border: "1px solid #ccc" }}>10%</td>
                  <td style={{ border: "1px solid #ccc" }}>15%</td>
                  <td style={{ border: "1px solid #ccc" }}>25%</td>
                </tr>
                <tr>
                  <td style={{ border: "1px solid #ccc" }}>Jurídico</td>
                  <td style={{ border: "1px solid #ccc" }} colspan="2">
                    N/A
                  </td>
                  <td style={{ border: "1px solid #ccc" }} colspan="2">
                    10%
                  </td>
                  <td style={{ border: "1px solid #ccc" }} colspan="2">
                    20%
                  </td>
                </tr>
                <tr>
                  <td style={{ border: "1px solid #ccc" }} colspan="7">
                    * Porcentaje de honorarios sobre las cuotas en mora
                    utilizando como referente la máxima morosidad del cliente.
                  </td>
                </tr>
              </table>
            </li>
          </ol>
        </li>
        <li className="paragraphs">
          <h2 className="o-heading-4">Uso de datos personales</h2>
          <p className="paragraphs">
            Para hacer el proceso mayormente digital, es necesario el
            tratamiento de tus datos personales, para los cuales deberás aceptar
            una autorización en el momento en que te registres en la plataforma.
          </p>
          <p className="paragraphs">
            Toda tu información será almacenada en ficheros de datos de carácter
            personal y/o comercial y/o archivos y/o mensajes de datos
            digitalizados de propiedad de Suprecredito, que manejará conforme a
            la ley, los cuales son necesarios para el uso de las funcionalidades
            de los Servicios, según la Política de Privacidad.
          </p>
          <p className="paragraphs">
            <b>Responsable:</b> Suprecredito es el responsable del tratamiento
            de los datos personales, pues será quien decida sobre la base de
            datos y/o tratamiento de estos.
          </p>
          <ol className="c-list-1">
            <li className="paragraphs">
              <p className="paragraphs">
                Le recordamos que cuenta con la posibilidad de acceder en
                cualquier momento de forma gratuita a consultar, actualizar,
                rectificar y suprimir los datos personales que reposan en
                nuestra base de datos en los términos establecidos en el
                artículo 14° de la Ley 1581 de 2012. Para solicitar esto, deberá
                comunicarse al siguiente correo (servicioalcliente@supre.com.co
                ).
              </p>
            </li>
            <li className="paragraphs">
              <p className="paragraphs">
                De compartir la forma responsable como nuestra compañía ha
                venido dando el tratamiento de sus datos personales que reposan
                en nuestros archivos y si no existe ninguna objeción al respecto
                le agradecemos su permanente confianza en nuestra empresa, en
                caso contrario lo invitamos a comunicarse por medio de nuestra
                Plataforma{" "}
                <a href="https://www.supre.com.co">www.supre.com.co</a>.
              </p>
            </li>
            <li className="paragraphs">
              <p className="paragraphs">
                El usuario se compromete a navegar por el{" "}
                <a href="https://www.supre.com.co">www.supre.com.co</a> y a
                utilizar el contenido de este de buena fe. El diligenciamiento
                de cualquier formulario existente en la plataforma o la remisión
                de un correo electrónico a cualquiera de nuestros buzones,
                implican la aceptación de estos Términos y Condiciones y de la
                Política de Privacidad. Lo anterior implica que el cumplimiento
                o incumplimiento de sus obligaciones, se reflejará en las
                mencionadas bases de datos, en donde se consignan de manera
                completa, todos los datos referentes a su actual y pasado
                comportamiento y, en general, al cumplimiento.
              </p>
            </li>
            <li className="paragraphs">
              <p className="paragraphs">
                Por la simple visita a la Plataforma, los Usuarios no facilitan
                información personal alguna ni quedan obligados a facilitarla.
                El servidor donde está alojado
                <a href="https://www.supre.com.co">www.supre.com.co</a>​ utiliza
                cookies para mejorar el servicio prestado por Suprecredito.
                Estas cookies se instalan automáticamente en el navegador
                empleado por el usuario, pero no almacenan ningún tipo de
                información relativa a éste.
              </p>
            </li>
          </ol>
        </li>
        <li className="paragraphs">
          <h2 className="o-heading-4">Responsabilidad</h2>
          <ol className="c-list-1">
            <li className="paragraphs">
              <p className="paragraphs">
                Suprecredito presume que los datos proporcionados han sido
                introducidos por su titular o por una persona debidamente
                autorizada por éste, que son correctos y exactos, y que
                pertenecen a una persona mayor de edad, presunción que se genera
                por el simple hecho de Usuario del Suprecredito, dado que, para
                ostentar dichas calidades, es un requisito ser mayor de edad.
              </p>
            </li>
            <li className="paragraphs">
              <p className="paragraphs">
                Corresponde al usuario la actualización de sus propios datos,
                por lo que en cualquier momento, el usuario tendrá el derecho de
                acceso, rectificación, cancelación y oposición a sus datos
                personales. Para ejercer esta facultad el usuario deberá acceder
                ​a su cuenta ​ en{" "}
                <a href="https://www.supre.com.co">www.supre.com.co</a>. Por lo
                tanto, el usuario será el único y exclusivo responsable de la
                veracidad y exactitud de sus datos personales. Conforme a la
                legislación vigente en materia de protección de datos,
                incluyendo, pero sin limitarse, a la Ley 1273 de 2009, Ley 1581
                de 2012 y Decreto 1377 de 2013.
              </p>
            </li>
            <li className="paragraphs">
              <p className="paragraphs">
                Suprecredito ha adoptado los niveles de seguridad adecuados para
                los datos facilitados por el usuario y, además, ha instalado
                todos los medios y medidas a su alcance para evitar la pérdida,
                mal uso, alteración, acceso no autorizado y extracción de estos.
                Nuestra política de privacidad puede ser consultada aquí{" "}
                <a href="https://www.supre.com.co">www.supre.com.co</a>.
              </p>
            </li>
            <li className="paragraphs">
              <p className="paragraphs">
                El Usuario, en oportunidad del uso de la plataforma, se obliga a
                no ejecutar y/o realizar operaciones y/o actos que pongan en
                riesgo, o generen daños inmediatos o mediatos, a la operación
                normal y habitual de{" "}
                <a href="https://www.supre.com.co">www.supre.com.co</a>. En tal
                evento, Suprecredito podrá suspender temporal o definitivamente
                al Usuario y/o cancelar su Cuenta, sin perjuicio del
                cumplimiento de las obligaciones contraídas y de las acciones
                legales que pudieran corresponder, particularmente en relación a
                los daños causados.
              </p>
            </li>
            <li className="paragraphs">
              <p className="paragraphs">
                Suprecredito en un fiel reflejo de su responsabilidad
                empresarial, informa al Usuario que la plataforma es de acceso
                voluntario por él, sujeto a los Términos y Condiciones y la
                Política de Privacidad, para el uso y/o goce de los servicios,
                información o entretenimientos que la{" "}
                <a href="https://www.supre.com.co">www.supre.com.co</a> contiene
                y brinda. En consecuencia, <b>Suprecredito</b> informa al
                Usuario que no asumen ninguna responsabilidad adicional a las
                que se fijan específicamente en los Términos y Condiciones y la
                Política de Privacidad.
              </p>
            </li>
            <li className="paragraphs">
              <p className="paragraphs">
                Suprecredito deja claramente establecido que no asume ningún
                tipo de responsabilidad por la publicidad de terceros que
                contenga <a href="https://www.supre.com.co">www.supre.com.co</a>{" "}
                ni serán responsables por las vinculaciones que el Usuario
                establezca con ellos y/o con otros Usuarios, ni serán
                responsables por las eventuales consecuencias, de cualquier tipo
                que fuera, resultantes de fallas en el sistema, en el servidor o
                en Internet que pudieran surgir del acceso y/o uso de{" "}
                <a href="https://www.supre.com.co">www.supre.com.co</a> bajo
                cualquier modalidad que el Usuario empleara. Suprecredito no
                garantiza el acceso y/o uso continuado o ininterrumpido del{" "}
                <a href="https://www.supre.com.co">www.supre.com.co</a> y por
                ello por cuanto el sistema puede eventualmente no estar
                disponible debido a dificultades técnicas o fallas de Internet.
                No obstante, se compromete a realizar sus mejores esfuerzos para
                procurar restablecer el sistema con la mayor celeridad posible
                sin que por ello pueda imputársele algún tipo de
                responsabilidad.
              </p>
            </li>
            <li className="paragraphs">
              <p className="paragraphs">
                Las aplicaciones, servicios y contenidos que provee en{" "}
                <a href="https://www.supre.com.co">www.supre.com.co</a> fueron
                exclusivamente diseñados para el uso por parte de los Usuarios.
                Los programas, bases de datos, redes y archivos que permiten al
                Usuario acceder y usar su cuenta, son de propiedad de{" "}
                Suprecredito y están protegidas por las leyes y los tratados
                internacionales de derecho de autor, marcas, patentes, modelos y
                diseños industriales. El uso indebido y la reproducción total o
                parcial de dichos contenidos quedan prohibidos, salvo
                autorización expresa y por escrito de Suprecredito.
              </p>
            </li>
          </ol>
        </li>
        <li className="paragraphs">
          <h2 className="o-heading-4">
            Política aplicable a todos los servicios prestados por
            <b>Suprecredito</b>{" "}
          </h2>
          <ol className="c-list-1">
            <li className="paragraphs">
              <p className="paragraphs">
                El Usuario acepta la plena validez de las notificaciones
                enviadas a Suprecredito y recibidas de <b>Suprecredito</b> a
                través de la{" "}
                <a href="https://www.supre.com.co">www.supre.com.co</a> y/o por
                correo electrónico (en la dirección comunicada por el Usuario),
                destacándose que el presente contrato se conviene sobre la base
                de la buena fe contractual y el deber de colaboración de las
                partes.
              </p>
            </li>
            <li className="paragraphs">
              <p className="paragraphs">
                El usuario acepta que los datos y documentos generados dentro de
                la plataforma, serán almacenados en un{" "}
                <b>ARCHIVO CONFIABLE DE DATOS.</b> El cual estará prestado por
                Suprecredito o <b>un tercero contratado para esos efectos</b>,
                permitiendo el almacenamiento de la información electrónica,
                ofreciendo garantías de seguridad, confidencialidad y
                conservación. Rigiéndose bajo los principios de integridad,
                autenticidad y no repudio necesarios para garantizar su
                confiabilidad y contengan valor probatorio.
              </p>
            </li>
            <li className="paragraphs">
              <p className="paragraphs">
                Suprecredito podrá, a su criterio, otorgarle al Usuario una
                opción de extensión del plazo para el repago del monto adeudado.
              </p>
            </li>
          </ol>
        </li>
        <li className="paragraphs">
          <h2 className="o-heading-4">Aceptación de términos y condiciones</h2>
          <ol className="c-list-1">
            <li className="paragraphs">
              <p className="paragraphs">
                Estos Términos y Condiciones estarán regidos en todos sus puntos
                por las leyes vigentes en la República de Colombia
              </p>
            </li>
            <li className="paragraphs">
              <p className="paragraphs">
                Los presentes Términos y Condiciones se actualizaron el 19 de
                agosto de 2023 (Esta información se completa el día en que se
                suba a la plataforma – variable de entorno)
              </p>
            </li>
            <li className="paragraphs">
              <p className="paragraphs">
                Suprecredito se reserva el derecho a modificar, suspender o
                descontinuar el Servicio, total o parcialmente, (o cualquier
                parte o contenido) en cualquier momento, con o sin mediar
                notificación, y no será responsable.
              </p>
            </li>
            <li className="paragraphs">
              <p className="paragraphs">
                Toda modificación a la información del procedimiento de
                Suprecredito será informada oportunamente y serán efectivas
                después que Suprecredito las publique en su página web.
              </p>
            </li>
            <li className="paragraphs">
              <p className="paragraphs">
                Si accedes a la plataforma después de dicha publicación,
                constituye un consentimiento a vincularse a los nuevos Términos
                y Condiciones complementarios y/o sus modificaciones.
              </p>
            </li>
          </ol>
        </li>
        <li className="paragraphs">
          <h2 className="o-heading-4">
            Quejas, disputas y soluciones de conflictos
          </h2>
          <p className="paragraphs">
            Las partes tratarán de buena fe, resolver toda controversia,
            disputa, conflicto o reclamación, las cuales deberán someterse a
            procedimientos de mediación directa. Para lo anterior, Suprecredito
            también podrá utilizar para sí, o facilitar a sus colaboradores,
            cualquier información necesaria para resolver quejas, disputas o
            solucionar algún conflicto que se llegue a presentar como
            Peticiones, Quejas, Reclamos y Sugerencias (PQRS) que se recibirán a
            través de www.supre.com.co.
          </p>
        </li>
        <li className="paragraphs">
          <h2 className="o-heading-4">Terminación</h2>
          <p className="paragraphs">
            Si Suprecredito tiene razones suficientes para considerar que no has
            cumplido con las reglas, podrá, a su exclusivo criterio y sin
            necesidad de darle previo aviso, optar por: (i) dar por terminado el
            presente Acuerdo, (ii) terminar la licencia del Servicio; o (iii)
            impedir el acceso al Servicio o cualquier parte del mismo.
          </p>
          <p className="paragraphs">
            Recuerda revisar estas secciones para estar informado de las
            modificaciones que pudieran realizársele a las mismas, pues cada
            nuevo acceso a la página web será considerado por Suprecredito como
            una aceptación tácita de las nuevas condiciones, las cuales fueron
            actualizadas por última vez en {GeneralData.politictUpdatedAt}.
          </p>
          <p className="paragraphs">
            Si no estás de acuerdo con estos Términos y Condiciones o con
            cualquier disposición de la Política de Privacidad, te sugerimos
            abstenerte de acceder o navegar.
          </p>
        </li>
        <li className="paragraphs">
          <h2 className="o-heading-4">Propiedad Intelectual</h2>
          <p className="paragraphs">
            La Plataforma, las soluciones y los diseños, gráficos, textos,
            imágenes, código fuente y código objeto y demás contenido al que el
            Usuario tiene acceso para el uso de los Servicios, es de propiedad
            de Suprecredito y está protegido por la propiedad intelectual e
            industrial según corresponda. La utilización de los aplicativos y
            del software de la Plataforma por parte de los clientes o usuarios,
            no implica cesión alguna de los derechos de propiedad industrial y
            de derechos de autor de la Plataforma.
          </p>
          <p className="paragraphs">
            Los usos autorizados estarán delimitados por los presentes términos
            y condiciones, la finalidad natural y funcional de los aplicativos y
            de la Plataforma. 
          </p>
          <p className="paragraphs">
            Los signos distintivos, tales como marcas, nombres comerciales,
            logotipos, y todos los componentes de los signos distintivos de
            Suprecredito, son propiedad exclusiva de la misma, por lo cual los
            clientes o Usuarios no tiene el derecho a usar las marcas sin
            autorización. 
          </p>
          <p className="paragraphs">
            En caso de que el Usuario infrinja los derechos de propiedad
            intelectual o industrial de Suprecredito, quedará obligado a
            indemnizarla por los daños y perjuicios que esto le cause a
            Suprecredito. 
          </p>
        </li>
      </ol>
    </div>
  );
};

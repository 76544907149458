import React from "react";

export const FrenoTrasero = ({ listas, filtrar }) => {
  return (
    <div className="c-modal-1__section">
      <div className="c-modal-1__heading">Freno trasero</div>
      <div className="c-modal-1__item">
        <div className="c-list-6">
          {listas.listasfrenotrasero.map((item) => (
            <div className="c-list-6__item" key={item.id}>
              <div className="o-form-check">
                <input
                  className="o-form-check__campo--v2"
                  id={`check-freno-trasero-${item.id}`}
                  type="checkbox"
                  name="freno_trasero_check"
                  value={item.id}
                  onClick={filtrar}
                />
                <label htmlFor={`check-freno-trasero-${item.id}`}>
                  {" "}
                  <span className="c-form-1__text-2">
                    {" "}
                    {item.nombre_freno_trasero}{" "}
                  </span>
                </label>
                <input type="hidden" />
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

import React from "react";
import { Typography } from "@mui/material";
import InventoryOutlinedIcon from "@mui/icons-material/InventoryOutlined";
import { styledIconSEO, styledTextSEO } from "../MuiStyles";

const LeftInformation = () => {
  return (
    <>
      <InventoryOutlinedIcon style={styledIconSEO} />
      <Typography variant="h4" style={{ fontWeight: "900", marginBottom: 15 }}>
        SEO
      </Typography>
      <Typography variant="caption" style={styledTextSEO}>
        Mejora el posicionamiento orgánico en las busquedas de Google.
      </Typography>
    </>
  );
};

export default LeftInformation;

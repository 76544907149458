import React, { useEffect, useState } from "react";
import { formatCurrency } from "../../../helpers/FormatMoney";
import { CircularProgress, Grid } from "@mui/material";
import { Link } from "react-router-dom";
import { GetStatusSessionByReferencesServices } from "../../../utils/payment";
import "./styles.css";

export const PayOnlineDetail = ({ id }) => {
  const [loading, setLoading] = useState(true);
  const [pay, setPay] = useState({
    status: "",
    date: new Date(),
    total: 0,
    reference: "",
  });

  useEffect(() => {
    GetStatusSessionByReferencesServices(id).then((res) => {
      const { status, reference, createdAt, value } = res.data;
      setPay({
        status,
        date: new Date(createdAt),
        total: value,
        reference,
      });
      setLoading(false);
    });
  }, [id]);

  let backgroundColor, statusText;
  switch (pay.status.toUpperCase()) {
    case "APPROVED":
      backgroundColor = "green";
      statusText = "Aprobado";
      break;
    case "PENDING":
      backgroundColor = "orange";
      statusText = "Pendiente";
      break;
    case "REJECTED":
      backgroundColor = "red";
      statusText = "Rechazado";
      break;
    default:
      backgroundColor = "blue";
      statusText = pay.status;
      break;
  }

  return (
    <div class="c-table-5__container">
      {loading === true ? (
        <div class="c-table-5__container_Progress">
          <CircularProgress />
        </div>
      ) : (
        <div class="pay">
          <Grid className="status-container">
            <div>
              <p className="text-label">Estado:</p>
            </div>
            <div
              style={{
                backgroundColor: backgroundColor,
              }}
              className="type-status">
              {statusText}
            </div>
          </Grid>
          <div class="detail-container">
            <span class="text-label">Fecha:</span>
            <span class="text-value">
              {new Date(pay.date).toLocaleString()}
            </span>
          </div>
          <div class="detail-container">
            <span class="text-label">Valor pagado:</span>
            <span class="text-value">
              {formatCurrency("es-CO", "COP", 0, pay.total)} COP
            </span>
          </div>
          <div class="detail-container">
            <span class="text-label">Referencia de pago:</span>
            <span class="text-value">{pay.reference}</span>
          </div>
          <div className="o-btn-pc">
            <Link to={"/paga-tu-cuota"}>
              <button className={"o-btn-3--v2"}>Nueva operación</button>
            </Link>
          </div>
        </div>
      )}
    </div>
  );
};

import * as React from "react";
import PropTypes from "prop-types";
import NumberFormat from "react-number-format";
import TextField from "@mui/material/TextField";

const NumberFormatCustom = React.forwardRef(function NumberFormatCustom(
  props,
  ref
) {
  const { onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      isNumericString
      prefix={props.prefix}
    />
  );
});

NumberFormatCustom.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default function InputNumber(props) {
  return (
    <TextField
      label={props.label || ""}
      value={props.value}
      onChange={props.handleChange}
      name={props.name}
      id={props.id}
      InputProps={{
        inputComponent: NumberFormatCustom,
      }}
      variant={props.variant}
      {...props}
      fullWidth
    />
  );
}

import { apiAdminInstance } from "../Api/ApiInstance";
import { string_to_slug } from "../helpers/Strings";

export async function CreateColor(color) {
  let { nombre_color, codigo_color } = color;
  const slug = string_to_slug(nombre_color);
  if (!codigo_color) codigo_color = "#000";

  return await apiAdminInstance.post("api/official-site/color/", {
    nombre_color,
    codigo_color,
    slug,
    state: 1,
  });
}
export async function ListColors() {
  return await apiAdminInstance.get("api/official-site/color");
}

export async function UpdateColor(color) {
  const { id, nombre_color, codigo_color, state } = color;
  const slug = nombre_color ? string_to_slug(nombre_color) : nombre_color;

  return await apiAdminInstance.put("api/official-site/color/" + id, {
    nombre_color,
    codigo_color,
    slug,
    state,
  });
}

export async function DeleteColor(id) {
  return await apiAdminInstance.delete("api/official-site/color/" + id);
}

import apiInstance from "../Api/ApiInstance";

export async function Register(values) {
  return await apiInstance.post("api/official-site/user", values);
}

export async function UpdateUser(values) {
  return await apiInstance.patch(
    "api/official-site/user/updateUser",
    values
  );
}

export async function UpdatePassword(values) {
  return await apiInstance.patch(
    "api/official-site/user/updatePassword",
    values
  );
}

import React from "react";
import { Helmet } from "react-helmet";
import AdminMui from "../components/Masters/AdminMui";
import DetailsMoto from "../components/molecules/AdminCreateMoto/DetailsMoto";
import FooterCreateMoto from "../components/molecules/AdminCreateMoto/Footer";
import GeneralInformationMoto from "../components/molecules/AdminCreateMoto/GeneralInformationMoto";
import PricesMotos from "../components/molecules/AdminCreateMoto/PricesMotos";
import SEO from "../components/molecules/AdminCreateMoto/SEO/";
import { CreateMotoProvider } from "../contexts/CreateMotoContext";
import { useChangeBackground } from "../hooks/useChangeBackground";
import { useChangeTitleAppBar } from "../hooks/useChangeTitleAppBar";

const AdminCreateMoto = (props) => {
  const [isEdit, setIsEdit] = React.useState(false);
  React.useEffect(() => {
    if (props.location.state) {
      setIsEdit(true);
    }
  }, [props.location.state]);

  useChangeBackground("#f5f5f8");
  useChangeTitleAppBar("Crear moto");
  
  return (
    <>
      <CreateMotoProvider>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Crear moto - Supre</title>
          <meta name="description" content="crear moto admin supre" />
        </Helmet>
        <AdminMui>
          <SEO isEdit={isEdit} />
          <br />
          <GeneralInformationMoto />
          <br />
          <PricesMotos />
          <br />
          <DetailsMoto />
          <br />
          {/* <PhotosMoto /> */}
          <FooterCreateMoto isEdit={isEdit} />
        </AdminMui>
      </CreateMotoProvider>
    </>
  );
};

export default AdminCreateMoto;

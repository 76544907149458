import React from "react";
import { DataGridPro, GridActionsCellItem, esES } from "@mui/x-data-grid-pro";
import ModeEditOutlinedIcon from "@mui/icons-material/ModeEditOutlined";

const CostoTable = ({ setShowMondal, costos_json }) => {
  const columns = [
    {
      headerName: "Porcentaje de intereses",
      field: "porcentaje_interes",
      flex: 1,
    },
    {
      headerName: "Gestion cobranza",
      field: "gestion_cobranza",
      flex: 1,
    },
    {
      headerName: "Sistematización",
      field: "sistematizacion",
      flex: 1,
    },
    {
      headerName: "Otros costos",
      field: "otros_costos",
      flex: 1,
    },
    {
      headerName: "Acciones",
      field: "actions",
      type: "actions",
      getActions: (data) => [
        <GridActionsCellItem
          icon={<ModeEditOutlinedIcon style={{ fontSize: 20 }} />}
          label="Edit"
          onClick={(e) => {
            e.stopPropagation();
            EditRowSelected(data?.row);
          }}
        />,
      ],
    },
  ];

  return (
    <div style={{ height: 400, marginTop: 12 }}>
      <DataGridPro
        rows={costos_json}
        columns={columns}
        localeText={esES.components.MuiDataGrid.defaultProps.localeText}
        pageSize={15}
        pagination
        style={{ fontSize: 15 }}
      />
    </div>
  );
  function EditRowSelected(rowData) {
    //display modal for edit or create
    setShowMondal({
      show: true,
      data: rowData,
    });
  }
};

export default CostoTable;

import { apiAdminInstance } from "../Api/ApiInstance";
import { string_to_slug } from "../helpers/Strings";

export const CreateFrontBrake = async (frontBrake) => {
  let { nombre_freno_delantero } = frontBrake;
  const slug = string_to_slug(nombre_freno_delantero);

  return await apiAdminInstance.post("api/official-site/frontBrake/", {
    nombre_freno_delantero,
    slug,
    state: 1,
  });
};

export const ListFrontBrakes = async () => {
  return await apiAdminInstance.get("api/official-site/frontBrake/");
};

export const ListFrontFilter = async () => {
  return await apiAdminInstance.get("api/official-site/frontBrake/filter");
};

export const UpdateFrontBrake = async (frontBrake) => {
  const { id, nombre_freno_delantero, state } = frontBrake;
  const slug = nombre_freno_delantero ? string_to_slug(nombre_freno_delantero) : nombre_freno_delantero;

  return await apiAdminInstance.put("api/official-site/frontBrake/" + id, {
    nombre_freno_delantero,
    slug,
    state,
  });
};

export const DeleteFrontBrake = async (id) => {
  return await apiAdminInstance.delete("api/official-site/frontBrake/" + id);
};

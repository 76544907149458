import { apiAdminInstance } from "../Api/ApiInstance";
import GeneralData from "../data/GeneralData.js";

const URL = `${GeneralData.host_api}api/official-site/`;

export async function CreateNewMotoColor(motocolor, selectedImage) {
  let { url_foto_color_peque, url_foto_color_grande, colore } = motocolor;
  let { id_moto, id_color } = colore;

  const formdata = new FormData();
  formdata.append("color", selectedImage.smallimage);
  formdata.append("color", selectedImage.hugeimage);

  let requestOptions = {
    method: "POST",
    body: formdata,
    redirect: "follow",
    headers: {
      Authorization: "Bearer " + process.env.REACT_APP_JWT_PORTFOLIO_TEMP,
      user: "Bearer " + localStorage.getItem("SESSION_ID"),
    },
  };

  let res = await fetch(`${URL}MotosColorAdmin/fotos/`, requestOptions);
  res = await res.json();

  url_foto_color_peque = res[0].path;
  url_foto_color_grande = res[1].path;

  return await apiAdminInstance.post("api/official-site/MotosColorAdmin/", {
    id_moto,
    id_color,
    url_foto_color_peque,
    url_foto_color_grande,
  });
}

export async function UpdateMotoColor(motocolor, selectedImage) {
  let { id, id_moto, colore, url_foto_color_peque, url_foto_color_grande } =
    motocolor;
  let { id_color } = colore;

  if (id_color === undefined) {
    id_color = motocolor.id_color;
  }

  if (selectedImage.smallimage != null || selectedImage.hugeimage != null) {
    const formdata = new FormData();
    formdata.append("color", selectedImage.smallimage);
    formdata.append("color", selectedImage.hugeimage);

    let requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
      headers: {
        Authorization: "Bearer " + process.env.REACT_APP_JWT_PORTFOLIO_TEMP,
        user: "Bearer " + localStorage.getItem("SESSION_ID"),
      },
    };

    let res = await fetch(`${URL}MotosColorAdmin/fotos/`, requestOptions);
    res = await res.json();

    if (selectedImage.smallimage != null) {
      url_foto_color_peque = res[0].path;
    }
    if (selectedImage.hugeimage != null) {
      url_foto_color_grande = res[1].path;
    }
  }
  return await apiAdminInstance.post(
    "api/official-site/MotosColorAdmin/update/",
    {
      id,
      id_moto,
      id_color,
      url_foto_color_peque,
      url_foto_color_grande,
    }
  );
}

export function DeleteMotoColor({ rowData }) {
  const id = rowData.id;

  // apiAdminInstance.post(
  //   "api/official-site/MotosColorAdmin/fotos-delete/",
  //   rowData
  // );
  return apiAdminInstance.delete(`api/official-site/MotosColorAdmin/${id}/`);
}

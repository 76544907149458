import React from "react";
import { styled } from "@mui/styles";
import MuiGrid from "@mui/material/Grid";
import MuiInputLabel from "@mui/material/InputLabel";
import MuiTextField from "@mui/material/TextField";
import MuiDivider from "@mui/material/Divider";
import InputNumber from "../../../../atoms/InputNumber";
import { Autocomplete } from "@mui/material";
import { CreateMotoContext } from "../../../../../contexts/CreateMotoContext";

const Grid = styled(MuiGrid)({
  display: "flex",
  marginTop: 50,
  marginBottom: 20,
  justifyContent: "center",
});

const InputLabel = styled(MuiInputLabel)({
  marginRight: 10,
  alignSelf: "center",
});

const Divider = styled(MuiDivider)({
  width: "100%",
});

const styleInputNumber = {
  marginLeft: 115,
  width: "50%",
  marginRight: 10,
  "& > p": {
    fontSize: "1.2rem",
  },
};

const TextField = styled(MuiTextField)({
  width: "50%",
  marginRight: 10,
  "& > p": {
    fontSize: "1.2rem",
  },
});

export const Motor = ({ transmitions }) => {
  const { values, onChange, onChangeTransmision } =
    React.useContext(CreateMotoContext);

  const defaultPropsTransmition = {
    options: transmitions,
    getOptionLabel: (option) => {
      if (option.hasOwnProperty("name")) {
        return option.name;
      }
      return option;
    },
  };

  return (
    <>
      <Grid container style={{ margin: 0 }}>
        <Grid item xs={12}>
          <InputLabel htmlFor="input-cylinder-capacity" style={{ left: 24 }}>
            Cilindraje
          </InputLabel>
          <InputNumber
            id="input-cylinder-capacity"
            name="cilindraje"
            variant="outlined"
            style={{
              ...styleInputNumber,
              marginLeft: 89,
            }}
            value={values.cilindraje}
            onChange={onChange}
          />
        </Grid>
        <Divider />
        <Grid item xs={12}>
          <InputLabel htmlFor="input-type-of-motor">Tipo de Motor</InputLabel>
          <TextField
            id="input-type-of-motor"
            name="tipo_motor"
            variant="outlined"
            style={{
              marginLeft: 54,
            }}
            maxLength={55}
            value={values.tipo_motor}
            onChange={onChange}
          />
        </Grid>
        <Divider />
        <Grid item xs={12}>
          <InputLabel
          // style={{
          //   position: "relative",
          //   right: 160,
          // }}
          >
            Potencia Máxima
          </InputLabel>

          <InputNumber
            id="input-poten-max-motor"
            name="potencia_maxima_hp"
            variant="outlined"
            style={{
              ...styleInputNumber,
              marginLeft: 50,
              width: "20%",
            }}
            maxLength={55}
            value={values.potencia_maxima_hp}
            onChange={onChange}
          />
          <InputLabel>Hp</InputLabel>

          <InputNumber
            id="input-pote-min-motor"
            name="potencia_maxima_rpm"
            variant="outlined"
            style={{
              ...styleInputNumber,
              marginLeft: 0,
              width: "20%",
            }}
            maxLength={55}
            value={values.potencia_maxima_rpm}
            onChange={onChange}
          />
          <InputLabel> &nbsp; rpm</InputLabel>
        </Grid>
        <Divider />
        <Grid item xs={12}>
          <InputLabel
          // style={{
          //   position: "relative",
          //   right: 160,
          // }}
          >
            Torque máximo
          </InputLabel>
          <InputNumber
            id="input-torque-max-motor"
            name="torque_maximo_nm"
            variant="outlined"
            style={{
              ...styleInputNumber,
              marginLeft: 50,
              width: "20%",
            }}
            maxLength={55}
            value={values.torque_maximo_nm}
            onChange={onChange}
          />
          <InputLabel>Nm</InputLabel>
          <InputNumber
            id="input-torque-min-motor"
            name="torque_maximo_rpm"
            variant="outlined"
            style={{
              ...styleInputNumber,
              marginLeft: 0,
              width: "20%",
            }}
            maxLength={55}
            value={values.torque_maximo_rpm}
            onChange={onChange}
          />
          <InputLabel> &nbsp; rpm</InputLabel>
        </Grid>
        <Divider />
        <Grid item xs={12}>
          <InputLabel
            htmlFor="input-relation-compress"
            // style={{
            //   // position: "relative",
            //   left: 0,
            //   // width: "100%",
            // }}
            // htmlFor="input-type-of-transmition"
          >
            Tipo de Transmisión
          </InputLabel>
          <Autocomplete
            id="input-type-of-transmition"
            name="type-of-transmition"
            noOptionsText="No se encuentran transmiciones"
            label="Seleccione el tipo de transmisión"
            data={transmitions}
            value={values.id_tipo_transmision?.name || null}
            size="small"
            isOptionEqualToValue={(option, value) => option.name === value.name}
            {...defaultPropsTransmition}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Seleccione el tipo de transmisión"
                margin="normal"
                variant="outlined"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginLeft: 115,
                  width: "60%",
                  marginRight: 10,
                  "& > p": {
                    fontSize: "1.2rem",
                  },
                }}
              />
            )}
            style={{
              width: "60%",
            }}
            fullWidth
            onChange={onChangeTransmision}
          />
        </Grid>
        <Divider />
        <Grid item xs={12}>
          <InputLabel htmlFor="input-relation-compress">
            Relación de Compresión
          </InputLabel>
          <TextField
            id="input-relation-compress"
            name="relacion_compresion"
            variant="outlined"
            style={{
              marginLeft: 20,
            }}
            maxLength={55}
            value={values.relacion_compresion}
            onChange={onChange}
          />
        </Grid>
        <Divider />
      </Grid>
    </>
  );
};

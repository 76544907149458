import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import GeneralData from "../../../data/GeneralData.js";
import clsx from "clsx";
import "./style.css";

export const ModalMotos = ({ listallmotos, opensearch, setOpenSearch }) => {
  const [ListFilterMotos, setListFilterMotos] = useState([]);
  let { search } = useLocation();
  let query = new URLSearchParams(search);
  let motos = query.get("motos");

  useEffect(() => {
    setListFilterMotos(listallmotos);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [opensearch]);

  const filtrarname = () => {
    const name = document.getElementById("keyword").value;
    setListFilterMotos(
      listallmotos.filter((motos) =>
        motos.nombre.toLowerCase().includes(name.toLowerCase())
      )
    );
  };

  const closefilter = () => {
    document.getElementById("barranav").classList.remove("op");
    document.getElementById("ComMotos").classList.remove("op");
    document.getElementById("containerglobal").classList.remove("op");
    setOpenSearch(!opensearch);
  };

  return (
    <div className={clsx("c-modal-1", opensearch && "open")} id="modal-filtros">
      <div className="c-modal-1__header">
        <div className="c-modal-1__header-bar">
          <div className="c-modal-1__header-title">
            Agregar moto al comparador
          </div>
          <div className="c-modal-1__header-close">
            <button
              className="c-modal-1__close js-close-filtros"
              onClick={closefilter}
            >
              <FontAwesomeIcon className="icon-cross2" icon={faXmark} />
              Cerrar
            </button>
          </div>
        </div>
        <div className="c-modal-1__tags-filter--v2">
          <div className="c-modal-1__section-2">
            <div className="c-modal-1__item">
              <div className="c-modal-1__row">
                <label className="o-form-label">Buscar moto</label>
                <div className="c-modal-1__field">
                  <input
                    className="o-form-field--v2"
                    type="text"
                    id="keyword"
                    onChange={() => filtrarname()}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="c-modal-1__content ps">
        <div id="lista-motos">
          <div className="c-list-10  js-listado-motos">
            <div className="jscroll-inner">
              {ListFilterMotos.map((item) => (
                <div className="c-list-10__item" key={item.id}>
                  <Link
                    to={`?motos=${motos !== null ? motos + "," : ""}${item.id}`}
                    className="c-list-10__hover"
                  >
                    <button
                      className="o-btn-3 c-list-10__btn"
                      onClick={closefilter}
                    >
                      Seleccionar
                    </button>
                  </Link>
                  <div className="c-list-10__img">
                    <img
                      src={`${GeneralData.host}${item.url_foto}`}
                      alt={item.nombre}
                    />
                  </div>
                  <div className="c-list-10__box">
                    <div className="c-list-10__name">{item.nombre}</div>
                    <div className="c-list-10__brand">
                      {item.MotosMarca.nombre_marca}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

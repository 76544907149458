export const styledGridSEO = {
  background: "#f9f9f9",
  padding: "43px 32px",
};

export const styledIconSEO = {
  color: "#e7e7e7",
  fontSize: 83,
};

export const styledTextSEO = {
  fontSize: 14,
  color: "#777",
  overflowWrap: "breack-word",
  textAlign: "left",
};

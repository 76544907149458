import React from "react";
import Helmet from "react-helmet";
import { Portada } from "../components/molecules/Portada";
import { PayOnlineDetail } from "../components/molecules/PayOnlineDetail";
import { useParams } from "react-router-dom";

import "../css/Contactos.css";

export const PayOnline = () => {
  const { id } = useParams(); // Obtiene el valor de "id" de la URL

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Resumen de pago</title>
        <meta name="description" content="Resumen de pago" />
      </Helmet>
      <Portada className="c-block-3" Name="Resumen de pago" id="contact" />
      <div className="c-block-4">
        <PayOnlineDetail id={id} />
      </div>
    </>
  );
};

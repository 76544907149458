import React from "react";
import GeneralData from "../../../data/GeneralData.js";
import "./style.css";

const coloresMoto = (moto) => {
  let colores = [];

  moto.MotosColores.map((color) => colores.push(color.Colore.nombre_color));

  return colores.join(", ");
};

export const DetailProduct = ({ product }) => {
  return (
    <>
      <div className="g-col-xl-8 c-block-5__pr1">
        <h2 className="o-heading-6 c-block-5__heading-2">Galería</h2>
        <div className="c-slider-3 c-block-5__mb-3 js-slider-3">
          {product.MotosGaleria.map((image, index) => (
            <div
              className="c-slider-3__item"
              style={{
                opacity: 1,
                width: "239px",
                height: "100%",
                left: "0px",
              }}
              key={index}>
              <a
                className="c-slider-3__img"
                href={GeneralData.host + image.url_foto_grande}
                data-fancybox="galeria"
                key={index}
                target="_blank"
                rel="noopener noreferrer">
                <img
                  src={GeneralData.host + image.url_foto_peque}
                  alt={image.descripcion}
                  key={index}
                />
              </a>
            </div>
          ))}
        </div>
        <h2 className="o-heading-6 c-block-5__heading-2">Especificaciones</h2>
        <div className="c-table-1">
          <div className="c-table-1__header">Motor</div>
          <div className="c-table-1__box">
            <div className="c-table-1__item">
              <div className="c-table-1__title--v2">Cilindraje</div>
              <div className="c-table-1__value--v2">{product.cilindraje}</div>
            </div>
            <div className="c-table-1__item">
              <div className="c-table-1__title--v2">Tipo de motor</div>
              <div className="c-table-1__value--v2">{product.tipo_motor}</div>
            </div>
            <div className="c-table-1__item">
              <div className="c-table-1__title--v2">Potencia máxima</div>
              <div className="c-table-1__value--v2">
                {product.potencia_maxima_hp} Hp - {product.potencia_maxima_rpm}{" "}
                rpm
              </div>
            </div>
            <div className="c-table-1__item">
              <div className="c-table-1__title--v2">Torque máximo</div>
              <div className="c-table-1__value--v2">
                {product.torque_maximo_nm} Nm{" "}
              </div>
            </div>
            <div className="c-table-1__item">
              <div className="c-table-1__title--v2">Relación de compresión</div>
              <div className="c-table-1__value--v2">
                {product.relacion_compresion}
              </div>
            </div>
            <div className="c-table-1__item">
              <div className="c-table-1__title--v2">Tipo de transmisión</div>
              <div className="c-table-1__value--v2">
                {product.MotosTransmision.nombre_transmision}
              </div>
            </div>
          </div>
        </div>
        <div className="c-table-1">
          <div className="c-table-1__header">Dimensiones</div>
          <div className="c-table-1__box">
            <div className="c-table-1__item">
              <div className="c-table-1__title--v2">Rueda delantera</div>
              <div className="c-table-1__value--v2">
                {product.rueda_delantera}
              </div>
            </div>
            <div className="c-table-1__item">
              <div className="c-table-1__title--v2">Rueda trasera</div>
              <div className="c-table-1__value--v2">
                {product.rueda_trasera}
              </div>
            </div>
            <div className="c-table-1__item">
              <div className="c-table-1__title--v2">Dimensión total</div>
              <div className="c-table-1__value--v2">
                {product.dimension_total}
              </div>
            </div>
            <div className="c-table-1__item">
              <div className="c-table-1__title--v2">Distancia entre ejes</div>
              <div className="c-table-1__value--v2">
                {product.distancia_entre_ejes}
              </div>
            </div>
            <div className="c-table-1__item">
              <div className="c-table-1__title--v2">Peso</div>
              <div className="c-table-1__value--v2">{product.peso} Kg</div>
            </div>
          </div>
        </div>
        <div className="c-table-1">
          <div className="c-table-1__header">Seguridad</div>
          <div className="c-table-1__box">
            <div className="c-table-1__item">
              <div className="c-table-1__title--v2">Freno delantero</div>
              <div className="c-table-1__value--v2">
                {product.MotosFrenoDelantero.nombre_freno_delantero}
              </div>
            </div>
            <div className="c-table-1__item">
              <div className="c-table-1__title--v2">Freno trasero</div>
              <div className="c-table-1__value--v2">
                {product.MotosFrenoTrasero.nombre_freno_trasero}
              </div>
            </div>
            <div className="c-table-1__item">
              <div className="c-table-1__title--v2">
                Tipo de suspensión delantera
              </div>
              <div className="c-table-1__value--v2">
                {product.MotosSuspensionDelantera.nombre_suspension_delantera}
              </div>
            </div>
            <div className="c-table-1__item">
              <div className="c-table-1__title--v2">
                Tipo de suspensión trasera
              </div>
              <div className="c-table-1__value--v2">
                {product.MotosSuspensionTrasera.nombre_suspension_trasera}
              </div>
            </div>
          </div>
        </div>
        <div className="c-table-1">
          <div className="c-table-1__header">Características adicionales</div>
          <div className="c-table-1__box">
            <div className="c-table-1__item">
              <div className="c-table-1__title--v2">Tipo de arranque</div>
              <div className="c-table-1__value--v2">
                {product.MotosTipoArranque.nombre_tipo_arranque}
              </div>
            </div>
            <div className="c-table-1__item">
              <div className="c-table-1__title--v2">Certificación</div>
              <div className="c-table-1__value--v2">
                {product.certificacion}
              </div>
            </div>
            <div className="c-table-1__item">
              <div className="c-table-1__title--v2">Modelo</div>
              <div className="c-table-1__value--v2">{product.modelo}</div>
            </div>
            <div className="c-table-1__item">
              <div className="c-table-1__title--v2">Colores</div>
              <div className="c-table-1__value--v2">{coloresMoto(product)}</div>
            </div>
          </div>
        </div>
      </div>
      <div className="g-col-xl-4 c-block-5__col-2">
        <div></div>
      </div>
    </>
  );
};

import GeneralData from "../GeneralData.js";

export var htmlAdmin = `<!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Transitional//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">
<html xmlns="http://www.w3.org/1999/xhtml" lang="es">

    <head>
        <meta http-equiv="Content-Type" content="text/html; charset=UTF-8">
        <title>Solicitud moto - Supre</title>
        <meta name="viewport" content="width=device-width, initial-scale=1.0">

    </head>

    <body style="-moz-osx-font-smoothing: grayscale; -webkit-font-smoothing: antialiased; color: #6d757c; font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; font-size: 16px; font-weight: 400; margin: 0; padding: 0;">
        <table width="100%" cellpadding="0" cellspacing="0" bgcolor="#ebeff2" class="full-content" style="-moz-osx-font-smoothing: grayscale; -webkit-font-smoothing: antialiased; background: #ebeff2; color: #6d757c; font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; font-size: 16px; font-weight: 400;">
            <tr>
                <td height="35" class="texto-inicial" style="-moz-osx-font-smoothing: grayscale; -webkit-font-smoothing: antialiased; color: #ebeff2; font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; font-size: 16px; font-weight: 400;"></td>
            </tr>
            <tr>
                <td style="-moz-osx-font-smoothing: grayscale; -webkit-font-smoothing: antialiased; color: #6d757c; font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; font-size: 16px; font-weight: 400;">
                    <table cellpadding="0" cellspacing="0" align="center" class="contenedor" style="-moz-osx-font-smoothing: grayscale; -webkit-font-smoothing: antialiased; color: #6d757c; font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; font-size: 16px; font-weight: 400; margin: 0 auto; max-width: 600px; width: 90%;">
                        <tr>
                            <td style="-moz-osx-font-smoothing: grayscale; -webkit-font-smoothing: antialiased; color: #6d757c; font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; font-size: 16px; font-weight: 400;">
                                <!-- header -->
                                <table width="100%" cellpadding="0" cellspacing="0" class="header" style="-moz-osx-font-smoothing: grayscale; -webkit-font-smoothing: antialiased; color: #6d757c; font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; font-size: 16px; font-weight: 400; width: 100%;">
                                    <tr>
                                        <td class="header__conten-logo" style="-moz-osx-font-smoothing: grayscale; -webkit-font-smoothing: antialiased; background: #fff; border-radius: 5px 5px 0 0; color: #6d757c; font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; font-size: 16px; font-weight: 400; padding: 20px;"><img src="${GeneralData.host}/images/logo-supre-dark-email@2x.png" width="144" alt="Logo Supre" style="-moz-osx-font-smoothing: grayscale; -webkit-font-smoothing: antialiased; color: #6d757c; font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; font-size: 16px; font-weight: 400;"></td>
                                    </tr>
                                </table>
                                <!-- / header -->
                                <!-- Bloque blanco-->
                                <table width="100%" cellpadding="0" cellspacing="0" style="-moz-osx-font-smoothing: grayscale; -webkit-font-smoothing: antialiased; color: #6d757c; font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; font-size: 16px; font-weight: 400;">
                                    <tr>
                                        <td class="bloque-blanco" style="-moz-osx-font-smoothing: grayscale; -webkit-font-smoothing: antialiased; background: #fff; color: #6d757c; font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; font-size: 16px; font-weight: 400;">
                                            <table cellpadding="0" cellspacing="0" align="center" class="contenido" style="-moz-osx-font-smoothing: grayscale; -webkit-font-smoothing: antialiased; color: #6d757c; font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; font-size: 16px; font-weight: 400; padding: 0 26px 0 26px; width: 100%;">
                                                <tr>
                                                    <td height="10" style="-moz-osx-font-smoothing: grayscale; -webkit-font-smoothing: antialiased; color: #6d757c; font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; font-size: 16px; font-weight: 400;"></td>
                                                </tr>
                                                <tr>
                                                    <td style="-moz-osx-font-smoothing: grayscale; -webkit-font-smoothing: antialiased; color: #6d757c; font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; font-size: 16px; font-weight: 400;">


														<!-- Contenido -->

                                                        <p style="-moz-osx-font-smoothing: grayscale; -webkit-font-smoothing: antialiased; color: #6d757c; font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; font-size: 16px; font-weight: 400; line-height: 1.4; margin-bottom: 25px; margin-top: 0;">Un usuario ha solicitado financiar una moto desde supre.com.co</p>

                                                        <p style="-moz-osx-font-smoothing: grayscale; -webkit-font-smoothing: antialiased; color: #6d757c; font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; font-size: 16px; font-weight: 400; line-height: 1.4; margin-bottom: 25px; margin-top: 0;">

															<strong class="strong" style="-moz-osx-font-smoothing: grayscale; -webkit-font-smoothing: antialiased; color: #151616; font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; font-size: 20px; font-weight: 700;">DETALLE DE LA SOLICITUD</strong>

															<br>
															<br>

															<strong class="strong" style="-moz-osx-font-smoothing: grayscale; -webkit-font-smoothing: antialiased; color: #151616; font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; font-size: 15px; font-weight: 700;">Nombre moto:</strong> @nombre_moto

															<br>

															<strong class="strong" style="-moz-osx-font-smoothing: grayscale; -webkit-font-smoothing: antialiased; color: #151616; font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; font-size: 15px; font-weight: 700;">Marca moto:</strong> @marca_moto

															<br>

															<strong class="strong" style="-moz-osx-font-smoothing: grayscale; -webkit-font-smoothing: antialiased; color: #151616; font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; font-size: 15px; font-weight: 700;">Color moto:</strong> @color_moto

															<br>

															<strong class="strong" style="-moz-osx-font-smoothing: grayscale; -webkit-font-smoothing: antialiased; color: #151616; font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; font-size: 15px; font-weight: 700;">Precio moto:</strong> @precio_moto

															<br>

															<strong class="strong" style="-moz-osx-font-smoothing: grayscale; -webkit-font-smoothing: antialiased; color: #151616; font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; font-size: 15px; font-weight: 700;">Plazo de pago:</strong> @plazo meses

															<br>

															<strong class="strong" style="-moz-osx-font-smoothing: grayscale; -webkit-font-smoothing: antialiased; color: #151616; font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; font-size: 15px; font-weight: 700;">Abono inicial:</strong> $ @abono_inicial

															<br>

															<strong class="strong" style="-moz-osx-font-smoothing: grayscale; -webkit-font-smoothing: antialiased; color: #151616; font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; font-size: 15px; font-weight: 700;">Código Cupón DTO:</strong> @cupon

															<br>

															<strong class="strong" style="-moz-osx-font-smoothing: grayscale; -webkit-font-smoothing: antialiased; color: #151616; font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; font-size: 15px; font-weight: 700;">Valor DTO Cupón:</strong> $ @valor_cupon_descuento

															<br>

															<strong class="strong" style="-moz-osx-font-smoothing: grayscale; -webkit-font-smoothing: antialiased; color: #151616; font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; font-size: 15px; font-weight: 700;">Cuota mínima:</strong> $ @cuota_min

															<br>

															<strong class="strong" style="-moz-osx-font-smoothing: grayscale; -webkit-font-smoothing: antialiased; color: #151616; font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; font-size: 15px; font-weight: 700;">Cuota máxima:</strong> $ @cuota_max

                                                        </p>

														<hr>

                                                        <p style="-moz-osx-font-smoothing: grayscale; -webkit-font-smoothing: antialiased; color: #6d757c; font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; font-size: 16px; font-weight: 400; line-height: 1.4; margin-bottom: 25px; margin-top: 0;">

															<strong class="strong" style="-moz-osx-font-smoothing: grayscale; -webkit-font-smoothing: antialiased; color: #151616; font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; font-size: 20px; font-weight: 700;">INFORMACIÓN PERSONAL</strong>

															<br>
															<br>

															<strong class="strong" style="-moz-osx-font-smoothing: grayscale; -webkit-font-smoothing: antialiased; color: #151616; font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; font-size: 15px; font-weight: 700;">Número de cédula:</strong> @cedula

															<br>

															<strong class="strong" style="-moz-osx-font-smoothing: grayscale; -webkit-font-smoothing: antialiased; color: #151616; font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; font-size: 15px; font-weight: 700;">Nombre:</strong> @nombres

															<br>

															<strong class="strong" style="-moz-osx-font-smoothing: grayscale; -webkit-font-smoothing: antialiased; color: #151616; font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; font-size: 15px; font-weight: 700;">Fecha de nacimiento:</strong> @fecha_nacimiento

															<br>

															<strong class="strong" style="-moz-osx-font-smoothing: grayscale; -webkit-font-smoothing: antialiased; color: #151616; font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; font-size: 15px; font-weight: 700;">Genero:</strong> @genero

															<br>

															<strong class="strong" style="-moz-osx-font-smoothing: grayscale; -webkit-font-smoothing: antialiased; color: #151616; font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; font-size: 15px; font-weight: 700;">Estado civil:</strong> @estado_civil

															<br>

															<strong class="strong" style="-moz-osx-font-smoothing: grayscale; -webkit-font-smoothing: antialiased; color: #151616; font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; font-size: 15px; font-weight: 700;">Personas a cargo:</strong> @personas_cargo

															<br>

															<strong class="strong" style="-moz-osx-font-smoothing: grayscale; -webkit-font-smoothing: antialiased; color: #151616; font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; font-size: 15px; font-weight: 700;">Celular:</strong> @celular

															<br>

															<strong class="strong" style="-moz-osx-font-smoothing: grayscale; -webkit-font-smoothing: antialiased; color: #151616; font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; font-size: 15px; font-weight: 700;">Correo electrónico:</strong> @email

															<br>

															<strong class="strong" style="-moz-osx-font-smoothing: grayscale; -webkit-font-smoothing: antialiased; color: #151616; font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; font-size: 15px; font-weight: 700;">Nivel de educación:</strong> @nivel_educacion

															<br>

															<strong class="strong" style="-moz-osx-font-smoothing: grayscale; -webkit-font-smoothing: antialiased; color: #151616; font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; font-size: 15px; font-weight: 700;">Tipo de vivienda:</strong> @tipo_vivienda

															<br>

															<strong class="strong" style="-moz-osx-font-smoothing: grayscale; -webkit-font-smoothing: antialiased; color: #151616; font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; font-size: 15px; font-weight: 700;">Ciudad:</strong> @ciudad

															<br>

															<strong class="strong" style="-moz-osx-font-smoothing: grayscale; -webkit-font-smoothing: antialiased; color: #151616; font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; font-size: 15px; font-weight: 700;">Dirección:</strong> @direccion

                                                        </p>

														<hr>

                                                        <p style="-moz-osx-font-smoothing: grayscale; -webkit-font-smoothing: antialiased; color: #6d757c; font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; font-size: 16px; font-weight: 400; line-height: 1.4; margin-bottom: 25px; margin-top: 0;">

															<strong class="strong" style="-moz-osx-font-smoothing: grayscale; -webkit-font-smoothing: antialiased; color: #151616; font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; font-size: 20px; font-weight: 700;">INFORMACIÓN LABORAL</strong>

															<br>
															<br>

															<strong class="strong" style="-moz-osx-font-smoothing: grayscale; -webkit-font-smoothing: antialiased; color: #151616; font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; font-size: 15px; font-weight: 700;">Ocupación:</strong> @ocupacion

															<br>

															<strong class="strong" style="-moz-osx-font-smoothing: grayscale; -webkit-font-smoothing: antialiased; color: #151616; font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; font-size: 15px; font-weight: 700;">Nombre empresa:</strong> @nombre_empresa

															<br>

															<strong class="strong" style="-moz-osx-font-smoothing: grayscale; -webkit-font-smoothing: antialiased; color: #151616; font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; font-size: 15px; font-weight: 700;">Dirección de la empresa:</strong> @direccion_empresa

															<br>
															<strong class="strong" style="-moz-osx-font-smoothing: grayscale; -webkit-font-smoothing: antialiased; color: #151616; font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; font-size: 15px; font-weight: 700;">Ingresos:</strong> $ @ingresos

                                                        </p>

														<!--
														<hr>

                                                        <p style="-moz-osx-font-smoothing: grayscale; -webkit-font-smoothing: antialiased; color: #6d757c; font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; font-size: 16px; font-weight: 400; line-height: 1.4; margin-bottom: 25px; margin-top: 0;">

															<strong class="strong" style="-moz-osx-font-smoothing: grayscale; -webkit-font-smoothing: antialiased; color: #151616; font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; font-size: 20px; font-weight: 700;">REFERENCIAS</strong>

															<br>
															<br>

															<strong class="strong" style="-moz-osx-font-smoothing: grayscale; -webkit-font-smoothing: antialiased; color: #151616; font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; font-size: 17px; font-weight: 700;">Referencias personales</strong>

															<br>
															<br>

															<strong class="strong" style="-moz-osx-font-smoothing: grayscale; -webkit-font-smoothing: antialiased; color: #151616; font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; font-size: 15px; font-weight: 700;">Nombre completo:</strong> <?php echo $datamail['referencia1_nombre']; ?>

															<br>

															<strong class="strong" style="-moz-osx-font-smoothing: grayscale; -webkit-font-smoothing: antialiased; color: #151616; font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; font-size: 15px; font-weight: 700;">Celular:</strong> <?php echo $datamail['referencia1_celular']; ?>

															<br>

															<strong class="strong" style="-moz-osx-font-smoothing: grayscale; -webkit-font-smoothing: antialiased; color: #151616; font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; font-size: 15px; font-weight: 700;">Ciudad:</strong> <?php echo $datamail['referencia1_ciudad']; ?>

															<br>
															<br>

															<strong class="strong" style="-moz-osx-font-smoothing: grayscale; -webkit-font-smoothing: antialiased; color: #151616; font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; font-size: 15px; font-weight: 700;">Nombre completo:</strong> <?php echo $datamail['referencia2_nombre']; ?>

															<br>

															<strong class="strong" style="-moz-osx-font-smoothing: grayscale; -webkit-font-smoothing: antialiased; color: #151616; font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; font-size: 15px; font-weight: 700;">Celular:</strong> <?php echo $datamail['referencia2_celular']; ?>

															<br>

															<strong class="strong" style="-moz-osx-font-smoothing: grayscale; -webkit-font-smoothing: antialiased; color: #151616; font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; font-size: 15px; font-weight: 700;">Ciudad:</strong> <?php echo $datamail['referencia2_ciudad']; ?>

															<br>
															<br>

															<strong class="strong" style="-moz-osx-font-smoothing: grayscale; -webkit-font-smoothing: antialiased; color: #151616; font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; font-size: 17px; font-weight: 700;">Referencias familiares</strong>

															<br>
															<br>

															<strong class="strong" style="-moz-osx-font-smoothing: grayscale; -webkit-font-smoothing: antialiased; color: #151616; font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; font-size: 15px; font-weight: 700;">Nombre completo:</strong> <?php echo $datamail['referencia3_nombre']; ?>

															<br>

															<strong class="strong" style="-moz-osx-font-smoothing: grayscale; -webkit-font-smoothing: antialiased; color: #151616; font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; font-size: 15px; font-weight: 700;">Celular:</strong> <?php echo $datamail['referencia3_celular']; ?>

															<br>

															<strong class="strong" style="-moz-osx-font-smoothing: grayscale; -webkit-font-smoothing: antialiased; color: #151616; font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; font-size: 15px; font-weight: 700;">Ciudad:</strong> <?php echo $datamail['referencia3_ciudad']; ?>

															<br>
															<br>

															<strong class="strong" style="-moz-osx-font-smoothing: grayscale; -webkit-font-smoothing: antialiased; color: #151616; font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; font-size: 15px; font-weight: 700;">Nombre completo:</strong> <?php echo $datamail['referencia4_nombre']; ?>

															<br>

															<strong class="strong" style="-moz-osx-font-smoothing: grayscale; -webkit-font-smoothing: antialiased; color: #151616; font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; font-size: 15px; font-weight: 700;">Celular:</strong> <?php echo $datamail['referencia4_celular']; ?>

															<br>

															<strong class="strong" style="-moz-osx-font-smoothing: grayscale; -webkit-font-smoothing: antialiased; color: #151616; font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; font-size: 15px; font-weight: 700;">Ciudad:</strong> <?php echo $datamail['referencia4_ciudad']; ?>


                                                        </p> -->


                                                        <!-- / Contenido -->


                                                    </td>
                                                </tr>
                                            </table>
                                        </td>
                                    </tr>
                                </table>
                                <!-- / Bloque blanco -->
                                <!-- Footer -->
                                <table width="100%" cellpadding="0" cellspacing="0" style="-moz-osx-font-smoothing: grayscale; -webkit-font-smoothing: antialiased; color: #6d757c; font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; font-size: 16px; font-weight: 400;">
                                    <tr>
                                        <td class="footer" style="-moz-osx-font-smoothing: grayscale; -webkit-font-smoothing: antialiased; background: #f7fafc; border-radius: 0 0 5px 5px; border-top: 1px solid #edf0f2; color: #6d757c; font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; font-size: 16px; font-weight: 400;">
                                            <table cellpadding="0" cellspacing="0" align="center" class="contenido" style="-moz-osx-font-smoothing: grayscale; -webkit-font-smoothing: antialiased; color: #6d757c; font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; font-size: 16px; font-weight: 400; padding: 0 26px 0 26px; width: 100%;">
                                                <tr>
                                                    <td height="30" style="-moz-osx-font-smoothing: grayscale; -webkit-font-smoothing: antialiased; color: #6d757c; font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; font-size: 16px; font-weight: 400;"></td>
                                                </tr>
                                                <tr>
                                                    <td class="footer__co1" style="-moz-osx-font-smoothing: grayscale; -webkit-font-smoothing: antialiased; color: #6d757c; font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; font-size: 16px; font-weight: 400; width: 60%;">
                                                        <table width="100%" cellpadding="0" cellspacing="0" style="-moz-osx-font-smoothing: grayscale; -webkit-font-smoothing: antialiased; color: #6d757c; font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; font-size: 16px; font-weight: 400;">
                                                            <tr>
                                                                <td style="-moz-osx-font-smoothing: grayscale; -webkit-font-smoothing: antialiased; color: #6d757c; font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; font-size: 16px; font-weight: 400;">Cordialmente,</td>
                                                            </tr>
                                                            <tr>
                                                                <td height="5" style="-moz-osx-font-smoothing: grayscale; -webkit-font-smoothing: antialiased; color: #6d757c; font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; font-size: 16px; font-weight: 500;">Supre</td>
                                                            </tr>
                                                        </table>
                                                    </td>
                                                    <td valign="middle" class="footer__co2" style="-moz-osx-font-smoothing: grayscale; -webkit-font-smoothing: antialiased; color: #6d757c; font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; font-size: 16px; font-weight: 400; width: 40%;"></td>
                                                </tr>
                                                <tr>
                                                    <td height="30" style="-moz-osx-font-smoothing: grayscale; -webkit-font-smoothing: antialiased; color: #6d757c; font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; font-size: 16px; font-weight: 400;"></td>
                                                </tr>
                                            </table>
                                        </td>
                                    </tr>
                                </table>
                                <!-- / Footer -->
                            </td>
                        </tr>
                    </table>
                </td>
            </tr>
            <tr>
                <td height="50" style="-moz-osx-font-smoothing: grayscale; -webkit-font-smoothing: antialiased; color: #6d757c; font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; font-size: 16px; font-weight: 400;"></td>
            </tr>
        </table>
    </body>

</html>`;

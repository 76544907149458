import { apiAdminInstance } from "../Api/ApiInstance";
import { string_to_slug } from "../helpers/Strings";

export async function ListSuspensionDelantera() {
  return await apiAdminInstance.get(
    "api/official-site/suspension-delantera/"
  );
}

export async function ListSuspensionDelanteraFilter() {
  return await apiAdminInstance.get(
    "api/official-site/suspension-delantera/filter"
  );
}

export async function DeleteSuspensionD(id) {
  return await apiAdminInstance.delete(
    "api/official-site/suspension-delantera/" + id
  );
}

export async function UpdateSuspensionD(suspension_name) {
  const { id, nombre_suspension_delantera, state } = suspension_name;
  const slug = nombre_suspension_delantera
    ? string_to_slug(nombre_suspension_delantera)
    : nombre_suspension_delantera;

  return await apiAdminInstance.put(
    "api/official-site/suspension-delantera/" + id,
    {
      nombre_suspension_delantera,
      slug,
      state,
    }
  );
}

export async function CreateSuspensionD(suspension_name) {
  const { nombre_suspension_delantera } = suspension_name;
  const slug = string_to_slug(nombre_suspension_delantera);

  return await apiAdminInstance.post(
    "api/official-site/suspension-delantera/",
    {
      nombre_suspension_delantera,
      slug,
      state: 1,
    }
  );
}

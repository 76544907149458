import React from 'react';
import { styled } from '@mui/styles';
import MuiGrid from '@mui/material/Grid';
import MuiInputLabel from '@mui/material/InputLabel';
import MuiDivider from '@mui/material/Divider';
import InputNumber from '../../../../atoms/InputNumber';
import { CreateMotoContext } from '../../../../../contexts/CreateMotoContext';

const Grid = styled(MuiGrid)({
  display: 'flex',
  marginTop: 50,
  marginBottom: 20,
  justifyContent: 'center',
});

const InputLabel = styled(MuiInputLabel)({
  marginRight: 10,
  alignSelf: 'center',
});

const Divider = styled(MuiDivider)({
  width: '100%',
});

const styleInputNumber = {
  marginLeft: 80,
  width: '60%',
  marginRight: 10,
  '& > p': {
    fontSize: '1.2rem',
  },
};
const Prices = () => {
  const { values, onChange } = React.useContext(CreateMotoContext);

  return (
    <Grid container style={{ margin: 0 }}>
      <Grid item xs={12}>
        <InputLabel htmlFor="input-price-moto">Precio moto ($)</InputLabel>
        <InputNumber
          id="input-price-moto"
          name="precio_moto"
          variant="outlined"
          style={styleInputNumber}
          value={values.precio_moto}
          onChange={onChange}
        />
      </Grid>
      <Divider />
      <Grid item xs={12}>
        <InputLabel htmlFor="input-documents-moto">
          Precio papeles ($)
        </InputLabel>
        <InputNumber
          id="input-documents-moto"
          name="precio_papeles"
          variant="outlined"
          style={{
            ...styleInputNumber,
            marginLeft: 67,
          }}
          value={values.precio_papeles}
          onChange={onChange}
        />
      </Grid>
      <Divider />
      <Grid item xs={12}>
        <InputLabel htmlFor="input-financed-value">
          Precio máximo a financiar ($)
        </InputLabel>
        <InputNumber
          id="input-financed-value"
          name="maxFinancedValue"
          variant="outlined"
          style={{
            ...styleInputNumber,
            marginLeft: 67,
          }}
          value={values.maxFinancedValue}
          onChange={onChange}
        />
      </Grid>
    </Grid>
  );
};

export default Prices;

import React, { useEffect, useState, useContext } from "react";
import { CounterContext } from "../contexts/CounterContext";
import { useLocation, useHistory } from "react-router-dom";
import { Portada } from "../components/molecules/Portada";
import { ModalMotos } from "../components/molecules/ModalMotos";
import { PrincipalTable } from "../components/molecules/TablasComparador/Principal";
import { MotorTable } from "../components/molecules/TablasComparador/Motor";
import { DimensionesTable } from "../components/molecules/TablasComparador/Dimensiones";
import { SeguridadTable } from "../components/molecules/TablasComparador/Seguridad";
import { CaracteristicasTable } from "../components/molecules/TablasComparador/Caracteristicas";
import { BtnsTable } from "../components/molecules/TablasComparador/Btns";
import { GetFilterMotos } from "../utils/AllMotos";
import Helmet from "react-helmet";
import "../css/ComparadorMotos.css";
import "../helpers/ScrollCM";

export const ComparadorMotos = () => {
  const [listallmotos, setListAllMotos] = useState([]);
  const [opensearch, setOpenSearch] = useState(false);
  const { setComparador } = useContext(CounterContext);

  let { search } = useLocation();
  let query = new URLSearchParams(search);
  let motos = query.get("motos") !== null ? query.get("motos").split(",") : [];
  let history = useHistory();

  useEffect(() => {
    GetFilterMotos(
      "''",
      "''",
      "''",
      "''",
      "''",
      "''",
      "''",
      "''",
      "''",
      "''",
      "''",
      "''",
      "''",
      "''",
      "''",
      "precio:asc"
    ).then((result) => {
      setListAllMotos(result.data);
    });
  }, []);

  useEffect(() => {
    includeComparador(); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, listallmotos]);

  const includeComparador = () => {
    let motosComparador = [];
    if (listallmotos.length > 0) {
      listallmotos.forEach((elemnt) => {
        if (motos.indexOf(elemnt.id.toString()) >= 0) {
          motosComparador.length === 3 && motosComparador.shift();
          motosComparador.push(elemnt);
        }
      });
      setComparador((prevState) => ({
        ...prevState,
        product: motosComparador,
      }));
    }
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Comparador de motos - Supre</title>
        <meta name="description" content="Comparador de motos Supre" />
      </Helmet>
      <Portada className="c-block-3" id="ComMotos" Name="Comparador de motos" />
      <ModalMotos
        listallmotos={listallmotos}
        opensearch={opensearch}
        setOpenSearch={setOpenSearch}
      />
      <div className="c-block-CM" id="containerglobal">
        <div className="g-containerCM">
          <h2 className="o-heading-7 c-block-5__heading-3">
            Puedes comparar hasta 3 modelos
          </h2>
          <div className="c-block-5__container">
            <div className="c-block-5__container-box" id="lista-comparador">
              <div
                className="sticky-wrapper"
                id="header-comparador-sticky-wrapper"
              >
                <PrincipalTable
                  motos={motos}
                  listallmotos={listallmotos}
                  setOpenSearch={setOpenSearch}
                  history={history}
                  includeComparador={includeComparador}
                />
                <div className="c-table-1">
                  <MotorTable motos={motos} listallmotos={listallmotos} />
                </div>
                <div className="c-table-1">
                  <DimensionesTable motos={motos} listallmotos={listallmotos} />
                </div>
                <div className="c-table-1">
                  <SeguridadTable motos={motos} listallmotos={listallmotos} />
                </div>
                <div className="c-table-1">
                  <CaracteristicasTable
                    motos={motos}
                    listallmotos={listallmotos}
                  />
                </div>
                <BtnsTable motos={motos} listallmotos={listallmotos} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

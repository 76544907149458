import React from "react";
import { Helmet } from "react-helmet";
import { Portada } from "../components/molecules/Portada";
import { BodyEdittProfile } from "../components/Organisms/Profile/BodyEdittProfile";
import "../css/EditProfile.css";

export const EditProfile = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Editar perfil - Supre</title>
        <meta name="description" content="Editar perfil Supre" />
      </Helmet>
      <Portada className="c-block-3" id="Edit profile" Name="Editar perfil" />
      <div className="c-block-5-ep--v2">
        <div className="g-container-ep" style={{ minHeight: "600px" }}>
            <BodyEdittProfile/>
        </div>
      </div>
    </>
  );
};

import React from "react";
import { Helmet } from "react-helmet";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { Login } from "../utils/InicioSesion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { useHistory } from "react-router-dom";
import "../css/IniciarSesion.css";

const SignupSchema = Yup.object().shape({
  email: Yup.string()
    .min(3, "Muy corto")
    .email("Correo no valido")
    .required("Este campo es requerido"),
  password: Yup.string()
    .min(3, "Muy corto")
    .required("Este campo es requerido"),
});

const IniciarSesion = () => {
  const history = useHistory();
  const [errorApi, setErrorApi] = React.useState(false);

  function AuthLogin(result) {
    localStorage.setItem("token_session", result.token);
    // window.location.reload();
    history.push("/perfil/");
  }

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Iniciar sesión - Supre</title>
        <meta name="description" content="Inicio de sesión Supre" />
      </Helmet>
      <div className="c-block-3">
        <div className="g-container">
          <h1 className="o-heading-1">Ingresar</h1>
        </div>
      </div>
      <div className="c-block-4-IS" style={{ margin: "0 auto" }}>
        <div className="g-container">
          <div className="c-block-4__box-3">
            <Formik
              initialValues={{
                email: "",
                password: "",
              }}
              validationSchema={SignupSchema}
              onSubmit={(values) => {
                Login(values)
                  .then((res) => {
                    const { data } = res;
                    AuthLogin(data);
                    setErrorApi(false);
                  })
                  .catch((err) => setErrorApi(err));
              }}
            >
              {({ errors, touched }) => (
                <Form className="c-form-1" id="form-login">
                  <div className="c-form-1__heading">Iniciar sesión</div>
                  <div className="c-form-1__field">
                    <label className="o-form-label">Correo electrónico</label>
                    <Field name="email" className="o-form-field-l" />
                    {errors.email && touched.email ? (
                      <div className="js_error">{errors.email}</div>
                    ) : null}
                  </div>
                  <div className="c-form-1__field">
                    <label className="o-form-label">Contraseña</label>
                    <Field
                      name="password"
                      className="o-form-field-l"
                      type="password"
                      autoComplete="off"
                    />
                    {errors.password && touched.password ? (
                      <div className="js_error">{errors.password}</div>
                    ) : null}
                  </div>
                  <div className="c-form-1__field">
                    <a href="/login/password/" className="login_a_recovery">
                      ¿Olvidaste tu contraseña?
                    </a>
                  </div>
                  {errorApi && (
                    <div className="c-form-ct__field">
                      <div className="c-form-ct__notification">
                        <div className="c-notify--alert_Requeriments">
                          <FontAwesomeIcon
                            icon={faInfoCircle}
                            className="icon_error_login"
                          />
                          <strong className="error_login_message">
                            {errorApi.message}
                          </strong>
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="c-form-1__btn">
                    <button className="o-btn-3--v2 js-btn-login" type="submit">
                      Ingresar
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
            <div className="c-modal-1__box-2">
              <span className="o-text-6">
                ¿No tienes cuenta?{" "}
                <a
                  className="o-link-2 js-step-registro-usuario"
                  href="/login/registro/"
                >
                  Regístrate
                </a>
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default IniciarSesion;

import React from "react";
import { Typography } from "@mui/material";
import InventoryOutlinedIcon from "@mui/icons-material/InventoryOutlined";
import { styledIconSEO, styledTextSEO } from "../../SEO/MuiStyles";

const LeftInformation = () => {
  return (
    <>
      <InventoryOutlinedIcon style={styledIconSEO} />
      <Typography variant="h4" style={{ fontWeight: "900", marginBottom: 15 }}>
        Información general
      </Typography>
      <Typography variant="caption" style={styledTextSEO}>
        Agrega aquí la descripción del producto con todos los detalles e
        información necesaria.
      </Typography>
    </>
  );
};

export default LeftInformation;

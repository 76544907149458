import GeneralData from "../data/GeneralData.js";

export async function WompiPay(value, handleSubmit) {
  let checkout = new global.WidgetCheckout({
    currency: "COP",
    amountInCents: value.price + "00",
    reference: value.orderId,
    publicKey: GeneralData.publicKey,
  });

  checkout.open(async function (result) {
    handleSubmit(result.transaction);
  });
}

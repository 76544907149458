import React from "react";
import { useChangeBackground } from "../hooks/useChangeBackground";
import useVerifyMenu from "../hooks/useVerifyMenu";
import { Helmet } from "react-helmet";
import { useHistory } from "react-router-dom";
import AdminMui from "../components/Masters/AdminMui";
import { useChangeTitleAppBar } from "../hooks/useChangeTitleAppBar";
import { CreateStyle, ListStyles, UpdateStyle } from "../utils/Styles";
import Swal from "sweetalert2";
import { StylesTable } from "../components/Organisms/StylesSettings/StylesTable/index";
import { EditStyle } from "../components/Organisms/StylesSettings/EditStyle/index";

export const Styles = () => {
  const back = useVerifyMenu();
  const history = useHistory();
  const [styles, setStyles] = React.useState([]);
  const [againFetch, setAgainFetch] = React.useState(false);
  const [showModal, setShowMondal] = React.useState({
    show: false,
    data: {},
  });

  useChangeBackground("#f5f5f8");
  useChangeTitleAppBar("Estilos");

  React.useEffect(() => {
    ListStyles()
      .then((res) => setStyles(res.data))
      .catch((err) =>
        Swal.fire({
          title: "Error!",
          text: err.message || "",
          icon: "error",
          confirmButtonText: "Ok",
        })
      );
  }, [againFetch]);

  const ApiDeleteStyle = (style) => {
    return UpdateStyle(style);
  };

  const handleClick = (style) => {
    if (!style.id) {
      CreateStyle(style)
        .then((res) => {
          setShowMondal({
            show: false,
            data: {},
          });
          Swal.fire({
            title: "Proceso Ejecutado exitosamente!",
            text: res.message || "",
            icon: "success",
            confirmButtonText: "Ok",
          }).then(() => setAgainFetch(!againFetch));
        })
        .catch((err) => {
          setShowMondal({
            show: false,
            data: {},
          });
          Swal.fire({
            title: "Error!",
            text: err || "",
            icon: "error",
            confirmButtonText: "Ok",
          });
        });
    } else {
      UpdateStyle(style)
        .then((res) => {
          setShowMondal({
            show: false,
            data: {},
          });
          Swal.fire({
            title: "Proceso Ejecutado exitosamente!",
            text: res.message || "",
            icon: "success",
            confirmButtonText: "Ok",
          }).then(() => setAgainFetch(!againFetch));
        })
        .catch((err) => {
          setShowMondal({
            show: false,
            data: {},
          });
          Swal.fire({
            title: "Error!",
            text: err.message || "",
            icon: "error",
            confirmButtonText: "Ok",
          });
        });
    }
  };

  return !back ? (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Configuración de estilos - Supre</title>
        <meta name="description" content="estilos admin supre" />
      </Helmet>
      <AdminMui>
        <StylesTable
          setShowMondal={setShowMondal}
          styles={styles}
          setAgainFetch={setAgainFetch}
          againFetch={againFetch}
          ApiDeleteStyle={ApiDeleteStyle}
        />
        {showModal.show && (
          <EditStyle
            showModal={showModal}
            setShowMondal={setShowMondal}
            handleClick={handleClick}
          />
        )}
      </AdminMui>
    </>
  ) : (
    <>{history.push("/admin/login")}</>
  );
};

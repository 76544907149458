import React from "react";
import { Link } from "react-router-dom";

export const ButtonSelect = ({
  className,
  id,
  links = [],
  setOpenBG,
  setUserLogged,
  setClose,
}) => {
  const busquedaGuiada = () => {
    setOpenBG(true);
    setClose(false);
  };

  const handleRemove = (link) => {
    if (link.name === "Cerrar sesión") {
      localStorage.removeItem("token_session");
      setUserLogged({});
    } else {
      setClose(false);
    }
  };

  return (
    <ul className={className} id={id}>
      {links.map((link) => (
        <li key={Math.random() * 9 + 1}>
          {link.id === "BG" ? (
            <Link to="#" onClick={busquedaGuiada} id={link.id}>
              {" "}
              {link.name}{" "}
            </Link>
          ) : (
            <div>
              {" "}
              <Link
                to={link.route}
                id={link.id}
                onClick={() => handleRemove(link)}
              >
                {" "}
                {link.name}{" "}
              </Link>
            </div>
          )}
        </li>
      ))}
    </ul>
  );
};

import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import {
  Select as MySelect,
  FormControl,
  InputLabel,
  MenuItem,
} from "@mui/material";
import clsx from "clsx";
import "./select.css";

const useStyles = makeStyles((theme) => ({
  formControl: {
    width: "100%",
  },
  select: {
    width: "100%",
    // marginBottom: 20,
  },
  menuItem: {
    boxShadow: "0 0 10px 0 rgba(0, 0, 0, 0.5)",
    background: "#304381",
    fontSize: "1.6rem",
    fontWeight: "500",
    color: "#758fe5",
    transition: "all 0.3s ease",
    borderBottom: "1px solid #1b2546",
    "&:hover": {
      background: `#374d95`,
      color: `#dde5ff`,
    },
  },
}));

export const Select = ({
  label = "",
  data = [],
  className = "",
  name = "",
  onChange,
  value,
  isDefault = true,
  disabled = false,
  required = false,
  margin = "dense",
  defaultValue = "",
  variant = "standard",
  size = "small",
}) => {
  const classes = useStyles();

  return (
    <FormControl className={classes.formControl} variant={variant}>
      <InputLabel>{label}</InputLabel>
      <MySelect
        component="select"
        name={name}
        className={clsx(className, isDefault && classes.select)}
        value={value}
        onChange={onChange}
        disabled={disabled}
        required={required}
        margin={margin}
        label={label}
        defaultValue={defaultValue}
        autoComplete="off"
        size={size}
      >
        <MenuItem value="" className={classes.menuItem}>
          <em>Seleccione</em>
        </MenuItem>

        {Array.isArray(data) ? (
          data.map((value) => (
            <MenuItem
              key={Math.random() + ""}
              value={value.id + ""}
              className={classes.menuItem}
            >
              {value.name || ""}
            </MenuItem>
          ))
        ) : (
          <></>
        )}
      </MySelect>
    </FormControl>
  );
};

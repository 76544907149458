import React from "react";
import { Card, Grid } from "@mui/material";
import { styledGridSEO } from "./MuiStyles";
import LeftInformation from "./LeftInformation";
import Meta from "./Meta";
import { CreateMotoContext } from "../../../../contexts/CreateMotoContext";
import { MotoGetInfo } from "../../../../utils/Catalogue";

const SEO = ({ isEdit }) => {
  const { setValues } = React.useContext(CreateMotoContext);

  // save id in url to get info
  const id = window.location.pathname.split("/")[4];

  React.useEffect(() => {
    if (isEdit && id) {
      MotoGetInfo(id)
        .then((res) => {
          setValues({
            ...res.data,
            mostrar_ocultar: res.data.mostrar_ocultar === 1 ? true : false,
            id_marca: res.data.MotosMarca,
            id_estilo: res.data.MotosEstilo,
            id_tipo_transmision: res.data.MotosTransmision,
            id_freno_delantero: res.data.MotosFrenoDelantero,
            id_freno_trasero: res.data.MotosFrenoTrasero,
            id_suspension_delantera: res.data.MotosSuspensionDelantera,
            id_suspension_trasera: res.data.MotosSuspensionTrasera,
            id_arranque: res.data.MotosTipoArranque,
          });
        })
        .catch((err) => {
          console.log(err);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, isEdit]);

  return (
    <Card style={{ minHeight: 637 }}>
      <Grid container style={{ minHeight: 637 }}>
        <Grid item xs={2} style={styledGridSEO}>
          <LeftInformation />
        </Grid>
        <Grid item xs={10}>
          <Meta />
        </Grid>
      </Grid>
    </Card>
  );
};

export default SEO;

import React from "react";
import Helmet from "react-helmet";
import { Bancos } from "../components/molecules/Bancos";
import { FormPagaCuota } from "../components/molecules/FormPagaCuota";
import { Localizacion } from "../components/molecules/Localizacion";
import { Portada } from "../components/molecules/Portada";
import "../css/PagaTuCuota.css";
import Ubicaciones from "../data/Ubicaciones.json";

export const PagaTuCuota = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Paga tu cuota - Supre</title>
        <meta name="description" content="paga tu cuota Supre" />
      </Helmet>
      <Portada className="c-block-3" Name="Paga tu cuota" />
      <div className="c-block-4">
        <div className="g-containerPC">
          <div className="c-block-5__heading-6">Canales de pago</div>
          <p className="c-block-5__pc">
            Paga 3 días antes de tu fecha de pago y obten un 3% de descuento en
            tu cuota (aplican términos y condiciones).
          </p>
          <div className="g-rowPC">
            <div className="g-col-xl-4PC  c-block-4__col-pagos-1">
              <div className="c-table-2--v2">
                <div className="c-table-2__header">
                  <span className="number">1</span>
                  <span className="number--movil">2</span>
                  Puntos de recaudo propios
                </div>
                <div className="c-list-3--v3">
                  {Ubicaciones.map((data) => (
                    <Localizacion data={data} key={Math.random() * 1520} />
                  ))}
                </div>
              </div>
            </div>
            <div className="g-col-xl-4PC  c-block-4__col-pagos-2">
              <div className="c-table-2--v2">
                <div className="c-table-2__header">
                  <span className="number">2</span>
                  <span className="number--movil">3</span>
                  Aliados de recaudo
                </div>
                <p>
                  {`Cuando realices tu pago por cualquiera de estos medios,
                  recuerda que como referencia debes poner la cédula del titular
                  del crédito y enviarnos el comprobante de pago a través de
                  WhatsApp: `}
                  <a
                    href="https://wa.me/573012064520"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    +57 (301) 206 4520
                  </a>
                </p>
                <div className="c-list-3--v3">
                  <Bancos />
                </div>
              </div>
            </div>
            <div className="g-col-xl-4PC  c-block-4__col-pagos-3">
              <div className="c-table-2--v2">
                <div className="c-table-2__header">
                  <span className="number">3</span>
                  <span className="number--movil">1</span>
                  Pago en línea
                </div>
                <div className="c-list-3--v3">
                  <FormPagaCuota />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

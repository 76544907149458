import { Card, Grid } from "@mui/material";
import React from "react";
import { ListFilterMarks } from "../../../../utils/Marks";
import { ListFilterStyles } from "../../../../utils/Styles";
import { styledGridSEO } from "../SEO/MuiStyles";
import InformationMoto from "./InformationMoto";
import LeftInformation from "./LeftInformation";

const GeneralInformationMoto = () => {
  const [marks, setMarks] = React.useState([]);
  const [styles, setStyles] = React.useState([]);
  
  React.useEffect(() => {
    ListFilterMarks()
      .then((response) => {
        setMarks(response.data);
      })
      .catch((error) => {
        console.log(error);
      });

    ListFilterStyles()
      .then((response) => {
        setStyles(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <>
      <Card style={{ minHeight: 637 }}>
        <Grid container style={{ minHeight: 637 }}>
          <Grid item xs={2} style={styledGridSEO}>
            <LeftInformation />
          </Grid>
          <Grid item xs={10}>
            <InformationMoto marks={marks} styles={styles} />
          </Grid>
        </Grid>
      </Card>
    </>
  );
};

export default GeneralInformationMoto;

import React, { useState, useEffect } from "react";
import { Orders } from "../../../utils/Order";
import { formatCurrency } from "../../../helpers/FormatMoney";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMotorcycle } from "@fortawesome/free-solid-svg-icons";
import { Button } from "../../atoms/Button/index.jsx";
import "./style.css";

export const DetailOrders = () => {
  const [orders, setOrders] = useState([]);
  const [load, setLoad] = useState(true);

  useEffect(() => {
    Orders()
      .then((res) => {
        setOrders(res.data);
        setLoad(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <>
      <div className="c-block-5-p__heading-6">Motos</div>
      <div className="c-block-5-p__tables">
        {orders.length > 0 ? (
          orders.map((elemnt, index) => {
            return (
              <div className="c-table-2-p--v2" key={index}>
                <div className="c-table-2-p__row">
                  <div className="c-table-2-p__col">
                    <div className="c-table-2-p__heading">Número orden</div>
                    <div className="c-table-2-p__content">{elemnt.ordenid}</div>
                  </div>
                  <div className="c-table-2-p__col">
                    <div className="c-table-2-p__heading">Fecha creación</div>
                    <div className="c-table-2-p__content">
                      {elemnt.fecha_creado}
                    </div>
                  </div>
                  <div className="c-table-2-p__col">
                    <div className="c-table-2-p__heading">Total</div>
                    <div className="c-table-2-p__content">
                      {formatCurrency("es-CO", "COP", 0, elemnt.valor_total)}
                    </div>
                  </div>
                  <div className="c-table-2-p__col">
                    <div className="c-table-2-p__heading">Método pago</div>
                    <div className="c-table-2-p__content">
                      {elemnt.Helper.name}
                    </div>
                  </div>
                  <div className="c-table-2-p__col">
                    <div className="c-table-2-p__heading">Estado</div>
                    <div
                      className="o-tag-1"
                      style={{
                        background: elemnt.OrdenesEstado.color_fondo_estado,
                        color: elemnt.OrdenesEstado.color_texto_estado,
                      }}
                    >
                      {elemnt.OrdenesEstado.nombre_estado}
                    </div>
                  </div>
                  <div className="c-table-2-p__col--btn">
                    <div className="c-table-2__content">
                      <Button
                        className="o-btn-or"
                        link={`/perfil/order/${elemnt.ordenid}`}
                        name={"Ver detalle"}
                      />
                    </div>
                  </div>
                </div>
              </div>
            );
          })
        ) : load ? (
          <div className="menu-mobile-overlay">
            <div className="loader-popup">
              <div className="loader__box">
                <div className="icon-spinner9 animate-spin"></div>
              </div>
            </div>
          </div>
        ) : (
          <div className="c-table-2-p--v2">
            <div className="c-table-2-p__row">
              <div className="c-table-2-p__col">
                <div className="c-table-2-p__heading-null">
                  <FontAwesomeIcon className="icon_check" icon={faMotorcycle} />
                </div>
                <div className="c-table-2-p__content-null">
                  Aún no tienes compras de motos
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

import { apiAdminInstance } from "../Api/ApiInstance";
import { string_to_slug } from "../helpers/Strings";

export const CreateMark = async (mark) => {
  let { nombre_marca } = mark;
  const slug = string_to_slug(nombre_marca);

  return await apiAdminInstance.post("api/official-site/mark/", {
    nombre_marca,
    slug,
    state: 1,
  });
};

export const ListMarks = async () => {
  return await apiAdminInstance.get("api/official-site/mark");
};

export const ListFilterMarks = async () => {
  return await apiAdminInstance.get(
    "api/official-site/mark/filter"
  );
};

export const UpdateMark = async (mark) => {
  const { id, nombre_marca, state } = mark;
  const slug = nombre_marca ? string_to_slug(nombre_marca) : nombre_marca;

  return await apiAdminInstance.put(
    "api/official-site/mark/" + id,
    {
      nombre_marca,
      slug,
      state,
    }
  );
};

export const DeleteMark = async (id) => {
  return await apiAdminInstance.delete(
    "api/official-site/mark/" + id
  );
};

import { apiAdminInstance } from "../Api/ApiInstance";

export async function ListCostos() {
  return await apiAdminInstance.get("api/official-site/costos-financiamiento/");
}

export async function UpdateCostos(costos_json) {
  const {
    id,
    porcentaje_interes,
    gestion_cobranza,
    sistematizacion,
    otros_costos,
  } = costos_json;
  return await apiAdminInstance.put(
    "api/official-site/costos-financiamiento/" + id,
    {
      porcentaje_interes,
      gestion_cobranza,
      sistematizacion,
      otros_costos,
    }
  );
}

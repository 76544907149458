import { apiAdminInstance } from '../Api/ApiInstance';
import GeneralData from '../data/GeneralData.js';

const URL = `${GeneralData.host_api}api/official-site/`;

export async function ListCatalogues() {
  return apiAdminInstance.get('api/official-site/catalogue');
}

export async function CreateCatalogue(data) {
  let {
    seo_titulo,
    seo_descripcion,
    slug_moto,
    nombre,
    descripcion_corta,
    modelo,
    id_marca,
    id_estilo,
    foto,
    url_foto,
    mostrar_ocultar,
    precio_moto,
    precio_papeles,
    cilindraje,
    tipo_motor,
    potencia_maxima_hp,
    potencia_maxima_rpm,
    torque_maximo_nm,
    torque_maximo_rpm,
    relacion_compresion,
    id_tipo_transmision,
    rueda_delantera,
    rueda_trasera,
    dimension_total,
    distancia_entre_ejes,
    peso,
    id_freno_delantero,
    id_freno_trasero,
    id_suspension_delantera,
    id_suspension_trasera,
    id_arranque,
    certificacion,
    maxFinancedValue,
    canShowInOmnia,
  } = data;

  // reestrucutre el objeto para enviarlo al backend
  // ! validar si es buena practica.
  id_marca = id_marca?.id ?? null;
  id_estilo = id_estilo?.id ?? null;
  id_tipo_transmision = id_tipo_transmision?.id ?? null;
  id_freno_delantero = id_freno_delantero?.id ?? null;
  id_freno_trasero = id_freno_trasero?.id ?? null;
  id_suspension_delantera = id_suspension_delantera?.id ?? null;
  id_suspension_trasera = id_suspension_trasera?.id ?? null;
  id_arranque = id_arranque?.id ?? null;

  let formDataFoto = new FormData();
  formDataFoto.append('foto', foto);
  let requestOptions = {
    method: 'POST',
    body: formDataFoto,
    redirect: 'follow',
    headers: {
      Authorization: 'Bearer ' + process.env.REACT_APP_JWT_PORTFOLIO_TEMP,
      user: 'Bearer ' + localStorage.getItem('SESSION_ID'),
    },
  };

  if (foto && foto.name !== '') {
    const res = await fetch(`${URL}catalogue/foto`, requestOptions);
    url_foto = await res.json();
  }

  return apiAdminInstance.post('api/official-site/catalogue', {
    seo_titulo,
    seo_descripcion,
    slug_moto,
    nombre,
    descripcion_corta,
    modelo,
    id_marca,
    id_estilo,
    url_foto,
    mostrar_ocultar,
    precio_moto,
    precio_papeles,
    cilindraje,
    tipo_motor,
    potencia_maxima_hp,
    potencia_maxima_rpm,
    torque_maximo_nm,
    torque_maximo_rpm,
    relacion_compresion,
    id_tipo_transmision,
    rueda_delantera,
    rueda_trasera,
    dimension_total,
    distancia_entre_ejes,
    peso,
    id_freno_delantero,
    id_freno_trasero,
    id_suspension_delantera,
    id_suspension_trasera,
    id_arranque,
    certificacion,
    url_fotos_360: slug_moto,
    maxFinancedValue,
    canShowInOmnia,
  });
}

export async function DeleteCatalogueById(id) {
  return apiAdminInstance.delete(`api/official-site/catalogue/${id}`);
}

export async function MotoByID(id) {
  return apiAdminInstance.get('api/official-site/catalogue/' + id);
}

export async function MotoGetInfo(id) {
  return apiAdminInstance.get('api/official-site/catalogue/info/' + id);
}

export async function UpdateCatalogue(data, id) {
  let {
    seo_titulo,
    seo_descripcion,
    slug_moto,
    nombre,
    descripcion_corta,
    modelo,
    id_marca,
    id_estilo,
    foto,
    url_foto,
    mostrar_ocultar,
    precio_moto,
    precio_papeles,
    cilindraje,
    tipo_motor,
    potencia_maxima_hp,
    potencia_maxima_rpm,
    torque_maximo_nm,
    torque_maximo_rpm,
    relacion_compresion,
    id_tipo_transmision,
    rueda_delantera,
    rueda_trasera,
    dimension_total,
    distancia_entre_ejes,
    peso,
    id_freno_delantero,
    id_freno_trasero,
    id_suspension_delantera,
    id_suspension_trasera,
    id_arranque,
    certificacion,
    maxFinancedValue,
    canShowInOmnia,
  } = data;

  // reestrucutre el objeto para enviarlo al backend
  // ! validar si es buena practica.
  id_marca = id_marca?.id ?? undefined;
  id_estilo = id_estilo?.id ?? undefined;
  id_tipo_transmision = id_tipo_transmision?.id ?? undefined;
  id_freno_delantero = id_freno_delantero?.id ?? undefined;
  id_freno_trasero = id_freno_trasero?.id ?? undefined;
  id_suspension_delantera = id_suspension_delantera?.id ?? undefined;
  id_suspension_trasera = id_suspension_trasera?.id ?? undefined;
  id_arranque = id_arranque?.id ?? undefined;

  let formDataFoto = new FormData();
  formDataFoto.append('foto', foto);
  let requestOptions = {
    method: 'POST',
    body: formDataFoto,
    redirect: 'follow',
    headers: {
      Authorization: 'Bearer ' + process.env.REACT_APP_JWT_PORTFOLIO_TEMP,
      user: 'Bearer ' + localStorage.getItem('SESSION_ID'),
    },
  };
  if (foto && foto.name !== '') {
    const res = await fetch(`${URL}catalogue/foto`, requestOptions);
    url_foto = await res.json();
  }

  return apiAdminInstance.put('api/official-site/catalogue/' + id, {
    seo_titulo,
    seo_descripcion,
    slug_moto,
    nombre,
    descripcion_corta,
    modelo,
    id_marca,
    id_estilo,
    url_foto,
    mostrar_ocultar,
    precio_moto,
    precio_papeles,
    cilindraje,
    tipo_motor,
    potencia_maxima_hp,
    potencia_maxima_rpm,
    torque_maximo_nm,
    torque_maximo_rpm,
    relacion_compresion,
    id_tipo_transmision,
    rueda_delantera,
    rueda_trasera,
    dimension_total,
    distancia_entre_ejes,
    peso,
    id_freno_delantero,
    id_freno_trasero,
    id_suspension_delantera,
    id_suspension_trasera,
    id_arranque,
    certificacion,
    url_fotos_360: slug_moto,
    maxFinancedValue,
    canShowInOmnia,
  });
}

import React from "react";
import { useChangeBackground } from "../hooks/useChangeBackground";
import useVerifyMenu from "../hooks/useVerifyMenu";
import { Helmet } from "react-helmet";
import { useHistory } from "react-router-dom";
import AdminMui from "../components/Masters/AdminMui";
import { useChangeTitleAppBar } from "../hooks/useChangeTitleAppBar";
import {
  ListSuspensionTrasera,
  CreateSuspensionT,
  UpdateSuspensionT,
} from "../utils/SuspensionTrasera";
import EditSuspensionT from "../components/Organisms/SuspensionTrasera/EditSuspensionT/index";
import Swal from "sweetalert2";
import SuspensionTTable from "../components/Organisms/SuspensionTrasera/SuspensionTTable/index";

const SuspensionDelantera = () => {
  const back = useVerifyMenu();
  const history = useHistory();
  const [suspension_t, setSuspension_T] = React.useState([]); // Cargar los datos
  const [againFetch, setAgainFetch] = React.useState(false);
  const [showModal, setShowMondal] = React.useState({
    show: false,
    data: {},
  });

  useChangeBackground("#f5f5f8");
  useChangeTitleAppBar("Configuración de suspensión trasera");

  React.useEffect(() => {
    ListSuspensionTrasera()
      .then((res) => setSuspension_T(res.data))
      .catch((err) =>
        Swal.fire({
          title: "Error!",
          text: err.message || "",
          icon: "error",
          confirmButtonText: "Ok",
        })
      );
  }, [againFetch]);

  const ApiDeleteSuspensionT = (suspensionT) => {
    return UpdateSuspensionT(suspensionT);
  };

  const handleClick = (suspension_name) => {
    if (!suspension_name.id) {
      CreateSuspensionT(suspension_name)
        .then((res) => {
          setShowMondal({
            show: false,
            data: {},
          });
          Swal.fire({
            title: "Proceso Ejecutado exitosamente!",
            text: res.message || "",
            icon: "success",
            confirmButtonText: "Ok",
          }).then(() => setAgainFetch(!againFetch));
        })
        .catch((err) => {
          setShowMondal({
            show: false,
            data: {},
          });
          Swal.fire({
            title: "Error!",
            text: err || "",
            icon: "error",
            confirmButtonText: "Ok",
          });
        });
    } else {
      UpdateSuspensionT(suspension_name)
        .then((res) => {
          setShowMondal({
            show: false,
            data: {},
          });
          Swal.fire({
            title: "Proceso Ejecutado exitosamente!",
            text: res.message || "",
            icon: "success",
            confirmButtonText: "Ok",
          }).then(() => setAgainFetch(!againFetch));
        })
        .catch((err) => {
          setShowMondal({
            show: false,
            data: {},
          });
          Swal.fire({
            title: "Error!",
            text: err.message || "",
            icon: "error",
            confirmButtonText: "Ok",
          });
        });
    }
  };

  return !back ? (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Configuración de suspension trasera - Supre</title>
        <meta name="description" content="suspension trasera admin supre" />
      </Helmet>

      <AdminMui>
        <SuspensionTTable
          suspension_t={suspension_t}
          ApiDeleteSuspensionT={ApiDeleteSuspensionT}
          setAgainFetch={setAgainFetch}
          setShowMondal={setShowMondal}
          againFetch={againFetch}
        />
        {showModal.show && (
          <EditSuspensionT
            showModal={showModal}
            setShowMondal={setShowMondal}
            handleClick={handleClick}
          />
        )}
      </AdminMui>
    </>
  ) : (
    <>{history.push("/admin/login")}</>
  );
};

export default SuspensionDelantera;

import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { ContactosItem } from "../ContactosItem";
import Ubicaciones from "../../../data/Ubicaciones.json";
import "../InfoLocalizacion/style.css";

export const InforLocalizacion = () => {
  return (
    <div className="c-block-4__col--loc">
      <div className="c-block-4__box-2">
        <div className="c-block-4__list">
          <div className="c-list-3--v2">
            <div className="c-list-3__item">
              <div className="c-list-3__icon">
                <div className="icon-whatsapp">
                  {" "}
                  <FontAwesomeIcon icon={faWhatsapp} />{" "}
                </div>
              </div>
              <div className="c-list-3__box">
                <h3 className="c-list-3__heading">WhatsApp</h3>
                <p className="c-list-3__p">{"+57 (301) 206 4520"}</p>
              </div>
            </div>
            <div className="c-list-3__item">
              <div className="c-list-3__icon">
                <div className="icon-envelop">
                  {" "}
                  <FontAwesomeIcon icon={faEnvelope} />{" "}
                </div>
              </div>
              <div className="c-list-3__box">
                <h3 className="c-list-3__heading">Email</h3>
                <p className="c-list-3__p">servicioalcliente@supre.com.co</p>
              </div>
            </div>
          </div>
          <div className="c-block__separador"></div>
          <div className="c-list-3">
            {Ubicaciones.map((data) => (
              <ContactosItem data={data} key={Math.random() * 150} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

import * as React from "react";
import { useHistory } from "react-router-dom";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ShoppingCartCheckoutOutlinedIcon from "@mui/icons-material/ShoppingCartCheckoutOutlined";
import ViewInArRoundedIcon from "@mui/icons-material/ViewInArRounded";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import ExpandMore from "@mui/icons-material/ExpandMore";
import ChevronRightOutlinedIcon from "@mui/icons-material/ChevronRightOutlined";
import { Link } from "react-router-dom";
import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import { Collapse, MenuItem } from "@mui/material";
import LogoutIcon from "@mui/icons-material/Logout";
import { AppBar, Drawer, DrawerHeader } from "./StyleComponentsMui";
import { Styles, useStyles } from "./StylesMui";
import ConfigurationMenus from "./ConfigurationMenus";
import { VerifyUserLogged } from "../../../utils/Permissions";
import Swal from "sweetalert2";
import GeneralData from "../../../data/GeneralData.js";
import clsx from "clsx";

export default function AdminMui({ children }) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(true);
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [openMenuConfig, setOpenMenuConfig] = React.useState(false);
  const [userLogged, setUserLogged] = React.useState({});
  const history = useHistory();

  React.useEffect(() => {
    VerifyUserLogged()
      .then((res) => setUserLogged(res.data))
      .catch((err) => {
        Swal.fire({
          title: "Error!",
          text: err.message || "",
          icon: "error",
          confirmButtonText: "Ok",
        }).then(() => {
          history.push("/admin/login/");
        });
      });
    // eslint-disable-next-line
  }, []);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
    setOpenMenuConfig(false);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };
  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar position="fixed" open={open} sx={Styles.appBar_root}>
        <Toolbar style={Styles.toolBar_root}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginLeft: 0,
              ...(open && { display: "none" }),
            }}
          >
            <MenuIcon style={{ fontSize: 24 }} />
          </IconButton>
          {open && (
            <Typography
              variant="h6"
              noWrap
              component="div"
              color="#000"
              style={Styles.appBar_title}
            >
              Dashboard
            </Typography>
          )}
          <Box style={Styles.box_avatar} onClick={handleOpenNavMenu}>
            <Avatar sx={Styles.avatar}>
              {userLogged?.nombres?.toString()[0]}
            </Avatar>
            <span>Hola, </span> &nbsp;
            <strong>{userLogged.nombres + "  " + userLogged.apellidos}</strong>
          </Box>
          <Menu
            id="menu-appbar"
            anchorEl={anchorElNav}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            open={Boolean(anchorElNav)}
            onClose={handleCloseNavMenu}
          >
            <Link
              to={"/admin/login"}
              onClick={() => localStorage.removeItem("SESSION_ID")}
              style={{ color: "rgb(171, 180, 190)" }}
            >
              <MenuItem onClick={handleCloseNavMenu}>
                <LogoutIcon style={Styles.logout_icon} />
                <Typography textAlign="center">Salir</Typography>
              </MenuItem>
            </Link>
          </Menu>
        </Toolbar>
      </AppBar>
      <AppBar sx={Styles.appBar_dark} open={open}>
        <Typography
          variant="h2"
          style={{ fontSize: "2.75rem", padding: "9px" }}
          id="appBar-dark-title"
        >
          Dashboard
        </Typography>
      </AppBar>
      <Drawer variant="permanent" open={open}>
        <img
          src={GeneralData.host + "images/logo-supre@2x.png"}
          alt="logo supre"
          style={Styles.img_supre}
        />
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose} style={{ top: "-42px" }}>
            <MenuIcon style={Styles.menu_icon_close} />
          </IconButton>
        </DrawerHeader>
        <List>
          <Link to={"/admin/"} style={{ color: "rgb(171, 180, 190)" }}>
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
                "&:hover": { background: "#2f3139" },
                top: "-32px",
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                <HomeOutlinedIcon
                  style={Styles.HomeOutlinedIcon}
                  className={clsx(!open && classes.HomeOutlinedIconClosed)}
                />
              </ListItemIcon>
              <ListItemText
                primary={"Dashboard"}
                sx={{
                  opacity: open ? 1 : 0,
                  "& .MuiTypography-root": { fontSize: "14px" },
                }}
              />
            </ListItemButton>
          </Link>
        </List>
        <List style={Styles.link2}>
          <Link to={"/admin/motos/"} style={{ color: "rgb(171, 180, 190)" }}>
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
                "&:hover": { background: "#2f3139" },
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                <ShoppingCartCheckoutOutlinedIcon
                  style={{ color: "#FFF", fontSize: "29px" }}
                />
              </ListItemIcon>
              <ListItemText
                primary={"Catálogo"}
                sx={{
                  opacity: open ? 1 : 0,
                  "& .MuiTypography-root": { fontSize: "14px" },
                }}
              />
            </ListItemButton>
          </Link>
          <ListItemButton
            sx={{
              minHeight: 48,
              justifyContent: open ? "initial" : "center",
              px: 2.5,
              "&:hover": { background: "#2f3139" },
            }}
            onClick={() => setOpenMenuConfig(!openMenuConfig)}
          >
            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: open ? 3 : "auto",
                justifyContent: "center",
              }}
            >
              <ViewInArRoundedIcon
                style={{ color: "#FFF", fontSize: "29px" }}
              />
            </ListItemIcon>
            <ListItemText
              primary={"Configuración"}
              sx={{
                opacity: open ? 1 : 0,
                "& .MuiTypography-root": { fontSize: "14px" },
              }}
            />
            {open &&
              (openMenuConfig ? <ExpandMore /> : <ChevronRightOutlinedIcon />)}
          </ListItemButton>
          <Collapse in={openMenuConfig} timeout="auto">
            {ConfigurationMenus.map((menu) => (
              <Link
                to={menu.to}
                style={{ color: "rgb(171, 180, 190)" }}
                key={menu.id}
              >
                <MenuItem>
                  <Typography style={Styles.menus_config}>
                    {menu.name}
                  </Typography>
                </MenuItem>
              </Link>
            ))}
          </Collapse>
        </List>
      </Drawer>
      <Box component="main" sx={{ flexGrow: 1, p: 9, mb: 20 }}>
        <DrawerHeader />
        {children}
      </Box>
    </Box>
  );
}

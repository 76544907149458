import React from "react";
import { styled } from "@mui/styles";
import MuiGrid from "@mui/material/Grid";
import MuiInputLabel from "@mui/material/InputLabel";
import MuiTextField from "@mui/material/TextField";
import MuiDivider from "@mui/material/Divider";
import InputNumber from "../../../../atoms/InputNumber";
import { CreateMotoContext } from "../../../../../contexts/CreateMotoContext";

const Grid = styled(MuiGrid)({
  display: "flex",
  marginTop: 50,
  marginBottom: 20,
  justifyContent: "center",
});

const InputLabel = styled(MuiInputLabel)({
  marginRight: 10,
  alignSelf: "center",
});

const Divider = styled(MuiDivider)({
  width: "100%",
});

const styleInputNumber = {
  marginLeft: 115,
  width: "60%",
  marginRight: 10,
  "& > p": {
    fontSize: "1.2rem",
  },
};

const TextField = styled(MuiTextField)({
  width: "60%",
  marginRight: 10,
  "& > p": {
    fontSize: "1.2rem",
  },
});

const Dimentions = () => {
  const { values, onChange } = React.useContext(CreateMotoContext);

  return (
    <>
      <Grid container style={{ margin: 0 }}>
        <Grid item xs={12}>
          <InputLabel htmlFor="input-rueda-front">Rueda delantera</InputLabel>
          <TextField
            id="input-rueda-front"
            name="rueda_delantera"
            variant="outlined"
            style={{
              marginLeft: 83,
            }}
            maxLength={55}
            onChange={onChange}
            value={values.rueda_delantera}
          />
        </Grid>
        <Divider />
        <Grid item xs={12}>
          <InputLabel htmlFor="input-rueda-back">Rueda trasera</InputLabel>
          <TextField
            id="input-rueda-back"
            name="rueda_trasera"
            variant="outlined"
            style={{
              marginLeft: 97,
            }}
            maxLength={55}
            onChange={onChange}
            value={values.rueda_trasera}
          />
        </Grid>
        <Divider />
        <Grid item xs={12}>
          <InputLabel htmlFor="input-total-dimentions">
            Dimension total
          </InputLabel>
          <TextField
            id="input-total-dimentions"
            name="dimension_total"
            variant="outlined"
            style={{
              marginLeft: 83,
            }}
            maxLength={55}
            onChange={onChange}
            value={values.dimension_total}
          />
        </Grid>
        <Divider />
        <Grid item xs={12}>
          <InputLabel htmlFor="input-total-dimentions">
            Distancia entre ejes
          </InputLabel>
          <TextField
            id="input-distacncia-dimentions"
            name="distancia_entre_ejes"
            variant="outlined"
            style={{
              marginLeft: 55,
            }}
            maxLength={55}
            onChange={onChange}
            value={values.distancia_entre_ejes}
          />
        </Grid>
        <Divider />
        <Grid item xs={12}>
          <InputLabel htmlFor="input-weight">Peso</InputLabel>
          <InputNumber
            id="input-weight"
            name="peso"
            variant="outlined"
            style={{
              ...styleInputNumber,
              marginLeft: 155,
            }}
            maxLength={55}
            value={values.peso}
            onChange={onChange}
          />
        </Grid>
        <Divider />
      </Grid>
    </>
  );
};

export default Dimentions;

import React from "react";
import { useChangeBackground } from "../hooks/useChangeBackground";
import { useChangeTitleAppBar } from "../hooks/useChangeTitleAppBar";
import { Helmet } from "react-helmet";
import AdminMui from "../components/Masters/AdminMui";
import { ListCatalogues, UpdateCatalogue } from "../utils/Catalogue";
import Swal from "sweetalert2";
import CatalogueTable from "../components/Organisms/Catalogue/CatalogueTable/index";

const Catalogue = () => {
  const [motos, setMotos] = React.useState([]);
  const [fetchAgain, setFetchAgain] = React.useState(false);

  React.useEffect(() => {
    ListCatalogues()
      .then((res) => setMotos(res.data))
      .catch((err) =>
        Swal.fire({
          title: "Error!",
          text: err.message || "",
          icon: "error",
          confirmButtonText: "Ok",
        })
      );
  }, [fetchAgain]);

  useChangeBackground("#f5f5f8");
  useChangeTitleAppBar("Listado de motos");

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Motos - Supre</title>
        <meta name="description" content="listado de motos admin supre" />
      </Helmet>
      <AdminMui>
        <CatalogueTable motos={motos} DeleteCatalogue={DeleteCatalogue} />
      </AdminMui>
    </>
  );

  function DeleteCatalogue(id) {
    Swal.fire({
      title: "¿Estás seguro que quieres eliminar esta fila?",
      text: "No podrás revertir estos cambios.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      cancelButtonText: "cancelar",
      confirmButtonText: "Si",
    }).then((result) => {
      if (result.isConfirmed) {
        UpdateCatalogue({ mostrar_ocultar: '0' }, id).then(
          Swal.fire(
            "Eliminado!",
            "Este archivo se ha elimidado",
            "Satisfactoriamente"
          ).then(() => setFetchAgain(!fetchAgain))
        );
      }
    });
  }
};

export default Catalogue;

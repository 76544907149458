import React from "react";
import { useChangeBackground } from "../hooks/useChangeBackground";
import useVerifyMenu from "../hooks/useVerifyMenu";
import { Helmet } from "react-helmet";
import { useHistory } from "react-router-dom";
import AdminMui from "../components/Masters/AdminMui";
import { useChangeTitleAppBar } from "../hooks/useChangeTitleAppBar";
import ColorTable from "../components/Organisms/Color/ColorTable/index";
import { CreateColor, ListColors, UpdateColor } from "../utils/Colors";
import Swal from "sweetalert2";
import EditColor from "../components/Organisms/Color/EditColor/index";

const Colors = () => {
  const back = useVerifyMenu();
  const history = useHistory();
  const [colors, setColors] = React.useState([]);
  const [againFetch, setAgainFetch] = React.useState(false);
  const [showModal, setShowMondal] = React.useState({
    show: false,
    data: {},
  });

  useChangeBackground("#f5f5f8");
  useChangeTitleAppBar("Colores");

  React.useEffect(() => {
    ListColors()
      .then((res) => setColors(res.data))
      .catch((err) =>
        Swal.fire({
          title: "Error!",
          text: err.message || "",
          icon: "error",
          confirmButtonText: "Ok",
        })
      );
  }, [againFetch]);

  const ApiDeleteColor = (color) => {
    return UpdateColor(color);
  };

  const handleClick = (color) => {
    if (!color.id) {
      CreateColor(color)
        .then((res) => {
          setShowMondal({
            show: false,
            data: {},
          });
          Swal.fire({
            title: "Proceso Ejecutado exitosamente!",
            text: res.message || "",
            icon: "success",
            confirmButtonText: "Ok",
          }).then(() => setAgainFetch(!againFetch));
        })
        .catch((err) => {
          setShowMondal({
            show: false,
            data: {},
          });
          Swal.fire({
            title: "Error!",
            text: err || "",
            icon: "error",
            confirmButtonText: "Ok",
          });
        });
    } else {
      UpdateColor(color)
        .then((res) => {
          setShowMondal({
            show: false,
            data: {},
          });
          Swal.fire({
            title: "Proceso Ejecutado exitosamente!",
            text: res.message || "",
            icon: "success",
            confirmButtonText: "Ok",
          }).then(() => setAgainFetch(!againFetch));
        })
        .catch((err) => {
          setShowMondal({
            show: false,
            data: {},
          });
          Swal.fire({
            title: "Error!",
            text: err.message || "",
            icon: "error",
            confirmButtonText: "Ok",
          });
        });
    }
  };

  return !back ? (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Configuración de colores - Supre</title>
        <meta name="description" content="colores admin supre" />
      </Helmet>
      <AdminMui>
        <ColorTable
          setShowMondal={setShowMondal}
          colors={colors}
          setAgainFetch={setAgainFetch}
          againFetch={againFetch}
          ApiDeleteColor={ApiDeleteColor}
        />
        {showModal.show && (
          <EditColor
            showModal={showModal}
            setShowMondal={setShowMondal}
            handleClick={handleClick}
          />
        )}
      </AdminMui>
    </>
  ) : (
    <>{history.push("/admin/login")}</>
  );
};

export default Colors;

import { apiAdminInstance } from "../Api/ApiInstance";
import { string_to_slug } from "../helpers/Strings";

export const CreateStyle = async (style) => {
  let { nombre_estilo } = style;
  const slug = string_to_slug(nombre_estilo);

  return await apiAdminInstance.post("api/official-site/style/", {
    nombre_estilo,
    slug,
    state: 1,
  });
};

export const ListStyles = async () => {
  return await apiAdminInstance.get("api/official-site/style");
};

export const ListFilterStyles = async () => {
  return await apiAdminInstance.get(
    "api/official-site/style/filter"
  );
};

export const UpdateStyle = async (style) => {
  const { id, nombre_estilo, state } = style;
  const slug = nombre_estilo ? string_to_slug(nombre_estilo) : nombre_estilo;

  return await apiAdminInstance.put(
    "api/official-site/style/" + id,
    {
      nombre_estilo,
      slug,
      state,
    }
  );
};

export const DeleteStyle = async (id) => {
  return await apiAdminInstance.delete(
    "api/official-site/style/" + id
  );
};

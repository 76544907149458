import React from "react";
import { Button, Divider, Grid, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

import "./style.css";

export const NewsCard = ({
  id,
  title,
  shortDescription,
  createdAt,
  shortImage,
}) => {
  return (
    <Grid item xs={12} sm={6} md={4} lg={2}>
      <div className="card" classes={{ item: "news-card-root" }}>
        <img src={shortImage} alt="" className="image" />
        <div className="secondContainer">
          <Typography variant="h5" className="text">
            {title}
          </Typography>
          <p className="text-detail">{shortDescription}</p>
        </div>
        <Divider className="divider" />
        <div className="footer">
          <p className="text-footer">{createdAt}</p>
          <Link to={`/detail-blog/${id}`}>
            <Button
              className="button"
              variant="text"
              endIcon={<ArrowForwardIcon />}
            >
              Ver noticia
            </Button>
          </Link>
        </div>
      </div>
    </Grid>
  );
};

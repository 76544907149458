import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";

export const Precio = ({ filtrar }) => {
  return (
    <div className="c-modal-1__section">
      <div className="c-modal-1__heading">Precio</div>
      <div className="c-modal-1__item">
        <div className="c-list-6 js-scrollbar-marca">
          <div className="c-list-6__item">
            <div className="o-form-check">
              <input
                className="o-form-check__campo--v2"
                id="precio_hasta"
                type="radio"
                name="precio__check"
                value1="''"
                value2="5000000"
                btn_name="Hasta $5.000.000"
                onClick={filtrar}
              />
              <label htmlFor="precio_hasta">
                <span className="c-form-1__text-2">Hasta $5.000.000</span>
              </label>
            </div>
          </div>
          <div className="c-list-6__item">
            <div className="o-form-check">
              <input
                className="o-form-check__campo--v2"
                id="precio_rango1"
                type="radio"
                name="precio__check"
                value1="5000000"
                value2="7500000"
                btn_name="$5.000.000 a $7.500.000"
                onClick={filtrar}
              />
              <label htmlFor="precio_rango1">
                <span className="c-form-1__text-2">
                  $5.000.000 a $7.500.000
                </span>
              </label>
            </div>
          </div>
          <div className="c-list-6__item">
            <div className="o-form-check">
              <input
                className="o-form-check__campo--v2"
                id="precio_rango2"
                type="radio"
                name="precio__check"
                value1="7500000"
                value2="10000000"
                btn_name="$7.500.000 a $10.000.000"
                onClick={filtrar}
              />
              <label htmlFor="precio_rango2">
                <span className="c-form-1__text-2">
                  $7.500.000 a $10.000.000
                </span>
              </label>
            </div>
          </div>
          <div className="c-list-6__item">
            <div className="o-form-check">
              <input
                className="o-form-check__campo--v2"
                id="precio_desde"
                type="radio"
                name="precio__check"
                value1="10000000"
                value2="''"
                btn_name="Más de $10.000.000"
                onClick={filtrar}
              />
              <label htmlFor="precio_desde">
                <span className="c-form-1__text-2">Más de $10.000.000</span>
              </label>
            </div>
          </div>
        </div>
      </div>
      <div className="c-modal-1__item">
        <div className="c-modal-1__row">
          <div className="c-modal-1__col">
            <label className="o-form-label">Precio mínimo</label>
            <div className="c-modal-1__field">
              <input
                className="o-form-field--v2"
                id="preciomin"
                type="number"
                min="0"
                step="0.01"
              />
            </div>
          </div>
          <div className="c-modal-1__col">
            <label className="o-form-label">Precio máximo</label>
            <div className="c-modal-1__field">
              <input
                className="o-form-field--v2"
                id="preciomax"
                type="number"
                min="0"
                step="0.01"
              />
            </div>
          </div>
          <div className="c-modal-1__btn">
            <button className="o-btn-6" name="precio_write" onClick={filtrar}>
              <FontAwesomeIcon
                className="icon-arrow-right2"
                icon={faAngleRight}
              />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

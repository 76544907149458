import React from "react";
import { styled } from "@mui/styles";
import MuiGrid from "@mui/material/Grid";
import MuiInputLabel from "@mui/material/InputLabel";
import MuiTextField from "@mui/material/TextField";
import MuiDivider from "@mui/material/Divider";
import { Typography } from "@mui/material";
import { CreateMotoContext } from "../../../../../contexts/CreateMotoContext";

const Grid = styled(MuiGrid)({
  display: "flex",
  marginTop: 50,
  marginBottom: 20,
  justifyContent: "center",
});

const InputLabel = styled(MuiInputLabel)({
  marginRight: 10,
  alignSelf: "center",
});

const TextField = styled(MuiTextField)({
  width: "60%",
  marginRight: 10,
  "& > p": {
    fontSize: "1.2rem",
  },
});

const Divider = styled(MuiDivider)({
  width: "100%",
});

const Meta = () => {
  const jsonStyledTitleBlue = {
    paddingTop: "4px",
    marginBottom: "3px",
    fontSize: "20px",
    color: "#1a0dab",
    lineHeight: "1.3",
    marginTop: "23px",
    position: "absolute",
  };

  const { values, onChange } = React.useContext(CreateMotoContext);

  return (
    <Grid container style={{ margin: 0 }}>
      <Grid item xs={12}>
        <InputLabel htmlFor="input-meta-title">Meta Title</InputLabel>
        <TextField
          id="input-meta-title"
          name="seo_titulo"
          variant="outlined"
          style={{
            marginLeft: 45,
          }}
          maxLength={55}
          helperText="Máximo de caracteres recomendados: 55"
          value={values.seo_titulo}
          onChange={onChange}
        />
      </Grid>
      <Divider />
      <Grid item xs={12}>
        <InputLabel htmlFor="input-meta-description">
          Meta Description
        </InputLabel>
        <TextField
          id="input-meta-description"
          name="seo_descripcion"
          multiline
          rows={4}
          variant="outlined"
          fullWidth
          maxLength={160}
          helperText="Máximo de caracteres recomendados: 160"
          value={values.seo_descripcion}
          onChange={onChange}
        />
      </Grid>
      <Divider />
      <Grid item xs={12}>
        <InputLabel htmlFor="input-meta-slug">Slug (URL)</InputLabel>
        <TextField
          id="input-meta-slug"
          name="slug_moto"
          variant="outlined"
          fullWidth
          style={{
            marginLeft: 45,
          }}
          helperText="Escribir URL en minúsculas, sin signos de puntuación y separando palabras con guiones, ejemplo: pulsar-sn-125"
          value={values.slug_moto}
          onChange={onChange}
        />
      </Grid>
      <Divider />
      <Grid
        item
        xs={12}
        style={{
          maxWidth: "600px",
          marginTop: 18,
          display: "block",
        }}
      >
        <div>
          <Typography
            color={"GrayText"}
            fontSize={14}
            position="absolute"
            fontFamily="arial"
          >
            <span style={{ color: "#202124" }}>https://supre.com.co</span>{" "}
            <span> {"> catalogo-de-motos >"} </span>{" "}
            <span>
              {values.slug_moto !== "" && values.slug_moto !== null
                ? values.slug_moto
                : "  url-de-pagina"}
            </span>
          </Typography>
        </div>
        <div>
          <Typography style={jsonStyledTitleBlue} fontFamily="arial">
            <span>
              {values.seo_titulo !== "" && values.seo_titulo !== null
                ? values.seo_titulo + " - "
                : "Titulo de página | Incluir palabras claves -"}
            </span>
            <span> </span>
            <span> Supre</span>
          </Typography>
        </div>
        <div>
          <Typography
            color={"GrayText"}
            fontSize={14}
            marginTop={8}
            marginBottom={2}
            fontFamily="arial"
            maxWidth="600px"
          >
            <span>
              {values.seo_descripcion !== "" && values.seo_descripcion !== null
                ? values.seo_descripcion
                : "Descripción corta con palabras clave. La longitud ideal de un metadescripción es de 160 caracteres o menos para permanecer visible dentro de Google."}
            </span>
          </Typography>
        </div>
      </Grid>
    </Grid>
  );
};

export default Meta;

export const styledButton = {
  fontSize: 14.4,
  minHeight: 50,
  marginRight: 40,
};

export const styledCard = {
  background: "#f5f5f8",
  position: "fixed",
  bottom: 0,
  right: 0,
  width: "calc( 100% - 300px )",
  boxShadow: "0 0 10px rgb(0 0 0 / 18%)",
  padding: "40px 60px",
  margin: 0,
  zIndex: 3,
  height: 115,
};

export const styledLink = {
  outlined: "none",
  color: "#000",
};

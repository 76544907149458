import { makeStyles } from '@mui/styles';

export const Styles = {
  appBar_root: {
    backgroundColor: "rgb(255, 255, 255)",
    color: "#000",
    boxShadow: "none",
  },
  appBar_dark: {
    backgroundColor: "#1D2127",
    color: "#FFF",
    boxShadow: "none",
    marginTop:'62px'
  },
  toolBar_root: {
    display: "inline-block",
    padding: 13,
  },
  appBar_title: {
    fontSize: "16px",
    float: "left",
    marginTop: 10,
  },
  box_avatar: {
    float: "right",
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    fontSize: "13px",
  },
  avatar: { background: "#0088CC", marginRight: 1 },
  logout_icon: { fontSize: "19px", color: "#000", marginRight: 4 },
  img_supre: {
    height: "56px",
    padding: "11px",
    margin: "-6px -10px -3px 0px",
    width: "135px",
    top: "16px",
    position: "relative",
    left: "6px",
  },
  menu_icon_close: { color: "#FFF", fontSize: "24px" },
  HomeOutlinedIcon: { color: "#FFF", fontSize: "29px" },
  link2: { position: "relative", top: "-47px" },
  menus_config: { margin: "o auto", fontSize: 16, marginLeft: 24 },
};


export const useStyles = makeStyles(theme => ({
  HomeOutlinedIconClosed: {
    marginTop: '30px'
  }
}))
//para dejar fija la barra de los filtros
window.addEventListener("scroll", () => {
  let headercat = document.getElementById("header-catalogo");
  if (window.scrollY > 75) {
    if (headercat !== null) {
      headercat.classList.add("static");
      document.getElementById("CatMotos").classList.add("static2");
      document.getElementById("lista4").classList.add("static3");
    }
  } else {
    if (headercat !== null) {
      document.getElementById("header-catalogo").classList.remove("static");
      document.getElementById("CatMotos").classList.remove("static2");
      document.getElementById("lista4").classList.remove("static3");
    }
  }
});

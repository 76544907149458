import React, { useEffect, useState } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { getIpClient } from "../../../helpers/GetIp";
import { formatCurrency } from "../../../helpers/FormatMoney";
import { ReferenceGenerator } from "../../../helpers/ReferenceGenerator";
import "./style.css";
import {
  createPaymentSessionService,
  updateStatusSessionService,
} from "../../../utils/payment";
import { PaymentStatus } from "../../atoms/PaymentStatus";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";

export const FormPagaCuota = () => {
  const [columns, setColumns] = useState({
    ip_usuario: "",
    ordenid: "",
    usuario_id: null,
    transaccion_id: "",
    valorCuota: 0,
    operation: false,
    state: "",
    dateTransaction: "",
    total: 0,
    reference: "",
  });
  const [isDisabled, setIsDisabled] = useState(false);
  const [showMessage, setShowMessage] = useState(false);

  useEffect(() => {
    getIpClient().then((res) => {
      setColumns((prevState) => ({
        ...prevState,
        ip_usuario: res,
      }));
    });
  }, []);

  const PagaTuCuotaSchema = Yup.object().shape({
    cedula: Yup.string()
      .min(5, "Muy corto!")
      .required("Este campo es requerido"),
  });

  const onSubmit = async (values, { resetForm }) => {
    setShowMessage(false);
    setIsDisabled(true);
    try {
      const reference = await ReferenceGenerator();
      const dni = values.cedula;
      const ip = columns.ip_usuario;
      const value = parseInt(
        columns.valorCuota
          .replace(/\D/g, "")
          .replace(/\B(?=(\d{3})+(?!\d))/g, "")
      ).toString();

      createPaymentSessionService({
        value,
        ip,
        dni,
        reference,
      })
        .then((res) => {
          if (res) {
            const { status } = res.data;
            const { processUrl } = res.data;
            if (status && status.status !== "OK") {
              setShowMessage(true);
            }
            // eslint-disable-next-line no-undef
            P.init(processUrl);
            setIsDisabled(false);
          }
        })
        .catch((error) => {
          console.error("Error en createPaymentSessionService:", error);
          setShowMessage(true); // Esto se ejecutará cuando la petición falle
          setIsDisabled(false);
        });
    } catch (error) {
      console.error("Error en onSubmit:", error);
    }

    // eslint-disable-next-line no-undef
    P.on("response", async function (response) {
      if (response) {
        const {
          requestId,
          signature,
          reference,
          status: { date, status },
        } = response;

        const value = parseInt(
          columns.valorCuota
            .replace(/\D/g, "")
            .replace(/\B(?=(\d{3})+(?!\d))/g, "")
        ).toString();

        updateStatusSessionService({
          requestId,
          signature,
          status,
          date,
        });

        setColumns((prevState) => ({
          ...prevState,
          state: status,
          dateTransaction: date,
          total: value,
          reference: reference,
        }));
      }
      setIsDisabled(false);
      resetForm();
      setColumns((prevState) => ({
        ...prevState,
        valorCuota: 0,
      }));
    });
  };

  const handleValue = () => {
    setColumns((prevState) => ({
      ...prevState,
      valorCuota: document
        .getElementById("inputValorCuota")
        .value.replace(/\D/g, "")
        .replace(/\B(?=(\d{3})+(?!\d))/g, ""),
    }));
  };

  return (
    <Formik
      initialValues={{
        cedula: "",
        valor_cuota: "",
      }}
      onSubmit={onSubmit}
      validationSchema={PagaTuCuotaSchema}>
      {({ errors, touched }) => (
        <>
          <Form className="js-pagar-cuota" id="form-paga-cuota">
            <div className="c-form-1__field ">
              <label className="o-form-label">
                Número de cédula del titular del crédito
              </label>
              <Field className="o-form-field-pc" name="cedula" />
              {errors.cedula && touched.cedula && (
                <div className="js_error">{errors.cedula}</div>
              )}
            </div>
            <div className="c-form-1__field">
              <label className="o-form-label">Valor a pagar</label>
              <Field
                className="o-form-field-pc"
                id="inputValorCuota"
                type="text"
                name="valor_cuota"
                autoComplete="off"
                value={formatCurrency("es-CO", "COP", 0, columns.valorCuota)}
                onChange={handleValue}
              />
              {errors.valor_cuota && touched.valor_cuota && (
                <div className="js_error">{errors.valor_cuota}</div>
              )}
            </div>
            {columns.state && (
              <PaymentStatus
                status={columns.state}
                date={columns.dateTransaction}
                reference={columns.reference}
                total={columns.total}
              />
            )}
            <div className="o-btn-pc">
              <button
                className={"o-btn-3--v2"}
                type="submit"
                disabled={isDisabled}>
                Realizar pago
              </button>
            </div>
          </Form>
          {showMessage && (
            <div className="c-notify--alert_form-pago">
              <FontAwesomeIcon
                icon={faInfoCircle}
                className="icon_error_login"
              />
              <strong className="error_login_message">
                {"¡Ups! Parece que algo salió mal. Por favor, verifica el monto a pagar y asegúrate de que sea mayor a $50,000. ¡Gracias por tu comprensión!"}
              </strong>
            </div>
          )}
          <p style={{ textAlign: "center" }}>{`¿Tienes dudas?`}</p>
          <p style={{ textAlign: "center", marginTop: "-12px" }}>
            {`Conoce nuestras `}
            <a
              href="https://supre.com.co/preguntas-frecuentes/"
              target="_blank"
              rel="noopener noreferrer">
              Preguntas frecuentes
            </a>
          </p>
        </>
      )}
    </Formik>
  );
};

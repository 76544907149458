import React from "react";
import { Helmet } from "react-helmet";
import { Portada } from "../components/molecules/Portada";
import { RecoveryPasswordForm } from "../components/Organisms/Login/RecoveryPasswordForm";
import "../css/RecoveryPassword.css";

export const RecoveryPassword = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Recuperar contraseña - Supre</title>
        <meta name="description" content="Cambiar contraseña Supre" />
      </Helmet>
      <Portada className="c-block-3" id="Edit profile" Name="Ingresar" />
      <div className="c-block-4-rp">
        <div className="g-container-rp">
          <RecoveryPasswordForm />
        </div>
      </div>
    </>
  );
};

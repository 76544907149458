import React from "react";
import "../VisionMision/style.css";

export const VisionMision = ({ title, parragraph }) => {
  return (
    <>
      <h3 className="o-heading-4">{title}</h3>
      <p>{parragraph}</p>
    </>
  );
};

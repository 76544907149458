import React, { useContext, useState, useEffect } from "react";
import { CounterContext } from "../../../contexts/CounterContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import clsx from "clsx";
import { SearchSave } from "../../../utils/BusquedaGuiada";
import { GetFilterMotos } from "../../../utils/AllMotos";
import { getIpClient } from "../../../helpers/GetIp";
import GeneralData from "../../../data/GeneralData.js";
import "../BusquedaGuiadaModal/style.css";
//manejo de errores
const SearchSchema = Yup.object().shape({
  mail: Yup.string()
    .min(3, "Muy corto!")
    .email("Por favor, escribe una dirección de correo válida.")
    .required("Este campo es requerido"),
  name: Yup.string().min(3, "Muy corto!").required("Este campo es requerido"),
  check: Yup.boolean().oneOf([true], "Este campo es obligatorio."),
});

export const BusquedaGuiadaModal = () => {
  const {
    setEstilosBG,
    setPrecioMinBG,
    setPrecioMaxBG,
    setTransmisionBG,
    openBG,
    setOpenBG,
  } = useContext(CounterContext);
  const [ip, setIP] = useState("");

  const closeBG = () => {
    setOpenBG(false);
  };

  const handleSearch = () => {
    closeBG();
    if (filtroestilo || preciomin || preciomax || filtrotransmision) {
      setEstilosBG(filtroestilo);
      setPrecioMinBG(preciomin);
      setPrecioMaxBG(preciomax);
      setTransmisionBG(filtrotransmision);
    }
  };

  useEffect(() => {
    getIpClient().then((result) => {
      setIP(result);
    });
  }, []);

  // --------------------------------------------------------------------------------------------------------------
  //variables para el envio de los datos a la base de datos
  let nombre, email, estilo, presupuesto, transmision;
  //variables para aplicar al filtro de motos
  let filtroestilo, preciomin, preciomax, filtrotransmision;

  // cuando se presiona el boton de enviar
  const secondstep = () => {
    nombre = document.getElementById("nombre").value;
    email = document.getElementById("email").value;
    document.getElementById("step-2").classList.remove("o-is-hidden-2");
    document.getElementById("form1").classList.add("o-is-hidden-2");

    let time2 = () => {
      document.getElementById("msj2").classList.remove("o-is-hidden-2");
      document.getElementById("load2").classList.add("o-is-hidden-2");
      document.getElementById("btns-step2").classList.remove("o-is-hidden-2");
    };
    setTimeout(time2, 1000);

    // Asignacion de valores para poder hacer la peticion al back
    let numNick = document.getElementsByClassName("usserName");
    for (let j = 0; j < numNick.length; j++) {
      numNick[j].innerHTML = nombre;
    }
  };

  //cuando se presiona la botoneria de los estilos
  const thirdstep = (e) => {
    document.getElementById("step-3").classList.remove("o-is-hidden-2");
    document.getElementById("Title1").classList.add("o-is-hidden-2");
    document.getElementById(e.target.id).classList.add("checked");
    let btns = document.getElementsByClassName("o-btn-8");

    for (let i1 = 0; i1 < btns.length; i1++) {
      btns[i1].classList.add("o-is-hidden-2");
    }
    let time3 = () => {
      document.getElementById("load3").classList.add("o-is-hidden-2");
      document.getElementById("msj3").classList.remove("o-is-hidden-2");
      document.getElementById("btns-step3").classList.remove("o-is-hidden-2");
    };
    setTimeout(time3, 1000);
    // Asignacion de valores para poder hacer la peticion al back
    estilo = e.target.value;
    filtroestilo = e.target.getAttribute("data-estilos");
  };

  //cuando se presiona la botoneria de los precios
  const fourthstep = (e) => {
    document.getElementById("step-4").classList.remove("o-is-hidden-2");
    document.getElementById("Title2").classList.add("o-is-hidden-2");
    document.getElementById(e.target.id).classList.add("checked");
    let btns2 = document.getElementsByClassName("o-btn-8_S3");

    for (let i2 = 0; i2 < btns2.length; i2++) {
      btns2[i2].classList.add("o-is-hidden-2");
    }

    let time4 = () => {
      document.getElementById("load4").classList.add("o-is-hidden-2");
      document.getElementById("msj4").classList.remove("o-is-hidden-2");
      document.getElementById("btns-step4").classList.remove("o-is-hidden-2");
    };

    setTimeout(time4, 1000);

    // Asignacion de valores para poder hacer la peticion al back
    presupuesto = e.target.value;
    preciomin = e.target.getAttribute("precio-min");
    preciomax = e.target.getAttribute("precio-max");
  };

  //cuando se presiona la botoneria de la tranmision
  const finalstep = (e) => {
    document.getElementById(e.target.id).classList.add("checked");
    document.getElementById("Title3").classList.add("o-is-hidden-2");
    document.getElementById("step-final").classList.remove("o-is-hidden-2");
    let btns3 = document.getElementsByClassName("o-btn-8_S4");

    for (let i3 = 0; i3 < btns3.length; i3++) {
      btns3[i3].classList.add("o-is-hidden-2");
    }
    let finaltime = () => {
      document.getElementById("loadfinal").classList.add("o-is-hidden-2");
      document.getElementById("msjfinal").classList.remove("o-is-hidden-2");
      document.getElementById("btns-final").classList.remove("o-is-hidden-2");
    };
    setTimeout(finaltime, 1000);
    // Asignacion de valores para poder hacer la peticion al back
    transmision = e.target.value;
    filtrotransmision = e.target.getAttribute("data-transmision");

    // Para guardar la informacion en la base dedatos
    const saveinfo = async () => {
      SearchSave({ nombre, email, estilo, presupuesto, transmision, ip });
    };
    // Para realizar el filtro automatico de motos
    const filtro = async () => {
      GetFilterMotos(
        "''",
        "''",
        preciomin,
        preciomax,
        filtroestilo,
        "''",
        "''",
        filtrotransmision,
        "''",
        "''",
        "''",
        "''",
        "''",
        "''",
        "''",
        "precio:asc"
      ).then((result) => {
        document.getElementById(
          "count-motos-chatbot"
        ).innerHTML = `He encontrado ${result.data.length} motos que pueden interesarte.`;
      });
    };

    saveinfo();
    filtro();
  };

  return (
    <div
      className={clsx("c-modal-1", openBG && "open")}
      id="modal-busqueda-guiada"
    >
      <div className="c-modal-1__header">
        <div className="c-modal-1__header-bar">
          <div className="c-modal-1__header-title">Búsqueda guiada</div>
          <div className="c-modal-1__header-close">
            <button className="c-modal-1__close" onClick={closeBG}>
              <FontAwesomeIcon className="icon-cross2" icon={faXmark} />
              Cerrar
            </button>
          </div>
        </div>
      </div>
      <div className="c-modal-1__content ps" id="form-busqueda-guiada">
        <div className="c-modal-1__section-2 BG">
          <div className="c-chatbot">
            <div className="c-chatbot__item" id="step-1">
              <div className="c-chatbot__container">
                <div className="c-chatbot__question">
                  <div className="c-chatbot__photo">
                    <img
                      src={GeneralData.host + "images/supre-bot.png"}
                      alt="Chatbot"
                    />
                  </div>
                  <div className="c-chatbot__loading o-is-hidden-2" id="load1">
                    <img
                      src={GeneralData.host + "images/loading-message.svg"}
                      alt="Loading"
                    />
                  </div>
                  <div className="c-chatbot__msj" id="msj1">
                    <p>
                      <strong className="c-chatbot__text-2">¡Hola!</strong> 👋
                    </p>
                    <p>
                      ¡Mi nombre es Wily! Para ayudarte a encontrar tu moto
                      ideal, por favor dime tu nombre, dirección de correo
                      electrónico y acepta la política de privacidad de datos.
                    </p>
                  </div>
                </div>
                <Formik
                  initialValues={{
                    mail: "",
                    name: "",
                    check: false,
                  }}
                  validationSchema={SearchSchema}
                  onSubmit={secondstep}
                >
                  {({ errors, touched }) => (
                    <Form>
                      <div className="c-chatbot__box" id="form1">
                        <div className="c-chatbot__form">
                          <div className="c-modal-1__item">
                            <div className="c-modal-1__row">
                              <label className="o-form-label-bg">Nombre</label>
                              <div className="c-modal-1__field js-show-error">
                                <Field
                                  name="name"
                                  id="nombre"
                                  className="o-form-field--v2"
                                />
                                {errors.name && touched.name ? (
                                  <div className="js_error">{errors.name}</div>
                                ) : null}
                              </div>
                            </div>
                          </div>
                          <div className="c-modal-1__item">
                            <div className="c-modal-1__row">
                              <label className="o-form-label-bg">
                                Correo electrónico
                              </label>
                              <div className="c-modal-1__field js-show-error">
                                <Field
                                  name="mail"
                                  id="email"
                                  className="o-form-field--v2"
                                />
                                {errors.mail && touched.mail ? (
                                  <div className="js_error">{errors.mail}</div>
                                ) : null}
                              </div>
                            </div>
                          </div>
                          <div className="c-modal-1__item">
                            <div className="js-show-error">
                              <div className="o-form-check">
                                <Field
                                  name="check"
                                  id="check-1"
                                  type="checkbox"
                                  className="o-form-check__campo--v2"
                                />
                                <label htmlFor="check-1"></label>
                                <span className="c-form-1__text-1">
                                  Acepto política de privacidad de datos.
                                  <br />
                                  <a
                                    className="o-link-1"
                                    id="popupTerminos"
                                    href="/politicas/"
                                    target="_blank"
                                  >
                                    Ver detalle legal
                                  </a>
                                </span>
                              </div>
                              {errors.check && touched.check ? (
                                <div className="js_error">{errors.check}</div>
                              ) : null}
                            </div>
                          </div>
                          <div className="c-chatbot__btns">
                            <div className="c-chatbot__btns-box">
                              <div className="c-chatbot__btns-box-2">
                                <button
                                  className="o-btn-3--v2"
                                  id="enviar-oficial"
                                  type="submit"
                                >
                                  Enviar
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
            <div className="c-chatbot__item o-is-hidden-2" id="step-2">
              <div className="c-chatbot__container">
                <div className="c-chatbot__question">
                  <div className="c-chatbot__photo">
                    <img
                      src={GeneralData.host + "images/supre-bot.png"}
                      alt="Chatbot"
                    />
                  </div>
                  <div className="c-chatbot__loading" id="load2">
                    <img
                      src={GeneralData.host + "images/loading-message.svg"}
                      alt="Loading"
                    />
                  </div>
                  <div className="c-chatbot__msj o-is-hidden-2" id="msj2">
                    <p>
                      <strong>
                        Listo <span className="usserName"> </span>, ¡empecemos!
                      </strong>
                      <br />
                      ¿Para qué necesitas tu moto?
                    </p>
                  </div>
                </div>
                <div className="c-chatbot__box o-is-hidden-2" id="btns-step2">
                  <div className="c-chatbot__text-1" id="Title1">
                    *Selecciona una opción
                  </div>
                  <div className="c-chatbot__btns">
                    <div className="c-chatbot__btns-box">
                      <div className="c-chatbot__btns-box-2">
                        <button
                          className="o-btn-8"
                          id="CT"
                          value="Para recorrer la ciudad y evitar el trafico"
                          data-estilos="1,2"
                          onClick={thirdstep}
                        >
                          Para recorrer la ciudad y evitar el trafico
                        </button>
                        <button
                          className="o-btn-8"
                          id="TI"
                          value="Para trabajar en ella y generar ingresos"
                          data-estilos="1"
                          onClick={thirdstep}
                        >
                          Para trabajar en ella y generar ingresos
                        </button>
                        <button
                          className="o-btn-8"
                          id="CC"
                          value="Para recorrer los campos y trabajar fuerte"
                          data-estilos="3"
                          onClick={thirdstep}
                        >
                          Para recorrer los campos y trabajo fuerte
                        </button>
                        <button
                          className="o-btn-8"
                          id="FS"
                          value="Para salir los fines de semana"
                          data-estilos="4,5"
                          onClick={thirdstep}
                        >
                          Para salir los fines de semana
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="c-chatbot__item o-is-hidden-2" id="step-3">
              <div className="c-chatbot__container">
                <div className="c-chatbot__question">
                  <div className="c-chatbot__photo">
                    <img
                      src={GeneralData.host + "images/supre-bot.png"}
                      alt="Chatbot"
                    />
                  </div>
                  <div className="c-chatbot__loading" id="load3">
                    <img
                      src={GeneralData.host + "images/loading-message.svg"}
                      alt="Loading"
                    />
                  </div>
                  <div className="c-chatbot__msj o-is-hidden-2" id="msj3">
                    <p>¿Cuál es el presupuesto para tu moto?</p>
                  </div>
                </div>
                <div className="c-chatbot__box o-is-hidden-2" id="btns-step3">
                  <div className="c-chatbot__text-1" id="Title2">
                    *Selecciona una opción
                  </div>
                  <div className="c-chatbot__btns">
                    <div className="c-chatbot__btns-box">
                      <div className="c-chatbot__btns-box-2">
                        <button
                          className="o-btn-8_S3"
                          id=">5"
                          value="Hasta $5'000.000"
                          precio-min="''"
                          precio-max="5000000"
                          onClick={fourthstep}
                        >
                          Hasta $5'000.000
                        </button>
                        <button
                          className="o-btn-8_S3"
                          id="5-7.5"
                          value="Entre $5'000.000 y $7'500.000"
                          precio-min="5000000"
                          precio-max="7500000"
                          onClick={fourthstep}
                        >
                          Entre $5'000.000 y $7'500.000
                        </button>
                        <button
                          className="o-btn-8_S3"
                          id="7.5-10"
                          value="Entre $7'500.000 y $10'000.000"
                          precio-min="7500000"
                          precio-max="10000000"
                          onClick={fourthstep}
                        >
                          Entre $7'500.000 y $10'000.000
                        </button>
                        <button
                          className="o-btn-8_S3"
                          id=">10"
                          value="Más de $10'000.000"
                          precio-min="10000000"
                          precio-max="''"
                          onClick={fourthstep}
                        >
                          Más de $10'000.000
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="c-chatbot__item o-is-hidden-2" id="step-4">
              <div className="c-chatbot__container">
                <div className="c-chatbot__question">
                  <div className="c-chatbot__photo">
                    <img
                      src={GeneralData.host + "images/supre-bot.png"}
                      alt="Chatbot"
                    />
                  </div>
                  <div className="c-chatbot__loading" id="load4">
                    <img
                      src={GeneralData.host + "images/loading-message.svg"}
                      alt="Loading"
                    />
                  </div>
                  <div className="c-chatbot__msj o-is-hidden-2" id="msj4">
                    <p>¿Qué tipo de transmisión te gusta más?</p>
                  </div>
                </div>
                <div className="c-chatbot__box  o-is-hidden-2" id="btns-step4">
                  <div className="c-chatbot__text-1" id="Title3">
                    *Selecciona una opción
                  </div>
                  <div className="c-chatbot__btns">
                    <div className="c-chatbot__btns-box">
                      <div className="c-chatbot__btns-box-2">
                        <button
                          className="o-btn-8_S4"
                          id="Aut"
                          value="Automática"
                          data-transmision="1"
                          onClick={finalstep}
                        >
                          Automática
                        </button>
                        <button
                          className="o-btn-8_S4"
                          id="SemiAut"
                          value="Semiautomática"
                          data-transmision="2"
                          onClick={finalstep}
                        >
                          Semiautomática
                        </button>
                        <button
                          className="o-btn-8_S4"
                          id="Mec"
                          value="Mecánica"
                          data-transmision="3"
                          onClick={finalstep}
                        >
                          Mecánica
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="c-chatbot__item o-is-hidden-2" id="step-final">
              <div className="c-chatbot__container">
                <div className="c-chatbot__question">
                  <div className="c-chatbot__photo">
                    <img
                      src={GeneralData.host + "images/supre-bot.png"}
                      alt="Chatbot"
                    />
                  </div>
                  <div className="c-chatbot__loading" id="loadfinal">
                    <img
                      src={GeneralData.host + "images/loading-message.svg"}
                      alt="Loading"
                    />
                  </div>
                  <div className="c-chatbot__msj o-is-hidden-2" id="msjfinal">
                    <p>
                      <p>
                        ¡Listo <span className="usserName"> </span>!,
                      </p>
                      <span id="count-motos-chatbot"> </span>
                    </p>
                  </div>
                </div>
                <div className="c-chatbot__box o-is-hidden-2" id="btns-final">
                  <div className="c-chatbot__btns">
                    <div className="c-chatbot__btns-box">
                      <Link
                        className="o-btn-3 Mostrar"
                        to="/catalogo-de-motos/"
                        onClick={handleSearch}
                      >
                        Mostrar motos
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

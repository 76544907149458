import React from "react";
import { Link } from "react-router-dom";

export const Button = ({ className, link, name }) => {
  return (
    <>
      <Link to={link} className={className}>
        {name}
      </Link>
    </>
  );
};

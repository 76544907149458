import apiInstance from "../Api/ApiInstance";

export async function ContactSave(values) {
  const { nombres, apellidos, celular, email, asunto, mensaje } = values.values;
  const { ip_usuario, id_pqrs } = values;

  return await apiInstance.post("api/official-site/contactos", {
    nombres,
    apellidos,
    celular,
    email,
    asunto,
    mensaje,
    ip_usuario,
    id_pqrs,
  });
}

import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faXmarkSquare } from "@fortawesome/free-solid-svg-icons";
import { formatCurrency } from "../../../helpers/FormatMoney";
import GeneralData from "../../../data/GeneralData.js";

export const PrincipalTable = ({
  motos,
  listallmotos,
  setOpenSearch,
  history,
  includeComparador,
}) => {
  const openfiltros = () => {
    document.getElementById("barranav").classList.add("op");
    document.getElementById("ComMotos").classList.add("op");
    document.getElementById("containerglobal").classList.add("op");
    setOpenSearch(true);
  };

  const closemoto = (id) => {
    for (let i = 0; i < motos.length; i++) {
      //eslint-disable-next-line
      if (motos[i] == id) {
        motos.splice(i, 1);
      }
    }
    includeComparador();
    history.push(
      `/comparador-de-motos/${
        motos.length > 0 ? "?motos=" + motos.join(",") : ""
      }`
    );
  };
  return (
    <div className="c-list-4--comparador" id="header-comparador">
      {[0, 1, 2].map((element) => {
        if (motos[element] !== undefined && listallmotos.length > 0) {
          //eslint-disable-next-line
          let moto1 = listallmotos.find((moto) => moto.id == motos[element]);
          if (moto1 !== undefined) {
            return (
              <div className="c-list-4__item-2" key={element}>
                <div className="c-list-4__img img5">
                  <div className="c-list-4__icons-grid--v2">
                    <div
                      className="c-list4__close fullview"
                      onClick={() => closemoto(moto1?.id)}>
                      <FontAwesomeIcon
                        className="icon-cancel-square2"
                        icon={faXmarkSquare}
                      />
                    </div>
                  </div>
                  <img
                    src={`${GeneralData.host}${moto1?.url_foto}`}
                    className="lazyload"
                    alt=" "
                  />
                </div>
                <div className="c-list-4__box-5">
                  <div className="c-list-4__icons-grid--v2 closeview">
                    <div
                      className="c-list4__close"
                      onClick={() => closemoto(moto1?.id)}>
                      <FontAwesomeIcon
                        className="icon-cancel-square2"
                        icon={faXmarkSquare}
                      />
                    </div>
                  </div>
                  <div className="c-list-4__data">
                    <div className="c-list-4__name">{moto1?.nombre}</div>
                    <div className="c-list-4__brand">
                      {moto1?.MotosMarca.nombre_marca}
                    </div>
                  </div>
                  <div className="c-list-4__data">
                    <div className="c-list-4__price-2">
                      {" "}
                      {formatCurrency("es-CO", "COP", 0, moto1?.precio_moto)}
                    </div>
                    <div className="c-list-4__text-1">IVA incluido</div>
                  </div>
                </div>
              </div>
            );
          }
        }
        return (
          <div
            className="c-list-4__item-2--vacio"
            href="/detalle-moto.html"
            id="aggcomparador"
            key={element}>
            <button
              to="linkmoto"
              className="o-btn-3 c-list-4__btn-add js-open-filtros"
              onClick={openfiltros}>
              <FontAwesomeIcon className="icon-plus2" icon={faPlus} />
              <span id="aggmoto">Agregar moto</span>
            </button>
          </div>
        );
      })}
    </div>
  );
};

import { Card, Grid } from "@mui/material";
import React from "react";
import MuiTab from "@mui/material/Tab";
import { styled } from "@mui/styles";
import MuiTabs from "@mui/material/Tabs";
import { Motor } from "./Motor";
import Dimentions from "./Dimentions";
import Security from "./Security";
import Aditionals from "./Aditionals";
import { ListFilterTransmition } from "../../../../utils/Transmition";
import { ListBackBrakesFilter } from "../../../../utils/BackBrake";
import { ListFrontFilter } from "../../../../utils/FrontBrake";
import { ListSuspensionDelanteraFilter } from "../../../../utils/SuspensionDelantera";
import { ListSuspensionTraserFilter } from "../../../../utils/SuspensionTrasera";
import { ListArranqueFilter } from "../../../../utils/Arranque";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && <>{children}</>}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

const Tabs = styled(MuiTabs)({
  borderRight: 1,
  borderColor: "divider",
  backgroundColor: "#f9f9f9",
  minWidth: 244,
});

const Tab = styled(MuiTab)({
  fontSize: "1.2rem",
  fontWeight: "bold",
  color: "#000",
  textAlign: "left",
});

const DetailsMoto = () => {
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const [transmitions, setTransmition] = React.useState([]);
  const [backBrakes, setBackBrakes] = React.useState([]);
  const [frontBrakes, setFrontBrakes] = React.useState([]);
  const [suspensionDelantera, setSuspensionDelantera] = React.useState([]);
  const [suspensionTrasera, setSuspensionTrasera] = React.useState([]);
  const [arranque, setArranque] = React.useState([]);

  React.useEffect(() => {
    ListFilterTransmition()
      .then((response) => {
        setTransmition(response.data);
      })
      .catch((error) => {
        console.log(error);
      });

    ListBackBrakesFilter()
      .then((response) => {
        setBackBrakes(response.data);
      })
      .catch((error) => {
        console.log(error);
      });

    ListFrontFilter()
      .then((response) => {
        setFrontBrakes(response.data);
      })
      .catch((error) => {
        console.log(error);
      });

    ListSuspensionDelanteraFilter()
      .then((response) => {
        setSuspensionDelantera(response.data);
      })
      .catch((error) => {
        console.log(error);
      });

    ListSuspensionTraserFilter()
      .then((response) => {
        setSuspensionTrasera(response.data);
      })
      .catch((error) => {
        console.log(error);
      });

    ListArranqueFilter()
      .then((response) => {
        setArranque(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <Card>
      <Grid container style={{ height: 760 }}>
        <Tabs
          orientation="vertical"
          variant="scrollable"
          value={value}
          onChange={handleChange}
          textColor="inherit"
        >
          <Tab label="Motor" {...a11yProps(0)} />

          <Tab label="Dimensiones" {...a11yProps(1)} />
          <Tab label="Seguridad" {...a11yProps(2)} />
          <Tab label="Características adicionales" {...a11yProps(3)} />
        </Tabs>
        <div style={{ flex: 1, height: "100%" }}>
          <TabPanel value={value} index={0}>
            <Motor transmitions={transmitions} />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <Dimentions />
          </TabPanel>
          <TabPanel value={value} index={2}>
            <Security
              backBrakes={backBrakes}
              frontBrakes={frontBrakes}
              suspensionDelantera={suspensionDelantera}
              suspensionTrasera={suspensionTrasera}
            />
          </TabPanel>
          <TabPanel value={value} index={3}>
            <Aditionals arranque={arranque} />
          </TabPanel>
        </div>
      </Grid>
    </Card>
  );
};

export default DetailsMoto;

import React from 'react';
import { DataGridPro, GridActionsCellItem, esES } from '@mui/x-data-grid-pro';
import { Button, Typography } from '@mui/material';
import { Link, useHistory } from 'react-router-dom';
import FormatColorFillOutlinedIcon from '@mui/icons-material/FormatColorFillOutlined';
import ModeEditOutlinedIcon from '@mui/icons-material/ModeEditOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import GeneralData from '../../../../data/GeneralData.js';

const CatalogueTable = ({ motos, DeleteCatalogue }) => {
  const history = useHistory();
  const columns = [
    {
      field: 'url_foto',
      headerName: 'Imagen',
      flex: 1,
      renderCell: (params) => (
        <img
          src={GeneralData.host + '/images' + params.row.url_foto}
          alt={params.row.nombre}
          style={{
            width: '70px',
            height: '47px',
          }}
        />
      ),
    },
    {
      field: 'nombre',
      headerName: 'Nombre moto',
      flex: 1,
    },
    {
      field: 'precio_moto',
      headerName: 'Precio',
      flex: 1,
    },
    {
      field: 'galeria',
      headerName: 'Galería fotos',
      flex: 1,
      renderCell: (params) => (
        <Link
          to={'galeria/' + params.row.id}
          style={{
            outline: 'none',
            color: '#1976d2 ',
          }}
        >
          <FormatColorFillOutlinedIcon /> &nbsp; Editar galería
        </Link>
      ),
    },
    {
      field: 'colores',
      headerName: 'Colores',
      flex: 1,
      renderCell: (params) => (
        <Link
          to={'colores/' + params.row.id} // no es necesario poner nuevamente admin ya que la primera ruta es admin
          style={{
            outline: 'none',
            color: '#1976d2 ',
          }}
        >
          <FormatColorFillOutlinedIcon /> &nbsp; Editar colores
        </Link>
      ),
    },
    {
      field: 'mostrar_ocultar',
      headerName: 'Estado',
      flex: 1,
      renderCell: (params) =>
        params.row.mostrar_ocultar ? (
          <Typography
            style={{
              color: '#28a745',
              fontSize: 15,
              fontWeight: 700,
            }}
          >
            Activo
          </Typography>
        ) : (
          <Typography
            style={{
              color: 'red',
              fontSize: 15,
              fontWeight: 700,
            }}
          >
            Inactivo
          </Typography>
        ),
    },
    {
      field: 'canShowInOmnia',
      headerName: 'Estado en Omnia',
      flex: 1,
      renderCell: (params) =>
        params.row.canShowInOmnia ? (
          <Typography
            style={{
              color: '#28a745',
              fontSize: 15,
              fontWeight: 700,
            }}
          >
            Activo
          </Typography>
        ) : (
          <Typography
            style={{
              color: 'red',
              fontSize: 15,
              fontWeight: 700,
            }}
          >
            Inactivo
          </Typography>
        ),
    },
    {
      field: 'actions',
      headerName: 'Acciones',
      type: 'actions',
      getActions: (data) => [
        <GridActionsCellItem
          icon={<RemoveRedEyeOutlinedIcon style={{ fontSize: 20 }} />}
          label="Edit"
          onClick={(e) => {
            e.stopPropagation();
            window.open(`/catalogo-de-motos/product/${data.row.slug_moto}/`);
          }}
        />,
        <GridActionsCellItem
          icon={<ModeEditOutlinedIcon style={{ fontSize: 20 }} />}
          label="Edit"
          onClick={(e) => {
            e.stopPropagation();
            //   EditRowSelected(data?.row);
            history.push(`/admin/motos/editar-moto/${data.id}/`, {
              data: data.row,
            });
          }}
        />,
        <GridActionsCellItem
          icon={<DeleteOutlineOutlinedIcon style={{ fontSize: 20 }} />}
          label="Delete"
          onClick={(e) => {
            e.stopPropagation();
            DeleteCatalogue(data?.row.id);
          }}
        />,
      ],
    },
  ];
  return (
    <div style={{ height: 672, marginTop: 12 }}>
      <Button
        variant="contained"
        // onClick={addRow}
        style={{ width: 200, height: 40, marginBottom: 20, fontSize: 13 }}
      >
        <Link
          style={{ outline: 'none', color: '#FFF' }}
          to="/admin/motos/crear-moto/"
        >
          Agregar moto
        </Link>
      </Button>
      <DataGridPro
        rows={motos}
        columns={columns}
        localeText={esES.components.MuiDataGrid.defaultProps.localeText}
        pageSize={15}
        pagination
        style={{ fontSize: 15 }}
      />
    </div>
  );
};

export default CatalogueTable;

import apiInstance from "../Api/ApiInstance";

export async function Login(values) {
  const { email, password } = values;
  return await apiInstance.post("api/official-site/auth", { email, password });
}

export async function ApiLoginAdmin(values) {
  const { email, password } = values;
  return await apiInstance.post("api/official-site/auth/admin", { email, password });
}

import { Grid } from "@mui/material";
import React from "react";
import { formatCurrency } from "../../../helpers/FormatMoney";

import "./styles.css";

export const PaymentStatus = ({ status, date, total, reference }) => {
  let backgroundColor, statusText;
  switch (status.toUpperCase()) {
    case "APPROVED":
      backgroundColor = "green";
      statusText = "Aprobado";
      break;
    case "PENDING":
      backgroundColor = "orange";
      statusText = "Pendiente";
      break;
    case "REJECTED":
      backgroundColor = "red";
      statusText = "Rechazado";
      break;
    default:
      backgroundColor = "blue";
      statusText = status;
      break;
  }
  return (
    <>
      <div className="c-table-2__header">
        Estado transacción
      </div>
      <Grid className="status-container">
        <div>
          <p className="text-status">Estado:</p>
        </div>
        <div
          style={{
            backgroundColor: backgroundColor,
          }}
          className="type-status"
        >
          {statusText}
        </div>
      </Grid>

      <div>
        <div className="detail-container ">
          <p className="text-detail-value">Valor:</p>
          <p style={{ marginTop: "-6px" }}>
            {formatCurrency("es-CO", "COP", 0, total)} COP
          </p>
        </div>
        <div className="detail-container ">
          <p className="detail-text">Fecha:</p>
          <p style={{ marginTop: "-14px" }}>
            {new Date(date).toLocaleString()}
          </p>
        </div>
        <div className="detail-container ">
          <p className="detail-text">Referencia:</p>
          <p className="text-detail-reference">{reference}</p>
        </div>
      </div>
    </>
  );
};

import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowsUpDown,
  faAngleDown,
  faAngleUp,
} from "@fortawesome/free-solid-svg-icons";
import clsx from "clsx";

export const SubMenuNavCat = ({
  menus,
  FiltrarPrecioMayor,
  FiltrarPrecioMenor,
  FiltrarCilindrajeMenor,
  FiltrarCilindrajeMayor,
  classes,
  setMenus,
}) => {
  const handleMenuOpen = () => {
    setMenus((prevState) => ({
      ...prevState,
      submenuordenar: !menus.submenuordenar,
    }));
    if (menus.angleicon === faAngleDown) {
      setMenus((prevState) => ({
        ...prevState,
        angleicon: faAngleUp,
      }));
    } else {
      setMenus((prevState) => ({
        ...prevState,
        angleicon: faAngleDown,
      }));
    }
  };

  const handlePrecioMayor = () => {
    setMenus((prevState) => ({
      ...prevState,
      submenuordenar: !menus.submenuordenar,
    }));
    FiltrarPrecioMayor();
  };

  const handlePrecioMenor = () => {
    setMenus((prevState) => ({
      ...prevState,
      submenuordenar: !menus.submenuordenar,
    }));
    FiltrarPrecioMenor();
  };

  const handleCilindrajeMayor = () => {
    setMenus((prevState) => ({
      ...prevState,
      submenuordenar: !menus.submenuordenar,
    }));
    FiltrarCilindrajeMayor();
  };

  const handleCilindrajeMenor = () => {
    setMenus((prevState) => ({
      ...prevState,
      submenuordenar: !menus.submenuordenar,
    }));
    FiltrarCilindrajeMenor();
  };

  return (
    <div className={classes[0].class1}>
      <div className={classes[0].class2} onClick={handleMenuOpen}>
        <FontAwesomeIcon className="c-nav-5__btn-icon" icon={faArrowsUpDown} />
        Ordenar por:
        <span className="c-nav-5__btn-text2">
          <span className="js-selected-order">{menus.nameorden}</span>
          <FontAwesomeIcon
            className="icon-arrow-down3"
            icon={menus.angleicon}
          />
        </span>
      </div>
      <ul
        className={clsx(classes[0].class3, menus.submenuordenar && "open")}
        id={classes[0].class4}
      >
        <li>
          <div onClick={handlePrecioMayor} className="submenu_orden">
            {" "}
            Precio más alto{" "}
          </div>
        </li>
        <li>
          <div onClick={handlePrecioMenor} className="submenu_orden">
            {" "}
            Precio más bajo{" "}
          </div>
        </li>
        <li>
          <div onClick={handleCilindrajeMayor} className="submenu_orden">
            {" "}
            Cilindrada más alta{" "}
          </div>
        </li>
        <li>
          <div onClick={handleCilindrajeMenor} className="submenu_orden">
            {" "}
            Cilindrada más baja{" "}
          </div>
        </li>
      </ul>
    </div>
  );
};
